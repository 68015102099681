/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CalendarBaseService } from '../calendar-base-service';
import { CalendarApiConfiguration } from '../calendar-api-configuration';
import { CalendarStrictHttpResponse } from '../calendar-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PatientOnboardingModel } from '../models/patient-onboarding-model';

@Injectable({
  providedIn: 'root',
})
export class CalendarPatientOnboardingService extends CalendarBaseService {
  constructor(
    config: CalendarApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation patientOnboardingIdGet
   */
  static readonly PatientOnboardingIdGetPath = '/PatientOnboarding/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientOnboardingIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientOnboardingIdGet$Response(params: {
    id: string;
  }): Observable<CalendarStrictHttpResponse<PatientOnboardingModel>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarPatientOnboardingService.PatientOnboardingIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<PatientOnboardingModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patientOnboardingIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientOnboardingIdGet(params: {
    id: string;
  }): Observable<PatientOnboardingModel> {

    return this.patientOnboardingIdGet$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<PatientOnboardingModel>) => r.body as PatientOnboardingModel)
    );
  }

  /**
   * Path part for operation patientOnboardingSearchIdNumberGet
   */
  static readonly PatientOnboardingSearchIdNumberGetPath = '/PatientOnboarding/search/{idNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientOnboardingSearchIdNumberGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientOnboardingSearchIdNumberGet$Response(params: {
    idNumber: string;
  }): Observable<CalendarStrictHttpResponse<PatientOnboardingModel>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarPatientOnboardingService.PatientOnboardingSearchIdNumberGetPath, 'get');
    if (params) {
      rb.path('idNumber', params.idNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<PatientOnboardingModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patientOnboardingSearchIdNumberGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientOnboardingSearchIdNumberGet(params: {
    idNumber: string;
  }): Observable<PatientOnboardingModel> {

    return this.patientOnboardingSearchIdNumberGet$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<PatientOnboardingModel>) => r.body as PatientOnboardingModel)
    );
  }

  /**
   * Path part for operation patientOnboardingPost
   */
  static readonly PatientOnboardingPostPath = '/PatientOnboarding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientOnboardingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patientOnboardingPost$Response(params?: {
    body?: PatientOnboardingModel
  }): Observable<CalendarStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarPatientOnboardingService.PatientOnboardingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patientOnboardingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patientOnboardingPost(params?: {
    body?: PatientOnboardingModel
  }): Observable<string> {

    return this.patientOnboardingPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<string>) => r.body as string)
    );
  }

}
