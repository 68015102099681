/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HealthFindrBaseService } from '../health-findr-base-service';
import { HealthFindrApiConfiguration } from '../health-findr-api-configuration';
import { HealthFindrStrictHttpResponse } from '../health-findr-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommunicationTemplateModel } from '../models/communication-template-model';
import { CommunicationTemplateSearchRequestModel } from '../models/communication-template-search-request-model';
import { CommunicationTemplateSearchResultModel } from '../models/communication-template-search-result-model';

@Injectable({
  providedIn: 'root',
})
export class HealthFindrCommunicationTemplateService extends HealthFindrBaseService {
  constructor(
    config: HealthFindrApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation communicationTemplateSearchPost
   */
  static readonly CommunicationTemplateSearchPostPath = '/CommunicationTemplate/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `communicationTemplateSearchPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  communicationTemplateSearchPost$Response(params?: {
    body?: CommunicationTemplateSearchRequestModel
  }): Observable<HealthFindrStrictHttpResponse<Array<CommunicationTemplateSearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrCommunicationTemplateService.CommunicationTemplateSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<Array<CommunicationTemplateSearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `communicationTemplateSearchPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  communicationTemplateSearchPost(params?: {
    body?: CommunicationTemplateSearchRequestModel
  }): Observable<Array<CommunicationTemplateSearchResultModel>> {

    return this.communicationTemplateSearchPost$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<CommunicationTemplateSearchResultModel>>) => r.body as Array<CommunicationTemplateSearchResultModel>)
    );
  }

  /**
   * Path part for operation communicationTemplateIdGet
   */
  static readonly CommunicationTemplateIdGetPath = '/CommunicationTemplate/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `communicationTemplateIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  communicationTemplateIdGet$Response(params: {
    id: string;
  }): Observable<HealthFindrStrictHttpResponse<CommunicationTemplateModel>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrCommunicationTemplateService.CommunicationTemplateIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<CommunicationTemplateModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `communicationTemplateIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  communicationTemplateIdGet(params: {
    id: string;
  }): Observable<CommunicationTemplateModel> {

    return this.communicationTemplateIdGet$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<CommunicationTemplateModel>) => r.body as CommunicationTemplateModel)
    );
  }

  /**
   * Path part for operation communicationTemplateIdDelete
   */
  static readonly CommunicationTemplateIdDeletePath = '/CommunicationTemplate/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `communicationTemplateIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  communicationTemplateIdDelete$Response(params: {
    id: string;
  }): Observable<HealthFindrStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrCommunicationTemplateService.CommunicationTemplateIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as HealthFindrStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `communicationTemplateIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  communicationTemplateIdDelete(params: {
    id: string;
  }): Observable<void> {

    return this.communicationTemplateIdDelete$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation communicationTemplateSavePost
   */
  static readonly CommunicationTemplateSavePostPath = '/CommunicationTemplate/Save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `communicationTemplateSavePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  communicationTemplateSavePost$Response(params?: {
    body?: CommunicationTemplateModel
  }): Observable<HealthFindrStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrCommunicationTemplateService.CommunicationTemplateSavePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `communicationTemplateSavePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  communicationTemplateSavePost(params?: {
    body?: CommunicationTemplateModel
  }): Observable<string> {

    return this.communicationTemplateSavePost$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation communicationTemplateGetEmailMacrosGet
   */
  static readonly CommunicationTemplateGetEmailMacrosGetPath = '/CommunicationTemplate/GetEmailMacros';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `communicationTemplateGetEmailMacrosGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  communicationTemplateGetEmailMacrosGet$Response(params?: {
  }): Observable<HealthFindrStrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrCommunicationTemplateService.CommunicationTemplateGetEmailMacrosGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `communicationTemplateGetEmailMacrosGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  communicationTemplateGetEmailMacrosGet(params?: {
  }): Observable<Array<string>> {

    return this.communicationTemplateGetEmailMacrosGet$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

}
