/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LocationPhysicalTypeModel } from '../models/location-physical-type-model';
import { LocationPhysicalTypeModelSearchResultModel } from '../models/location-physical-type-model-search-result-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MDILocationPhysicalTypeService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation locationPhysicalTypeSearchLocationPhysicalTypePost
   */
  static readonly LocationPhysicalTypeSearchLocationPhysicalTypePostPath = '/LocationPhysicalType/SearchLocationPhysicalType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationPhysicalTypeSearchLocationPhysicalTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  locationPhysicalTypeSearchLocationPhysicalTypePost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<LocationPhysicalTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDILocationPhysicalTypeService.LocationPhysicalTypeSearchLocationPhysicalTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<LocationPhysicalTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `locationPhysicalTypeSearchLocationPhysicalTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  locationPhysicalTypeSearchLocationPhysicalTypePost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<LocationPhysicalTypeModelSearchResultModel> {

    return this.locationPhysicalTypeSearchLocationPhysicalTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<LocationPhysicalTypeModelSearchResultModel>) => r.body as LocationPhysicalTypeModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationPhysicalTypeSearchLocationPhysicalTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  locationPhysicalTypeSearchLocationPhysicalTypePost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<LocationPhysicalTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDILocationPhysicalTypeService.LocationPhysicalTypeSearchLocationPhysicalTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<LocationPhysicalTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `locationPhysicalTypeSearchLocationPhysicalTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  locationPhysicalTypeSearchLocationPhysicalTypePost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<LocationPhysicalTypeModelSearchResultModel> {

    return this.locationPhysicalTypeSearchLocationPhysicalTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<LocationPhysicalTypeModelSearchResultModel>) => r.body as LocationPhysicalTypeModelSearchResultModel)
    );
  }

  /**
   * Path part for operation locationPhysicalTypeIdGet
   */
  static readonly LocationPhysicalTypeIdGetPath = '/LocationPhysicalType/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationPhysicalTypeIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationPhysicalTypeIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<LocationPhysicalTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDILocationPhysicalTypeService.LocationPhysicalTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<LocationPhysicalTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `locationPhysicalTypeIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationPhysicalTypeIdGet$Plain(params: {
    id: string;
  }): Observable<LocationPhysicalTypeModel> {

    return this.locationPhysicalTypeIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<LocationPhysicalTypeModel>) => r.body as LocationPhysicalTypeModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationPhysicalTypeIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationPhysicalTypeIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<LocationPhysicalTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDILocationPhysicalTypeService.LocationPhysicalTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<LocationPhysicalTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `locationPhysicalTypeIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationPhysicalTypeIdGet$Json(params: {
    id: string;
  }): Observable<LocationPhysicalTypeModel> {

    return this.locationPhysicalTypeIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<LocationPhysicalTypeModel>) => r.body as LocationPhysicalTypeModel)
    );
  }

  /**
   * Path part for operation locationPhysicalTypePost
   */
  static readonly LocationPhysicalTypePostPath = '/LocationPhysicalType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationPhysicalTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  locationPhysicalTypePost$Plain$Response(params?: {
    body?: LocationPhysicalTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDILocationPhysicalTypeService.LocationPhysicalTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `locationPhysicalTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  locationPhysicalTypePost$Plain(params?: {
    body?: LocationPhysicalTypeModel
  }): Observable<string> {

    return this.locationPhysicalTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationPhysicalTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  locationPhysicalTypePost$Json$Response(params?: {
    body?: LocationPhysicalTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDILocationPhysicalTypeService.LocationPhysicalTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `locationPhysicalTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  locationPhysicalTypePost$Json(params?: {
    body?: LocationPhysicalTypeModel
  }): Observable<string> {

    return this.locationPhysicalTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
