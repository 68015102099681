import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BookmarkAction, BookmarkKey } from './enums';
import { Bookmark, ContentBookmark } from './models';
import moment from 'moment';
import { SecureStorageService } from '../storage/secure-storage.service';

@Injectable({
	providedIn: 'root'
})
export class BookmarkService {
	private inProgress: BehaviorSubject<Bookmark[]> = new BehaviorSubject(
		new Array<Bookmark>()
	);
	private completed: BehaviorSubject<Bookmark[]> = new BehaviorSubject(
		new Array<Bookmark>()
	);
	private saved: BehaviorSubject<Bookmark[]> = new BehaviorSubject(
		new Array<Bookmark>()
	);

	public inProgress$: Observable<Bookmark[]> = this.inProgress.asObservable();
	public completed$: Observable<Bookmark[]> = this.completed.asObservable();
	public saved$: Observable<Bookmark[]> = this.saved.asObservable();

	constructor(private secureStorageService: SecureStorageService) {}

	public convertToBookmark: any = (
		content: ContentBookmark,
		progress = '0%'
	) => ({
		id: content.Id,
		progress,
		date: moment().toISOString(),
		imageUrl: content.ImageUrl
	});

	public async initializeAsync(): Promise<void> {
		await this.loadCompletedAsync();
		await this.loadInProgressAsync();
		await this.loadSavedAsync();
	}

	public async toggleBookmarkAsync(content: ContentBookmark): Promise<void> {
		await this.setContentBookmarkAsync(
			BookmarkKey.Saved,
			BookmarkAction.AddOrRemove,
			content
		);
	}

	public async setContentBookmarkAsync(
		key: BookmarkKey,
		action: BookmarkAction,
		content: ContentBookmark,
		progress = '0%'
	): Promise<void> {
		await this.setBookmarkAsync(
			key,
			action,
			this.convertToBookmark(content, progress)
		);
	}

	public isBookmarked$(id: string): Observable<boolean> {
		return this.saved$.pipe(
			map((bookmarks: Bookmark[]) =>
				bookmarks.some((message) => message.id === id)
			)
		);
	}

	private async setBookmarkAsync(
		key: BookmarkKey,
		action: BookmarkAction,
		bookmark: Bookmark
	) {
		let content = await this.getContentAsync(key);
		if (action === BookmarkAction.AddOrRemove) {
			action = content.some((c) => c.id === bookmark.id)
				? BookmarkAction.Remove
				: BookmarkAction.Add;
		}

		if (action === BookmarkAction.Add) {
			const exists = content.find((element) => element.id == bookmark.id);
			if (exists && exists.progress == bookmark.progress) {
				return;
			}

			content = content.filter((element) => element.id !== bookmark.id);
			content.push(bookmark);
		} else if (action === BookmarkAction.Remove) {
			content = content.filter((element) => element.id !== bookmark.id);
		}

		await this.secureStorageService.set(key, JSON.stringify(content));
		this.loadBookmark(key, content);
	}

	private async loadInProgressAsync() {
		this.inProgress.next(
			await this.getContentAsync(BookmarkKey.InProgress)
		);
	}
	private async loadCompletedAsync() {
		this.completed.next(await this.getContentAsync(BookmarkKey.Completed));
	}
	private async loadSavedAsync() {
		this.saved.next(await this.getContentAsync(BookmarkKey.Saved));
	}

	private async getContentAsync(key: BookmarkKey) {
		const content = JSON.parse(
			await this.secureStorageService.get(key)
		) as Bookmark[];

		if (content) return content;

		return [];
	}

	private loadBookmark(key: BookmarkKey, content: Bookmark[]): void {
		switch (key) {
			case BookmarkKey.Completed:
				this.completed.next(content);
				break;
			case BookmarkKey.InProgress:
				this.inProgress.next(content);
				break;
			case BookmarkKey.Saved:
				this.saved.next(content);
				break;
			default:
				break;
		}
	}
}
