import {
	Directive,
	DoCheck,
	Input,
	TemplateRef,
	ViewContainerRef
} from '@angular/core';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Directive({
	selector: '[layout]'
})
export class LayoutDirective implements DoCheck {
	private hasView = false;

	@Input() layout: string;

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private theme: ThemeService
	) {}

	ngDoCheck(): void {
		const condition = this.theme.layout == this.layout;

		if (condition && !this.hasView) {
			this.viewContainer.createEmbeddedView(this.templateRef);
			this.hasView = true;
		} else if (!condition && this.hasView) {
			this.viewContainer.clear();
			this.hasView = false;
		}
	}
}
