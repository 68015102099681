import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { ThemeService } from '../theme/theme.service';
import { BookmarkService } from '../bookmark/bookmark.service';
import { CountriesService } from '../countries/countries.service';
import { GrowService } from '../grow/grow.service';
import { LanguagesDisplayService } from '../languages-display/languages-display.service';
import { LocationService } from '../location/location.service';
import { SecureStorageService } from '../storage/secure-storage.service';
import { TitleHelperService } from '../title-helper/title-helper.service';
import { TranslatorService } from '../translate/translator.service';
import { NavigationEnd, Router } from '@angular/router';
import { HyperlinkService } from '../hyperlink/hyperlink.service';
import { ModalService } from '../modal/modal.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LanguageSpecificOverridesService } from '../language-specific-overrides/language-specific-overrides.service';
import { AuthService } from '../auth-service/auth.service';
import { RefreshService } from '../refresh/refresh.service';
import { BrowserService } from '../browser/browser.service';
import { DateTimeService } from '../date-time/date-time.service';
import { RegisteredUserProfileService } from '../sessions/registered-user-profile/registered-user-profile.service';

@Injectable({
	providedIn: 'root'
})
export class ServicesStartupService {
	private mainServicesLoaded: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	private userServicesLoaded: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	/**
	 * Observable for when core services are loaded
	 */
	public coreServicesLoaded$: Observable<boolean> = combineLatest([
		this.secureStorageService.loaded$,
		this.translatorService.loaded$
	]).pipe(
		map(([secureStorageLoaded, translatorLoaded]) => {
			return secureStorageLoaded && translatorLoaded;
		})
	);

	/**
	 * Observable for when main services are loaded
	 */
	public mainServicesLoaded$: Observable<boolean> =
		this.mainServicesLoaded.asObservable();

	/**
	 * Observable for when company services are loaded
	 */
	public userServicesLoaded$: Observable<boolean> =
		this.userServicesLoaded.asObservable();

	constructor(
		private themeService: ThemeService,
		private translatorService: TranslatorService,
		private countries: CountriesService,
		private bookmarkService: BookmarkService,
		private growService: GrowService,
		public alertController: AlertController,
		public titleHelperService: TitleHelperService,
		public languageDisplayService: LanguagesDisplayService,
		private locationService: LocationService,
		private hyperlinkService: HyperlinkService,
		private modalService: ModalService,
		private router: Router,
		private secureStorageService: SecureStorageService,
		private languageSpecificOverridesService: LanguageSpecificOverridesService,
		private authService: AuthService,
		private refreshService: RefreshService,
		private browserService: BrowserService,
		private dateTimeService: DateTimeService,
		private registeredUserProfileService: RegisteredUserProfileService
	) {}

	/**
	 * Registers needed Subscribers and kicks of the initialization of the apps services
	 */
	public async initializeAsync(): Promise<void> {
		this.registerSubscribers();

		await this.initializeCoreServicesAsync();
	}

	/**
	 * Subscribers used to trigger the different initialization steps of this service.
	 */
	private registerSubscribers(): void {
		this.coreServicesLoaded$.subscribe((coreLoaded) => {
			if (coreLoaded) {
				void this.initializeMainServicesAsync();
			}
		});

		combineLatest([this.mainServicesLoaded$]).subscribe(
			([mainServicesLoaded]) => {
				if (!mainServicesLoaded) {
					return;
				}
				void this.initializeUserServicesAsync();
			}
		);

		combineLatest([this.userServicesLoaded$, this.router.events]).subscribe(
			([userServicesLoaded, event]) => {
				if (!userServicesLoaded) return;

				if (event instanceof NavigationEnd) {
					const fromHyperlink = this.hyperlinkService.fromHyperlink;
					this.titleHelperService.setPageTitle();
					if (!fromHyperlink)
						void this.modalService.closeModalsAsync();
					this.hyperlinkService.fromHyperlink = false;
				}
			}
		);
	}

	/**
	 * This are there core services needed for the application to startup
	 * and should only contain services that are needed asap or to startup
	 * services that are needed by the main initialization process.
	 */
	private async initializeCoreServicesAsync(): Promise<void> {
		//await this.locationService.initializeAsync();

		if (Capacitor.getPlatform() != 'web') {
			await this.browserService.initializeAsync();
		}

		await this.secureStorageService.initializeAsync();

		await this.translatorService.initializeAsync().then(() => {
			this.languageDisplayService.initialize();
			this.languageSpecificOverridesService.initialize();
			this.registeredUserProfileService.initialize();
		});

		await this.themeService.initializeAsync();

		this.dateTimeService.initialize();
	}

	/**
	 * These are the main services needed to startup before the app is allowed
	 * to route into itself and usually contain services that can be started without
	 * the need for user data.
	 */
	private async initializeMainServicesAsync(): Promise<void> {
		this.authService.initialize();
		await this.refreshService.initializeAsync();
		this.mainServicesLoaded.next(true);
	}

	/**
	 * The final initialization of services that are used by different parts
	 * of the app and are generally loaded by environment determined by the
	 * logged in user.
	 */
	private async initializeUserServicesAsync(): Promise<void> {
		this.countries.initialize();
		this.growService.initialize();
		await this.bookmarkService.initializeAsync();
		this.userServicesLoaded.next(true);
	}
}
