/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EnumTypeModel } from '../models/enum-type-model';
import { KeyListModel } from '../models/key-list-model';
import { KeyValueCodeModel } from '../models/key-value-code-model';
import { KeyValueModel } from '../models/key-value-model';
import { PublicHolidayListModel } from '../models/public-holiday-list-model';
import { QualificationKeyValueCodeModel } from '../models/qualification-key-value-code-model';
import { RelationshipTypeKeyValueModel } from '../models/relationship-type-key-value-model';
import { TimeZoneModel } from '../models/time-zone-model';

@Injectable({
  providedIn: 'root',
})
export class MDIMasterDataService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation masterDataSystemIdentifiersGet
   */
  static readonly MasterDataSystemIdentifiersGetPath = '/MasterData/SystemIdentifiers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataSystemIdentifiersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSystemIdentifiersGet$Plain$Response(params?: {
    countryISOCode?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataSystemIdentifiersGetPath, 'get');
    if (params) {
      rb.query('countryISOCode', params.countryISOCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataSystemIdentifiersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSystemIdentifiersGet$Plain(params?: {
    countryISOCode?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataSystemIdentifiersGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataSystemIdentifiersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSystemIdentifiersGet$Json$Response(params?: {
    countryISOCode?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataSystemIdentifiersGetPath, 'get');
    if (params) {
      rb.query('countryISOCode', params.countryISOCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataSystemIdentifiersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSystemIdentifiersGet$Json(params?: {
    countryISOCode?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataSystemIdentifiersGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataSystemIdentifierLookupByKeyPost
   */
  static readonly MasterDataSystemIdentifierLookupByKeyPostPath = '/MasterData/SystemIdentifierLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataSystemIdentifierLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataSystemIdentifierLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataSystemIdentifierLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataSystemIdentifierLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataSystemIdentifierLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataSystemIdentifierLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataSystemIdentifierLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataSystemIdentifierLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataSystemIdentifierLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataSystemIdentifierLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataSystemIdentifierLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataSystemIdentifierLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataSystemIdentifierLookupByValueGet
   */
  static readonly MasterDataSystemIdentifierLookupByValueGetPath = '/MasterData/SystemIdentifierLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataSystemIdentifierLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSystemIdentifierLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    countryISOCode?: string;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataSystemIdentifierLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('countryISOCode', params.countryISOCode, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataSystemIdentifierLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSystemIdentifierLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    countryISOCode?: string;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataSystemIdentifierLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataSystemIdentifierLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSystemIdentifierLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    countryISOCode?: string;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataSystemIdentifierLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('countryISOCode', params.countryISOCode, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataSystemIdentifierLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSystemIdentifierLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    countryISOCode?: string;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataSystemIdentifierLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataTimeZonesGet
   */
  static readonly MasterDataTimeZonesGetPath = '/MasterData/TimeZones';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataTimeZonesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataTimeZonesGet$Plain$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<TimeZoneModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataTimeZonesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<TimeZoneModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataTimeZonesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataTimeZonesGet$Plain(params?: {
  }): Observable<Array<TimeZoneModel>> {

    return this.masterDataTimeZonesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<TimeZoneModel>>) => r.body as Array<TimeZoneModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataTimeZonesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataTimeZonesGet$Json$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<TimeZoneModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataTimeZonesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<TimeZoneModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataTimeZonesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataTimeZonesGet$Json(params?: {
  }): Observable<Array<TimeZoneModel>> {

    return this.masterDataTimeZonesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<TimeZoneModel>>) => r.body as Array<TimeZoneModel>)
    );
  }

  /**
   * Path part for operation masterDataTitlesGet
   */
  static readonly MasterDataTitlesGetPath = '/MasterData/Titles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataTitlesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataTitlesGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataTitlesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataTitlesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataTitlesGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataTitlesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataTitlesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataTitlesGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataTitlesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataTitlesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataTitlesGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataTitlesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataTitleLookupByKeyPost
   */
  static readonly MasterDataTitleLookupByKeyPostPath = '/MasterData/TitleLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataTitleLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataTitleLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataTitleLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataTitleLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataTitleLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataTitleLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataTitleLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataTitleLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataTitleLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataTitleLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataTitleLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataTitleLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataTitleLookupByValueGet
   */
  static readonly MasterDataTitleLookupByValueGetPath = '/MasterData/TitleLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataTitleLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataTitleLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataTitleLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataTitleLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataTitleLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataTitleLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataTitleLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataTitleLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataTitleLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataTitleLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataTitleLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataTitleLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataContactPointTypesGet
   */
  static readonly MasterDataContactPointTypesGetPath = '/MasterData/ContactPointTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataContactPointTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataContactPointTypesGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataContactPointTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataContactPointTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataContactPointTypesGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataContactPointTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataContactPointTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataContactPointTypesGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataContactPointTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataContactPointTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataContactPointTypesGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataContactPointTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataContactPointTypeLookupByKeyPost
   */
  static readonly MasterDataContactPointTypeLookupByKeyPostPath = '/MasterData/ContactPointTypeLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataContactPointTypeLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataContactPointTypeLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataContactPointTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataContactPointTypeLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataContactPointTypeLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataContactPointTypeLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataContactPointTypeLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataContactPointTypeLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataContactPointTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataContactPointTypeLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataContactPointTypeLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataContactPointTypeLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataContactPointTypeLookupByValueGet
   */
  static readonly MasterDataContactPointTypeLookupByValueGetPath = '/MasterData/ContactPointTypeLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataContactPointTypeLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataContactPointTypeLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataContactPointTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataContactPointTypeLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataContactPointTypeLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataContactPointTypeLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataContactPointTypeLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataContactPointTypeLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataContactPointTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataContactPointTypeLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataContactPointTypeLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataContactPointTypeLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataCountriesGet
   */
  static readonly MasterDataCountriesGetPath = '/MasterData/Countries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataCountriesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataCountriesGet$Plain$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataCountriesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataCountriesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataCountriesGet$Plain(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataCountriesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataCountriesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataCountriesGet$Json$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataCountriesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataCountriesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataCountriesGet$Json(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataCountriesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataCountryLookupByKeyPost
   */
  static readonly MasterDataCountryLookupByKeyPostPath = '/MasterData/CountryLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataCountryLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataCountryLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataCountryLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataCountryLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataCountryLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataCountryLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataCountryLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataCountryLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataCountryLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataCountryLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataCountryLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataCountryLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataCountryLookupByValueGet
   */
  static readonly MasterDataCountryLookupByValueGetPath = '/MasterData/CountryLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataCountryLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataCountryLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataCountryLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataCountryLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataCountryLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataCountryLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataCountryLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataCountryLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataCountryLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataCountryLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataCountryLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataCountryLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataEducationSubFieldsGet
   */
  static readonly MasterDataEducationSubFieldsGetPath = '/MasterData/EducationSubFields';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataEducationSubFieldsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataEducationSubFieldsGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataEducationSubFieldsGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataEducationSubFieldsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataEducationSubFieldsGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataEducationSubFieldsGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataEducationSubFieldsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataEducationSubFieldsGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataEducationSubFieldsGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataEducationSubFieldsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataEducationSubFieldsGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataEducationSubFieldsGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataEducationSubFieldLookupByKeyPost
   */
  static readonly MasterDataEducationSubFieldLookupByKeyPostPath = '/MasterData/EducationSubFieldLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataEducationSubFieldLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataEducationSubFieldLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataEducationSubFieldLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataEducationSubFieldLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataEducationSubFieldLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataEducationSubFieldLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataEducationSubFieldLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataEducationSubFieldLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataEducationSubFieldLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataEducationSubFieldLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataEducationSubFieldLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataEducationSubFieldLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataEducationSubFieldLookupByValueGet
   */
  static readonly MasterDataEducationSubFieldLookupByValueGetPath = '/MasterData/EducationSubFieldLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataEducationSubFieldLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataEducationSubFieldLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataEducationSubFieldLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataEducationSubFieldLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataEducationSubFieldLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataEducationSubFieldLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataEducationSubFieldLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataEducationSubFieldLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataEducationSubFieldLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataEducationSubFieldLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataEducationSubFieldLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataEducationSubFieldLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataLanguageTypesGet
   */
  static readonly MasterDataLanguageTypesGetPath = '/MasterData/LanguageTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLanguageTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLanguageTypesGet$Plain$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLanguageTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLanguageTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLanguageTypesGet$Plain(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataLanguageTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLanguageTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLanguageTypesGet$Json$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLanguageTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLanguageTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLanguageTypesGet$Json(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataLanguageTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataOrganizationContactTypesGet
   */
  static readonly MasterDataOrganizationContactTypesGetPath = '/MasterData/OrganizationContactTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataOrganizationContactTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationContactTypesGet$Plain$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataOrganizationContactTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataOrganizationContactTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationContactTypesGet$Plain(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataOrganizationContactTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataOrganizationContactTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationContactTypesGet$Json$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataOrganizationContactTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataOrganizationContactTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationContactTypesGet$Json(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataOrganizationContactTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataGenderTypesGet
   */
  static readonly MasterDataGenderTypesGetPath = '/MasterData/GenderTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataGenderTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataGenderTypesGet$Plain$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataGenderTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataGenderTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataGenderTypesGet$Plain(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataGenderTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataGenderTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataGenderTypesGet$Json$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataGenderTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataGenderTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataGenderTypesGet$Json(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataGenderTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataMaritalStatusTypesGet
   */
  static readonly MasterDataMaritalStatusTypesGetPath = '/MasterData/MaritalStatusTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataMaritalStatusTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMaritalStatusTypesGet$Plain$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataMaritalStatusTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataMaritalStatusTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMaritalStatusTypesGet$Plain(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataMaritalStatusTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataMaritalStatusTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMaritalStatusTypesGet$Json$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataMaritalStatusTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataMaritalStatusTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMaritalStatusTypesGet$Json(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataMaritalStatusTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataAddressUseTypesGet
   */
  static readonly MasterDataAddressUseTypesGetPath = '/MasterData/AddressUseTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataAddressUseTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataAddressUseTypesGet$Plain$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataAddressUseTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataAddressUseTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataAddressUseTypesGet$Plain(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataAddressUseTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataAddressUseTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataAddressUseTypesGet$Json$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataAddressUseTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataAddressUseTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataAddressUseTypesGet$Json(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataAddressUseTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataAddressTypesGet
   */
  static readonly MasterDataAddressTypesGetPath = '/MasterData/AddressTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataAddressTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataAddressTypesGet$Plain$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataAddressTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataAddressTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataAddressTypesGet$Plain(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataAddressTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataAddressTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataAddressTypesGet$Json$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataAddressTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataAddressTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataAddressTypesGet$Json(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataAddressTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataPreferredEmailTypesGet
   */
  static readonly MasterDataPreferredEmailTypesGetPath = '/MasterData/PreferredEmailTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPreferredEmailTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPreferredEmailTypesGet$Plain$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPreferredEmailTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPreferredEmailTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPreferredEmailTypesGet$Plain(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataPreferredEmailTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPreferredEmailTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPreferredEmailTypesGet$Json$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPreferredEmailTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPreferredEmailTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPreferredEmailTypesGet$Json(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataPreferredEmailTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataPersonIdentifierTypesGet
   */
  static readonly MasterDataPersonIdentifierTypesGetPath = '/MasterData/PersonIdentifierTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPersonIdentifierTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPersonIdentifierTypesGet$Plain$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPersonIdentifierTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPersonIdentifierTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPersonIdentifierTypesGet$Plain(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataPersonIdentifierTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPersonIdentifierTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPersonIdentifierTypesGet$Json$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPersonIdentifierTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPersonIdentifierTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPersonIdentifierTypesGet$Json(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataPersonIdentifierTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataLocationTypesGet
   */
  static readonly MasterDataLocationTypesGetPath = '/MasterData/LocationTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLocationTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLocationTypesGet$Plain$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLocationTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLocationTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLocationTypesGet$Plain(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataLocationTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLocationTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLocationTypesGet$Json$Response(params?: {
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLocationTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLocationTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLocationTypesGet$Json(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataLocationTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataEnumTypeModelGet
   */
  static readonly MasterDataEnumTypeModelGetPath = '/MasterData/EnumTypeModel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataEnumTypeModelGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataEnumTypeModelGet$Plain$Response(params?: {
  }): Observable<MDIStrictHttpResponse<EnumTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataEnumTypeModelGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<EnumTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataEnumTypeModelGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataEnumTypeModelGet$Plain(params?: {
  }): Observable<EnumTypeModel> {

    return this.masterDataEnumTypeModelGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<EnumTypeModel>) => r.body as EnumTypeModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataEnumTypeModelGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataEnumTypeModelGet$Json$Response(params?: {
  }): Observable<MDIStrictHttpResponse<EnumTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataEnumTypeModelGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<EnumTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataEnumTypeModelGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataEnumTypeModelGet$Json(params?: {
  }): Observable<EnumTypeModel> {

    return this.masterDataEnumTypeModelGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<EnumTypeModel>) => r.body as EnumTypeModel)
    );
  }

  /**
   * Path part for operation masterDataLocationPhysicalTypesGet
   */
  static readonly MasterDataLocationPhysicalTypesGetPath = '/MasterData/LocationPhysicalTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLocationPhysicalTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLocationPhysicalTypesGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLocationPhysicalTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLocationPhysicalTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLocationPhysicalTypesGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataLocationPhysicalTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLocationPhysicalTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLocationPhysicalTypesGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLocationPhysicalTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLocationPhysicalTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLocationPhysicalTypesGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataLocationPhysicalTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataLocationPhysicalTypeLookupByKeyPost
   */
  static readonly MasterDataLocationPhysicalTypeLookupByKeyPostPath = '/MasterData/LocationPhysicalTypeLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLocationPhysicalTypeLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataLocationPhysicalTypeLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLocationPhysicalTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLocationPhysicalTypeLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataLocationPhysicalTypeLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataLocationPhysicalTypeLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLocationPhysicalTypeLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataLocationPhysicalTypeLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLocationPhysicalTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLocationPhysicalTypeLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataLocationPhysicalTypeLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataLocationPhysicalTypeLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataLocationPhysicalTypeLookupByValueGet
   */
  static readonly MasterDataLocationPhysicalTypeLookupByValueGetPath = '/MasterData/LocationPhysicalTypeLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLocationPhysicalTypeLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLocationPhysicalTypeLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLocationPhysicalTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLocationPhysicalTypeLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLocationPhysicalTypeLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataLocationPhysicalTypeLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLocationPhysicalTypeLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLocationPhysicalTypeLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLocationPhysicalTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLocationPhysicalTypeLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLocationPhysicalTypeLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataLocationPhysicalTypeLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataLogoUseTypesGet
   */
  static readonly MasterDataLogoUseTypesGetPath = '/MasterData/LogoUseTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLogoUseTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLogoUseTypesGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLogoUseTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLogoUseTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLogoUseTypesGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataLogoUseTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLogoUseTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLogoUseTypesGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLogoUseTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLogoUseTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLogoUseTypesGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataLogoUseTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataLogoUseTypeLookupByKeyPost
   */
  static readonly MasterDataLogoUseTypeLookupByKeyPostPath = '/MasterData/LogoUseTypeLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLogoUseTypeLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataLogoUseTypeLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLogoUseTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLogoUseTypeLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataLogoUseTypeLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataLogoUseTypeLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLogoUseTypeLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataLogoUseTypeLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLogoUseTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLogoUseTypeLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataLogoUseTypeLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataLogoUseTypeLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataLogoUseTypeLookupByValueGet
   */
  static readonly MasterDataLogoUseTypeLookupByValueGetPath = '/MasterData/LogoUseTypeLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLogoUseTypeLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLogoUseTypeLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLogoUseTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLogoUseTypeLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLogoUseTypeLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataLogoUseTypeLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataLogoUseTypeLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLogoUseTypeLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataLogoUseTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataLogoUseTypeLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataLogoUseTypeLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataLogoUseTypeLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataMedicalAidGet
   */
  static readonly MasterDataMedicalAidGetPath = '/MasterData/MedicalAid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataMedicalAidGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidGet$Plain$Response(params?: {
    isoCountryCode?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataMedicalAidGetPath, 'get');
    if (params) {
      rb.query('isoCountryCode', params.isoCountryCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataMedicalAidGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidGet$Plain(params?: {
    isoCountryCode?: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataMedicalAidGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataMedicalAidGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidGet$Json$Response(params?: {
    isoCountryCode?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataMedicalAidGetPath, 'get');
    if (params) {
      rb.query('isoCountryCode', params.isoCountryCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataMedicalAidGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidGet$Json(params?: {
    isoCountryCode?: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataMedicalAidGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataMedicalAidSchemeMedicalAidIdGet
   */
  static readonly MasterDataMedicalAidSchemeMedicalAidIdGetPath = '/MasterData/MedicalAidScheme/{medicalAidId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataMedicalAidSchemeMedicalAidIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidSchemeMedicalAidIdGet$Plain$Response(params: {
    medicalAidId: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataMedicalAidSchemeMedicalAidIdGetPath, 'get');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataMedicalAidSchemeMedicalAidIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidSchemeMedicalAidIdGet$Plain(params: {
    medicalAidId: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataMedicalAidSchemeMedicalAidIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataMedicalAidSchemeMedicalAidIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidSchemeMedicalAidIdGet$Json$Response(params: {
    medicalAidId: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataMedicalAidSchemeMedicalAidIdGetPath, 'get');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataMedicalAidSchemeMedicalAidIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidSchemeMedicalAidIdGet$Json(params: {
    medicalAidId: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataMedicalAidSchemeMedicalAidIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataMedicalAidOptionMedicalAidIdMedicalAidSchemeIdGet
   */
  static readonly MasterDataMedicalAidOptionMedicalAidIdMedicalAidSchemeIdGetPath = '/MasterData/MedicalAidOption/{medicalAidId}/{medicalAidSchemeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataMedicalAidOptionMedicalAidIdMedicalAidSchemeIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidOptionMedicalAidIdMedicalAidSchemeIdGet$Plain$Response(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataMedicalAidOptionMedicalAidIdMedicalAidSchemeIdGetPath, 'get');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
      rb.path('medicalAidSchemeId', params.medicalAidSchemeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataMedicalAidOptionMedicalAidIdMedicalAidSchemeIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidOptionMedicalAidIdMedicalAidSchemeIdGet$Plain(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataMedicalAidOptionMedicalAidIdMedicalAidSchemeIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataMedicalAidOptionMedicalAidIdMedicalAidSchemeIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidOptionMedicalAidIdMedicalAidSchemeIdGet$Json$Response(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataMedicalAidOptionMedicalAidIdMedicalAidSchemeIdGetPath, 'get');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
      rb.path('medicalAidSchemeId', params.medicalAidSchemeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataMedicalAidOptionMedicalAidIdMedicalAidSchemeIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidOptionMedicalAidIdMedicalAidSchemeIdGet$Json(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataMedicalAidOptionMedicalAidIdMedicalAidSchemeIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataMedicalAidLookupByKeyPost
   */
  static readonly MasterDataMedicalAidLookupByKeyPostPath = '/MasterData/MedicalAidLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataMedicalAidLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataMedicalAidLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataMedicalAidLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataMedicalAidLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataMedicalAidLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataMedicalAidLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataMedicalAidLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataMedicalAidLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataMedicalAidLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataMedicalAidLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataMedicalAidLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataMedicalAidLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataMedicalAidLookupByValueGet
   */
  static readonly MasterDataMedicalAidLookupByValueGetPath = '/MasterData/MedicalAidLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataMedicalAidLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataMedicalAidLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataMedicalAidLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataMedicalAidLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataMedicalAidLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataMedicalAidLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataMedicalAidLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataMedicalAidLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataMedicalAidLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataOrganizationRolesGet
   */
  static readonly MasterDataOrganizationRolesGetPath = '/MasterData/OrganizationRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataOrganizationRolesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationRolesGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataOrganizationRolesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataOrganizationRolesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationRolesGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataOrganizationRolesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataOrganizationRolesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationRolesGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataOrganizationRolesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataOrganizationRolesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationRolesGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataOrganizationRolesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataOrganizationRoleLookupByKeyPost
   */
  static readonly MasterDataOrganizationRoleLookupByKeyPostPath = '/MasterData/OrganizationRoleLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataOrganizationRoleLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataOrganizationRoleLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataOrganizationRoleLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataOrganizationRoleLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataOrganizationRoleLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataOrganizationRoleLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataOrganizationRoleLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataOrganizationRoleLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataOrganizationRoleLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataOrganizationRoleLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataOrganizationRoleLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataOrganizationRoleLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataOrganizationRoleLookupByValueGet
   */
  static readonly MasterDataOrganizationRoleLookupByValueGetPath = '/MasterData/OrganizationRoleLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataOrganizationRoleLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationRoleLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataOrganizationRoleLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataOrganizationRoleLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationRoleLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataOrganizationRoleLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataOrganizationRoleLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationRoleLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataOrganizationRoleLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataOrganizationRoleLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationRoleLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataOrganizationRoleLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataOrganizationTypesGet
   */
  static readonly MasterDataOrganizationTypesGetPath = '/MasterData/OrganizationTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataOrganizationTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationTypesGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataOrganizationTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataOrganizationTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationTypesGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataOrganizationTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataOrganizationTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationTypesGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataOrganizationTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataOrganizationTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationTypesGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataOrganizationTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataOrganizationTypeLookupByKeyPost
   */
  static readonly MasterDataOrganizationTypeLookupByKeyPostPath = '/MasterData/OrganizationTypeLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataOrganizationTypeLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataOrganizationTypeLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataOrganizationTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataOrganizationTypeLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataOrganizationTypeLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataOrganizationTypeLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataOrganizationTypeLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataOrganizationTypeLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataOrganizationTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataOrganizationTypeLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataOrganizationTypeLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataOrganizationTypeLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataOrganizationTypeLookupByValueGet
   */
  static readonly MasterDataOrganizationTypeLookupByValueGetPath = '/MasterData/OrganizationTypeLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataOrganizationTypeLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationTypeLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataOrganizationTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataOrganizationTypeLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationTypeLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataOrganizationTypeLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataOrganizationTypeLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationTypeLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataOrganizationTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataOrganizationTypeLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataOrganizationTypeLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataOrganizationTypeLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataPracticeTypesGet
   */
  static readonly MasterDataPracticeTypesGetPath = '/MasterData/PracticeTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPracticeTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPracticeTypesGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPracticeTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPracticeTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPracticeTypesGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataPracticeTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPracticeTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPracticeTypesGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPracticeTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPracticeTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPracticeTypesGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataPracticeTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataPracticeTypeLookupByKeyPost
   */
  static readonly MasterDataPracticeTypeLookupByKeyPostPath = '/MasterData/PracticeTypeLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPracticeTypeLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataPracticeTypeLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPracticeTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPracticeTypeLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataPracticeTypeLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataPracticeTypeLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPracticeTypeLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataPracticeTypeLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPracticeTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPracticeTypeLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataPracticeTypeLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataPracticeTypeLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataPracticeTypeLookupByValueGet
   */
  static readonly MasterDataPracticeTypeLookupByValueGetPath = '/MasterData/PracticeTypeLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPracticeTypeLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPracticeTypeLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPracticeTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPracticeTypeLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPracticeTypeLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataPracticeTypeLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPracticeTypeLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPracticeTypeLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPracticeTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPracticeTypeLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPracticeTypeLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataPracticeTypeLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataProfessionsGet
   */
  static readonly MasterDataProfessionsGetPath = '/MasterData/Professions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataProfessionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataProfessionsGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataProfessionsGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataProfessionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataProfessionsGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataProfessionsGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataProfessionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataProfessionsGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataProfessionsGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataProfessionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataProfessionsGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataProfessionsGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataProfessionLookupByKeyPost
   */
  static readonly MasterDataProfessionLookupByKeyPostPath = '/MasterData/ProfessionLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataProfessionLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataProfessionLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataProfessionLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataProfessionLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataProfessionLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataProfessionLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataProfessionLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataProfessionLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataProfessionLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataProfessionLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataProfessionLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataProfessionLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataProfessionLookupByValueGet
   */
  static readonly MasterDataProfessionLookupByValueGetPath = '/MasterData/ProfessionLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataProfessionLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataProfessionLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    countryISOCode?: string;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataProfessionLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('countryISOCode', params.countryISOCode, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataProfessionLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataProfessionLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    countryISOCode?: string;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataProfessionLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataProfessionLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataProfessionLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    countryISOCode?: string;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataProfessionLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('countryISOCode', params.countryISOCode, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataProfessionLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataProfessionLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    countryISOCode?: string;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataProfessionLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataPublicHolidaysGet
   */
  static readonly MasterDataPublicHolidaysGetPath = '/MasterData/PublicHolidays';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPublicHolidaysGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPublicHolidaysGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<PublicHolidayListModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPublicHolidaysGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<PublicHolidayListModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPublicHolidaysGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPublicHolidaysGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<PublicHolidayListModel>> {

    return this.masterDataPublicHolidaysGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<PublicHolidayListModel>>) => r.body as Array<PublicHolidayListModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPublicHolidaysGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPublicHolidaysGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<PublicHolidayListModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPublicHolidaysGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<PublicHolidayListModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPublicHolidaysGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPublicHolidaysGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<PublicHolidayListModel>> {

    return this.masterDataPublicHolidaysGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<PublicHolidayListModel>>) => r.body as Array<PublicHolidayListModel>)
    );
  }

  /**
   * Path part for operation masterDataPublicHolidayLookupByKeyPost
   */
  static readonly MasterDataPublicHolidayLookupByKeyPostPath = '/MasterData/PublicHolidayLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPublicHolidayLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataPublicHolidayLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<PublicHolidayListModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPublicHolidayLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<PublicHolidayListModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPublicHolidayLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataPublicHolidayLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<PublicHolidayListModel>> {

    return this.masterDataPublicHolidayLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<PublicHolidayListModel>>) => r.body as Array<PublicHolidayListModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPublicHolidayLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataPublicHolidayLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<PublicHolidayListModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPublicHolidayLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<PublicHolidayListModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPublicHolidayLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataPublicHolidayLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<PublicHolidayListModel>> {

    return this.masterDataPublicHolidayLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<PublicHolidayListModel>>) => r.body as Array<PublicHolidayListModel>)
    );
  }

  /**
   * Path part for operation masterDataPublicHolidayLookupByValueGet
   */
  static readonly MasterDataPublicHolidayLookupByValueGetPath = '/MasterData/PublicHolidayLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPublicHolidayLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPublicHolidayLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    countryISOCode?: string;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<PublicHolidayListModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPublicHolidayLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('countryISOCode', params.countryISOCode, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<PublicHolidayListModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPublicHolidayLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPublicHolidayLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    countryISOCode?: string;
    languageRegion?: string;
  }): Observable<Array<PublicHolidayListModel>> {

    return this.masterDataPublicHolidayLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<PublicHolidayListModel>>) => r.body as Array<PublicHolidayListModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPublicHolidayLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPublicHolidayLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    countryISOCode?: string;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<PublicHolidayListModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataPublicHolidayLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('countryISOCode', params.countryISOCode, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<PublicHolidayListModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPublicHolidayLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPublicHolidayLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    countryISOCode?: string;
    languageRegion?: string;
  }): Observable<Array<PublicHolidayListModel>> {

    return this.masterDataPublicHolidayLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<PublicHolidayListModel>>) => r.body as Array<PublicHolidayListModel>)
    );
  }

  /**
   * Path part for operation masterDataQualificationsQualificationTypeIdGet
   */
  static readonly MasterDataQualificationsQualificationTypeIdGetPath = '/MasterData/Qualifications/{qualificationTypeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataQualificationsQualificationTypeIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationsQualificationTypeIdGet$Plain$Response(params: {
    qualificationTypeId: string;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataQualificationsQualificationTypeIdGetPath, 'get');
    if (params) {
      rb.path('qualificationTypeId', params.qualificationTypeId, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataQualificationsQualificationTypeIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationsQualificationTypeIdGet$Plain(params: {
    qualificationTypeId: string;
    languageRegion?: string;
  }): Observable<Array<QualificationKeyValueCodeModel>> {

    return this.masterDataQualificationsQualificationTypeIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>) => r.body as Array<QualificationKeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataQualificationsQualificationTypeIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationsQualificationTypeIdGet$Json$Response(params: {
    qualificationTypeId: string;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataQualificationsQualificationTypeIdGetPath, 'get');
    if (params) {
      rb.path('qualificationTypeId', params.qualificationTypeId, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataQualificationsQualificationTypeIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationsQualificationTypeIdGet$Json(params: {
    qualificationTypeId: string;
    languageRegion?: string;
  }): Observable<Array<QualificationKeyValueCodeModel>> {

    return this.masterDataQualificationsQualificationTypeIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>) => r.body as Array<QualificationKeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataQualificationLookupByKeyPost
   */
  static readonly MasterDataQualificationLookupByKeyPostPath = '/MasterData/QualificationLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataQualificationLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataQualificationLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataQualificationLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataQualificationLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataQualificationLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<QualificationKeyValueCodeModel>> {

    return this.masterDataQualificationLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>) => r.body as Array<QualificationKeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataQualificationLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataQualificationLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataQualificationLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataQualificationLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataQualificationLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<QualificationKeyValueCodeModel>> {

    return this.masterDataQualificationLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>) => r.body as Array<QualificationKeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataQualificationLookupByValueGet
   */
  static readonly MasterDataQualificationLookupByValueGetPath = '/MasterData/QualificationLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataQualificationLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    qualificationTypeId?: string;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataQualificationLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('qualificationTypeId', params.qualificationTypeId, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataQualificationLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    qualificationTypeId?: string;
    languageRegion?: string;
  }): Observable<Array<QualificationKeyValueCodeModel>> {

    return this.masterDataQualificationLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>) => r.body as Array<QualificationKeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataQualificationLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    qualificationTypeId?: string;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataQualificationLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('qualificationTypeId', params.qualificationTypeId, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataQualificationLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    qualificationTypeId?: string;
    languageRegion?: string;
  }): Observable<Array<QualificationKeyValueCodeModel>> {

    return this.masterDataQualificationLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<QualificationKeyValueCodeModel>>) => r.body as Array<QualificationKeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataQualificationTypesGet
   */
  static readonly MasterDataQualificationTypesGetPath = '/MasterData/QualificationTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataQualificationTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationTypesGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataQualificationTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataQualificationTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationTypesGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataQualificationTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataQualificationTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationTypesGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataQualificationTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataQualificationTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationTypesGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataQualificationTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataQualificationTypeLookupByKeyPost
   */
  static readonly MasterDataQualificationTypeLookupByKeyPostPath = '/MasterData/QualificationTypeLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataQualificationTypeLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataQualificationTypeLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataQualificationTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataQualificationTypeLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataQualificationTypeLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataQualificationTypeLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataQualificationTypeLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataQualificationTypeLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataQualificationTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataQualificationTypeLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataQualificationTypeLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataQualificationTypeLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataQualificationTypeLookupByValueGet
   */
  static readonly MasterDataQualificationTypeLookupByValueGetPath = '/MasterData/QualificationTypeLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataQualificationTypeLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationTypeLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataQualificationTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataQualificationTypeLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationTypeLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataQualificationTypeLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataQualificationTypeLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationTypeLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataQualificationTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataQualificationTypeLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataQualificationTypeLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataQualificationTypeLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataRelationshipTypesGet
   */
  static readonly MasterDataRelationshipTypesGetPath = '/MasterData/RelationshipTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataRelationshipTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataRelationshipTypesGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataRelationshipTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataRelationshipTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataRelationshipTypesGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<RelationshipTypeKeyValueModel>> {

    return this.masterDataRelationshipTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>) => r.body as Array<RelationshipTypeKeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataRelationshipTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataRelationshipTypesGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataRelationshipTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataRelationshipTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataRelationshipTypesGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<RelationshipTypeKeyValueModel>> {

    return this.masterDataRelationshipTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>) => r.body as Array<RelationshipTypeKeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataRelationshipTypeLookupByKeyPost
   */
  static readonly MasterDataRelationshipTypeLookupByKeyPostPath = '/MasterData/RelationshipTypeLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataRelationshipTypeLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataRelationshipTypeLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataRelationshipTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataRelationshipTypeLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataRelationshipTypeLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<RelationshipTypeKeyValueModel>> {

    return this.masterDataRelationshipTypeLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>) => r.body as Array<RelationshipTypeKeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataRelationshipTypeLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataRelationshipTypeLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataRelationshipTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataRelationshipTypeLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataRelationshipTypeLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<RelationshipTypeKeyValueModel>> {

    return this.masterDataRelationshipTypeLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>) => r.body as Array<RelationshipTypeKeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataRelationshipTypeLookupByValueGet
   */
  static readonly MasterDataRelationshipTypeLookupByValueGetPath = '/MasterData/RelationshipTypeLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataRelationshipTypeLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataRelationshipTypeLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataRelationshipTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataRelationshipTypeLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataRelationshipTypeLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<RelationshipTypeKeyValueModel>> {

    return this.masterDataRelationshipTypeLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>) => r.body as Array<RelationshipTypeKeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataRelationshipTypeLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataRelationshipTypeLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataRelationshipTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataRelationshipTypeLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataRelationshipTypeLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<RelationshipTypeKeyValueModel>> {

    return this.masterDataRelationshipTypeLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<RelationshipTypeKeyValueModel>>) => r.body as Array<RelationshipTypeKeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataServiceCategoriesGet
   */
  static readonly MasterDataServiceCategoriesGetPath = '/MasterData/ServiceCategories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceCategoriesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceCategoriesGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceCategoriesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceCategoriesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceCategoriesGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceCategoriesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceCategoriesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceCategoriesGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceCategoriesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceCategoriesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceCategoriesGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceCategoriesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataServiceCategoryLookupByKeyPost
   */
  static readonly MasterDataServiceCategoryLookupByKeyPostPath = '/MasterData/ServiceCategoryLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceCategoryLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataServiceCategoryLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceCategoryLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceCategoryLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataServiceCategoryLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceCategoryLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceCategoryLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataServiceCategoryLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceCategoryLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceCategoryLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataServiceCategoryLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceCategoryLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataServiceCategoryLookupByValueGet
   */
  static readonly MasterDataServiceCategoryLookupByValueGetPath = '/MasterData/ServiceCategoryLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceCategoryLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceCategoryLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceCategoryLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceCategoryLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceCategoryLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceCategoryLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceCategoryLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceCategoryLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceCategoryLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceCategoryLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceCategoryLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceCategoryLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataServiceDeliveryLocationRoleTypesGet
   */
  static readonly MasterDataServiceDeliveryLocationRoleTypesGetPath = '/MasterData/ServiceDeliveryLocationRoleTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceDeliveryLocationRoleTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceDeliveryLocationRoleTypesGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceDeliveryLocationRoleTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceDeliveryLocationRoleTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceDeliveryLocationRoleTypesGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceDeliveryLocationRoleTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceDeliveryLocationRoleTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceDeliveryLocationRoleTypesGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceDeliveryLocationRoleTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceDeliveryLocationRoleTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceDeliveryLocationRoleTypesGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceDeliveryLocationRoleTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataServiceDeliveryLocationRoleTypeLookupByKeyPost
   */
  static readonly MasterDataServiceDeliveryLocationRoleTypeLookupByKeyPostPath = '/MasterData/ServiceDeliveryLocationRoleTypeLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceDeliveryLocationRoleTypeLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataServiceDeliveryLocationRoleTypeLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceDeliveryLocationRoleTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceDeliveryLocationRoleTypeLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataServiceDeliveryLocationRoleTypeLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceDeliveryLocationRoleTypeLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceDeliveryLocationRoleTypeLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataServiceDeliveryLocationRoleTypeLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceDeliveryLocationRoleTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceDeliveryLocationRoleTypeLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataServiceDeliveryLocationRoleTypeLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceDeliveryLocationRoleTypeLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataServiceDeliveryLocationRoleTypeLookupByValueGet
   */
  static readonly MasterDataServiceDeliveryLocationRoleTypeLookupByValueGetPath = '/MasterData/ServiceDeliveryLocationRoleTypeLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceDeliveryLocationRoleTypeLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceDeliveryLocationRoleTypeLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceDeliveryLocationRoleTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceDeliveryLocationRoleTypeLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceDeliveryLocationRoleTypeLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceDeliveryLocationRoleTypeLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceDeliveryLocationRoleTypeLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceDeliveryLocationRoleTypeLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceDeliveryLocationRoleTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceDeliveryLocationRoleTypeLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceDeliveryLocationRoleTypeLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceDeliveryLocationRoleTypeLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataServiceTypesGet
   */
  static readonly MasterDataServiceTypesGetPath = '/MasterData/ServiceTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceTypesGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceTypesGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceTypesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceTypesGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceTypesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceTypesGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceTypesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataServiceTypeLookupByKeyPost
   */
  static readonly MasterDataServiceTypeLookupByKeyPostPath = '/MasterData/ServiceTypeLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceTypeLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataServiceTypeLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceTypeLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataServiceTypeLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceTypeLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceTypeLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataServiceTypeLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceTypeLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceTypeLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataServiceTypeLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceTypeLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataServiceTypeLookupByValueGet
   */
  static readonly MasterDataServiceTypeLookupByValueGetPath = '/MasterData/ServiceTypeLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceTypeLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceTypeLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceTypeLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceTypeLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceTypeLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataServiceTypeLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceTypeLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataServiceTypeLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataServiceTypeLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataServiceTypeLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataServiceTypeLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataSpecialitiesGet
   */
  static readonly MasterDataSpecialitiesGetPath = '/MasterData/Specialities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataSpecialitiesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSpecialitiesGet$Plain$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataSpecialitiesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataSpecialitiesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSpecialitiesGet$Plain(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataSpecialitiesGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataSpecialitiesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSpecialitiesGet$Json$Response(params?: {
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataSpecialitiesGetPath, 'get');
    if (params) {
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataSpecialitiesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSpecialitiesGet$Json(params?: {
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataSpecialitiesGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataSpecialityLookupByKeyPost
   */
  static readonly MasterDataSpecialityLookupByKeyPostPath = '/MasterData/SpecialityLookupByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataSpecialityLookupByKeyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataSpecialityLookupByKeyPost$Plain$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataSpecialityLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataSpecialityLookupByKeyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataSpecialityLookupByKeyPost$Plain(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataSpecialityLookupByKeyPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataSpecialityLookupByKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataSpecialityLookupByKeyPost$Json$Response(params?: {
    body?: KeyListModel
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataSpecialityLookupByKeyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataSpecialityLookupByKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  masterDataSpecialityLookupByKeyPost$Json(params?: {
    body?: KeyListModel
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataSpecialityLookupByKeyPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation masterDataSpecialityLookupByValueGet
   */
  static readonly MasterDataSpecialityLookupByValueGetPath = '/MasterData/SpecialityLookupByValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataSpecialityLookupByValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSpecialityLookupByValueGet$Plain$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataSpecialityLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataSpecialityLookupByValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSpecialityLookupByValueGet$Plain(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataSpecialityLookupByValueGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataSpecialityLookupByValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSpecialityLookupByValueGet$Json$Response(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<MDIStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMasterDataService.MasterDataSpecialityLookupByValueGetPath, 'get');
    if (params) {
      rb.query('value', params.value, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataSpecialityLookupByValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSpecialityLookupByValueGet$Json(params?: {
    value?: string;
    maxResults?: number;
    languageRegion?: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.masterDataSpecialityLookupByValueGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

}
