import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
// import { AtlasLocation } from 'src/app/api/atlas-api/models';
// import { AtlasService } from 'src/app/api/atlas-api/services/atlas.service';

@Injectable({
	providedIn: 'root'
})
export class LocationService {
	// private location: BehaviorSubject<AtlasLocation> =
	// 	new BehaviorSubject<AtlasLocation>(null);
	// public location$: Observable<AtlasLocation> = this.location
	// 	.asObservable()
	// 	.pipe(filter((location) => location != null));
	// constructor(private atlasService: AtlasService) {}
	// public async initializeAsync(): Promise<void> {
	// 	await this.loadAsync();
	// }
	// public async loadAsync(): Promise<void> {
	// 	const location = await this.atlasService.getLocation().toPromise();
	// 	this.location.next(location);
	// }
}
