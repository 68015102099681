import { Component } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { Location } from '@angular/common';
import { LanguagesDisplayService } from 'src/app/services/languages-display/languages-display.service';
import { HyperlinkService } from 'src/app/services/hyperlink/hyperlink.service';
import { Router } from '@angular/router';

@Component({
	selector: 'navigation-tabs',
	templateUrl: './navigation-tabs.component.html',
	styleUrls: ['./navigation-tabs.component.scss']
})
export class NavigationTabsComponent {
	constructor(
		public themeService: ThemeService,
		protected router: Router,
		public languageDisplayService: LanguagesDisplayService,
		public modalService: ModalService,
		public hyperlinkService: HyperlinkService,
		private location: Location
	) {}

	public async tabClick(): Promise<void> {
		this.location.replaceState(
			this.location.path().split('/').splice(0, 3).join('/')
		);
		await this.closeAllModals();
	}

	public async navigateTo(routeTo: string, newProps = null): Promise<void> {
		const fromUrl = window.location.pathname;

		await this.hyperlinkService.navigateTo(routeTo, fromUrl, newProps);
	}

	public async closeAllModals(): Promise<void> {
		await this.modalService.closeModalsAsync();
	}

	public async goHome(): Promise<void> {
		await this.modalService.closeModalsAsync();

		this.router.navigate(['healthFindr']);
	}
}
