/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PractitionerBaseService } from '../practitioner-base-service';
import { PractitionerApiConfiguration } from '../practitioner-api-configuration';
import { PractitionerStrictHttpResponse } from '../practitioner-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConsumerSearchModel } from '../models/consumer-search-model';
import { PhotoModel } from '../models/photo-model';
import { PractitionerModel } from '../models/practitioner-model';
import { PractitionerProfileModel } from '../models/practitioner-profile-model';
import { PractitionerRoleModel } from '../models/practitioner-role-model';

@Injectable({
  providedIn: 'root',
})
export class PRACConsumerService extends PractitionerBaseService {
  constructor(
    config: PractitionerApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation consumerSearchAsyncPost
   */
  static readonly ConsumerSearchAsyncPostPath = '/Consumer/SearchAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerSearchAsyncPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerSearchAsyncPost$Plain$Response(params?: {
    latitude?: number;
    longitude?: number;
    body?: ConsumerSearchModel
  }): Observable<PractitionerStrictHttpResponse<Array<PractitionerProfileModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerSearchAsyncPostPath, 'post');
    if (params) {
      rb.query('latitude', params.latitude, {});
      rb.query('longitude', params.longitude, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<Array<PractitionerProfileModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerSearchAsyncPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerSearchAsyncPost$Plain(params?: {
    latitude?: number;
    longitude?: number;
    body?: ConsumerSearchModel
  }): Observable<Array<PractitionerProfileModel>> {

    return this.consumerSearchAsyncPost$Plain$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<Array<PractitionerProfileModel>>) => r.body as Array<PractitionerProfileModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerSearchAsyncPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerSearchAsyncPost$Json$Response(params?: {
    latitude?: number;
    longitude?: number;
    body?: ConsumerSearchModel
  }): Observable<PractitionerStrictHttpResponse<Array<PractitionerProfileModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerSearchAsyncPostPath, 'post');
    if (params) {
      rb.query('latitude', params.latitude, {});
      rb.query('longitude', params.longitude, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<Array<PractitionerProfileModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerSearchAsyncPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerSearchAsyncPost$Json(params?: {
    latitude?: number;
    longitude?: number;
    body?: ConsumerSearchModel
  }): Observable<Array<PractitionerProfileModel>> {

    return this.consumerSearchAsyncPost$Json$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<Array<PractitionerProfileModel>>) => r.body as Array<PractitionerProfileModel>)
    );
  }

  /**
   * Path part for operation consumerPersonIdPhotoGet
   */
  static readonly ConsumerPersonIdPhotoGetPath = '/Consumer/{personId}/Photo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdPhotoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdPhotoGet$Response(params: {
    personId: string;
  }): Observable<PractitionerStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerPersonIdPhotoGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PractitionerStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdPhotoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdPhotoGet(params: {
    personId: string;
  }): Observable<void> {

    return this.consumerPersonIdPhotoGet$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation consumerPersonIdThumbnailGet
   */
  static readonly ConsumerPersonIdThumbnailGetPath = '/Consumer/{personId}/Thumbnail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdThumbnailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdThumbnailGet$Response(params: {
    personId: string;
  }): Observable<PractitionerStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerPersonIdThumbnailGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PractitionerStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdThumbnailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdThumbnailGet(params: {
    personId: string;
  }): Observable<void> {

    return this.consumerPersonIdThumbnailGet$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation consumerGetPractitionerFromRolePractitionerRoleIdGet
   */
  static readonly ConsumerGetPractitionerFromRolePractitionerRoleIdGetPath = '/Consumer/GetPractitionerFromRole/{practitionerRoleId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerGetPractitionerFromRolePractitionerRoleIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGetPractitionerFromRolePractitionerRoleIdGet$Plain$Response(params: {
    practitionerRoleId: string;
  }): Observable<PractitionerStrictHttpResponse<PractitionerProfileModel>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerGetPractitionerFromRolePractitionerRoleIdGetPath, 'get');
    if (params) {
      rb.path('practitionerRoleId', params.practitionerRoleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<PractitionerProfileModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerGetPractitionerFromRolePractitionerRoleIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGetPractitionerFromRolePractitionerRoleIdGet$Plain(params: {
    practitionerRoleId: string;
  }): Observable<PractitionerProfileModel> {

    return this.consumerGetPractitionerFromRolePractitionerRoleIdGet$Plain$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<PractitionerProfileModel>) => r.body as PractitionerProfileModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerGetPractitionerFromRolePractitionerRoleIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGetPractitionerFromRolePractitionerRoleIdGet$Json$Response(params: {
    practitionerRoleId: string;
  }): Observable<PractitionerStrictHttpResponse<PractitionerProfileModel>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerGetPractitionerFromRolePractitionerRoleIdGetPath, 'get');
    if (params) {
      rb.path('practitionerRoleId', params.practitionerRoleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<PractitionerProfileModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerGetPractitionerFromRolePractitionerRoleIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGetPractitionerFromRolePractitionerRoleIdGet$Json(params: {
    practitionerRoleId: string;
  }): Observable<PractitionerProfileModel> {

    return this.consumerGetPractitionerFromRolePractitionerRoleIdGet$Json$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<PractitionerProfileModel>) => r.body as PractitionerProfileModel)
    );
  }

  /**
   * Path part for operation consumerGet
   */
  static readonly ConsumerGetPath = '/Consumer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGet$Plain$Response(params?: {
  }): Observable<PractitionerStrictHttpResponse<PractitionerModel>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<PractitionerModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGet$Plain(params?: {
  }): Observable<PractitionerModel> {

    return this.consumerGet$Plain$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<PractitionerModel>) => r.body as PractitionerModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGet$Json$Response(params?: {
  }): Observable<PractitionerStrictHttpResponse<PractitionerModel>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<PractitionerModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGet$Json(params?: {
  }): Observable<PractitionerModel> {

    return this.consumerGet$Json$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<PractitionerModel>) => r.body as PractitionerModel)
    );
  }

  /**
   * Path part for operation consumerPost
   */
  static readonly ConsumerPostPath = '/Consumer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPost$Plain$Response(params?: {
    body?: PractitionerModel
  }): Observable<PractitionerStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPost$Plain(params?: {
    body?: PractitionerModel
  }): Observable<string> {

    return this.consumerPost$Plain$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPost$Json$Response(params?: {
    body?: PractitionerModel
  }): Observable<PractitionerStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPost$Json(params?: {
    body?: PractitionerModel
  }): Observable<string> {

    return this.consumerPost$Json$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation consumerPersonIdGet
   */
  static readonly ConsumerPersonIdGetPath = '/Consumer/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdGet$Plain$Response(params: {
    personId: string;
  }): Observable<PractitionerStrictHttpResponse<Array<PractitionerRoleModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerPersonIdGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<Array<PractitionerRoleModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdGet$Plain(params: {
    personId: string;
  }): Observable<Array<PractitionerRoleModel>> {

    return this.consumerPersonIdGet$Plain$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<Array<PractitionerRoleModel>>) => r.body as Array<PractitionerRoleModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdGet$Json$Response(params: {
    personId: string;
  }): Observable<PractitionerStrictHttpResponse<Array<PractitionerRoleModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerPersonIdGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<Array<PractitionerRoleModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdGet$Json(params: {
    personId: string;
  }): Observable<Array<PractitionerRoleModel>> {

    return this.consumerPersonIdGet$Json$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<Array<PractitionerRoleModel>>) => r.body as Array<PractitionerRoleModel>)
    );
  }

  /**
   * Path part for operation consumerPersonIdSaveRolesPost
   */
  static readonly ConsumerPersonIdSaveRolesPostPath = '/Consumer/{personId}/SaveRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdSaveRolesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPersonIdSaveRolesPost$Plain$Response(params: {
    personId: string;
    body?: PractitionerRoleModel
  }): Observable<PractitionerStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerPersonIdSaveRolesPostPath, 'post');
    if (params) {
      rb.path('personId', params.personId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdSaveRolesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPersonIdSaveRolesPost$Plain(params: {
    personId: string;
    body?: PractitionerRoleModel
  }): Observable<string> {

    return this.consumerPersonIdSaveRolesPost$Plain$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdSaveRolesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPersonIdSaveRolesPost$Json$Response(params: {
    personId: string;
    body?: PractitionerRoleModel
  }): Observable<PractitionerStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerPersonIdSaveRolesPostPath, 'post');
    if (params) {
      rb.path('personId', params.personId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdSaveRolesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPersonIdSaveRolesPost$Json(params: {
    personId: string;
    body?: PractitionerRoleModel
  }): Observable<string> {

    return this.consumerPersonIdSaveRolesPost$Json$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation consumerDeleteRoleRoleIdDelete
   */
  static readonly ConsumerDeleteRoleRoleIdDeletePath = '/Consumer/DeleteRole/{roleId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerDeleteRoleRoleIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerDeleteRoleRoleIdDelete$Response(params: {
    roleId: string;
  }): Observable<PractitionerStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerDeleteRoleRoleIdDeletePath, 'delete');
    if (params) {
      rb.path('roleId', params.roleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PractitionerStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerDeleteRoleRoleIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerDeleteRoleRoleIdDelete(params: {
    roleId: string;
  }): Observable<void> {

    return this.consumerDeleteRoleRoleIdDelete$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation consumerPhotoPost
   */
  static readonly ConsumerPhotoPostPath = '/Consumer/Photo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPhotoPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPhotoPost$Plain$Response(params?: {
    body?: PhotoModel
  }): Observable<PractitionerStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerPhotoPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPhotoPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPhotoPost$Plain(params?: {
    body?: PhotoModel
  }): Observable<string> {

    return this.consumerPhotoPost$Plain$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPhotoPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPhotoPost$Json$Response(params?: {
    body?: PhotoModel
  }): Observable<PractitionerStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PRACConsumerService.ConsumerPhotoPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPhotoPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPhotoPost$Json(params?: {
    body?: PhotoModel
  }): Observable<string> {

    return this.consumerPhotoPost$Json$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<string>) => r.body as string)
    );
  }

}
