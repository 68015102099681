import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CountryRoutingModule } from './country-routing.module';
import { CountryPage } from './country.page';
import { isoCountryNamePipe } from 'src/app/shared/pipes/iso-country-name/iso-country-name.pipe';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
	declarations: [CountryPage],
	imports: [CommonModule, IonicModule, SharedModule, CountryRoutingModule],
	providers: [isoCountryNamePipe]
})
export class CountryModule {}
