/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HealthFindrBaseService } from '../health-findr-base-service';
import { HealthFindrApiConfiguration } from '../health-findr-api-configuration';
import { HealthFindrStrictHttpResponse } from '../health-findr-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EntityMetadataModel } from '../models/entity-metadata-model';
import { HealthCareServiceMetadataModel } from '../models/health-care-service-metadata-model';
import { KeyValueModel } from '../models/key-value-model';
import { OrganizationMetadataModel } from '../models/organization-metadata-model';

@Injectable({
  providedIn: 'root',
})
export class HealthFindrAdminService extends HealthFindrBaseService {
  constructor(
    config: HealthFindrApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation adminOrganizationsGet
   */
  static readonly AdminOrganizationsGetPath = '/Admin/Organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminOrganizationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminOrganizationsGet$Response(params?: {
  }): Observable<HealthFindrStrictHttpResponse<Array<OrganizationMetadataModel>>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrAdminService.AdminOrganizationsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<Array<OrganizationMetadataModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminOrganizationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminOrganizationsGet(params?: {
  }): Observable<Array<OrganizationMetadataModel>> {

    return this.adminOrganizationsGet$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<OrganizationMetadataModel>>) => r.body as Array<OrganizationMetadataModel>)
    );
  }

  /**
   * Path part for operation adminFhirIdHealthCareServicesGet
   */
  static readonly AdminFhirIdHealthCareServicesGetPath = '/Admin/{fhirId}/HealthCareServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdHealthCareServicesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdHealthCareServicesGet$Response(params: {
    fhirId: string;
  }): Observable<HealthFindrStrictHttpResponse<Array<EntityMetadataModel>>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrAdminService.AdminFhirIdHealthCareServicesGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<Array<EntityMetadataModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdHealthCareServicesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdHealthCareServicesGet(params: {
    fhirId: string;
  }): Observable<Array<EntityMetadataModel>> {

    return this.adminFhirIdHealthCareServicesGet$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<EntityMetadataModel>>) => r.body as Array<EntityMetadataModel>)
    );
  }

  /**
   * Path part for operation adminLocationsSpecialitiesHealthCareServiceFhirIdGet
   */
  static readonly AdminLocationsSpecialitiesHealthCareServiceFhirIdGetPath = '/Admin/LocationsSpecialities/{healthCareServiceFhirId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminLocationsSpecialitiesHealthCareServiceFhirIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminLocationsSpecialitiesHealthCareServiceFhirIdGet$Response(params: {
    healthCareServiceFhirId: string;
  }): Observable<HealthFindrStrictHttpResponse<HealthCareServiceMetadataModel>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrAdminService.AdminLocationsSpecialitiesHealthCareServiceFhirIdGetPath, 'get');
    if (params) {
      rb.path('healthCareServiceFhirId', params.healthCareServiceFhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<HealthCareServiceMetadataModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminLocationsSpecialitiesHealthCareServiceFhirIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminLocationsSpecialitiesHealthCareServiceFhirIdGet(params: {
    healthCareServiceFhirId: string;
  }): Observable<HealthCareServiceMetadataModel> {

    return this.adminLocationsSpecialitiesHealthCareServiceFhirIdGet$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<HealthCareServiceMetadataModel>) => r.body as HealthCareServiceMetadataModel)
    );
  }

  /**
   * Path part for operation adminFhirIdSpecialitiesGet
   */
  static readonly AdminFhirIdSpecialitiesGetPath = '/Admin/{fhirId}/Specialities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdSpecialitiesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdSpecialitiesGet$Response(params: {
    fhirId: string;
  }): Observable<HealthFindrStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrAdminService.AdminFhirIdSpecialitiesGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdSpecialitiesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdSpecialitiesGet(params: {
    fhirId: string;
  }): Observable<Array<KeyValueModel>> {

    return this.adminFhirIdSpecialitiesGet$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

}
