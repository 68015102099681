/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PractitionerApiConfiguration, PractitionerApiConfigurationParams } from './practitioner-api-configuration';

import { PRACAdminService } from './services/prac-admin.service';
import { PRACConsumerService } from './services/prac-consumer.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    PRACAdminService,
    PRACConsumerService,
    PractitionerApiConfiguration
  ],
})
export class PractitionerModule {
  static forRoot(params: PractitionerApiConfigurationParams): ModuleWithProviders<PractitionerModule> {
    return {
      ngModule: PractitionerModule,
      providers: [
        {
          provide: PractitionerApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: PractitionerModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('PractitionerModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
