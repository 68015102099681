import { Injectable, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SecureStorageKey } from '../storage/models/secure-storage-key.enum';
import { SecureStorageService } from '../storage/secure-storage.service';
import { TranslatorService } from '../translate/translator.service';

@Injectable({
	providedIn: 'root'
})
export class ThemeService {
	renderer: any;
	fontSizeCSSVariable = '--ion-default-font-size';

	currentTheme = '';
	currentFontSize = '15pt';
	private defaultFontSize = 15;

	public sos: boolean;

	private htmlDirection: BehaviorSubject<string> =
		new BehaviorSubject<string>(null);

	public htmlDirection$: Observable<string> =
		this.htmlDirection.asObservable();

	constructor(
		private rendererFactory: RendererFactory2,
		private secureStorageService: SecureStorageService,
		private translatorService: TranslatorService
	) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	public async initializeAsync(): Promise<void> {
		await this.setStoredTheme();
		await this.setStoredFontSize();
		this.registerSubscribers();
	}

	private documentElementComputedStyle = window.getComputedStyle(
		document.documentElement
	);

	private lastLayout = 'nolayout';
	public get layout(): string {
		const value = this.documentElementComputedStyle
			.getPropertyValue('--layout')
			.trim();

		if (value != this.lastLayout) {
			document.documentElement.classList.remove(this.lastLayout);
			document.documentElement.classList.add(value);
			this.lastLayout = value;
		}

		return value;
	}

	private lastSizeGrow = 6;
	public get colSizeGrow(): number {
		const value = parseInt(
			this.documentElementComputedStyle
				.getPropertyValue('--colSizeGrow')
				.trim()
		);
		if (value != this.lastSizeGrow) {
			this.lastSizeGrow = value;
		}
		return value;
	}

	private lastSizeSessions = 6;
	public get colSizeSessions(): number {
		const value = parseInt(
			this.documentElementComputedStyle
				.getPropertyValue('--colSizeSessions')
				.trim()
		);
		if (value != this.lastSizeSessions) {
			this.lastSizeSessions = value;
		}
		return value;
	}

	private lastStackFontSize = 3.4;
	public get stackFontSize(): number {
		const value = parseInt(
			this.documentElementComputedStyle
				.getPropertyValue('--stackFontSize')
				.trim()
		);
		if (value != this.lastStackFontSize) {
			this.lastStackFontSize = value;
		}
		return value;
	}

	private lastThumbnailFontSize = 14;
	public get thumbnailFontSize(): number {
		const value = parseInt(
			this.documentElementComputedStyle
				.getPropertyValue('--thumbnailFontSize')
				.trim()
		);
		if (value != this.lastThumbnailFontSize) {
			this.lastThumbnailFontSize = value;
		}
		return value;
	}

	private lastThumbnailFontSizeMore = 14;
	public get thumbnailFontSizeMore(): number {
		const value = parseInt(
			this.documentElementComputedStyle
				.getPropertyValue('--thumbnailFontSizeMore')
				.trim()
		);
		if (value != this.lastThumbnailFontSizeMore) {
			this.lastThumbnailFontSizeMore = value;
		}
		return value;
	}

	private lastThumbnailFontSizeSearch = 15;
	public get thumbnailFontSizeSearch(): number {
		const value = parseInt(
			this.documentElementComputedStyle
				.getPropertyValue('--thumbnailFontSizeSearch')
				.trim()
		);
		if (value != this.lastThumbnailFontSizeSearch) {
			this.lastThumbnailFontSizeSearch = value;
		}
		return value;
	}

	private lastcheckInMargin = 0;
	public get checkInMargin(): number {
		const value = parseInt(
			this.documentElementComputedStyle
				.getPropertyValue('--checkInMargin')
				.trim()
		);
		if (value != this.lastcheckInMargin) {
			this.lastcheckInMargin = value;
		}
		return value;
	}

	private lastCheckInWidth = 100;
	public get checkInWidth(): number {
		const value = parseInt(
			this.documentElementComputedStyle
				.getPropertyValue('--checkInWidth')
				.trim()
		);
		if (value != this.lastCheckInWidth) {
			this.lastCheckInWidth = value;
		}
		return value;
	}

	private lastSizeInterest = 6;
	public get colSizeInterest(): number {
		const value = parseInt(
			this.documentElementComputedStyle
				.getPropertyValue('--colSizeInterest')
				.trim()
		);
		if (value != this.lastSizeInterest) {
			this.lastSizeInterest = value;
		}
		return value;
	}

	private lastEmojiWidth = 6;
	public get colEmojiWidth(): number {
		const value = parseInt(
			this.documentElementComputedStyle
				.getPropertyValue('--emojiWidth')
				.trim()
		);
		if (value != this.lastEmojiWidth) {
			this.lastEmojiWidth = value;
		}
		return value;
	}

	private lastSizeServices = 6;
	public get colSizeServices(): number {
		const value = parseInt(
			this.documentElementComputedStyle
				.getPropertyValue('--colSizeServices')
				.trim()
		);
		if (value != this.lastSizeServices) {
			this.lastSizeServices = value;
		}
		return value;
	}

	async setStoredTheme(): Promise<void> {
		const theme = await this.secureStorageService.get(
			SecureStorageKey.Theme
		);
		if (theme != null) {
			await this.changeTheme(theme);
		} else {
			this.currentTheme = theme;
			await this.clearTheme();
		}
	}

	async changeTheme(bodyClass: any): Promise<void> {
		this.currentTheme = await this.secureStorageService.get(
			SecureStorageKey.Theme
		);
		await this.clearTheme();
		this.currentTheme = bodyClass;
		await this.secureStorageService.set(SecureStorageKey.Theme, bodyClass);
		this.addBodyClass(bodyClass);
	}

	async clearTheme(): Promise<void> {
		if (this.currentTheme != null || this.currentTheme != '') {
			this.removeBodyClass(this.currentTheme);
			await this.secureStorageService.remove(SecureStorageKey.Theme);
		}
		this.currentTheme = '';
	}

	private addBodyClass(bodyClass: string) {
		if (!bodyClass || bodyClass.length == 0) return;

		this.renderer.addClass(document.body, bodyClass);
	}

	private removeBodyClass(bodyClass: string) {
		if (!bodyClass || bodyClass.length == 0) return;

		this.renderer.removeClass(document.body, bodyClass);
	}

	async getFontSizeModifier(): Promise<any> {
		const ret = await this.secureStorageService.get(
			SecureStorageKey.FontSizeModifier
		);
		return ret ? ret : 1;
	}

	async setStoredFontSize(): Promise<void> {
		const size = await this.secureStorageService.get(
			SecureStorageKey.FontSize
		);
		if (size != null) {
			await this.applyFontSize(size);
		} else {
			this.currentFontSize = size;
			await this.setDefaultFontSize();
		}
	}

	async setDefaultFontSize(): Promise<void> {
		this.currentFontSize = '15pt';
		await this.applyFontSize(this.currentFontSize);
	}

	async changeFontSize(modifier: number): Promise<void> {
		const sizeNumber: number = this.defaultFontSize * modifier;
		const sizeStr: string = sizeNumber.toString() + 'pt';
		await this.secureStorageService.set(
			SecureStorageKey.FontSizeModifier,
			modifier.toString()
		);
		await this.applyFontSize(sizeStr);
	}

	private async applyFontSize(fontSize: string) {
		this.currentFontSize = fontSize;
		await this.secureStorageService.set(
			SecureStorageKey.FontSize,
			fontSize
		);
		document.documentElement.style.setProperty(
			this.fontSizeCSSVariable,
			this.currentFontSize
		);
	}

	private registerSubscribers(): void {
		this.translatorService.language$.subscribe(() => {
			const dir = document
				.getElementsByTagName('html')[0]
				.getAttribute('dir');
			this.htmlDirection.next(dir);
		});
	}
}
