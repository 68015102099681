import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { Injectable } from '@angular/core';
import { SecureStorageKey } from './models/secure-storage-key.enum';
import { StorageService } from './storage.service';
import { StorageKey } from './models/storage-key.enum';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SecureStorageService {
	private loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);
	public loaded$: Observable<boolean> = this.loaded.asObservable();

	constructor(private storageService: StorageService) {}

	public async initializeAsync(): Promise<void> {
		await this.cloneOldStorageAsync();
		await this.clearStorageOnInitialLaunch();

		this.loaded.next(true);
	}

	public async set(key: SecureStorageKey, value: string): Promise<boolean> {
		value = encodeURIComponent(value);
		return await SecureStoragePlugin.set({ key, value })
			.then((response) => response.value)
			.catch(() => false);
	}

	public async get(key: SecureStorageKey): Promise<string> {
		return await SecureStoragePlugin.get({ key })
			.then((response) => decodeURIComponent(response.value))
			.catch(() => null as string);
	}

	public async remove(key: SecureStorageKey): Promise<boolean> {
		return await SecureStoragePlugin.remove({ key: key })
			.then((response) => response.value)
			.catch(() => false);
	}

	public async clear(): Promise<boolean> {
		return await SecureStoragePlugin.clear()
			.then((response) => response.value)
			.catch(() => false);
	}

	public async keys(): Promise<Array<SecureStorageKey>> {
		return await SecureStoragePlugin.keys()
			.then((response) => response.value as SecureStorageKey[])
			.catch(() => []);
	}

	private async clearStorageOnInitialLaunch(): Promise<void> {
		const initialAppLaunchValue = await this.storageService.get(
			StorageKey.InitialAppLaunch
		);

		if (!initialAppLaunchValue) {
			await this.clear();
			await this.storageService.set(StorageKey.InitialAppLaunch, 'true');
		}
	}

	private async cloneOldStorageAsync(): Promise<void> {
		const storageKeys = Object.values(SecureStorageKey);

		for (const storageKey of storageKeys) {
			await this.copyFromOldStorageAsync(storageKey);
		}
	}

	private async copyFromOldStorageAsync(key: StorageKey): Promise<void> {
		await this.storageService.get(key).then(async (storageValue) => {
			if (storageValue) {
				await this.set(key as SecureStorageKey, storageValue);
				await this.storageService.remove(key);
			}
		});
	}
}
