/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HealthFindrBaseService } from '../health-findr-base-service';
import { HealthFindrApiConfiguration } from '../health-findr-api-configuration';
import { HealthFindrStrictHttpResponse } from '../health-findr-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BroadcastMessageModel } from '../models/broadcast-message-model';
import { BroadcastMessageSearchResultModel } from '../models/broadcast-message-search-result-model';
import { ConfigSearchRequestModel } from '../models/config-search-request-model';

@Injectable({
  providedIn: 'root',
})
export class HealthFindrBroadcastMessageService extends HealthFindrBaseService {
  constructor(
    config: HealthFindrApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation broadcastMessageSearchPost
   */
  static readonly BroadcastMessageSearchPostPath = '/BroadcastMessage/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `broadcastMessageSearchPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  broadcastMessageSearchPost$Response(params?: {
    body?: ConfigSearchRequestModel
  }): Observable<HealthFindrStrictHttpResponse<Array<BroadcastMessageSearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrBroadcastMessageService.BroadcastMessageSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<Array<BroadcastMessageSearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `broadcastMessageSearchPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  broadcastMessageSearchPost(params?: {
    body?: ConfigSearchRequestModel
  }): Observable<Array<BroadcastMessageSearchResultModel>> {

    return this.broadcastMessageSearchPost$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<BroadcastMessageSearchResultModel>>) => r.body as Array<BroadcastMessageSearchResultModel>)
    );
  }

  /**
   * Path part for operation broadcastMessageIdGet
   */
  static readonly BroadcastMessageIdGetPath = '/BroadcastMessage/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `broadcastMessageIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  broadcastMessageIdGet$Response(params: {
    id: string;
  }): Observable<HealthFindrStrictHttpResponse<BroadcastMessageModel>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrBroadcastMessageService.BroadcastMessageIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<BroadcastMessageModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `broadcastMessageIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  broadcastMessageIdGet(params: {
    id: string;
  }): Observable<BroadcastMessageModel> {

    return this.broadcastMessageIdGet$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<BroadcastMessageModel>) => r.body as BroadcastMessageModel)
    );
  }

  /**
   * Path part for operation broadcastMessageIdDelete
   */
  static readonly BroadcastMessageIdDeletePath = '/BroadcastMessage/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `broadcastMessageIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  broadcastMessageIdDelete$Response(params: {
    id: string;
  }): Observable<HealthFindrStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrBroadcastMessageService.BroadcastMessageIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as HealthFindrStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `broadcastMessageIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  broadcastMessageIdDelete(params: {
    id: string;
  }): Observable<void> {

    return this.broadcastMessageIdDelete$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation broadcastMessageSavePost
   */
  static readonly BroadcastMessageSavePostPath = '/BroadcastMessage/Save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `broadcastMessageSavePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  broadcastMessageSavePost$Response(params?: {
    body?: BroadcastMessageModel
  }): Observable<HealthFindrStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrBroadcastMessageService.BroadcastMessageSavePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `broadcastMessageSavePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  broadcastMessageSavePost(params?: {
    body?: BroadcastMessageModel
  }): Observable<string> {

    return this.broadcastMessageSavePost$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<string>) => r.body as string)
    );
  }

}
