import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslatorService } from '../translate/translator.service';
import { Country } from './models/country.model';

@Injectable({
	providedIn: 'root'
})
export class CountriesService {
	// cache of country file with language key
	private _countryFile = new Map<string, any>();

	private _countries: BehaviorSubject<Country[]> = new BehaviorSubject<
		Country[]
	>([]);
	public Countries$: Observable<Country[]> = this._countries.asObservable();

	constructor(
		private translatorService: TranslatorService,
		private httpClient: HttpClient
	) {}

	public initialize(): void {
		this.translatorService.language$.subscribe((language) => {
			void this.loadCountriesAsync(language);
		});
	}

	private async loadFileAsync(language: string): Promise<any> {
		if (this._countryFile.has(language)) {
			return this._countryFile.get(language);
		} else {
			// else load country file by languange code
			return this.httpClient
				.get(`assets/i18n/countries/${language}.json`)
				.toPromise()
				.then(
					(data) => {
						this._countryFile.set(language, data);
						return data;
					},
					() => {
						return null;
					}
				);
		}
	}

	private async loadCountriesAsync(language: string) {
		const defaultLanguage = this.translatorService.defaultLanguage;

		let newCountries = await this.loadFileAsync(language);
		if (!newCountries)
			newCountries = await this.loadFileAsync(defaultLanguage);

		this._countries.next(newCountries);
	}

	public getCountryName(code: string): string {
		return this._countries.value.find((element) => element.alpha2 == code)
			?.name;
	}

	public filterCountryIsoName(
		code: string,
		name: string,
		term: string
	): boolean {
		return (
			this._countries.value
				.find(
					(itmInner) =>
						itmInner.alpha2.toLowerCase() === code?.toLowerCase()
				)
				?.name.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase()
				.includes(term) ?? name?.toLowerCase().startsWith(term)
		);
	}
}
