import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SecureStorageService } from '../storage/secure-storage.service';
import { HttpClient } from '@angular/common/http';
import { Language } from './interfaces/language.interface';
import { SecureStorageKey } from '../storage/models/secure-storage-key.enum';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class TranslatorService {
	private loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);
	private language: BehaviorSubject<string>;
	private contentLanguage: BehaviorSubject<string>;

	public language$: Observable<string>;
	public languageMapped$: Observable<string>;
	public contentLanguageMapped$: Observable<string>;
	public contentLanguageFormatted$: Observable<string>;
	public hasContentLanguage$: Observable<boolean>;
	public contentLanguageDirection$: Observable<string>;
	public loaded$: Observable<boolean> = this.loaded.asObservable();

	public readonly defaultLanguage: string = 'en';
	public availableLanguages: Language[];
	public availableContentLanguages: Language[];
	public allLanguages: Language[];

	constructor(
		private translate: TranslateService,
		private secureStorageService: SecureStorageService,
		private httpClient: HttpClient
	) {}

	public async initializeAsync(): Promise<void> {
		this.translate.setDefaultLang(this.defaultLanguage);

		//reads langauges from json file
		// const data = await this.httpClient
		// 	.get('assets/languages/languages.json')
		// 	.toPromise();

		const data = await environment.availablelanguages;

		this.allLanguages = data as Language[];
		this.availableLanguages = this.allLanguages.filter(
			(l) => l.contentSupport
		);
		this.availableContentLanguages = this.availableLanguages.filter(
			(l) => l.code != 'es-la'
		);

		const language = await this.getInitializeLanguageAsync();
		const contentLanguage = await this.getInitializeContentLanguageAsync();

		this.language = new BehaviorSubject<string>(language);
		this.contentLanguage = new BehaviorSubject<string>(
			contentLanguage ?? language
		);

		this.initializeObservables();
		this.initializeSubscribers();

		this.loaded.next(true);
	}

	public setContentLanguage(language: string): void {
		if (this.contentLanguage.value != language)
			this.contentLanguage.next(language);
	}

	public setLanguage(language: string): void {
		if (this.language.value != language) this.language.next(language);

		this.setContentLanguage(language);
	}

	public getTranslation(i18nPath: string): string {
		return this.translate.instant(i18nPath) as string;
	}

	private initializeSubscribers(): void {
		this.language.subscribe((language) => {
			this.translate.use(language);
			this.updateDocumentForLanguage(language);
			void this.secureStorageService.set(
				SecureStorageKey.Language,
				language
			);
		});

		this.contentLanguage.subscribe((language) => {
			if (language == this.language.value) {
				void this.secureStorageService.remove(
					SecureStorageKey.ContentLangOverride
				);
			} else {
				void this.secureStorageService.set(
					SecureStorageKey.ContentLangOverride,
					language
				);
			}
		});
	}

	private initializeObservables(): void {
		this.language$ = this.language.asObservable();

		this.contentLanguageMapped$ = this.contentLanguage.asObservable().pipe(
			map((language) => {
				return this.mapLanguage(language);
			})
		);

		this.contentLanguageFormatted$ = this.contentLanguageMapped$.pipe(
			map((language) => {
				return language.replace('-', '_');
			})
		);

		this.languageMapped$ = this.language.asObservable().pipe(
			map((language) => {
				return this.mapLanguage(language);
			})
		);

		this.hasContentLanguage$ = combineLatest([
			this.language,
			this.contentLanguage
		]).pipe(
			map(([language, contentLanguage]) => {
				return language != contentLanguage;
			})
		);

		this.contentLanguageDirection$ = this.contentLanguage.pipe(
			map((language) => {
				const availableLanguage = this.availableLanguages.find(
					(availableLanguage) => availableLanguage.code == language
				);

				if (!availableLanguage) return 'rtl';

				return availableLanguage.isRightToLeft ? 'rtl' : 'ltr';
			})
		);
	}

	private async getInitializeContentLanguageAsync(): Promise<string> {
		const language = await this.secureStorageService.get(
			SecureStorageKey.ContentLangOverride
		);

		return language;
	}

	private async getInitializeLanguageAsync(): Promise<string> {
		let language = await this.secureStorageService
			.get(SecureStorageKey.Language)
			.then((value) => value || this.translate.getBrowserLang());

		if (!this.availableLanguages.find((l) => l.code == language))
			language = this.defaultLanguage;

		return language;
	}

	private updateDocumentForLanguage(language: string): void {
		document.documentElement.dir =
			this.availableLanguages.find((e) => e.code === language)
				.isRightToLeft == true
				? 'rtl'
				: 'ltr';

		document.documentElement.lang = language;
	}

	private mapLanguage(language: string): string {
		if (language == 'es-la') return 'es';
		return language;
	}
}
