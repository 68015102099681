/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HealthFindrBaseService } from '../health-findr-base-service';
import { HealthFindrApiConfiguration } from '../health-findr-api-configuration';
import { HealthFindrStrictHttpResponse } from '../health-findr-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ServiceConfigurationModel } from '../models/service-configuration-model';
import { ServiceConfigurationSearchRequestModel } from '../models/service-configuration-search-request-model';
import { ServiceConfigurationSearchResultModel } from '../models/service-configuration-search-result-model';

@Injectable({
  providedIn: 'root',
})
export class HealthFindrServiceConfigurationService extends HealthFindrBaseService {
  constructor(
    config: HealthFindrApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation serviceConfigurationSearchPost
   */
  static readonly ServiceConfigurationSearchPostPath = '/ServiceConfiguration/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceConfigurationSearchPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceConfigurationSearchPost$Response(params?: {
    body?: ServiceConfigurationSearchRequestModel
  }): Observable<HealthFindrStrictHttpResponse<Array<ServiceConfigurationSearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrServiceConfigurationService.ServiceConfigurationSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<Array<ServiceConfigurationSearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceConfigurationSearchPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceConfigurationSearchPost(params?: {
    body?: ServiceConfigurationSearchRequestModel
  }): Observable<Array<ServiceConfigurationSearchResultModel>> {

    return this.serviceConfigurationSearchPost$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<ServiceConfigurationSearchResultModel>>) => r.body as Array<ServiceConfigurationSearchResultModel>)
    );
  }

  /**
   * Path part for operation serviceConfigurationIdGet
   */
  static readonly ServiceConfigurationIdGetPath = '/ServiceConfiguration/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceConfigurationIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceConfigurationIdGet$Response(params: {
    id: string;
  }): Observable<HealthFindrStrictHttpResponse<ServiceConfigurationModel>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrServiceConfigurationService.ServiceConfigurationIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<ServiceConfigurationModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceConfigurationIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceConfigurationIdGet(params: {
    id: string;
  }): Observable<ServiceConfigurationModel> {

    return this.serviceConfigurationIdGet$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<ServiceConfigurationModel>) => r.body as ServiceConfigurationModel)
    );
  }

  /**
   * Path part for operation serviceConfigurationIdDelete
   */
  static readonly ServiceConfigurationIdDeletePath = '/ServiceConfiguration/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceConfigurationIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceConfigurationIdDelete$Response(params: {
    id: string;
  }): Observable<HealthFindrStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrServiceConfigurationService.ServiceConfigurationIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as HealthFindrStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceConfigurationIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceConfigurationIdDelete(params: {
    id: string;
  }): Observable<void> {

    return this.serviceConfigurationIdDelete$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation serviceConfigurationSavePost
   */
  static readonly ServiceConfigurationSavePostPath = '/ServiceConfiguration/Save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceConfigurationSavePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceConfigurationSavePost$Response(params?: {
    body?: ServiceConfigurationModel
  }): Observable<HealthFindrStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrServiceConfigurationService.ServiceConfigurationSavePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceConfigurationSavePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceConfigurationSavePost(params?: {
    body?: ServiceConfigurationModel
  }): Observable<string> {

    return this.serviceConfigurationSavePost$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<string>) => r.body as string)
    );
  }

}
