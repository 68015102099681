/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CalendarBaseService } from '../calendar-base-service';
import { CalendarApiConfiguration } from '../calendar-api-configuration';
import { CalendarStrictHttpResponse } from '../calendar-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AvailibilityRequestModel } from '../models/availibility-request-model';
import { BookingDetailModel } from '../models/booking-detail-model';
import { CancelBookingRequestModel } from '../models/cancel-booking-request-model';
import { ConfirmReservationRequest } from '../models/confirm-reservation-request';
import { HealthCareServiceRequest } from '../models/health-care-service-request';
import { HealthCareServiceResponse } from '../models/health-care-service-response';
import { ReservationRequest } from '../models/reservation-request';
import { SessionDetailRespone } from '../models/session-detail-respone';
import { SessionRequestModel } from '../models/session-request-model';
import { SessionResponseModel } from '../models/session-response-model';
import { SpecialitySearchResultModel } from '../models/speciality-search-result-model';
import { UrnAvailabilityDayModelAvailibilityModel } from '../models/urn-availability-day-model-availibility-model';

@Injectable({
  providedIn: 'root',
})
export class CalendarHealthFindrService extends CalendarBaseService {
  constructor(
    config: CalendarApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation healthFindrFhirIdSpecialitiesGet
   */
  static readonly HealthFindrFhirIdSpecialitiesGetPath = '/HealthFindr/{fhirId}/Specialities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrFhirIdSpecialitiesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrFhirIdSpecialitiesGet$Response(params: {
    fhirId: string;
  }): Observable<CalendarStrictHttpResponse<SpecialitySearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarHealthFindrService.HealthFindrFhirIdSpecialitiesGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<SpecialitySearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthFindrFhirIdSpecialitiesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrFhirIdSpecialitiesGet(params: {
    fhirId: string;
  }): Observable<SpecialitySearchResultModel> {

    return this.healthFindrFhirIdSpecialitiesGet$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<SpecialitySearchResultModel>) => r.body as SpecialitySearchResultModel)
    );
  }

  /**
   * Path part for operation healthFindrHealthCareServicesPost
   */
  static readonly HealthFindrHealthCareServicesPostPath = '/HealthFindr/HealthCareServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrHealthCareServicesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrHealthCareServicesPost$Response(params?: {
    body?: HealthCareServiceRequest
  }): Observable<CalendarStrictHttpResponse<Array<HealthCareServiceResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarHealthFindrService.HealthFindrHealthCareServicesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<Array<HealthCareServiceResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthFindrHealthCareServicesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrHealthCareServicesPost(params?: {
    body?: HealthCareServiceRequest
  }): Observable<Array<HealthCareServiceResponse>> {

    return this.healthFindrHealthCareServicesPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<Array<HealthCareServiceResponse>>) => r.body as Array<HealthCareServiceResponse>)
    );
  }

  /**
   * Path part for operation healthFindrAvailibilityPost
   */
  static readonly HealthFindrAvailibilityPostPath = '/HealthFindr/Availibility';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrAvailibilityPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrAvailibilityPost$Response(params?: {
    body?: AvailibilityRequestModel
  }): Observable<CalendarStrictHttpResponse<UrnAvailabilityDayModelAvailibilityModel>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarHealthFindrService.HealthFindrAvailibilityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<UrnAvailabilityDayModelAvailibilityModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthFindrAvailibilityPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrAvailibilityPost(params?: {
    body?: AvailibilityRequestModel
  }): Observable<UrnAvailabilityDayModelAvailibilityModel> {

    return this.healthFindrAvailibilityPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<UrnAvailabilityDayModelAvailibilityModel>) => r.body as UrnAvailabilityDayModelAvailibilityModel)
    );
  }

  /**
   * Path part for operation healthFindrRequestReservationPost
   */
  static readonly HealthFindrRequestReservationPostPath = '/HealthFindr/RequestReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrRequestReservationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrRequestReservationPost$Response(params?: {
    body?: ReservationRequest
  }): Observable<CalendarStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarHealthFindrService.HealthFindrRequestReservationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthFindrRequestReservationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrRequestReservationPost(params?: {
    body?: ReservationRequest
  }): Observable<string> {

    return this.healthFindrRequestReservationPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation healthFindrConfirmReservationPost
   */
  static readonly HealthFindrConfirmReservationPostPath = '/HealthFindr/ConfirmReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrConfirmReservationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrConfirmReservationPost$Response(params?: {
    body?: ConfirmReservationRequest
  }): Observable<CalendarStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarHealthFindrService.HealthFindrConfirmReservationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthFindrConfirmReservationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrConfirmReservationPost(params?: {
    body?: ConfirmReservationRequest
  }): Observable<string> {

    return this.healthFindrConfirmReservationPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation healthFindrConfirmBookingPost
   */
  static readonly HealthFindrConfirmBookingPostPath = '/HealthFindr/ConfirmBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrConfirmBookingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrConfirmBookingPost$Response(params?: {
    body?: BookingDetailModel
  }): Observable<CalendarStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarHealthFindrService.HealthFindrConfirmBookingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthFindrConfirmBookingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrConfirmBookingPost(params?: {
    body?: BookingDetailModel
  }): Observable<string> {

    return this.healthFindrConfirmBookingPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation healthFindrBookingDetailUrnBookingIdGet
   */
  static readonly HealthFindrBookingDetailUrnBookingIdGetPath = '/HealthFindr/BookingDetail/{urnBookingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrBookingDetailUrnBookingIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrBookingDetailUrnBookingIdGet$Response(params: {
    urnBookingId: string;
  }): Observable<CalendarStrictHttpResponse<BookingDetailModel>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarHealthFindrService.HealthFindrBookingDetailUrnBookingIdGetPath, 'get');
    if (params) {
      rb.path('urnBookingId', params.urnBookingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<BookingDetailModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthFindrBookingDetailUrnBookingIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrBookingDetailUrnBookingIdGet(params: {
    urnBookingId: string;
  }): Observable<BookingDetailModel> {

    return this.healthFindrBookingDetailUrnBookingIdGet$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<BookingDetailModel>) => r.body as BookingDetailModel)
    );
  }

  /**
   * Path part for operation healthFindrCancelBookingPost
   */
  static readonly HealthFindrCancelBookingPostPath = '/HealthFindr/CancelBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrCancelBookingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrCancelBookingPost$Response(params?: {
    body?: CancelBookingRequestModel
  }): Observable<CalendarStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarHealthFindrService.HealthFindrCancelBookingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthFindrCancelBookingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrCancelBookingPost(params?: {
    body?: CancelBookingRequestModel
  }): Observable<string> {

    return this.healthFindrCancelBookingPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation healthFindrRequestSessionGet
   */
  static readonly HealthFindrRequestSessionGetPath = '/HealthFindr/RequestSession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrRequestSessionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrRequestSessionGet$Response(params?: {
    accountId?: string;
    parentEntityId?: string;
    firstName?: string;
    surname?: string;
    mobileNumber?: string;
    emailAddress?: string;
    dateOfBirth?: string;
    brokerEmail?: string;
    caseNumber?: string;
    identificationNumber?: string;
    bookingReasonId?: string;
  }): Observable<CalendarStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarHealthFindrService.HealthFindrRequestSessionGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
      rb.query('parentEntityId', params.parentEntityId, {});
      rb.query('firstName', params.firstName, {});
      rb.query('surname', params.surname, {});
      rb.query('mobileNumber', params.mobileNumber, {});
      rb.query('emailAddress', params.emailAddress, {});
      rb.query('dateOfBirth', params.dateOfBirth, {});
      rb.query('brokerEmail', params.brokerEmail, {});
      rb.query('caseNumber', params.caseNumber, {});
      rb.query('identificationNumber', params.identificationNumber, {});
      rb.query('bookingReasonId', params.bookingReasonId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as CalendarStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthFindrRequestSessionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrRequestSessionGet(params?: {
    accountId?: string;
    parentEntityId?: string;
    firstName?: string;
    surname?: string;
    mobileNumber?: string;
    emailAddress?: string;
    dateOfBirth?: string;
    brokerEmail?: string;
    caseNumber?: string;
    identificationNumber?: string;
    bookingReasonId?: string;
  }): Observable<void> {

    return this.healthFindrRequestSessionGet$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation healthFindrRequestSessionPost
   */
  static readonly HealthFindrRequestSessionPostPath = '/HealthFindr/RequestSession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrRequestSessionPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrRequestSessionPost$Response(params?: {
    body?: SessionRequestModel
  }): Observable<CalendarStrictHttpResponse<SessionResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarHealthFindrService.HealthFindrRequestSessionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<SessionResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthFindrRequestSessionPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrRequestSessionPost(params?: {
    body?: SessionRequestModel
  }): Observable<SessionResponseModel> {

    return this.healthFindrRequestSessionPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<SessionResponseModel>) => r.body as SessionResponseModel)
    );
  }

  /**
   * Path part for operation healthFindrSessionDetailSessionIdGet
   */
  static readonly HealthFindrSessionDetailSessionIdGetPath = '/HealthFindr/SessionDetail/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrSessionDetailSessionIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrSessionDetailSessionIdGet$Response(params: {
    sessionId: string;
  }): Observable<CalendarStrictHttpResponse<SessionDetailRespone>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarHealthFindrService.HealthFindrSessionDetailSessionIdGetPath, 'get');
    if (params) {
      rb.path('sessionId', params.sessionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<SessionDetailRespone>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthFindrSessionDetailSessionIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrSessionDetailSessionIdGet(params: {
    sessionId: string;
  }): Observable<SessionDetailRespone> {

    return this.healthFindrSessionDetailSessionIdGet$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<SessionDetailRespone>) => r.body as SessionDetailRespone)
    );
  }

}
