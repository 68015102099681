import { Pipe, PipeTransform } from '@angular/core';
import { CountriesService } from 'src/app/services/countries/countries.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Country } from 'src/app/services/countries/models/country.model';

@Pipe({
	name: 'isoCountryName$'
})
export class isoCountryNamePipe implements PipeTransform {
	constructor(private countriesService: CountriesService) {}

	transform(items: Country[], selected: Country): Observable<any[]> {
		return this.countriesService.Countries$.pipe(
			map((countries: Country[]) => {
				if (!items) return items;
				items = items
					.map(function (item) {
						item.name =
							countries.find(
								(itmInner) =>
									itmInner.alpha2.toLowerCase() ===
									item.alpha2?.toLowerCase()
							)?.name ?? item.name;
						return item;
					})
					.filter(
						(value, index, self) =>
							index ===
							self.findIndex((t) => t.alpha2 == value.alpha2)
					)
					.sort((a, b) => a.name?.localeCompare(b.name));

				if (selected) {
					if (items.find((i) => i.alpha2 == selected.alpha2)) {
						items = items.filter(
							(e) => e.alpha2 != selected.alpha2
						);
						items.unshift(selected);
					}
				}

				return items;
			})
		);
	}
}
