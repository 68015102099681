/* eslint-disable @typescript-eslint/no-misused-promises */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { EventService } from 'src/app/services/events/event.service';
import {
	OAuth2AuthenticateOptions,
	OAuth2RefreshTokenOptions,
	OAuth2Client
} from '@velocitycubed/capacitor-oauth2';
import { environment } from 'src/environments/environment';
import {
	AccessTokenResponse,
	AuthenticateResponse
} from './auth-response.model';
import { UserData } from './user-data.model';
import { TranslatorService } from '../translate/translator.service';
import { B2CRouting } from './B2CRouting-enum';
import * as _ from 'lodash';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { Browser } from '@velocitycubed/capacitor-browser';
import { LoadingService } from '../loading/loading.service';
import { SecureStorageKey } from '../storage/models/secure-storage-key.enum';
import { SecureStorageService } from '../storage/secure-storage.service';
import { combineLatest } from 'rxjs';
import { BookmarkKey } from '../bookmark/enums';
import { BrowserService } from '../browser/browser.service';
import { RegisteredUserProfileService } from '../sessions/registered-user-profile/registered-user-profile.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public userInfo: UserData;
	public accessToken = null;
	private tokenResponse: AccessTokenResponse;
	private refreshInterval: any;
	private lastOAuth2Options: OAuth2AuthenticateOptions;

	private languageMapped: string;
	private contentLanguageDirection: string;

	constructor(
		private http: HttpClient,
		private events: EventService,
		private translatorService: TranslatorService,
		private secureStorageService: SecureStorageService,
		private router: Router,
		private loadingService: LoadingService,
		private browserService: BrowserService,
		private registeredUserProfileService: RegisteredUserProfileService
	) {}

	public initialize(): void {
		combineLatest([
			this.translatorService.languageMapped$,
			this.translatorService.contentLanguageDirection$
		]).subscribe(([languageMapped, contentLanguageDirection]) => {
			this.languageMapped = languageMapped;
			this.contentLanguageDirection = contentLanguageDirection;
		});
	}

	getAzureB2cOAuth2Options(b2cRouting: string): OAuth2AuthenticateOptions {
		const options = _.cloneDeep(environment.authenticateOptions);

		options.additionalParameters.ui_locales = this.languageMapped;
		options.additionalParameters.ui_platform = Capacitor.getPlatform();

		return options;
	}

	async getRefreshTokenOptions(): Promise<OAuth2RefreshTokenOptions> {
		const options = environment.refreshOptions as OAuth2RefreshTokenOptions;
		if (this.tokenResponse?.refresh_token) {
			options.refreshToken = this.tokenResponse.refresh_token;
		} else {
			options.refreshToken = await this.getRefreshToken();
		}

		return options;
	}

	public getToken(): string {
		return this.tokenResponse?.access_token;
	}

	private parseJwt(token) {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(function (c) {
					return (
						'%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
					);
				})
				.join('')
		);
		return JSON.parse(jsonPayload);
	}

	async login(b2cRouting: string, code: string = null): Promise<void> {
		this.browserService.setBrowserOpened(true);
		if (b2cRouting == B2CRouting.signin) {
			await this.removeUserLoggedInAsync();
		}
		await this.loadingService.show();
		this.clearRefresh();

		await this.secureStorageService.set(
			SecureStorageKey.RoutingName,
			b2cRouting
		);

		const oauth2Options = this.getAzureB2cOAuth2Options(b2cRouting);
		this.lastOAuth2Options = oauth2Options;
		if (code) oauth2Options.code = code;

		await OAuth2Client.authenticate(oauth2Options)
			.then(async (data) => {
				const response = data as AuthenticateResponse;
				if (!response.access_token)
					response.access_token =
						response.access_token_response.access_token;

				if (response.access_token && response.access_token.length > 0) {
					this.tokenResponse = response.access_token_response;
					if (!this.tokenResponse.expires_in) {
						this.tokenResponse.expires_in = 55 * 60;
					}
					await this.setRefreshToken(
						this.tokenResponse.refresh_token
					);
					const expiry = moment(new Date())
						.add(this.tokenResponse.refresh_token_expires_in, 's')
						.toDate();

					await this.setRefreshTokenExpiry(expiry);

					let waitTime = 55 * 60; // seconds
					if (this.tokenResponse.expires_in) {
						waitTime = this.tokenResponse.expires_in * 0.7;
					}

					const token = this.parseJwt(response.access_token);
					this.accessToken = response.access_token;
					if (this.tokenResponse.additionalParameters) {
						this.events.clientAccessToken.emit({
							token: response.access_token,
							expiry: this.tokenResponse.additionalParameters
								.expires_on
						});
					} else {
						this.events.clientAccessToken.emit({
							token: response.access_token,
							expiry: this.tokenResponse.expires_on
						});
					}

					this.userInfo = {
						sub: token.sub,
						given_name: token.given_name,
						family_name: token.family_name,
						name: token.name,
						time: moment().toISOString(),
						email: token.email
					};

					await this.setUserLoggedInAsync();
					this.registeredUserProfileService.userIsLoggedIn =
						await this.hasUserLoggedInAsync();
					this.events.isLoggedIn.next(this.userInfo);

					this.refreshInterval = setInterval(() => {
						void this.refresh();
					}, waitTime * 1000);
					await this.router.navigateByUrl('/home');
				} else {
					await this.loadingService.hide();
				}
			})
			.catch(async (reason) => {
				const url = new URL('/welcome', window.location.origin);
				await this.logout(B2CRouting.signin, url.href);
				this.events.removeAccessToken.emit(true);
				console.log(`OAuth rejected ${reason}`);
				await this.loadingService.hide();
			});
	}

	public async logout(
		b2cRouting: string,
		redirectUrl?: string
	): Promise<void> {
		this.browserService.setBrowserOpened(true);
		this.clearRefresh();

		const oauth2Options = this.getAzureB2cOAuth2Options(b2cRouting);

		const url = new URL(environment.authenticateOptions.logoutUrl);
		url.searchParams.set('id_token_hint', this.tokenResponse.id_token);
		url.searchParams.set(
			'post_logout_redirect_uri',
			redirectUrl || window.location.href
		);
		await OAuth2Client.logout(oauth2Options)
			.then(async () => {
				await this.removeUserLoggedInAsync();
				this.registeredUserProfileService.userIsLoggedIn = false;
				await this.removeRefreshToken();
				await this.removeRefreshTokenExpiry();
				if (this.userInfo) {
					this.userInfo.time = moment().toISOString();
					this.events.loggedOut.emit(this.userInfo);
					this.events.isLoggedIn.next(null);
				}
				if (Capacitor.getPlatform() == 'web') {
					window.open(url.toString(), '_self');
				} else {
					await this.loadingService.show();
					await Browser.addListener('browserPageLoaded', async () => {
						await Browser.close();
						await this.loadingService.hide();
						await Browser.removeAllListeners();
						await this.loadingService.hide();
						await this.logoutRemoveEverything();
						document.location.href = 'index.html';
					});

					await this.router.navigate(['welcome']);
					this.events.removeAccessToken.emit(true);

					await Browser.open({ url: url.toString() });
				}
				await this.logoutAll();
			})
			.catch((reason) => {
				console.error('OAuth logout failed', reason);
			});
	}

	private clearRefresh() {
		if (this.refreshInterval) {
			clearInterval(this.refreshInterval);
			this.refreshInterval = null;
		}
	}

	private async refresh(): Promise<void> {
		if (this.lastOAuth2Options?.accessTokenEndpoint) {
			const refreshExpiry = await this.getRefreshTokenExpiry();
			if (refreshExpiry <= Date()) {
				const refreshToken = await this.getRefreshToken();
				const clientId = this.lastOAuth2Options.appId;
				await this.http
					.post(
						this.lastOAuth2Options.accessTokenEndpoint,
						`grant_type=refresh_token&client_id=${clientId}&scope=openid&refresh_token=${refreshToken}`,
						{
							headers: {
								'Content-type':
									'application/x-www-form-urlencoded'
							}
						}
					)
					.toPromise()
					.then(async (data) => {
						const response = data as AccessTokenResponse;
						response.access_token = response.id_token;
						this.tokenResponse = response;
						if (!this.tokenResponse.expires_in) {
							this.tokenResponse.expires_in = 55 * 60;
						}
						if (!response.refresh_token) {
							const options =
								environment.refreshOptions as OAuth2RefreshTokenOptions;
							response.refresh_token = options.refreshToken;
						}
						await this.setRefreshToken(response.refresh_token);
						if (this.tokenResponse.additionalParameters) {
							this.events.clientAccessToken.emit({
								token: response.access_token,
								expiry: this.tokenResponse.additionalParameters
									.expires_on
							});
						} else {
							this.events.clientAccessToken.emit({
								token: response.access_token,
								expiry: this.tokenResponse.expires_on
							});
						}
					})
					.catch((err) => {
						this.clearRefresh();
						console.error('Refreshing token failed', err);
					});
			} else {
				await this.removeRefreshToken();
				await this.removeRefreshTokenExpiry();
				await this.login(B2CRouting.signin);
			}
		}
	}

	public async refreshToken(): Promise<AccessTokenResponse> {
		this.getRefreshTokenOptions();
		await this.refresh();
		return this.tokenResponse;
	}

	private async setUserLoggedInAsync(): Promise<void> {
		await this.secureStorageService.set(
			SecureStorageKey.UserLoggedIn,
			'true'
		);
	}

	public async removeUserLoggedInAsync(): Promise<void> {
		await this.secureStorageService.remove(SecureStorageKey.UserLoggedIn);
	}

	public async hasUserLoggedInAsync(): Promise<boolean> {
		return await this.secureStorageService
			.get(SecureStorageKey.UserLoggedIn)
			.then((value) => value != null);
	}

	public async setRefreshToken(refreshToken): Promise<void> {
		await this.secureStorageService.set(
			SecureStorageKey.RefreshToken,
			refreshToken
		);
	}

	public async removeRefreshToken(): Promise<void> {
		await this.secureStorageService.remove(SecureStorageKey.RefreshToken);
	}

	public async getRefreshToken(): Promise<any> {
		return await this.secureStorageService.get(
			SecureStorageKey.RefreshToken
		);
	}

	public async setRefreshTokenExpiry(expiry): Promise<void> {
		await this.secureStorageService.set(
			SecureStorageKey.RefreshTokenExpiry,
			expiry
		);
	}

	public async removeRefreshTokenExpiry(): Promise<void> {
		await this.secureStorageService.remove(
			SecureStorageKey.RefreshTokenExpiry
		);
	}

	public async getRefreshTokenExpiry(): Promise<any> {
		return await this.secureStorageService.get(
			SecureStorageKey.RefreshTokenExpiry
		);
	}

	public async logoutAll(): Promise<void> {
		await this.secureStorageService.remove(SecureStorageKey.UrlHistory);
		await this.secureStorageService.remove(SecureStorageKey.RoutingName);
		await this.secureStorageService.remove(SecureStorageKey.Language);
		await this.secureStorageService.remove(
			SecureStorageKey.ContentLangOverride
		);
		await this.secureStorageService.remove(BookmarkKey.InProgress);
		await this.secureStorageService.remove(BookmarkKey.Completed);
		await this.secureStorageService.remove(BookmarkKey.Saved);
		await this.logoutRemoveEverything();
	}

	private async logoutRemoveEverything(): Promise<void> {
		await this.removeRefreshTokenExpiry();
		await this.removeRefreshToken();
		await this.removeUserLoggedInAsync();
		this.registeredUserProfileService.userIsLoggedIn = false;
	}
}
