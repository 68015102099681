import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { ionButton } from './ionButton-enum';

@Directive({
	selector: '[textReveal]'
})
export class TextRevealDirective implements AfterViewInit {
	private static device: DeviceInfo;
	private static deviceLoading: boolean;
	private static timer: any;
	private static textRevealIsOpen = false;

	constructor(private elRef: ElementRef) {
		if (!TextRevealDirective.device && !TextRevealDirective.deviceLoading) {
			TextRevealDirective.deviceLoading = true;
			void Device.getInfo().then((d) => (TextRevealDirective.device = d));
			window.addEventListener(
				'scroll',
				(e) => {
					if (TextRevealDirective.textRevealIsOpen == false) {
						clearTimeout(TextRevealDirective.timer);
					}
				},
				true
			);
			window.addEventListener(
				'touchend',
				(e) => {
					if (TextRevealDirective.textRevealIsOpen == false) {
						clearTimeout(TextRevealDirective.timer);
					} else {
						this.eventEnd(e);
					}
				},
				true
			);
			document.documentElement.addEventListener(
				'touchend',
				(e) => {
					if (TextRevealDirective.textRevealIsOpen == false) {
						clearTimeout(TextRevealDirective.timer);
					} else {
						this.eventEnd(e);
					}
				},
				true
			);
			window.addEventListener(
				'click',
				(e) => {
					clearTimeout(TextRevealDirective.timer);
				},
				true
			);
		}
	}

	private element: HTMLElement;
	@Input() textReveal?: string;

	ngAfterViewInit(): void {
		if (
			this.elRef != null &&
			(TextRevealDirective.device != null ||
				this.textReveal == ionButton.label ||
				this.textReveal == ionButton.buttonInnerWithIcon ||
				this.textReveal == ionButton.buttonInnerTextOnly ||
				this.textReveal == 'vertical' ||
				this.textReveal == 'shadow')
		) {
			this.elRef.nativeElement.addEventListener('touchstart', (e) => {
				if (TextRevealDirective.textRevealIsOpen == false) {
					clearTimeout(TextRevealDirective.timer);
					TextRevealDirective.timer = setTimeout(
						() => {
							this.elementStyling(e);
						},
						Capacitor.getPlatform() == 'ios' ? 800 : 400
					);
				} else {
					this.eventEnd(e);
				}
			});
			this.elRef.nativeElement.addEventListener('mouseleave', (e) => {
				if (TextRevealDirective.textRevealIsOpen == false) {
					clearTimeout(TextRevealDirective.timer);
				}
			});
			this.elRef.nativeElement.addEventListener('mouseover', (e) => {
				if (
					TextRevealDirective.textRevealIsOpen == false &&
					Capacitor.getPlatform() == 'web'
				) {
					TextRevealDirective.timer = setTimeout(() => {
						this.elementStyling(e);
					}, 400);
				}
			});
		}
	}

	private eventEnd(e) {
		if (TextRevealDirective.textRevealIsOpen == true) {
			TextRevealDirective.textRevealIsOpen = false;
			this.element = document.getElementById('hover-container');
			this.element.innerHTML = '';
			this.element.style.visibility = 'hidden';
		}
	}

	private elementStyling(e: any) {
		TextRevealDirective.textRevealIsOpen = true;
		let scrollWidth = this.elRef.nativeElement.scrollWidth;
		let clientWidth = this.elRef.nativeElement.clientWidth;
		let scrollHeight = this.elRef.nativeElement.scrollHeight;
		let clientHeight = this.elRef.nativeElement.clientHeight;
		if (this.textReveal == 'shadow') {
			if (Capacitor.getPlatform() == 'ios') {
				scrollWidth =
					this.elRef.nativeElement.shadowRoot.childNodes[1]
						.scrollWidth;
				clientWidth =
					this.elRef.nativeElement.shadowRoot.childNodes[1]
						.clientWidth;
			} else {
				scrollWidth =
					this.elRef.nativeElement.shadowRoot.childNodes[0]
						.scrollWidth;
				clientWidth =
					this.elRef.nativeElement.shadowRoot.childNodes[0]
						.clientWidth;
			}
		} else if (this.textReveal == ionButton.label) {
			scrollWidth = this.elRef.nativeElement.children[0].scrollWidth;
			clientWidth = this.elRef.nativeElement.children[0].clientWidth;
			scrollHeight = clientHeight;
		} else if (this.textReveal == ionButton.buttonInnerWithIcon) {
			scrollWidth =
				this.elRef.nativeElement.children[0].children[1].scrollWidth;
			clientWidth =
				this.elRef.nativeElement.children[0].children[1].clientWidth;
			scrollHeight = clientHeight;
		} else if (this.textReveal == ionButton.buttonInnerTextOnly) {
			scrollWidth =
				this.elRef.nativeElement.children[0].children[0].scrollWidth;
			clientWidth =
				this.elRef.nativeElement.children[0].children[0].clientWidth;
			scrollHeight = clientHeight;
		}
		if (
			document.documentElement.lang == 'zh' ||
			document.documentElement.lang == 'zh-hant' ||
			document.documentElement.lang == 'ja'
		) {
			scrollHeight = this.elRef.nativeElement.offsetHeight;
		}
		if (document.documentElement.lang == 'ar') {
			if (
				this.elRef.nativeElement.scrollHeight -
					this.elRef.nativeElement.offsetHeight >
				10
			) {
				scrollHeight = this.elRef.nativeElement.scrollHeight;
			} else {
				scrollHeight = this.elRef.nativeElement.offsetHeight;
			}
		}
		if (
			(document.documentElement.lang == 'ar' && this.textReveal != '') ||
			(document.documentElement.lang != 'ar' && this.textReveal == '')
		) {
			const addToClientHeight =
				document.documentElement.style.getPropertyValue(
					'--ion-client-height-override'
				);
			clientHeight = parseInt(clientHeight) + parseInt(addToClientHeight);
		}
		if (
			scrollWidth > clientWidth ||
			(scrollHeight > clientHeight && this.textReveal == 'vertical')
		) {
			this.element = document.getElementById('hover-container');
			if (
				this.element.style.visibility != 'visible' &&
				e.target.innerText != ''
			) {
				this.element.innerHTML = `<span class="hover-text">${e.target.innerText}</span>`;
				this.element.style.visibility = 'visible';
				const text = this.element.querySelector('span') as HTMLElement;
				text.style.width = window
					.getComputedStyle(this.elRef.nativeElement, null)
					.getPropertyValue('width');
				TextRevealDirective.device.operatingSystem == 'ios'
					? (text.style.maxHeight = 'max-content')
					: TextRevealDirective.device.operatingSystem == 'android'
					? (text.style.maxHeight = 'max-content')
					: (text.style.maxHeight = text.style.width);
				text.style.fontSize = window
					.getComputedStyle(this.elRef.nativeElement, null)
					.getPropertyValue('font-size');
				text.style.fontStyle = window
					.getComputedStyle(this.elRef.nativeElement, null)
					.getPropertyValue('font-style');
				text.style.fontWeight = window
					.getComputedStyle(this.elRef.nativeElement, null)
					.getPropertyValue('font-weight');
				text.style.lineHeight = window
					.getComputedStyle(this.elRef.nativeElement, null)
					.getPropertyValue('line-height');
				text.style.color = window
					.getComputedStyle(this.elRef.nativeElement, null)
					.getPropertyValue('color');
				if (
					text.style.color == 'rgb(255, 255, 255)' ||
					text.style.color == 'white' ||
					text.style.color == '#FFFFFF' ||
					text.style.color == '#ffffff'
				) {
					text.style.color = 'var(--ion-color-dark)';
				}
				const fromTop =
					this.elRef.nativeElement.getBoundingClientRect().top;
				const textheight = window
					.getComputedStyle(text, null)
					.getPropertyValue('height');
				if (
					parseInt(fromTop) + parseInt(textheight) >
					window.innerHeight
				) {
					text.style.top = `calc(calc(${
						window.innerHeight - parseInt(textheight)
					}px - env(safe-area-inset-bottom)) - env(safe-area-inset-top))`;
				} else {
					text.style.top = `calc(calc(${
						this.elRef.nativeElement.getBoundingClientRect().top
					}px - env(safe-area-inset-bottom)) - env(safe-area-inset-top))`;
				}

				text.style.left = `${
					this.elRef.nativeElement.getBoundingClientRect().left
				}px`;
				if (
					TextRevealDirective.device.operatingSystem != 'ios' &&
					TextRevealDirective.device.operatingSystem != 'android'
				) {
					text.addEventListener('mouseleave', (e) => {
						this.eventEnd(e);
					});
				}
			}
		} else {
			TextRevealDirective.textRevealIsOpen = false;
		}
	}
}
