import { Component, Input, SimpleChanges } from '@angular/core';
import { LoadingService } from 'src/app/services/loading/loading.service';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
	@Input()
	public loading = false;

	@Input()
	public spinner = false;

	constructor(private loadingService: LoadingService) {}

	async ngOnChanges(changes: SimpleChanges) {
		if (this.loadingService.loading) {
			await this.loadingService.hide();
		}
		if (!this.spinner) {
			if (changes.loading.currentValue == true) {
				await this.loadingService.show();
			} else {
				await this.loadingService.hide();
			}
		}
	}
}
