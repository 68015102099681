/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SearchRequestModel } from '../models/search-request-model';
import { SystemIdentifierRecordModel } from '../models/system-identifier-record-model';
import { SystemIdentifierRecordModelSearchResultModel } from '../models/system-identifier-record-model-search-result-model';

@Injectable({
  providedIn: 'root',
})
export class MDISystemIdentifierService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation systemIdentifierSearchSystemIdentifierPost
   */
  static readonly SystemIdentifierSearchSystemIdentifierPostPath = '/SystemIdentifier/SearchSystemIdentifier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemIdentifierSearchSystemIdentifierPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  systemIdentifierSearchSystemIdentifierPost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<SystemIdentifierRecordModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDISystemIdentifierService.SystemIdentifierSearchSystemIdentifierPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<SystemIdentifierRecordModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `systemIdentifierSearchSystemIdentifierPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  systemIdentifierSearchSystemIdentifierPost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<SystemIdentifierRecordModelSearchResultModel> {

    return this.systemIdentifierSearchSystemIdentifierPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<SystemIdentifierRecordModelSearchResultModel>) => r.body as SystemIdentifierRecordModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemIdentifierSearchSystemIdentifierPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  systemIdentifierSearchSystemIdentifierPost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<SystemIdentifierRecordModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDISystemIdentifierService.SystemIdentifierSearchSystemIdentifierPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<SystemIdentifierRecordModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `systemIdentifierSearchSystemIdentifierPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  systemIdentifierSearchSystemIdentifierPost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<SystemIdentifierRecordModelSearchResultModel> {

    return this.systemIdentifierSearchSystemIdentifierPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<SystemIdentifierRecordModelSearchResultModel>) => r.body as SystemIdentifierRecordModelSearchResultModel)
    );
  }

  /**
   * Path part for operation systemIdentifierIdGet
   */
  static readonly SystemIdentifierIdGetPath = '/SystemIdentifier/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemIdentifierIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemIdentifierIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<SystemIdentifierRecordModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDISystemIdentifierService.SystemIdentifierIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<SystemIdentifierRecordModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `systemIdentifierIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemIdentifierIdGet$Plain(params: {
    id: string;
  }): Observable<SystemIdentifierRecordModel> {

    return this.systemIdentifierIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<SystemIdentifierRecordModel>) => r.body as SystemIdentifierRecordModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemIdentifierIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemIdentifierIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<SystemIdentifierRecordModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDISystemIdentifierService.SystemIdentifierIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<SystemIdentifierRecordModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `systemIdentifierIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemIdentifierIdGet$Json(params: {
    id: string;
  }): Observable<SystemIdentifierRecordModel> {

    return this.systemIdentifierIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<SystemIdentifierRecordModel>) => r.body as SystemIdentifierRecordModel)
    );
  }

  /**
   * Path part for operation systemIdentifierPost
   */
  static readonly SystemIdentifierPostPath = '/SystemIdentifier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemIdentifierPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  systemIdentifierPost$Plain$Response(params?: {
    body?: SystemIdentifierRecordModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDISystemIdentifierService.SystemIdentifierPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `systemIdentifierPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  systemIdentifierPost$Plain(params?: {
    body?: SystemIdentifierRecordModel
  }): Observable<string> {

    return this.systemIdentifierPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemIdentifierPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  systemIdentifierPost$Json$Response(params?: {
    body?: SystemIdentifierRecordModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDISystemIdentifierService.SystemIdentifierPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `systemIdentifierPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  systemIdentifierPost$Json(params?: {
    body?: SystemIdentifierRecordModel
  }): Observable<string> {

    return this.systemIdentifierPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
