import { Injectable } from '@angular/core';
import { TranslatorService } from '../translate/translator.service';
import * as GoogleFontsLoader from 'google-fonts-loader';
import { LanguagesDisplayService } from '../languages-display/languages-display.service';

@Injectable({
	providedIn: 'root'
})
export class LanguageSpecificOverridesService {
	constructor(
		private translatorService: TranslatorService,
		public languageDisplayService: LanguagesDisplayService
	) {}

	public initialize(): void {
		this.translatorService.contentLanguageMapped$.subscribe((language) => {
			this.contentLanguageUpdate(language);
		});

		this.translatorService.language$.subscribe((language) => {
			this.updateFonts(
				language,
				['--ion-font-family', '--ion-content-font-family'],
				true
			);
		});

		this.translatorService.availableLanguages.forEach((lang) => {
			if (lang.fontFamily != 'ApaxThree' && lang.fontFamily != null) {
				GoogleFontsLoader.preloadByFamilyNames([
					{ family: lang.fontFamily, wght: 300 },
					{ family: lang.fontFamily, wght: 300, ital: 1 },
					{ family: lang.fontFamily, wght: 400 },
					{ family: lang.fontFamily, wght: 400, ital: 1 },
					{ family: lang.fontFamily, wght: 500 },
					{ family: lang.fontFamily, wght: 500, ital: 1 },
					{ family: lang.fontFamily, wght: 700 },
					{ family: lang.fontFamily, wght: 700, ital: 1 }
				]);
				this.loadedFonts.push(lang.fontFamily);
			}
		});
	}

	private loadedFonts = [];

	public contentLanguageUpdate(lang) {
		if (
			document.documentElement.style.getPropertyValue(
				'--ion-content-font-family'
			) !=
			document.documentElement.style.getPropertyValue('--ion-font-family')
		) {
			this.updateFonts(lang, ['--ion-content-font-family'], false);
		}
	}

	public updateFonts(
		lang: string,
		properties: Array<string>,
		updateRoot = false
	) {
		const fontFamily = this.translatorService.availableLanguages.find(
			(e) => e.code == lang
		).fontFamily;
		const fontSize = this.translatorService.availableLanguages.find(
			(e) => e.code == lang
		).fontSize;
		const lineHeight = this.translatorService.availableLanguages.find(
			(e) => e.code == lang
		).lineHeight;
		const textRevealClientHeightAdd =
			this.translatorService.availableLanguages.find(
				(e) => e.code == lang
			).textRevealClientHeightAdd;
		if (
			!this.loadedFonts.find((e) => e == fontFamily) &&
			fontFamily != 'ApaxThree' &&
			fontFamily != null
		) {
			GoogleFontsLoader.load([
				{ family: fontFamily, wght: 300 },
				{ family: fontFamily, wght: 300, ital: 1 },
				{ family: fontFamily, wght: 400 },
				{ family: fontFamily, wght: 400, ital: 1 },
				{ family: fontFamily, wght: 500 },
				{ family: fontFamily, wght: 500, ital: 1 },
				{ family: fontFamily, wght: 700 },
				{ family: fontFamily, wght: 700, ital: 1 }
			]);
			this.loadedFonts.push(fontFamily);
		}
		if (updateRoot == true) {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
			const r = document.querySelector(':root') as HTMLElement;
			r.style.setProperty(
				'font-family',
				fontFamily == null ? 'ApaxThree' : fontFamily
			);
			if (fontSize) {
				document.documentElement.style.setProperty(
					'--ion-font-size-intro-heading',
					fontSize[0]
				);
				document.documentElement.style.setProperty(
					'--ion-font-size-intro-body',
					fontSize[1]
				);
			} else {
				document.documentElement.style.setProperty(
					'--ion-font-size-intro-heading',
					'8.8vw'
				);
				document.documentElement.style.setProperty(
					'--ion-font-size-intro-body',
					'32.vh'
				);
			}

			if (lineHeight) {
				document.documentElement.style.setProperty(
					'--ion-line-height-max-override',
					lineHeight[0]
				);
				document.documentElement.style.setProperty(
					'--ion-line-height-max',
					lineHeight[1]
				);
				document.documentElement.style.setProperty(
					'--ion-line-height-mid-max',
					lineHeight[2]
				);
				document.documentElement.style.setProperty(
					'--ion-line-height-mid',
					lineHeight[3]
				);
				document.documentElement.style.setProperty(
					'--ion-line-height-min-mid',
					lineHeight[4]
				);
				document.documentElement.style.setProperty(
					'--ion-line-height-min',
					lineHeight[5]
				);
				if (lineHeight[6]) {
					document.documentElement.style.setProperty(
						'--ion-line-height-icon-override',
						lineHeight[6]
					);
				}
			} else {
				document.documentElement.style.setProperty(
					'--ion-line-height-max-override',
					'inherit'
				);
				document.documentElement.style.setProperty(
					'--ion-line-height-max',
					'inherit'
				);
				document.documentElement.style.setProperty(
					'--ion-line-height-mid-max',
					'28.8px'
				);
				document.documentElement.style.setProperty(
					'--ion-line-height-mid',
					'inherit'
				);
				document.documentElement.style.setProperty(
					'--ion-line-height-min-mid',
					'inherit'
				);
				document.documentElement.style.setProperty(
					'--ion-line-height-min',
					'inherit'
				);
			}
			if (textRevealClientHeightAdd) {
				document.documentElement.style.setProperty(
					'--ion-client-height-override',
					textRevealClientHeightAdd
				);
			}
		}
		properties.forEach((property) => {
			document.documentElement.style.setProperty(
				property,
				fontFamily == null ? 'ApaxThree' : fontFamily
			);
		});
	}
}
