/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MediaBaseService } from '../media-base-service';
import { MediaApiConfiguration } from '../media-api-configuration';
import { MediaStrictHttpResponse } from '../media-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { KeyValueModel } from '../models/key-value-model';
import { PublisherListModel } from '../models/publisher-list-model';
import { PublisherModel } from '../models/publisher-model';
import { PublisherSearchItemModelSearchResultModel } from '../models/publisher-search-item-model-search-result-model';
import { PublisherUpdateModel } from '../models/publisher-update-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MediaPublisherService extends MediaBaseService {
  constructor(
    config: MediaApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation publisherSearchPublisherPost
   */
  static readonly PublisherSearchPublisherPostPath = '/Publisher/SearchPublisher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publisherSearchPublisherPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publisherSearchPublisherPost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MediaStrictHttpResponse<PublisherSearchItemModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaPublisherService.PublisherSearchPublisherPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<PublisherSearchItemModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publisherSearchPublisherPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publisherSearchPublisherPost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<PublisherSearchItemModelSearchResultModel> {

    return this.publisherSearchPublisherPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<PublisherSearchItemModelSearchResultModel>) => r.body as PublisherSearchItemModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publisherSearchPublisherPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publisherSearchPublisherPost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MediaStrictHttpResponse<PublisherSearchItemModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaPublisherService.PublisherSearchPublisherPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<PublisherSearchItemModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publisherSearchPublisherPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publisherSearchPublisherPost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<PublisherSearchItemModelSearchResultModel> {

    return this.publisherSearchPublisherPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<PublisherSearchItemModelSearchResultModel>) => r.body as PublisherSearchItemModelSearchResultModel)
    );
  }

  /**
   * Path part for operation publisherIdGet
   */
  static readonly PublisherIdGetPath = '/Publisher/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publisherIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  publisherIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MediaStrictHttpResponse<PublisherModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaPublisherService.PublisherIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<PublisherModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publisherIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publisherIdGet$Plain(params: {
    id: string;
  }): Observable<PublisherModel> {

    return this.publisherIdGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<PublisherModel>) => r.body as PublisherModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publisherIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  publisherIdGet$Json$Response(params: {
    id: string;
  }): Observable<MediaStrictHttpResponse<PublisherModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaPublisherService.PublisherIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<PublisherModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publisherIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publisherIdGet$Json(params: {
    id: string;
  }): Observable<PublisherModel> {

    return this.publisherIdGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<PublisherModel>) => r.body as PublisherModel)
    );
  }

  /**
   * Path part for operation publisherPublisherListGet
   */
  static readonly PublisherPublisherListGetPath = '/Publisher/PublisherList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publisherPublisherListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  publisherPublisherListGet$Plain$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<PublisherListModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaPublisherService.PublisherPublisherListGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<PublisherListModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publisherPublisherListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publisherPublisherListGet$Plain(params?: {
  }): Observable<Array<PublisherListModel>> {

    return this.publisherPublisherListGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<PublisherListModel>>) => r.body as Array<PublisherListModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publisherPublisherListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  publisherPublisherListGet$Json$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<PublisherListModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaPublisherService.PublisherPublisherListGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<PublisherListModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publisherPublisherListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publisherPublisherListGet$Json(params?: {
  }): Observable<Array<PublisherListModel>> {

    return this.publisherPublisherListGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<PublisherListModel>>) => r.body as Array<PublisherListModel>)
    );
  }

  /**
   * Path part for operation publisherPost
   */
  static readonly PublisherPostPath = '/Publisher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publisherPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publisherPost$Plain$Response(params?: {
    body?: PublisherUpdateModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaPublisherService.PublisherPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publisherPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publisherPost$Plain(params?: {
    body?: PublisherUpdateModel
  }): Observable<string> {

    return this.publisherPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publisherPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publisherPost$Json$Response(params?: {
    body?: PublisherUpdateModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaPublisherService.PublisherPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publisherPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publisherPost$Json(params?: {
    body?: PublisherUpdateModel
  }): Observable<string> {

    return this.publisherPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation publisherIdLanguagesGet
   */
  static readonly PublisherIdLanguagesGetPath = '/Publisher/{id}/Languages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publisherIdLanguagesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  publisherIdLanguagesGet$Plain$Response(params: {
    id: string;
  }): Observable<MediaStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaPublisherService.PublisherIdLanguagesGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publisherIdLanguagesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publisherIdLanguagesGet$Plain(params: {
    id: string;
  }): Observable<Array<KeyValueModel>> {

    return this.publisherIdLanguagesGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publisherIdLanguagesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  publisherIdLanguagesGet$Json$Response(params: {
    id: string;
  }): Observable<MediaStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaPublisherService.PublisherIdLanguagesGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publisherIdLanguagesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publisherIdLanguagesGet$Json(params: {
    id: string;
  }): Observable<Array<KeyValueModel>> {

    return this.publisherIdLanguagesGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

}
