import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { TokenService } from 'src/app/services/token/token.service';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
	constructor(private token: TokenService) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		return from(this.handle(request, next));
	}

	async handle(
		request: HttpRequest<any>,
		next: HttpHandler
	): Promise<HttpEvent<unknown>> {
		if (
			request.url.includes('/token/') ||
			request.url.includes('/atlas/location')
		)
			return next.handle(request).toPromise();

		const token = await this.token.getTokenAsync();

		if (token) {
			request = request.clone({
				setHeaders: {
					Authorization: 'Bearer ' + token
				}
			});
		}
		return next.handle(request).toPromise();
	}
}
