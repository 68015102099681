import { Injectable } from '@angular/core';
import { LanguagePage } from 'src/app/shared/pages/language/language.page';
import { ModalService } from '../modal/modal.service';
import { ThemeService } from '../theme/theme.service';
import { HyperlinkStructure } from './model/hyperlink';
import { Location } from '@angular/common';
import { CountryPage } from 'src/app/shared/pages/country/country.page';
@Injectable({
	providedIn: 'root'
})
export class HyperlinkService {
	public fromHyperlink = false;

	private allHyperlinks: HyperlinkStructure[] = [];

	constructor(
		private modalService: ModalService,
		private themeService: ThemeService,
		private location: Location
	) {
		this.reloadHyperlinks();
		this.modalService.hyperlinkModalClosed$.subscribe(
			(e) => void this.removeHyperlink(e)
		);
	}

	public async navigateTo(
		url: string,
		fromUrl: string,
		newProps = null
	): Promise<void> {
		this.reloadHyperlinks();
		const destination = this.allHyperlinks.find((link) => {
			if (link.regex.test(url)) {
				link.hyperlink = url;
				link.fromUrl = fromUrl;
				return link;
			}
		});

		if (destination) {
			this.fromHyperlink = true;
			destination.componentProps = newProps
				? newProps
				: destination.componentProps;
			await this.openDestination(destination);
		}
	}

	private async openDestination(link: HyperlinkStructure): Promise<void> {
		await this.modalService
			.viewDrawerModalAsync(
				link.componentProps,
				link.component,
				link.cssClass,
				link.id
			)
			.then((_) => {
				this.setHyperlink(link.hyperlink);
			});
	}

	private setHyperlink(hyperlink: string): void {
		this.location.replaceState(hyperlink);
	}

	public removeHyperlink(id: string): void {
		const loc = window.location.pathname;
		this.allHyperlinks.find((link) => {
			if (link.id === id && link.hyperlink === loc) {
				this.location.replaceState(link.fromUrl);
			}
		});
	}

	private reloadHyperlinks(): void {
		this.allHyperlinks = [
			{
				hyperlink: '',
				regex: /^\/(home)\/(settings)\/(supported-languages)$/,
				fromUrl: '',
				componentProps: {},
				component: LanguagePage,
				cssClass: 'drawer-modal',
				id: 'LanguagePage',
				animated: true
			},
			{
				hyperlink: '',
				regex: /^\/(home)\/(healtFindr)\/(supported-languages)$/,
				fromUrl: '',
				componentProps: {},
				component: LanguagePage,
				cssClass: 'drawer-modal',
				id: 'LanguagePage',
				animated: true
			},
			{
				hyperlink: '',
				regex: /^\/(home)\/(settings)\/(country)$/,
				fromUrl: '',
				componentProps: { fromSettings: true },
				component: CountryPage,
				cssClass: 'drawer-modal',
				id: 'CountryPage',
				animated: true
			},
			{
				hyperlink: '',
				regex: /^\/(home)\/(settings)\/(country)$/,
				fromUrl: '',
				componentProps: { fromSettings: true },
				component: CountryPage,
				cssClass: 'drawer-modal',
				id: 'CountryPage',
				animated: true
			}
		];
	}
}
