/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { QualificationTypeModel } from '../models/qualification-type-model';
import { QualificationTypeModelSearchResultModel } from '../models/qualification-type-model-search-result-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MDIQualificationTypeService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation qualificationTypeSearchQualificationTypePost
   */
  static readonly QualificationTypeSearchQualificationTypePostPath = '/QualificationType/SearchQualificationType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qualificationTypeSearchQualificationTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  qualificationTypeSearchQualificationTypePost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<QualificationTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIQualificationTypeService.QualificationTypeSearchQualificationTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<QualificationTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qualificationTypeSearchQualificationTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  qualificationTypeSearchQualificationTypePost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<QualificationTypeModelSearchResultModel> {

    return this.qualificationTypeSearchQualificationTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<QualificationTypeModelSearchResultModel>) => r.body as QualificationTypeModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qualificationTypeSearchQualificationTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  qualificationTypeSearchQualificationTypePost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<QualificationTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIQualificationTypeService.QualificationTypeSearchQualificationTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<QualificationTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qualificationTypeSearchQualificationTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  qualificationTypeSearchQualificationTypePost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<QualificationTypeModelSearchResultModel> {

    return this.qualificationTypeSearchQualificationTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<QualificationTypeModelSearchResultModel>) => r.body as QualificationTypeModelSearchResultModel)
    );
  }

  /**
   * Path part for operation qualificationTypeIdGet
   */
  static readonly QualificationTypeIdGetPath = '/QualificationType/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qualificationTypeIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  qualificationTypeIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<QualificationTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIQualificationTypeService.QualificationTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<QualificationTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qualificationTypeIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qualificationTypeIdGet$Plain(params: {
    id: string;
  }): Observable<QualificationTypeModel> {

    return this.qualificationTypeIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<QualificationTypeModel>) => r.body as QualificationTypeModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qualificationTypeIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  qualificationTypeIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<QualificationTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIQualificationTypeService.QualificationTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<QualificationTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qualificationTypeIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qualificationTypeIdGet$Json(params: {
    id: string;
  }): Observable<QualificationTypeModel> {

    return this.qualificationTypeIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<QualificationTypeModel>) => r.body as QualificationTypeModel)
    );
  }

  /**
   * Path part for operation qualificationTypePost
   */
  static readonly QualificationTypePostPath = '/QualificationType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qualificationTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  qualificationTypePost$Plain$Response(params?: {
    body?: QualificationTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIQualificationTypeService.QualificationTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qualificationTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  qualificationTypePost$Plain(params?: {
    body?: QualificationTypeModel
  }): Observable<string> {

    return this.qualificationTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qualificationTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  qualificationTypePost$Json$Response(params?: {
    body?: QualificationTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIQualificationTypeService.QualificationTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qualificationTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  qualificationTypePost$Json(params?: {
    body?: QualificationTypeModel
  }): Observable<string> {

    return this.qualificationTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
