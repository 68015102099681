import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root'
})
export class TitleHelperService {
	public content = null;

	constructor(
		public currentRoute: ActivatedRoute,
		public titleService: Title
	) {}

	public setPageTitle(content: string = null) {
		this.content = content;
		if (content == null) {
			if (this.currentRoute.snapshot.firstChild.firstChild != null) {
				if (
					this.currentRoute.snapshot.firstChild.firstChild
						.firstChild != null
				) {
					this.titleService.setTitle(`Medical Advisor`);
					return;
				}
				this.titleService.setTitle(`Medical Advisor`);
				return;
			}
			this.titleService.setTitle(`Medical Advisor`);
			return;
		}
		this.titleService.setTitle(`Medical Advisor`);
		return;
	}

	public getTitle() {
		return this.titleService.getTitle();
	}
}
