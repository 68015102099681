import {
	HttpClientModule,
	HttpClient,
	HTTP_INTERCEPTORS
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { AuthHeaderInterceptor } from './shared/auth/auth-header.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DeviceIDInterceptor } from './services/device-id-interceptor/device-id.interceptor';
import { CountryModule } from './shared/pages/country/country.module';
import { environment } from 'src/environments/environment';
import { i18nArrayTranslatePipe } from './shared/pipes/i18n-translate/i18n-array-translate/i18n-array-translate.pipe';
import { SharedModule } from './shared/shared.module';
import { MPIModule } from './api/proxy/mpi/mpi.module';
import { AuthModule } from './api/proxy/auth/auth.module';
import { MDIModule } from './api/proxy/mdi/mdi.module';
import { MediaModule } from './api/proxy/media/media.module';
import { OrganizationModule } from './api/proxy/organization/organization.module';
import { PractitionerModule } from './api/proxy/practitioner/practitioner.module';
import { CalendarModule } from './api/proxy/calendar/calendar.module';
import { CoreModule } from './core/core.module';
import { APP_BASE_HREF } from '@angular/common';
import { AuthConfigModule } from './shared/auth/auth-config.module';
import { HealthFindrModule } from './api/proxy/healthFindr/health-findr.module';

export function LanguageLoader(http: HttpClient) {
	return new TranslateHttpLoader(
		http,
		'assets/i18n/',
		'.json?' + environment.build
	);
}

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		IonicModule.forRoot({
			rippleEffect: false,
			mode: 'ios'
		}),
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		CountryModule,
		SharedModule,
		CoreModule,

		AuthConfigModule,
		MPIModule.forRoot({ rootUrl: environment.mpiBaseUrl }),
		MDIModule.forRoot({ rootUrl: environment.mdiBaseUrl }),
		OrganizationModule.forRoot({
			rootUrl: environment.organizationBaseUrl
		}),
		MediaModule.forRoot({ rootUrl: environment.mediaBaseUrl }),
		PractitionerModule.forRoot({
			rootUrl: environment.practitionerBaseUrl
		}),
		AuthModule.forRoot({ rootUrl: environment.authBaseUrl }),
		CalendarModule.forRoot({ rootUrl: environment.calendarBaseUrl }),
		HealthFindrModule.forRoot({ rootUrl: environment.healthFindrBaseUrl })
	],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		i18nArrayTranslatePipe,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthHeaderInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: DeviceIDInterceptor,
			multi: true
		},
		{ provide: APP_BASE_HREF, useValue: '/' }
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
