import { Injectable } from '@angular/core';
import {
	AnimationController,
	ModalController,
	ModalOptions,
	Animation
} from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { ContentViewComponent } from 'src/app/shared/components/content-view/content-view.component';
import { Timer } from 'src/app/utilities';
import { ThemeService } from '../theme/theme.service';
import { ReopenOnDismiss } from './models/reopen-on-dismiss.model';

declare type CustomAnimationBuilder = (
	baseEl: HTMLElement,
	opts?: any
) => Animation;

@Injectable({
	providedIn: 'root'
})
export class ModalService {
	private isModalOpen: Subject<boolean> = new Subject<boolean>();
	public isModalOpen$: Observable<boolean> = this.isModalOpen.asObservable();

	private reopenOnDismiss: Subject<ReopenOnDismiss> =
		new Subject<ReopenOnDismiss>();
	public reopenOnDismiss$: Observable<ReopenOnDismiss> =
		this.reopenOnDismiss.asObservable();

	private hyperlinkModalClosed: Subject<string> = new Subject<string>();
	public hyperlinkModalClosed$: Observable<string> =
		this.hyperlinkModalClosed.asObservable();

	private isProcessing = false;
	private openModalIds: string[] = [];
	private modalOptions: ModalOptions[] = [];
	private ignoreDirection = false;

	constructor(
		private modalController: ModalController,
		private animationC: AnimationController,
		private themeService: ThemeService
	) {}

	public async dismissModalAsync(
		id: string,
		reopenOnDismissData: ReopenOnDismiss = null
	): Promise<void> {
		try {
			await this.modalController.dismiss(
				reopenOnDismissData,
				undefined,
				id
			);
		} catch (error) {
			return;
		}
	}

	/**
	 * Close all open modals
	 */
	public async closeModalsAsync(): Promise<void> {
		if (this.isModalOpen || this.modalOptions.length > 0) {
			this.claerModalIds();
			this.modalOptions = [];
			this.ignoreDirection = false;

			let topMost = await this.modalController.getTop();
			while (topMost) {
				await topMost.dismiss();
				topMost = await this.modalController.getTop();
			}
		}
	}

	//#region Content View Modals
	public async contentDismissModalAsync(): Promise<void> {
		this.ignoreDirection = false;
		await this.modalController.dismiss({
			dismissed: true
		});
	}

	public async contentViewByContentModal(
		currentContentId: string,
		contentIds: string[],
		stack?
	): Promise<void> {
		await this.contentViewModalAsync(
			{
				currentContentId: currentContentId,
				contentIds: contentIds,
				allowSwipe: false,
				stack: stack
			},
			ContentViewComponent
		);
	}

	public async contentViewByStackModal(
		stack,
		contentId: string
	): Promise<void> {
		await this.contentViewModalAsync(
			{
				stack,
				allowSwipe: true,
				currentContentId: contentId
			},
			ContentViewComponent
		);
	}

	private async contentViewModalAsync(
		componentProps: any,
		component: any
	): Promise<void> {
		const modalOption: ModalOptions = {
			component: component,
			componentProps: componentProps,
			cssClass: 'custom-modal',
			backdropDismiss: true,
			id: this.modalOptions.length.toString()
		};

		const modal = await this.modalController.create(modalOption);
		await modal.present();

		if (this.modalOptions.length > 0) {
			const idToDismiss = this.modalOptions.length - 1;
			void this.modalController.dismiss(
				{ fakeDismiss: true },
				null,
				idToDismiss.toString()
			);
		}

		this.modalOptions.push(modalOption);

		void modal.onWillDismiss().then(async (event) => {
			await this.contentDismissEventAsync(event);
		});
	}

	private async contentDismissEventAsync(event: any) {
		this.ignoreDirection = false;
		if (event && event.data && !event.data.fakeDismiss) {
			this.modalOptions.pop();
			const modalOptionToDisplay = this.modalOptions.slice(-1)[0];

			if (modalOptionToDisplay) {
				const modal = await this.modalController.create(
					modalOptionToDisplay
				);
				void modal.onWillDismiss().then(async (event) => {
					await this.contentDismissEventAsync(event);
				});
				await modal.present();
			}
		}
	}
	//#endregion Content View Modals

	public async viewDrawerModalAsync(
		componentProps: any,
		component: any,
		cssClass: string,
		id: string,
		animated = true
	): Promise<void> {
		let counter = 0;
		do {
			await Timer.sleep(100);
			counter++;
		} while (this.isProcessing && counter < 3);

		if (!this.isProcessing) {
			this.isProcessing = true;

			try {
				if (
					this.openModalIds.find((openModalId) => openModalId == id)
				) {
					return;
				}

				this.addModalId(id);

				await this.drawerModal(
					componentProps,
					component,
					cssClass,
					id,
					animated
				);
			} finally {
				this.isProcessing = false;
			}
		}
	}

	public async ignoreDirectionModal(
		componentProps: any,
		component: any,
		cssClass: string,
		id: string
	): Promise<void> {
		this.ignoreDirection = true;
		await this.viewDrawerModalAsync(
			componentProps,
			component,
			cssClass,
			id
		);
	}

	private async drawerModal(
		componentProps: any,
		component: any,
		cssClass: string,
		id: any,
		animated: boolean
	) {
		const layout = this.themeService.layout;
		const modal = await this.modalController.create({
			component: component,
			id: id,
			componentProps: componentProps,
			cssClass: cssClass,
			backdropDismiss: true,
			mode: 'ios',
			animated: animated
		});

		void modal.onWillDismiss().then((event) => {
			this.hyperlinkModalClosed.next(id);
			this.removeModalId(id);

			if (event.data) {
				const reopenOnDismissData = event.data as ReopenOnDismiss;
				this.reopenOnDismiss.next(reopenOnDismissData);
			}
		});

		if (layout == 'web') {
			modal.enterAnimation = this.customEnter;
			modal.leaveAnimation = this.customLeave;
		}
		this.isProcessing = false;
		return await modal.present();
	}

	private claerModalIds() {
		this.openModalIds = [];
		this.isModalOpen.next(false);
	}

	private removeModalId(id: string) {
		this.openModalIds = this.openModalIds.filter(
			(openModalId) => openModalId != id
		);

		this.isModalOpen.next(this.openModalIds.length > 0);
	}

	private addModalId(id: string) {
		this.openModalIds.push(id);
		this.isModalOpen.next(true);
	}

	private customLeave: CustomAnimationBuilder = (baseEl) => {
		const root = baseEl.shadowRoot;

		const backdropAnimation = this.animationC
			.create()
			.fromTo('opacity', 'var(--backdrop-opacity)', 0);

		const dir = document
			.getElementsByTagName('html')[0]
			.getAttribute('dir');

		let wrapperAnimation;

		if (dir == 'ltr' || this.ignoreDirection == true) {
			wrapperAnimation = this.animationC
				.create()
				.fromTo('transform', 'translateX(0%)', 'translateX(100%)');
			this.ignoreDirection = false;
		} else {
			wrapperAnimation = this.animationC
				.create()
				.fromTo('transform', 'translateX(0%)', 'translateX(-100%)');
		}

		backdropAnimation.addElement(root.querySelector('ion-backdrop'));

		wrapperAnimation
			.addElement(root.querySelectorAll('.modal-wrapper, .modal-shadow'))
			.beforeStyles({ opacity: 1 });

		const baseAnimation = this.animationC
			.create('leaving-base')
			.addElement(baseEl)
			.easing('cubic-bezier(0.32,0.72,0,1)')
			.duration(500)
			.addAnimation(wrapperAnimation)
			.addAnimation(backdropAnimation);

		return baseAnimation;
	};

	//https://github.com/ionic-team/ionic-framework/blob/223f36f6adacf8adce47cee4809a60c94a9e0efa/core/src/components/modal/animations/ios.enter.ts
	private customEnter: CustomAnimationBuilder = (baseEl) => {
		const root = baseEl.shadowRoot;

		const backdropAnimation = this.animationC
			.create()
			.fromTo('opacity', 0.01, 'var(--backdrop-opacity)');

		const dir = document
			.getElementsByTagName('html')[0]
			.getAttribute('dir');

		let wrapperAnimation;

		if (dir == 'ltr' || this.ignoreDirection == true) {
			wrapperAnimation = this.animationC
				.create()
				.fromTo('transform', 'translateX(100vh)', 'translateX(0vh)');
		} else {
			wrapperAnimation = this.animationC
				.create()
				.fromTo('transform', 'translateX(-100vh)', 'translateX(0vh)');
		}

		backdropAnimation
			.addElement(root.querySelector('ion-backdrop'))
			.beforeStyles({
				'pointer-events': 'none'
			})
			.afterClearStyles(['pointer-events']);

		wrapperAnimation
			.addElement(root.querySelectorAll('.modal-wrapper, .modal-shadow'))
			.beforeStyles({ opacity: 1 });

		const baseAnimation = this.animationC
			.create('entering-base')
			.addElement(baseEl)
			.easing('cubic-bezier(0.32,0.72,0,1)')
			.duration(500)
			.addAnimation(wrapperAnimation)
			.addAnimation(backdropAnimation);

		return baseAnimation;
	};
}
