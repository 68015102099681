/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProfessionModel } from '../models/profession-model';
import { ProfessionModelSearchResultModel } from '../models/profession-model-search-result-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MDIProfessionService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation professionSearchProfessionPost
   */
  static readonly ProfessionSearchProfessionPostPath = '/Profession/SearchProfession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `professionSearchProfessionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  professionSearchProfessionPost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<ProfessionModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIProfessionService.ProfessionSearchProfessionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ProfessionModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `professionSearchProfessionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  professionSearchProfessionPost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<ProfessionModelSearchResultModel> {

    return this.professionSearchProfessionPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ProfessionModelSearchResultModel>) => r.body as ProfessionModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `professionSearchProfessionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  professionSearchProfessionPost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<ProfessionModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIProfessionService.ProfessionSearchProfessionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ProfessionModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `professionSearchProfessionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  professionSearchProfessionPost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<ProfessionModelSearchResultModel> {

    return this.professionSearchProfessionPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ProfessionModelSearchResultModel>) => r.body as ProfessionModelSearchResultModel)
    );
  }

  /**
   * Path part for operation professionIdGet
   */
  static readonly ProfessionIdGetPath = '/Profession/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `professionIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  professionIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<ProfessionModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIProfessionService.ProfessionIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ProfessionModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `professionIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  professionIdGet$Plain(params: {
    id: string;
  }): Observable<ProfessionModel> {

    return this.professionIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ProfessionModel>) => r.body as ProfessionModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `professionIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  professionIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<ProfessionModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIProfessionService.ProfessionIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ProfessionModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `professionIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  professionIdGet$Json(params: {
    id: string;
  }): Observable<ProfessionModel> {

    return this.professionIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ProfessionModel>) => r.body as ProfessionModel)
    );
  }

  /**
   * Path part for operation professionPost
   */
  static readonly ProfessionPostPath = '/Profession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `professionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  professionPost$Plain$Response(params?: {
    body?: ProfessionModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIProfessionService.ProfessionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `professionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  professionPost$Plain(params?: {
    body?: ProfessionModel
  }): Observable<string> {

    return this.professionPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `professionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  professionPost$Json$Response(params?: {
    body?: ProfessionModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIProfessionService.ProfessionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `professionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  professionPost$Json(params?: {
    body?: ProfessionModel
  }): Observable<string> {

    return this.professionPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
