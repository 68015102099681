import { Injectable } from '@angular/core';
import { CanActivateChild, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ServicesStartupService } from 'src/app/services/services-startup/services-startup.service';

@Injectable({
	providedIn: 'root'
})
export class IsServicesLoadedGuard implements CanActivateChild {
	constructor(private servicesStartupService: ServicesStartupService) {}

	canActivateChild():
		| boolean
		| UrlTree
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree> {
		return combineLatest([
			this.servicesStartupService.mainServicesLoaded$
		]).pipe(
			filter(([mainServicesLoaded]) => mainServicesLoaded),
			map(([mainServicesLoaded]) => {
				return mainServicesLoaded;
			})
		);
	}
}
