import { Injectable } from '@angular/core';
import { ModalService } from '../modal/modal.service';
import { DismissType } from '../modal/models/dismiss-type.enums';
import { ThemeService } from '../theme/theme.service';

@Injectable({
	providedIn: 'root'
})
export class DeviceResizeService {
	public currentLayout = '';

	constructor(
		private themeService: ThemeService,
		public modalService: ModalService
	) {}

	public changeModalLayout(
		currentLayout: string,
		data: any,
		modal: string,
		dismissType: DismissType
	): void {
		if (currentLayout != this.themeService.layout) {
			void this.modalService.dismissModalAsync(modal, {
				dismissType: dismissType,
				data: data
			});
		} else {
			void this.modalService.dismissModalAsync(modal);
		}
	}

	public setCurrentLayout(): void {
		this.currentLayout = this.themeService.layout;
	}

	public deviceLayoutChange(): boolean {
		if (this.currentLayout != this.themeService.layout) {
			return true;
		}
		return false;
	}
}
