/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthApiConfiguration, AuthApiConfigurationParams } from './auth-api-configuration';

import { AuthRoleService } from './services/auth-role.service';
import { AuthServiceTokenService } from './services/auth-service-token.service';
import { AuthUserService } from './services/auth-user.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AuthRoleService,
    AuthServiceTokenService,
    AuthUserService,
    AuthApiConfiguration
  ],
})
export class AuthModule {
  static forRoot(params: AuthApiConfigurationParams): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: AuthApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: AuthModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
