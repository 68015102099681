/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CalendarBaseService } from '../calendar-base-service';
import { CalendarApiConfiguration } from '../calendar-api-configuration';
import { CalendarStrictHttpResponse } from '../calendar-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AvailabilityDayModelAvailibilityModel } from '../models/availability-day-model-availibility-model';
import { BookingRequestModel } from '../models/booking-request-model';
import { PractitionerAvailabilityRequestModel } from '../models/practitioner-availability-request-model';
import { PractitionerSearchRequestModel } from '../models/practitioner-search-request-model';
import { PractitionerSearchResponseModel } from '../models/practitioner-search-response-model';

@Injectable({
  providedIn: 'root',
})
export class CalendarPractitionerFindrService extends CalendarBaseService {
  constructor(
    config: CalendarApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation practitionerFindrPractitionersPost
   */
  static readonly PractitionerFindrPractitionersPostPath = '/PractitionerFindr/Practitioners';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerFindrPractitionersPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerFindrPractitionersPost$Response(params?: {
    body?: PractitionerSearchRequestModel
  }): Observable<CalendarStrictHttpResponse<Array<PractitionerSearchResponseModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarPractitionerFindrService.PractitionerFindrPractitionersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<Array<PractitionerSearchResponseModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `practitionerFindrPractitionersPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerFindrPractitionersPost(params?: {
    body?: PractitionerSearchRequestModel
  }): Observable<Array<PractitionerSearchResponseModel>> {

    return this.practitionerFindrPractitionersPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<Array<PractitionerSearchResponseModel>>) => r.body as Array<PractitionerSearchResponseModel>)
    );
  }

  /**
   * Path part for operation practitionerFindrAvailabilityPost
   */
  static readonly PractitionerFindrAvailabilityPostPath = '/PractitionerFindr/Availability';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerFindrAvailabilityPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerFindrAvailabilityPost$Response(params?: {
    body?: PractitionerAvailabilityRequestModel
  }): Observable<CalendarStrictHttpResponse<AvailabilityDayModelAvailibilityModel>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarPractitionerFindrService.PractitionerFindrAvailabilityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<AvailabilityDayModelAvailibilityModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `practitionerFindrAvailabilityPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerFindrAvailabilityPost(params?: {
    body?: PractitionerAvailabilityRequestModel
  }): Observable<AvailabilityDayModelAvailibilityModel> {

    return this.practitionerFindrAvailabilityPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<AvailabilityDayModelAvailibilityModel>) => r.body as AvailabilityDayModelAvailibilityModel)
    );
  }

  /**
   * Path part for operation practitionerFindrBookSlotPost
   */
  static readonly PractitionerFindrBookSlotPostPath = '/PractitionerFindr/BookSlot';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerFindrBookSlotPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerFindrBookSlotPost$Response(params?: {
    body?: BookingRequestModel
  }): Observable<CalendarStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarPractitionerFindrService.PractitionerFindrBookSlotPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as CalendarStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `practitionerFindrBookSlotPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerFindrBookSlotPost(params?: {
    body?: BookingRequestModel
  }): Observable<void> {

    return this.practitionerFindrBookSlotPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation practitionerFindrLobbyBookingIdGet
   */
  static readonly PractitionerFindrLobbyBookingIdGetPath = '/PractitionerFindr/Lobby/{bookingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerFindrLobbyBookingIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  practitionerFindrLobbyBookingIdGet$Response(params: {
    bookingId: string;
  }): Observable<CalendarStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarPractitionerFindrService.PractitionerFindrLobbyBookingIdGetPath, 'get');
    if (params) {
      rb.path('bookingId', params.bookingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `practitionerFindrLobbyBookingIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  practitionerFindrLobbyBookingIdGet(params: {
    bookingId: string;
  }): Observable<string> {

    return this.practitionerFindrLobbyBookingIdGet$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<string>) => r.body as string)
    );
  }

}
