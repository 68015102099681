import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonSlides } from '@ionic/angular';
import {
	CalendarComponentMonthChange,
	CalendarComponentOptions,
	DayConfig
} from 'ion2-calendar';
import { CalendarComponent as Cal } from 'ion2-calendar';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { DateTimeService } from 'src/app/services/date-time/date-time.service';
import { Dots } from './models/dot.model';

@Component({
	selector: 'calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CalendarComponent),
			multi: true
		}
	]
})
export class CalendarComponent implements OnInit, ControlValueAccessor {
	public date: moment.Moment;
	public type: 'moment';
	public format = 'YYYY-MM-DD';
	public dots = Dots;
	@Input() sessionDate: string;
	@Output() sessionDateChange: EventEmitter<string> =
		new EventEmitter<string>();

	public optionsRange: CalendarComponentOptions = null;

	private currentMonth: Subject<moment.Moment> = new Subject<moment.Moment>();

	private allowedDates: BehaviorSubject<Array<moment.Moment>> =
		new BehaviorSubject<Array<moment.Moment>>([]);

	public dayConfigs: BehaviorSubject<Array<DayConfig>> = new BehaviorSubject<
		Array<DayConfig>
	>([]);

	public slideOpts = {
		loop: true,
		grabCursor: true
	};

	@ViewChild('slider')
	public slider: IonSlides;

	@ViewChild('calendar')
	public calendar: Cal;

	private slideInit = true;

	constructor(private dateTimeService: DateTimeService) {}

	public ngOnInit(): void {
		this.registerSubscribers();
		this.allowedDates.next(this.getAllowedDates(moment()));
		if (this.sessionDate) {
			this.date = moment(this.sessionDate);
		}
	}

	private getDisallowedDates(
		allowedDates: Array<moment.Moment>,
		preDisallowedDates: Array<moment.Moment>
	): Array<moment.Moment> {
		const comparisonValues = allowedDates.map((v) =>
			v.startOf('day').valueOf()
		);
		const disallowedDates = preDisallowedDates.filter(
			(v) => !comparisonValues.includes(v.startOf('day').valueOf())
		);

		return disallowedDates;
	}

	private setDisallowedConfigDays(
		currentDate: moment.Moment,
		allowedDates: Array<moment.Moment>
	): void {
		const preDisallowedDates = this.getPreDisallowedDates(currentDate);

		const disallowedDates = this.getDisallowedDates(
			allowedDates,
			preDisallowedDates
		);

		const mappedDisallowedDates = disallowedDates.map((disallowedDate) => {
			return {
				date: disallowedDate.toDate(),
				disable: true,
				cssClass: 'disabled'
			} as DayConfig;
		});

		this.dayConfigs.next(mappedDisallowedDates);
	}

	public writeValue(value: Date): void {
		if (value) this.date = moment(value.toISOString());
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public dayChange(event: any): void {
		this.onChange(this.date.toDate());
		this.sessionDate = this.date.format('YYYY-MM-DD').toString();
		this.sessionDateChange.emit(this.sessionDate);
	}

	public monthChange(event: CalendarComponentMonthChange): void {
		const date = moment(event.newMonth.string);

		this.setActiveDot(date);
		this.currentMonth.next(date);
	}

	private setActiveDot(selectedDate: moment.Moment) {
		const currentMonth = moment();

		const dotPosition = Math.ceil(
			selectedDate.diff(currentMonth, 'months', true)
		);

		const closestDot = this.dots.reduce((prev, curr) => {
			return Math.abs(curr.position - dotPosition) <
				Math.abs(prev.position - dotPosition)
				? curr
				: prev;
		});

		this.dots.forEach((dot) => {
			if (dot.position == closestDot.position) {
				dot.active = true;
			} else {
				dot.active = false;
			}
		});
	}

	private getPreDisallowedDates(currentDate: moment.Moment): moment.Moment[] {
		const now = currentDate.clone().add(-1, 'months').startOf('month');
		const endDate = currentDate.clone().add(1, 'months').endOf('month');

		const dates: Array<moment.Moment> = [];

		while (now.isSameOrBefore(endDate)) {
			dates.push(now.clone());
			now.add(1, 'days');
		}

		return dates;
	}

	private getAllowedDates(date: moment.Moment): Array<moment.Moment> {
		let bufferDays = 2;
		while (bufferDays > 0) {
			date = date.add(1, 'days');
			if (date.isoWeekday() != 6 && date.isoWeekday() != 7) {
				bufferDays -= 1;
			}
		}

		let countAvailableDays = 14;
		const availableDays: Array<moment.Moment> = [];

		while (countAvailableDays > 0) {
			date = date.add(1, 'days');
			if (date.isoWeekday() != 6 && date.isoWeekday() != 7) {
				availableDays.push(date.clone());
				countAvailableDays -= 1;
			}
		}

		return availableDays;
	}

	private onChange = (value: Date) => void 0;
	private onTouched = () => void 0;

	private registerSubscribers(): void {
		this.allowedDates.subscribe((allowedDates) => {
			this.setDisallowedConfigDays(moment(), allowedDates);
		});

		this.currentMonth.subscribe((date) => {
			this.setDisallowedConfigDays(date, this.allowedDates.value);
		});

		combineLatest([
			this.dateTimeService.weekdays$,
			this.dayConfigs
		]).subscribe(([weekdays, dayConfigs]) => {
			this.optionsRange = {
				weekStart: 1,
				showMonthPicker: false,
				pickMode: 'single',
				monthFormat: 'MMMM YYYY',
				weekdays: weekdays,
				daysConfig: dayConfigs
			};
		});
	}

	public next(): void {
		if (this.slideInit) {
			this.slideInit = false;
			return;
		}
		this.calendar.nextMonth();
		setTimeout(() => {
			void this.slider.slideTo(1, 0, false);
		}, 100);
	}

	public previous(): void {
		if (this.slideInit) {
			this.slideInit = false;
			return;
		}
		this.calendar.backMonth();
		setTimeout(() => {
			void this.slider.slideTo(1, 0, false);
		}, 100);
	}
}
