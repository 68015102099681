/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SearchRequestModel } from '../models/search-request-model';
import { ServiceTypeModel } from '../models/service-type-model';
import { ServiceTypeModelSearchResultModel } from '../models/service-type-model-search-result-model';

@Injectable({
  providedIn: 'root',
})
export class MDIServiceTypeService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation serviceTypeSearchServiceTypePost
   */
  static readonly ServiceTypeSearchServiceTypePostPath = '/ServiceType/SearchServiceType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceTypeSearchServiceTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceTypeSearchServiceTypePost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<ServiceTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIServiceTypeService.ServiceTypeSearchServiceTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ServiceTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceTypeSearchServiceTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceTypeSearchServiceTypePost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<ServiceTypeModelSearchResultModel> {

    return this.serviceTypeSearchServiceTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ServiceTypeModelSearchResultModel>) => r.body as ServiceTypeModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceTypeSearchServiceTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceTypeSearchServiceTypePost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<ServiceTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIServiceTypeService.ServiceTypeSearchServiceTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ServiceTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceTypeSearchServiceTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceTypeSearchServiceTypePost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<ServiceTypeModelSearchResultModel> {

    return this.serviceTypeSearchServiceTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ServiceTypeModelSearchResultModel>) => r.body as ServiceTypeModelSearchResultModel)
    );
  }

  /**
   * Path part for operation serviceTypeIdGet
   */
  static readonly ServiceTypeIdGetPath = '/ServiceType/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceTypeIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceTypeIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<ServiceTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIServiceTypeService.ServiceTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ServiceTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceTypeIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceTypeIdGet$Plain(params: {
    id: string;
  }): Observable<ServiceTypeModel> {

    return this.serviceTypeIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ServiceTypeModel>) => r.body as ServiceTypeModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceTypeIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceTypeIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<ServiceTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIServiceTypeService.ServiceTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ServiceTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceTypeIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceTypeIdGet$Json(params: {
    id: string;
  }): Observable<ServiceTypeModel> {

    return this.serviceTypeIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ServiceTypeModel>) => r.body as ServiceTypeModel)
    );
  }

  /**
   * Path part for operation serviceTypePost
   */
  static readonly ServiceTypePostPath = '/ServiceType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceTypePost$Plain$Response(params?: {
    body?: ServiceTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIServiceTypeService.ServiceTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceTypePost$Plain(params?: {
    body?: ServiceTypeModel
  }): Observable<string> {

    return this.serviceTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceTypePost$Json$Response(params?: {
    body?: ServiceTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIServiceTypeService.ServiceTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceTypePost$Json(params?: {
    body?: ServiceTypeModel
  }): Observable<string> {

    return this.serviceTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
