import {
	Component,
	Input,
	OnInit,
	Output,
	EventEmitter,
	OnDestroy
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BookmarkService } from 'src/app/services/bookmark/bookmark.service';
import { TranslatorService } from 'src/app/services/translate/translator.service';

@Component({
	selector: 'app-content-thumbnail',
	templateUrl: './content-thumbnail.component.html',
	styleUrls: ['./content-thumbnail.component.scss']
})
export class ContentThumbnailComponent implements OnInit, OnDestroy {
	@Input() content: any;
	@Input() showCompleted = true;
	@Input() dismissCurrentModal = false;

	@Input() isSaved = false;

	@Input() cssClass = '';

	@Output()
	public contentClicked: EventEmitter<string> = new EventEmitter<string>();

	public inProgress: boolean;

	public progressPercentage$: Observable<string>;
	private progressPercentage: BehaviorSubject<string> =
		new BehaviorSubject<string>('0%');
	private subscription: Subscription = new Subscription();

	constructor(
		public bookmarkService: BookmarkService,
		public translatorService: TranslatorService
	) {
		this.progressPercentage$ = this.progressPercentage.asObservable();
	}

	ngOnInit(): void {
		if (this.content) {
			if (this.isSaved) return;
			this.progressBarCalculation();
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	viewContent(): void {
		this.contentClicked.emit(this.content.Id);
	}

	private progressBarCalculation() {
		const progressPercentageSubscriber = this.progressPercentage$.subscribe(
			(_) => (this.inProgress = _ != '0%')
		);
		this.subscription.add(progressPercentageSubscriber);

		const inProgressSubscriber = this.bookmarkService.inProgress$.subscribe(
			(articlesInProgress) => {
				const article = articlesInProgress.find(
					(element) => element.id == this.content.Id
				);

				void this.bookmarkService.completed$
					.pipe(
						map((completed) => {
							const completedArticle = completed.find(
								(completedArticle) =>
									completedArticle.id == this.content.Id
							)
								? true
								: false;
							if (completedArticle && this.showCompleted) {
								this.progressPercentage.next('100%');
							} else {
								this.progressPercentage.next(
									article?.progress?.toString() ?? '0%'
								);
							}
						})
					)
					.toPromise();
			}
		);
		this.subscription.add(inProgressSubscriber);
	}
}
