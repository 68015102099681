import { EventEmitter, Injectable } from '@angular/core';
import * as Rx from 'rxjs';
import { from } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserData } from '../auth-service/user-data.model';

@Injectable()
export class EventService {
	public isLoggedIn: Rx.BehaviorSubject<UserData> =
		new Rx.BehaviorSubject<UserData>(null);

	public loggedOut: EventEmitter<any> = new EventEmitter<any>();

	public clientAccessToken: EventEmitter<any> = new EventEmitter<any>();

	public removeAccessToken: EventEmitter<boolean> =
		new EventEmitter<boolean>();

	protected listeners: any;
	protected eventsSubject: any;
	protected events: any;

	constructor() {
		this.listeners = {};
		this.eventsSubject = new Rx.Subject();
		this.events = from(this.eventsSubject);

		this.events.subscribe(({ name, args }) => {
			if (this.listeners[name])
				for (let listener of this.listeners[name])
					listener.function(...args);
		});
	}

	/**
	 * Listen to the loadingSub property in the LoadingService class. This drives the
	 * display of the loading spinner.
	 */

	public CLog(object: any, title: string = '') {
		if (!environment.production || true)
			console.log({ title: title, object });
	}

	on(name, tag, listener) {
		if (!this.listeners[name]) this.listeners[name] = [];

		if (this.listeners[name].map((e) => e.tag).indexOf(tag) < 0) {
			const event = new EventClass();
			event.tag = tag;
			event.function = listener;
			this.listeners[name].push(event);
			this.CLog({ name, tag }, 'register-event');
		}
	}

	removeAll(tag) {
		for (const property of Object.keys(this.listeners))
			if (this.listeners[property].map((e) => e.tag).indexOf(tag) >= 0)
				this.remove(property, tag);
	}

	remove(name, tag) {
		if (!this.listeners[name]) return;

		const index = this.listeners[name].map((e) => e.tag).indexOf(tag);
		if (index >= 0) {
			this.listeners[name].splice(index, 1);
			this.CLog({ name, tag }, 'remove-event');
		}
	}

	broadcast(name, ...args) {
		if (name !== 'tick-event') this.CLog({ name, args }, 'execute-event');
		this.eventsSubject.next({ name, args });
	}
}

class EventClass {
	tag: string | undefined;
	function: any;
}
