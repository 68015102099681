import { Injectable } from '@angular/core';
import { BrowserService } from '../../browser/browser.service';
import { LoadingService } from '../../loading/loading.service';

@Injectable({
	providedIn: 'root'
})
export class AcsService {
	constructor(
		private loadingService: LoadingService,
		private browserService: BrowserService
	) {}

	public async startCall(session: any) {
		await this.loadingService.show();
	}
}
