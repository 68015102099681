import { Injectable } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import moment from 'moment';
import { DeviceResizeService } from '../../device-resize/device-resize.service';
import { LoadingService } from '../../loading/loading.service';
import { TranslatorService } from '../../translate/translator.service';
import * as _ from 'lodash';
import { ModalService } from '../../modal/modal.service';
import SessionMock from '../../../utilities/dummy-data/sessions.json';
import UserMock from '../../../utilities/dummy-data/user.json';

@Injectable({
	providedIn: 'root'
})
export class RegisteredUserProfileService {
	public clicked = false;
	public appLanguage = '';
	public loading = false;
	public isSubmitted = false;
	public requestSession = {
		Username: '',
		calendarDate: '',
		timeslot: '',
		Date: moment().toISOString(),
		LanguageName: '',
		LanguageCode: '',
		Message: '',
		ServiceId: '',
		email: '',
		phoneNumber: '',
		rememberDetails: false,
		emailInvalid: false,
		phoneInvalid: false,
		service: null
	};

	public requestModel;

	public userSessions = [];
	public upcomingSessions = [];
	public previousSessions = [];
	public user;
	public requestSessionForm: FormGroupDirective;
	public userIsLoggedIn = false;

	constructor(
		private translatorService: TranslatorService,
		private loadingService: LoadingService,
		private deviceResizeService: DeviceResizeService,
		private modalService: ModalService
	) {}

	public initialize(): void {
		this.translatorService.language$.subscribe((lang) => {
			this.appLanguage = lang;
		});
	}

	public setSessionServiceNameLanguage(
		service,
		languageCode: string = null
	): string {
		if (languageCode) {
			return service?.name;
		}

		if (
			this.appLanguage != 'en' &&
			this.appLanguage != 'nl' &&
			this.appLanguage != 'fr'
		) {
			return service?.name;
		}
		return service?.name;
	}

	public getUserData(): any {
		this.requestModel = UserMock;
		return this.requestModel;
	}

	public getUserSessions(): any {
		this.userSessions.push(...SessionMock);
		this.userSessions = this.userSessions.filter(
			(v, i, a) => a.findIndex((v2) => v2.Id === v.Id) === i
		);
		const cutoffDateTime = moment();
		this.getUpcomingSessions(cutoffDateTime);
		this.getPreviousSessions(cutoffDateTime);
	}

	private getUpcomingSessions(cutoffDateTime: moment.Moment) {
		this.upcomingSessions = this.userSessions.filter((session) => {
			const endDate = moment(session.EndTime);
			return cutoffDateTime.isBefore(endDate);
		});
	}

	private getPreviousSessions(cutoffDateTime: moment.Moment) {
		this.previousSessions = this.userSessions.filter((session) => {
			const endDate = moment(session.EndTime);
			return cutoffDateTime.isSameOrAfter(endDate);
		});
	}

	public async submitRequest(): Promise<void> {
		if (this.clicked) return;
		await this.loadingService.show();
		this.clicked = true;

		try {
			this.requestSessionForm.form.markAllAsTouched();
			if (
				this.requestSessionForm.form.controls.email.invalid &&
				this.requestSessionForm.form.value.email != ''
			) {
				this.requestSession.emailInvalid = true;
				this.clicked = false;
				await this.loadingService.hide(true);
				document.getElementsByTagName('form')[0].scrollIntoView();
				return;
			}

			this.requestSession.emailInvalid = false;

			if (this.requestSessionForm.form.controls.phoneNumber.invalid) {
				this.requestSession.phoneInvalid = true;
				this.clicked = false;
				await this.loadingService.hide(true);
				document.getElementsByTagName('form')[0].scrollIntoView();
				return;
			}

			this.requestSession.phoneInvalid = false;

			if (!this.requestSessionForm.valid) {
				this.clicked = false;
				await this.loadingService.hide(true);
				document.getElementsByTagName('form')[0].scrollIntoView();
				return;
			}
			this.requestSessionForm.form.markAllAsTouched();
			this.requestSession.emailInvalid = false;
			this.requestSession.phoneInvalid = false;
			if (this.requestSessionForm.valid) {
				if (!this.requestSession.timeslot) {
					this.clicked = false;
					await this.loadingService.hide(true);
					document
						.getElementsByTagName('time-selector')[0]
						.scrollIntoView();
					return;
				}

				if (this.requestSession.calendarDate == '') {
					this.clicked = false;
					await this.loadingService.hide(true);
					document
						.getElementsByTagName('calendar')[0]
						.scrollIntoView();
					return;
				}

				this.requestSession.Date = moment(
					this.requestSession.calendarDate +
						' ' +
						this.requestSession.timeslot.substring(
							0,
							this.requestSession.timeslot.indexOf('-')
						)
				)
					.toISOString(true)
					.toString();

				this.isSubmitted = true;
				const tempSessionDemo = {
					Id: (this.userSessions.length + 1).toString(),
					Date: moment(this.requestSession.Date)
						.format('YYYY-MM-DD')
						.toString(),
					EndTime: moment(this.requestSession.Date),
					LanguageCode: this.requestSession.LanguageCode,
					LanguageName: this.requestSession.LanguageName,
					Message: this.requestSession.Message,
					ServiceId: this.requestSession.ServiceId,
					ServiceName: this.requestSession.service.name,
					StartTime: moment(this.requestSession.Date)
						.add(2, 'hours')
						.toString(),
					Status: 'Received',
					TeamsLink: ''
				};
				this.userSessions.push(tempSessionDemo);
				void this.loadingService.hide(true);
				void this.completeSubmissionShowFeedbackModal('custom-modal');
				this.clicked = false;
			}
		} catch (e) {
			console.error(e);
			this.clicked = false;
			await this.loadingService.hide(true);
		}
	}

	public async completeSubmissionShowFeedbackModal(
		cssClass: string
	): Promise<void> {
		// await this.modalService.viewDrawerModalAsync(
		// 	{
		// 		requestSession: true,
		// 		layout: this.deviceResizeService.currentLayout
		// 	},
		// 	FeedbackComponent,
		// 	cssClass,
		// 	'Feedback'
		// );
	}
}
