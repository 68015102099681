import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
	selector: 'join-session',
	templateUrl: './join-session.component.html',
	styleUrls: ['./join-session.component.scss']
})
export class JoinSessionComponent implements OnInit {
	public session;
	public formFactor = 'desktop';
	public loading = true;

	public ready(): void {
		this.loading = false;
	}

	constructor(private themeService: ThemeService) {}

	ngOnInit(): void {
		console.log('');
		this.formFactor =
			this.themeService.layout == 'app' ? 'mobile' : 'desktop';
	}
}
