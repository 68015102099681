/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HealthFindrBaseService } from '../health-findr-base-service';
import { HealthFindrApiConfiguration } from '../health-findr-api-configuration';
import { HealthFindrStrictHttpResponse } from '../health-findr-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PromotionalLinkModel } from '../models/promotional-link-model';

@Injectable({
  providedIn: 'root',
})
export class HealthFindrPromotionalLinkService extends HealthFindrBaseService {
  constructor(
    config: HealthFindrApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation promotionalLinkPromotionalLinkPost
   */
  static readonly PromotionalLinkPromotionalLinkPostPath = '/PromotionalLink/PromotionalLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionalLinkPromotionalLinkPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promotionalLinkPromotionalLinkPost$Response(params?: {
    body?: PromotionalLinkModel
  }): Observable<HealthFindrStrictHttpResponse<PromotionalLinkModel>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrPromotionalLinkService.PromotionalLinkPromotionalLinkPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<PromotionalLinkModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionalLinkPromotionalLinkPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promotionalLinkPromotionalLinkPost(params?: {
    body?: PromotionalLinkModel
  }): Observable<PromotionalLinkModel> {

    return this.promotionalLinkPromotionalLinkPost$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<PromotionalLinkModel>) => r.body as PromotionalLinkModel)
    );
  }

  /**
   * Path part for operation promotionalLinkFhirIdPromotionalLinksGet
   */
  static readonly PromotionalLinkFhirIdPromotionalLinksGetPath = '/PromotionalLink/{fhirId}/PromotionalLinks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionalLinkFhirIdPromotionalLinksGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionalLinkFhirIdPromotionalLinksGet$Response(params: {
    fhirId: string;
  }): Observable<HealthFindrStrictHttpResponse<Array<PromotionalLinkModel>>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrPromotionalLinkService.PromotionalLinkFhirIdPromotionalLinksGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<Array<PromotionalLinkModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionalLinkFhirIdPromotionalLinksGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionalLinkFhirIdPromotionalLinksGet(params: {
    fhirId: string;
  }): Observable<Array<PromotionalLinkModel>> {

    return this.promotionalLinkFhirIdPromotionalLinksGet$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<PromotionalLinkModel>>) => r.body as Array<PromotionalLinkModel>)
    );
  }

}
