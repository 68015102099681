/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MediaBaseService } from '../media-base-service';
import { MediaApiConfiguration } from '../media-api-configuration';
import { MediaStrictHttpResponse } from '../media-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientListModel } from '../models/client-list-model';
import { ClientModel } from '../models/client-model';
import { ClientSearchItemModelSearchResultModel } from '../models/client-search-item-model-search-result-model';
import { ClientUpdateModel } from '../models/client-update-model';
import { ClientUserModel } from '../models/client-user-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MediaClientService extends MediaBaseService {
  constructor(
    config: MediaApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientSearchClientPost
   */
  static readonly ClientSearchClientPostPath = '/Client/SearchClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientSearchClientPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientSearchClientPost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MediaStrictHttpResponse<ClientSearchItemModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaClientService.ClientSearchClientPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<ClientSearchItemModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientSearchClientPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientSearchClientPost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<ClientSearchItemModelSearchResultModel> {

    return this.clientSearchClientPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<ClientSearchItemModelSearchResultModel>) => r.body as ClientSearchItemModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientSearchClientPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientSearchClientPost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MediaStrictHttpResponse<ClientSearchItemModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaClientService.ClientSearchClientPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<ClientSearchItemModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientSearchClientPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientSearchClientPost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<ClientSearchItemModelSearchResultModel> {

    return this.clientSearchClientPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<ClientSearchItemModelSearchResultModel>) => r.body as ClientSearchItemModelSearchResultModel)
    );
  }

  /**
   * Path part for operation clientIdGet
   */
  static readonly ClientIdGetPath = '/Client/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MediaStrictHttpResponse<ClientModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaClientService.ClientIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<ClientModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientIdGet$Plain(params: {
    id: string;
  }): Observable<ClientModel> {

    return this.clientIdGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<ClientModel>) => r.body as ClientModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientIdGet$Json$Response(params: {
    id: string;
  }): Observable<MediaStrictHttpResponse<ClientModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaClientService.ClientIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<ClientModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientIdGet$Json(params: {
    id: string;
  }): Observable<ClientModel> {

    return this.clientIdGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<ClientModel>) => r.body as ClientModel)
    );
  }

  /**
   * Path part for operation clientClientListGet
   */
  static readonly ClientClientListGetPath = '/Client/ClientList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientClientListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientClientListGet$Plain$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<ClientListModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaClientService.ClientClientListGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<ClientListModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientClientListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientClientListGet$Plain(params?: {
  }): Observable<Array<ClientListModel>> {

    return this.clientClientListGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<ClientListModel>>) => r.body as Array<ClientListModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientClientListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientClientListGet$Json$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<ClientListModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaClientService.ClientClientListGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<ClientListModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientClientListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientClientListGet$Json(params?: {
  }): Observable<Array<ClientListModel>> {

    return this.clientClientListGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<ClientListModel>>) => r.body as Array<ClientListModel>)
    );
  }

  /**
   * Path part for operation clientPost
   */
  static readonly ClientPostPath = '/Client';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientPost$Plain$Response(params?: {
    body?: ClientUpdateModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaClientService.ClientPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientPost$Plain(params?: {
    body?: ClientUpdateModel
  }): Observable<string> {

    return this.clientPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientPost$Json$Response(params?: {
    body?: ClientUpdateModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaClientService.ClientPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientPost$Json(params?: {
    body?: ClientUpdateModel
  }): Observable<string> {

    return this.clientPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation clientIdInviteUserPost
   */
  static readonly ClientIdInviteUserPostPath = '/Client/{id}/InviteUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientIdInviteUserPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientIdInviteUserPost$Plain$Response(params: {
    id: string;
    body?: ClientUserModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaClientService.ClientIdInviteUserPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientIdInviteUserPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientIdInviteUserPost$Plain(params: {
    id: string;
    body?: ClientUserModel
  }): Observable<string> {

    return this.clientIdInviteUserPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientIdInviteUserPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientIdInviteUserPost$Json$Response(params: {
    id: string;
    body?: ClientUserModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaClientService.ClientIdInviteUserPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientIdInviteUserPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientIdInviteUserPost$Json(params: {
    id: string;
    body?: ClientUserModel
  }): Observable<string> {

    return this.clientIdInviteUserPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation clientIdRemoveUserUserIdDelete
   */
  static readonly ClientIdRemoveUserUserIdDeletePath = '/Client/{id}/RemoveUser/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientIdRemoveUserUserIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientIdRemoveUserUserIdDelete$Response(params: {
    id: string;
    userId: string;
  }): Observable<MediaStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaClientService.ClientIdRemoveUserUserIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as MediaStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientIdRemoveUserUserIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientIdRemoveUserUserIdDelete(params: {
    id: string;
    userId: string;
  }): Observable<void> {

    return this.clientIdRemoveUserUserIdDelete$Response(params).pipe(
      map((r: MediaStrictHttpResponse<void>) => r.body as void)
    );
  }

}
