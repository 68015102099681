import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { App } from '@capacitor/app';
@Injectable({
	providedIn: 'root'
})
export class BrowserService {
	private browserOpened: BehaviorSubject<boolean> = new BehaviorSubject(
		false
	);
	private tagsToIdentify = ['a'];

	public $browserOpened: Observable<boolean> =
		this.browserOpened.asObservable();

	public setBrowserOpened(opened: boolean): void {
		this.browserOpened.next(opened);
	}

	public async initializeAsync(): Promise<void> {
		let cannotReload = false;
		await App.addListener('appUrlOpen', (data) => {
			cannotReload = true;
		});
		window.addEventListener('reload', () => {
			cannotReload = true;
		});
		await App.addListener('appStateChange', ({ isActive }) => {
			if (isActive && !cannotReload && !this.browserOpened.value) {
				window.location.reload();
			} else if (isActive && this.browserOpened.value) {
				this.setBrowserOpened(false);
			} else {
				cannotReload = false;
			}
		});
		window.addEventListener('click', (e) => {
			const clickedElement = this.clickOrigin(e);
			for (let i = 0; i < this.tagsToIdentify.length; i++) {
				if (
					clickedElement.tagType == this.tagsToIdentify[i] ||
					clickedElement.parent == this.tagsToIdentify[i] ||
					clickedElement.secondParent == this.tagsToIdentify[i]
				) {
					this.setBrowserOpened(true);
				} else if (
					(clickedElement.tagClass as any).find(
						(e) => e == 'upload-icon'
					)
				) {
					this.setBrowserOpened(true);
				} else {
					this.setBrowserOpened(false);
				}
			}
		});
	}

	private clickOrigin(e) {
		const target = e.target;
		const tag = {
			tagType: '',
			tagClass: '',
			id: '',
			parent: '',
			secondParent: ''
		};
		tag.tagType = target.tagName.toLowerCase();
		tag.tagClass = target.className.split(' ');
		tag.id = target.id;
		tag.parent = target.parentNode.tagName.toLowerCase();
		tag.secondParent = target.parentNode.parentNode.tagName.toLowerCase();

		return tag;
	}
}
