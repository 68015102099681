import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { Device } from '@capacitor/device';

@Injectable()
export class DeviceIDInterceptor implements HttpInterceptor {
	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		return from(this.handle(request, next));
	}

	async handle(request: HttpRequest<any>, next: HttpHandler) {
		if (request.url.includes('localhost')) {
			const deviceID = await Device.getId();
			request = request.clone({
				setHeaders: {
					'device-id': deviceID.uuid
				}
			});
		}

		return next.handle(request).toPromise();
	}
}
