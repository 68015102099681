import {
	Component,
	ChangeDetectionStrategy,
	ViewEncapsulation,
	Input
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { TranslatorService } from 'src/app/core/translator/translator.service';

@Component({
	selector: 'html-container',
	templateUrl: './html-container.component.html',
	styleUrls: ['./html-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class HtmlContainerComponent {
	@Input()
	public safeHtml: SafeHtml;

	constructor(public translator: TranslatorService) {}
}
