/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MPIBaseService } from '../mpi-base-service';
import { MPIApiConfiguration } from '../mpi-api-configuration';
import { MPIStrictHttpResponse } from '../mpi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PatientAdminModel } from '../models/patient-admin-model';
import { PatientSearchModel } from '../models/patient-search-model';
import { PatientSearchResultModel } from '../models/patient-search-result-model';
import { PhotoModel } from '../models/photo-model';

@Injectable({
  providedIn: 'root',
})
export class MPIAdminService extends MPIBaseService {
  constructor(
    config: MPIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation adminSearchPost
   */
  static readonly AdminSearchPostPath = '/Admin/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSearchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchPost$Plain$Response(params?: {
    body?: PatientSearchModel
  }): Observable<MPIStrictHttpResponse<Array<PatientSearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MPIAdminService.AdminSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MPIStrictHttpResponse<Array<PatientSearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminSearchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchPost$Plain(params?: {
    body?: PatientSearchModel
  }): Observable<Array<PatientSearchResultModel>> {

    return this.adminSearchPost$Plain$Response(params).pipe(
      map((r: MPIStrictHttpResponse<Array<PatientSearchResultModel>>) => r.body as Array<PatientSearchResultModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSearchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchPost$Json$Response(params?: {
    body?: PatientSearchModel
  }): Observable<MPIStrictHttpResponse<Array<PatientSearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MPIAdminService.AdminSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MPIStrictHttpResponse<Array<PatientSearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminSearchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchPost$Json(params?: {
    body?: PatientSearchModel
  }): Observable<Array<PatientSearchResultModel>> {

    return this.adminSearchPost$Json$Response(params).pipe(
      map((r: MPIStrictHttpResponse<Array<PatientSearchResultModel>>) => r.body as Array<PatientSearchResultModel>)
    );
  }

  /**
   * Path part for operation adminPersonIdGet
   */
  static readonly AdminPersonIdGetPath = '/Admin/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGet$Plain$Response(params: {
    personId: string;
    summary?: boolean;
  }): Observable<MPIStrictHttpResponse<PatientAdminModel>> {

    const rb = new RequestBuilder(this.rootUrl, MPIAdminService.AdminPersonIdGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {});
      rb.query('summary', params.summary, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MPIStrictHttpResponse<PatientAdminModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGet$Plain(params: {
    personId: string;
    summary?: boolean;
  }): Observable<PatientAdminModel> {

    return this.adminPersonIdGet$Plain$Response(params).pipe(
      map((r: MPIStrictHttpResponse<PatientAdminModel>) => r.body as PatientAdminModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGet$Json$Response(params: {
    personId: string;
    summary?: boolean;
  }): Observable<MPIStrictHttpResponse<PatientAdminModel>> {

    const rb = new RequestBuilder(this.rootUrl, MPIAdminService.AdminPersonIdGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {});
      rb.query('summary', params.summary, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MPIStrictHttpResponse<PatientAdminModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGet$Json(params: {
    personId: string;
    summary?: boolean;
  }): Observable<PatientAdminModel> {

    return this.adminPersonIdGet$Json$Response(params).pipe(
      map((r: MPIStrictHttpResponse<PatientAdminModel>) => r.body as PatientAdminModel)
    );
  }

  /**
   * Path part for operation adminPersonIdDelete
   */
  static readonly AdminPersonIdDeletePath = '/Admin/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdDelete$Response(params: {
    personId: string;
  }): Observable<MPIStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MPIAdminService.AdminPersonIdDeletePath, 'delete');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as MPIStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdDelete(params: {
    personId: string;
  }): Observable<void> {

    return this.adminPersonIdDelete$Response(params).pipe(
      map((r: MPIStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation adminPost
   */
  static readonly AdminPostPath = '/Admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost$Plain$Response(params?: {
    body?: PatientAdminModel
  }): Observable<MPIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MPIAdminService.AdminPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MPIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost$Plain(params?: {
    body?: PatientAdminModel
  }): Observable<string> {

    return this.adminPost$Plain$Response(params).pipe(
      map((r: MPIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost$Json$Response(params?: {
    body?: PatientAdminModel
  }): Observable<MPIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MPIAdminService.AdminPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MPIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost$Json(params?: {
    body?: PatientAdminModel
  }): Observable<string> {

    return this.adminPost$Json$Response(params).pipe(
      map((r: MPIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation adminPersonIdPhotoPost
   */
  static readonly AdminPersonIdPhotoPostPath = '/Admin/{personId}/Photo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdPhotoPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdPhotoPost$Plain$Response(params: {
    personId: string;
    body?: PhotoModel
  }): Observable<MPIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MPIAdminService.AdminPersonIdPhotoPostPath, 'post');
    if (params) {
      rb.path('personId', params.personId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MPIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdPhotoPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdPhotoPost$Plain(params: {
    personId: string;
    body?: PhotoModel
  }): Observable<string> {

    return this.adminPersonIdPhotoPost$Plain$Response(params).pipe(
      map((r: MPIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdPhotoPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdPhotoPost$Json$Response(params: {
    personId: string;
    body?: PhotoModel
  }): Observable<MPIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MPIAdminService.AdminPersonIdPhotoPostPath, 'post');
    if (params) {
      rb.path('personId', params.personId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MPIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdPhotoPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdPhotoPost$Json(params: {
    personId: string;
    body?: PhotoModel
  }): Observable<string> {

    return this.adminPersonIdPhotoPost$Json$Response(params).pipe(
      map((r: MPIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
