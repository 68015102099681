import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { Url } from 'src/app/utilities';

@Directive({
	selector: '[dynamicImage]'
})
export class DynamicImageDirective implements AfterViewInit {
	constructor(private element: ElementRef) {}

	sizes = [32, 64, 128, 256, 512, 1024];

	ngAfterViewInit(): void {
		let width = document.documentElement.clientWidth;
		width =
			this.sizes.find((s) => width <= s) ||
			this.sizes[this.sizes.length - 1];

		const computed = window.getComputedStyle(this.element.nativeElement);
		let s = computed.getPropertyValue('background-image');

		if (s.includes('url(') && !s.includes('.svg')) {
			const startPos = s.indexOf('url(') + 5;
			const endPos = s.indexOf(')', startPos) - 1;
			const url = s.substring(startPos, endPos);

			if (!Url.isValidURL(url)) return;

			const newUrl = new URL(url);
			newUrl.searchParams.set('width', `${width}`);

			s = s.replace(url, newUrl.href);

			this.element.nativeElement.style.backgroundImage = s;
		}
	}
}
