/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { OrganizationBaseService } from '../organization-base-service';
import { OrganizationApiConfiguration } from '../organization-api-configuration';
import { OrganizationStrictHttpResponse } from '../organization-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrganizationConfigurationModel } from '../models/organization-configuration-model';
import { SpecialityModel } from '../models/speciality-model';

@Injectable({
  providedIn: 'root',
})
export class ORGAnonymousService extends OrganizationBaseService {
  constructor(
    config: OrganizationApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation anonymousSpecialityIdGet
   */
  static readonly AnonymousSpecialityIdGetPath = '/Anonymous/Speciality/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousSpecialityIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousSpecialityIdGet$Response(params: {
    id: string;
  }): Observable<OrganizationStrictHttpResponse<SpecialityModel>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAnonymousService.AnonymousSpecialityIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<SpecialityModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `anonymousSpecialityIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousSpecialityIdGet(params: {
    id: string;
  }): Observable<SpecialityModel> {

    return this.anonymousSpecialityIdGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<SpecialityModel>) => r.body as SpecialityModel)
    );
  }

  /**
   * Path part for operation anonymousParentEntityIdForHostnameGet
   */
  static readonly AnonymousParentEntityIdForHostnameGetPath = '/Anonymous/ParentEntityIdForHostname';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousParentEntityIdForHostnameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousParentEntityIdForHostnameGet$Response(params?: {
    hostName?: string;
  }): Observable<OrganizationStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAnonymousService.AnonymousParentEntityIdForHostnameGetPath, 'get');
    if (params) {
      rb.query('hostName', params.hostName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `anonymousParentEntityIdForHostnameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousParentEntityIdForHostnameGet(params?: {
    hostName?: string;
  }): Observable<string> {

    return this.anonymousParentEntityIdForHostnameGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation anonymousHostnameForParentEntityIdGet
   */
  static readonly AnonymousHostnameForParentEntityIdGetPath = '/Anonymous/HostnameForParentEntityId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousHostnameForParentEntityIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousHostnameForParentEntityIdGet$Response(params?: {
    parentEntityId?: string;
  }): Observable<OrganizationStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAnonymousService.AnonymousHostnameForParentEntityIdGetPath, 'get');
    if (params) {
      rb.query('parentEntityId', params.parentEntityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `anonymousHostnameForParentEntityIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousHostnameForParentEntityIdGet(params?: {
    parentEntityId?: string;
  }): Observable<string> {

    return this.anonymousHostnameForParentEntityIdGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation anonymousOrganizationConfigurationGet
   */
  static readonly AnonymousOrganizationConfigurationGetPath = '/Anonymous/OrganizationConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousOrganizationConfigurationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousOrganizationConfigurationGet$Response(params?: {
    parentEntityId?: string;
  }): Observable<OrganizationStrictHttpResponse<OrganizationConfigurationModel>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAnonymousService.AnonymousOrganizationConfigurationGetPath, 'get');
    if (params) {
      rb.query('parentEntityId', params.parentEntityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<OrganizationConfigurationModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `anonymousOrganizationConfigurationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousOrganizationConfigurationGet(params?: {
    parentEntityId?: string;
  }): Observable<OrganizationConfigurationModel> {

    return this.anonymousOrganizationConfigurationGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationConfigurationModel>) => r.body as OrganizationConfigurationModel)
    );
  }

  /**
   * Path part for operation anonymousFhirIdImageGet
   */
  static readonly AnonymousFhirIdImageGetPath = '/Anonymous/{fhirId}/Image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousFhirIdImageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousFhirIdImageGet$Response(params: {
    fhirId: string;
  }): Observable<OrganizationStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAnonymousService.AnonymousFhirIdImageGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as OrganizationStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `anonymousFhirIdImageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousFhirIdImageGet(params: {
    fhirId: string;
  }): Observable<void> {

    return this.anonymousFhirIdImageGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<void>) => r.body as void)
    );
  }

}
