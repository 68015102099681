import {
	AfterContentInit,
	Component,
	HostBinding,
	HostListener,
	NgZone,
	OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { StatusBar, Style } from '@capacitor/status-bar';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { AlertController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { TitleHelperService } from './services/title-helper/title-helper.service';
import { ServicesStartupService } from './services/services-startup/services-startup.service';
import { environment } from 'src/environments/environment';


@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements AfterContentInit, OnInit {
	@HostListener('document:click', ['$event'])
	public handleClick(event: Event): void {
		let element: HTMLAnchorElement = null;
		if (event.target instanceof HTMLSpanElement) {
			if (event.target.parentElement instanceof HTMLAnchorElement) {
				element = event.target.parentElement;
			}
		} else if (event.target instanceof HTMLAnchorElement) {
			element = event.target;
		}

		if (element) {
			if (element.pathname.startsWith('/')) {
				event.preventDefault();
				const route = element?.getAttribute('href');
				if (route) {
					void this.router.navigateByUrl(`${route}`);
				}
			}
		}
	}

	@HostBinding('style.--app-width')
	appWidth: number;

	@HostBinding('style.--ion-tab-spacing')
	marginLeft: string;

	@HostBinding('style.--app-height')
	appHeight: number;

	@HostBinding('class.landscape')
	landscape = false;

	@HostListener('window:resize', ['$event'])
	onResize(): void {
		this.appWidth = document.getElementsByTagName('ion-app')[0].clientWidth;
		this.appHeight =
			document.getElementsByTagName('ion-app')[0].clientHeight;
		const vw =
			(window.innerWidth -
				160 -
				150 -
				((window.innerWidth - 160 - 150) / 6) * 4.5) /
			5;
		this.marginLeft = `${vw}px`;
	}

	private shouldHide = false;

	constructor(
		private router: Router,
		private zone: NgZone,
		public alertController: AlertController,
		public titleHelperService: TitleHelperService,
		public servicesStartupService: ServicesStartupService
	) {
		//this.loadGoogleApi();
	}

	ngAfterContentInit(): void {
		this.onResize();
	}

	async ngOnInit(): Promise<void> {
		this.registerSubscribers();
		document.documentElement.style.setProperty(
			'--vh',
			`${window.innerHeight * 0.01}px`
		);

		if (Capacitor.getPlatform() != 'web') {
			this.initializeForMobile();
		}

		await this.servicesStartupService.initializeAsync();

	
	}

	private initializeForMobile(): void {
		void window.screen.orientation.lock('portrait');

		window.addEventListener('keyboardWillShow', (info) => {
			this.shouldHide = false;
			if (!document.documentElement.classList.contains('keyboard-open'))
				document.documentElement.classList.add('keyboard-open');

			if (Capacitor.getPlatform() != 'ios') {
				document.documentElement.style.setProperty(
					'--keyboard-height',
					`${(info as any)?.keyboardHeight || 250}px`
				);
			} else
				document.documentElement.style.setProperty(
					'--keyboard-height',
					'0px'
				);
		});

		window.addEventListener('keyboardDidHide', () => {
			this.shouldHide = true;
			window.setTimeout(() => {
				if (this.shouldHide)
					document.documentElement.classList.remove('keyboard-open');
			}, 100);
		});

		void StatusBar.setStyle({ style: Style.Light });
		void StatusBar.setBackgroundColor({ color: '#ffffff' });
		void StatusBar.show();
	}

	private registerSubscribers() {
		void App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			this.zone.run(() => {
				const slug = event.url.split('localhost').pop();
				if (slug) {
					void this.router.navigateByUrl(slug);
				}
			});
		});
	}

	

}
