import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
	Router,
	NavigationStart,
	Event as NavigationEvent
} from '@angular/router';
import { GestureController, GestureDetail } from '@ionic/angular';
import { BookmarkService } from 'src/app/services/bookmark/bookmark.service';
import { BookmarkAction, BookmarkKey } from 'src/app/services/bookmark/enums';
import * as _ from 'lodash';
import { TranslatorService } from 'src/app/services/translate/translator.service';
import { TitleHelperService } from 'src/app/services/title-helper/title-helper.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { SeeAllPage } from '../../pages/see-all/see-all.page';
import { ModalService } from 'src/app/services/modal/modal.service';
import { LanguagePage } from '../../pages/language/language.page';
import { iif, interval, of, Subscription } from 'rxjs';
import { ionButton } from '../../../shared/directives/text-reveal/ionButton-enum';
import { ArticleService } from 'src/app/services/article/article.service';
import { SlideDirection } from 'src/app/services/article/models/slideDirection.enums';
import { switchMap, takeWhile } from 'rxjs/operators';

@Component({
	selector: 'article-reading',
	templateUrl: './article-reading.component.html',
	styleUrls: ['./article-reading.component.scss']
})
export class ArticleReadingComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	public ionButton = ionButton;
	public _article;
	public moreLikeThis: any[] = [];
	@Input()
	public set article(value) {
		this._article = value;
	}
	public get article() {
		return this._article;
	}

	@Input()
	public interestTagCodes: string[];
	@Input()
	public allowSwipe = true;
	@Input()
	public inView = true;
	@Input()
	public articles: any[];
	@Input()
	public stack;
	@Input()
	public set parentWidth(w) {
		this.width = w;
	}

	@Input()
	public firstIndex: boolean;

	@Input()
	public lastIndex: boolean;

	@ViewChild('swipeImage')
	public swipeImage: ElementRef;

	@ViewChild('container')
	public container: ElementRef;

	@Output()
	public swipedChange: EventEmitter<boolean> = new EventEmitter<boolean>(
		true
	);
	@HostBinding('class.swiped')
	private _swiped: boolean;
	public get swiped() {
		return this._swiped;
	}
	public set swiped(value) {
		if (this._swiped != value) {
			this.swiping = true;
			window.setTimeout((_) => {
				this.swiping = false;
			}, this.swipeDuration);
		}
		this._swiped = value;
		this.swipedChange.emit(value);
	}

	@Output()
	public swipingChange: EventEmitter<boolean> = new EventEmitter<boolean>(
		true
	);
	@HostBinding('class.swiping')
	private _swiping: boolean;
	public get swiping() {
		return this._swiping;
	}
	public set swiping(value) {
		this._swiping = value;
		this.swipingChange.emit(value);
	}

	@Output() public lockSlider = new EventEmitter<boolean>();

	public segment = 'summary';

	@HostBinding('class.article')
	get isArticle() {
		return this.article.ContentType == 'Article';
	}

	@HostBinding('style.--swipe-duration')
	public swipeDuration = 300;

	@HostBinding('style.--width')
	width: number;
	@HostBinding('style.--height')
	height: number;

	private hasRead = false;

	public scrollHeight = 0;
	public scrollOffset = 0;
	public trueHeight = 0;
	public scrolledPixels = 0;
	public scrollPercentage = 0;
	public navigating = false;

	@HostBinding('style.--scrollPercentageString')
	public scrollPercentageString: string;

	public buttonsInfo: any;

	public fromLibrary = false;
	public fromExplore = false;

	@ViewChild('contentScroll')
	contentScroll: ElementRef<HTMLDivElement>;

	@ViewChild('contentScrollWeb')
	contentScrollWeb: ElementRef<HTMLDivElement>;
	private subscriptions = new Subscription();

	public fullScreen = false;

	public stickControls = false;
	private stickyContentHeight = 0;
	private stickyContentPosition = 0;

	@HostListener('window:resize', ['$event'])
	private onResize() {
		if (this.themeService.layout == 'web') {
			this.calculateHeights();
		}
	}

	@Input() public slideWillChange;

	private checkScrollComponent$ = interval(250).pipe(
		switchMap((number) => {
			return iif(() => number <= 40, of(number), of(-1));
		}),
		takeWhile((value) => value != -1),
		switchMap(() => {
			return of(this.checkElementsRendered());
		}),
		takeWhile((hasChecked) => !hasChecked)
	);

	constructor(
		private gestureCtrl: GestureController,
		private router: Router,
		protected sanitizer: DomSanitizer,
		public bookmarkService: BookmarkService,
		public translator: TranslatorService,
		private titleHelperService: TitleHelperService,
		private themeService: ThemeService,
		public modalService: ModalService,
		public translatorService: TranslatorService,
		private articleService: ArticleService
	) {}

	ngOnInit(): void {
		if (this.themeService.layout == 'web') {
			const url = window.location.href;
			if (url.includes('/home/search/')) {
				this.fromLibrary = true;
			} else {
				this.fromLibrary = false;
			}

			if (url.includes('/home/explore/')) {
				this.fromExplore = true;
			} else {
				this.fromExplore = false;
			}

			if (this.articles.length <= 1) {
				this.lastIndex = true;
				this.firstIndex = true;
			}
		}

		this.width = document.getElementsByTagName('ion-app')[0].clientWidth;
		this.height = document.getElementsByTagName('ion-app')[0].clientHeight;

		if (!this.allowSwipe || this._article.articleScrolled) {
			this.swiped = true;
		}

		if (this.themeService.layout == 'web') {
			this.swiped = true;
		}

		const inProgressSubscriber = this.bookmarkService.inProgress$.subscribe(
			(data) => {
				const articlesInProgress = data;
				const article = articlesInProgress.find(
					(element) => element.id == this._article.Id
				);
				this.scrollPercentageString = '0%';

				const progress = Number(article?.progress.replace('%', ''));

				progress
					? (this.scrollPercentage = progress)
					: (this.scrollPercentage = 0);

				if (this.scrollPercentage > 0) {
					const scrollSubscirber =
						this.checkScrollComponent$.subscribe({
							complete: () => this.scrollToStoredPercentage()
						});
					this.subscriptions.add(scrollSubscirber);
				}
			}
		);
		this.subscriptions.add(inProgressSubscriber);

		const routerEventsSubscriber = this.router.events.subscribe(
			(event: NavigationEvent) => {
				if (event instanceof NavigationStart) {
					this.navigating = true;
				}
			}
		);
		this.subscriptions.add(routerEventsSubscriber);
		this.calculateHeights();

		const slideWillChangeSubscriber = this.slideWillChange.subscribe(
			(_) => {
				if (this.inView) {
					this.saveArticleProgress();
				}

				this.subscriptions.add(slideWillChangeSubscriber);
			}
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.inView?.currentValue == true) {
			this.scrollToStoredPercentage();
		}
	}

	ngAfterViewInit(): void {
		if (this.themeService.layout == 'app') {
			const contentLanguageChangeSubscriber =
				this.translator.contentLanguageMapped$.subscribe((language) => {
					this.container.nativeElement.lang = language;
				});
			this.subscriptions.add(contentLanguageChangeSubscriber);
		}

		const gesture = this.gestureCtrl.create(
			{
				el: this.swipeImage.nativeElement,
				direction: 'y',
				disableScroll: true,
				gestureName: 'my-gesture',
				onMove: (detail) => this.onMoveGesture(detail)
			},
			true
		);

		gesture.enable();
	}

	ngOnDestroy() {
		if (this.inView) this.saveArticleProgress();

		this.subscriptions.unsubscribe();
	}

	private onMoveGesture(detail: GestureDetail) {
		if (!this.allowSwipe) return;

		if (detail.deltaY > 9 || detail.deltaY < -9) {
			if (detail.deltaY > 9) {
				this.swiped = false;
			} else if (detail.deltaY < -9) {
				this.swiped = true;
			}

			this.article.articleScrolled = this.swiped;
		}

		if (this.article.articleScrolled) {
			this.titleHelperService.setPageTitle(this._article.Title);
		}
	}

	onScrollEvent(event): void {
		if (this.navigating) return;

		this.scrollHeight = event.target.scrollHeight;
		this.scrollOffset = event.target.offsetHeight;
		this.trueHeight = this.scrollHeight - this.scrollOffset;
		this.scrolledPixels = event.target.scrollTop;
		this.scrollPercentage = (this.scrolledPixels / this.trueHeight) * 100;
		this.scrollPercentage = Math.min(
			Math.max(this.scrollPercentage, 0),
			100
		);
		this.scrollPercentageString = this.scrollPercentage.toString() + '%';

		if (!this.hasRead) {
			this.hasRead = true;
		}

		if (this.themeService.layout == 'web') {
			if (this.stickControls) {
				if (
					this.scrolledPixels + this.stickyContentHeight <
					this.stickyContentPosition
				) {
					this.stickControls = false;
					this.swipeImage.nativeElement.style.opacity = 1;
				}
			} else {
				if (this.scrolledPixels > this.stickyContentPosition) {
					this.stickControls = true;
					document.getElementById('content').style.paddingBottom =
						this.stickyContentHeight.toString() + 'px';

					this.swipeImage.nativeElement.style.opacity = 0;
				}
			}
		}
	}

	private async addArticleToInProgress(article, progress: string) {
		await this.bookmarkService.setContentBookmarkAsync(
			BookmarkKey.InProgress,
			BookmarkAction.Add,
			article,
			progress
		);
	}

	private async addArticleToCompleted(article, progress: string) {
		await this.bookmarkService.setContentBookmarkAsync(
			BookmarkKey.Completed,
			BookmarkAction.Add,
			article,
			progress
		);
		await this.bookmarkService.setContentBookmarkAsync(
			BookmarkKey.InProgress,
			BookmarkAction.Remove,
			article
		);
	}

	async presentGalleryDrawer(): Promise<void> {
		await this.modalService.viewDrawerModalAsync(
			{
				allContent: this.articles,
				type: 'interest',
				stack: this.stack
			},
			SeeAllPage,
			'drawer-modal',
			'SeeAllPage'
		);
	}

	async presentFilterModal(): Promise<void> {
		await this.modalService.viewDrawerModalAsync(
			{ isContentLanguage: true },
			LanguagePage,
			'drawer-modal',
			'LanguagePage'
		);
	}

	public nextSlide() {
		this.saveArticleProgress();
		this.articleService.changeSlideDirection(SlideDirection.Forward);
	}

	public prevSlide() {
		this.saveArticleProgress();
		this.articleService.changeSlideDirection(SlideDirection.Backward);
	}

	public saveArticleProgress() {
		if (this.article.ContentType == 'Article') {
			if (
				this.scrollPercentage >= 0 &&
				this.scrollPercentage < 100 &&
				this.hasRead
			) {
				this.addArticleToInProgress(
					this.article,
					this.scrollPercentageString
				);
			} else if (this.scrollPercentage > 99) {
				this.addArticleToCompleted(this.article, '0%');
			}
		}
	}

	public moreLikeThisChange(event): void {
		this.moreLikeThis = event;
		this.moreLikeThis.length > 0
			? (this.fullScreen = false)
			: (this.fullScreen = true);
	}

	private calculateHeights(): void {
		this.stickyContentPosition =
			Math.max(
				document.documentElement.clientHeight || 0,
				window.innerHeight || 0
			) * 0.44;

		this.stickyContentHeight =
			Math.max(
				document.documentElement.clientHeight || 0,
				window.innerHeight || 0
			) *
				0.1 +
			Math.max(
				document.documentElement.clientHeight || 0,
				window.innerHeight || 0
			) *
				0.072;
	}

	private checkElementsRendered(): boolean {
		const webScroll = this.contentScrollWeb;
		const appScroll = this.contentScroll;

		if (webScroll || appScroll) {
			return true;
		}
		return false;
	}

	private scrollToStoredPercentage(): void {
		if (!this.checkElementsRendered()) return;
		let scrollComponent: ElementRef<HTMLDivElement> = null;
		this.themeService.layout == 'web'
			? (scrollComponent = this.contentScrollWeb)
			: (scrollComponent = this.contentScroll);

		const scrollToPositionValue =
			(this.scrollPercentage / 100) *
			(scrollComponent.nativeElement.scrollHeight -
				scrollComponent.nativeElement.offsetHeight);

		if (scrollToPositionValue > 0 && this.inView) {
			this.swiped = true;
			this.article.articleScrolled = this.swiped;
			scrollComponent.nativeElement.scroll({
				top: scrollToPositionValue,
				behavior: 'smooth'
			});
			if (
				scrollToPositionValue ==
				Math.trunc(scrollComponent.nativeElement.scrollTop)
			)
				this.scrollPercentageString =
					this.scrollPercentage.toString() + '%';
		}
	}
}
