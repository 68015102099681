import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecureStorageService } from 'src/app/services/storage/secure-storage.service';
import { SecureStorageKey } from 'src/app/services/storage/models/secure-storage-key.enum';
import { Language } from 'src/app/services/translate/interfaces/language.interface';
import { environment } from '../../../environments/environment';

@Injectable()
export class TranslatorService {
	private loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);
	private language: BehaviorSubject<string>;
	private contentLanguage: BehaviorSubject<string>;

	public language$: Observable<string>;
	public languageMapped$: Observable<string>;
	public contentLanguageMapped$: Observable<string>;
	public contentLanguageFormatted$: Observable<string>;
	public hasContentLanguage$: Observable<boolean>;
	public contentLanguageDirection$: Observable<string>;
	public loaded$: Observable<boolean> = this.loaded.asObservable();

	public availableLanguages: Language[];
	public availableContentLanguages: Language[];
	public allLanguages: Language[];

	public readonly defaultLanguage: string = 'en';

	private currentLangauge: string = 'en';

	public isRightToLeft: boolean = false;

	constructor(
		public translate: TranslateService,
		private httpClient: HttpClient,
		private secureStorageService: SecureStorageService,
		@Inject(DOCUMENT) private document: Document
	) {
		if (!translate.getDefaultLang())
			translate.setDefaultLang(this.defaultLanguage);

		this.useLanguage();
	}

	useLanguage(lang: string = '', fireEvents: boolean = true) {
		var lanToUse = lang || this.translate.getDefaultLang();

		var selectedLanguage = environment.availablelanguages.filter((obj) => {
			return obj.code === lanToUse;
		});

		if (selectedLanguage) {
			this.currentLangauge = lanToUse;
			this.document.documentElement.lang = lanToUse;

			if (selectedLanguage[0].isRightToLeft)
				this.document.documentElement.dir = 'rtl';
			else this.document.documentElement.dir = 'ltr';

			var elem = this.document.getElementsByClassName(
				'section-container'
			)[0] as any;
			if (elem) {
				if (selectedLanguage[0].isRightToLeft) elem.dir = 'rtl';
				else elem.dir = 'ltr';
			}
			this.isRightToLeft = selectedLanguage[0].isRightToLeft;
		}
		if (fireEvents) this.translate.use(lanToUse);
	}

	getAvailableLanguages() {
		return environment.availablelanguages;
	}

	getCurrentLanguage(): string {
		return this.currentLangauge;
	}

	getIsRTL(): boolean {
		return this.isRightToLeft;
	}

	refreshRTL() {
		if (this.isRightToLeft) {
			var remeberLan = this.currentLangauge;
			setTimeout(() => {
				this.useLanguage('en', false);
				setTimeout(() => {
					this.useLanguage(remeberLan, false);
				}, 500);
			}, 500);
		}
	}

	public async initializeAsync(): Promise<void> {
		this.translate.setDefaultLang(this.defaultLanguage);

		const data1 = await this.httpClient
			.get('assets/languages/languages.json')
			.toPromise();

		const data = await environment.availablelanguages;

		this.allLanguages = data as Language[];
		this.availableLanguages = this.allLanguages.filter(
			(l) => l.contentSupport
		);
		this.availableContentLanguages = this.availableLanguages.filter(
			(l) => l.code != 'es-la'
		);

		const language = await this.getInitializeLanguageAsync();
		const contentLanguage = await this.getInitializeContentLanguageAsync();

		this.language = new BehaviorSubject<string>(language);
		this.contentLanguage = new BehaviorSubject<string>(
			contentLanguage ?? language
		);

		this.initializeObservables();
		this.initializeSubscribers();

		this.loaded.next(true);
	}

	private initializeObservables(): void {
		this.language$ = this.language.asObservable();

		this.contentLanguageMapped$ = this.contentLanguage.asObservable().pipe(
			map((language) => {
				return this.mapLanguage(language);
			})
		);

		this.contentLanguageFormatted$ = this.contentLanguageMapped$.pipe(
			map((language) => {
				return language.replace('-', '_');
			})
		);

		this.languageMapped$ = this.language.asObservable().pipe(
			map((language) => {
				return this.mapLanguage(language);
			})
		);

		this.hasContentLanguage$ = combineLatest([
			this.language,
			this.contentLanguage
		]).pipe(
			map(([language, contentLanguage]) => {
				return language != contentLanguage;
			})
		);

		this.contentLanguageDirection$ = this.contentLanguage.pipe(
			map((language) => {
				const availableLanguage = this.availableLanguages.find(
					(availableLanguage) => availableLanguage.code == language
				);

				if (!availableLanguage) return 'rtl';

				return availableLanguage.isRightToLeft ? 'rtl' : 'ltr';
			})
		);
	}

	private initializeSubscribers(): void {
		this.language.subscribe((language) => {
			this.translate.use(language);
			this.updateDocumentForLanguage(language);
			void this.secureStorageService.set(
				SecureStorageKey.Language,
				language
			);
		});

		this.contentLanguage.subscribe((language) => {
			if (language == this.language.value) {
				void this.secureStorageService.remove(
					SecureStorageKey.ContentLangOverride
				);
			} else {
				void this.secureStorageService.set(
					SecureStorageKey.ContentLangOverride,
					language
				);
			}
		});
	}

	public loadGridLocale() {
		if (this.translate.currentLang) {
			for (let key in this.gridLocale) {
				let value = this.gridLocale[key];
				var text = this.translate.instant('ar-grid.' + key);
				this.gridLocale[key] = text;
			}
		}
	}

	private async getInitializeContentLanguageAsync(): Promise<string> {
		const language = await this.secureStorageService.get(
			SecureStorageKey.ContentLangOverride
		);

		return language;
	}

	private async getInitializeLanguageAsync(): Promise<string> {
		let language = await this.secureStorageService
			.get(SecureStorageKey.Language)
			.then((value) => value || this.translate.getBrowserLang());

		if (!this.availableLanguages.find((l) => l.code == language))
			language = this.defaultLanguage;

		return language;
	}

	private updateDocumentForLanguage(language: string): void {
		document.documentElement.dir =
			this.availableLanguages.find((e) => e.code === language)
				.isRightToLeft == true
				? 'rtl'
				: 'ltr';

		document.documentElement.lang = language;
	}

	private mapLanguage(language: string): string {
		if (language == 'es-la') return 'es';
		return language;
	}

	public getTranslation(i18nPath: string): string {
		return this.translate.instant(i18nPath) as string;
	}

	public setContentLanguage(language: string): void {
		if (this.contentLanguage.value != language)
			this.contentLanguage.next(language);
	}

	public setLanguage(language: string): void {
		if (this.language.value != language) this.language.next(language);

		this.setContentLanguage(language);
	}

	public gridLocale: {
		[key: string]: string;
	} = {
		selectAll: '(Select All)',
		selectAllSearchResults: '(Select All Search Results)',
		searchOoo: 'Search...',
		blanks: '(Blanks)',
		noMatches: 'No matches',
		filterOoo: 'Filter...',
		equals: 'Equals',
		notEqual: 'Not equal',
		blank: 'Blank',
		notBlank: 'Not blank',
		empty: 'Choose One',
		lessThan: 'Less than',
		greaterThan: 'Greater than',
		lessThanOrEqual: 'Less than or equal',
		greaterThanOrEqual: 'Greater than or equal',
		inRange: 'In range',
		inRangeStart: 'from',
		inRangeEnd: 'to',
		contains: 'Contains',
		notContains: 'Not contains',
		startsWith: 'Starts with',
		endsWith: 'Ends with',
		dateFormatOoo: 'yyyy-mm-dd',
		andCondition: 'AND',
		orCondition: 'OR',
		applyFilter: 'Apply',
		resetFilter: 'Reset',
		clearFilter: 'Clear',
		cancelFilter: 'Cancel',
		textFilter: 'Text Filter',
		numberFilter: 'Number Filter',
		dateFilter: 'Date Filter',
		setFilter: 'Set Filter',
		columns: 'Columns',
		filters: 'Filters',
		pivotMode: 'Pivot Mode',
		groups: 'Row Groups',
		rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
		values: 'Values',
		valueColumnsEmptyMessage: 'Drag here to aggregate',
		pivots: 'Column Labels',
		pivotColumnsEmptyMessage: 'Drag here to set column labels',
		group: 'Group',
		rowDragRows: 'rows',
		loadingOoo: 'Loading...',
		noRowsToShow: 'No Rows To Show',
		enabled: 'Enabled',
		pinColumn: 'Pin Column',
		pinLeft: 'Pin Left',
		pinRight: 'Pin Right',
		noPin: 'No Pin',
		valueAggregation: 'Value Aggregation',
		autosizeThiscolumn: 'Autosize This Column',
		autosizeAllColumns: 'Autosize All Columns',
		groupBy: 'Group by',
		ungroupBy: 'Un-Group by',
		addToValues: 'Add ${variable} to values',
		removeFromValues: 'Remove ${variable} from values',
		addToLabels: 'Add ${variable} to labels',
		removeFromLabels: 'Remove ${variable} from labels',
		resetColumns: 'Reset Columns',
		expandAll: 'Expand All',
		collapseAll: 'Close All',
		copy: 'Copy',
		ctrlC: 'Ctrl+C',
		copyWithHeaders: 'Copy With Headers',
		copyWithGroupHeaders: 'Copy with Group Headers',
		paste: 'Paste',
		ctrlV: 'Ctrl+V',
		export: 'Export',
		csvExport: 'CSV Export',
		excelExport: 'Excel Export',
		sum: 'Sum',
		min: 'Min',
		max: 'Max',
		none: 'None',
		count: 'Count',
		avg: 'Average',
		filteredRows: 'Filtered',
		selectedRows: 'Selected',
		totalRows: 'Total Rows',
		totalAndFilteredRows: 'Rows',
		more: 'More',
		to: 'to',
		of: 'of',
		page: 'Page',
		nextPage: 'Next Page',
		lastPage: 'Last Page',
		firstPage: 'First Page',
		previousPage: 'Previous Page',
		pivotColumnGroupTotals: 'Total',
		pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
		pivotChart: 'Pivot Chart',
		chartRange: 'Chart Range',
		columnChart: 'Column',
		groupedColumn: 'Grouped',
		stackedColumn: 'Stacked',
		normalizedColumn: '100% Stacked',
		barChart: 'Bar',
		groupedBar: 'Grouped',
		stackedBar: 'Stacked',
		normalizedBar: '100% Stacked',
		pieChart: 'Pie',
		pie: 'Pie',
		doughnut: 'Doughnut',
		line: 'Line',
		xyChart: 'X Y (Scatter)',
		scatter: 'Scatter',
		bubble: 'Bubble',
		areaChart: 'Area',
		area: 'Area',
		stackedArea: 'Stacked',
		normalizedArea: '100% Stacked',
		histogramChart: 'Histogram',
		combinationChart: 'Combination',
		columnLineCombo: 'Column & Line',
		AreaColumnCombo: 'Area & Column',
		pivotChartTitle: 'Pivot Chart',
		rangeChartTitle: 'Range Chart',
		settings: 'Settings',
		data: 'Data',
		format: 'Format',
		categories: 'Categories',
		defaultCategory: '(None)',
		series: 'Series',
		xyValues: 'X Y Values',
		paired: 'Paired Mode',
		axis: 'Axis',
		navigator: 'Navigator',
		color: 'Color',
		thickness: 'Thickness',
		xType: 'X Type',
		automatic: 'Automatic',
		category: 'Category',
		number: 'Number',
		time: 'Time',
		autoRotate: 'Auto Rotate',
		xRotation: 'X Rotation',
		yRotation: 'Y Rotation',
		ticks: 'Ticks',
		width: 'Width',
		height: 'Height',
		length: 'Length',
		padding: 'Padding',
		spacing: 'Spacing',
		chart: 'Chart',
		title: 'Title',
		titlePlaceholder: 'Chart title - double click to edit',
		background: 'Background',
		font: 'Font',
		top: 'Top',
		right: 'Right',
		bottom: 'Bottom',
		left: 'Left',
		labels: 'Labels',
		size: 'Size',
		minSize: 'Minimum Size',
		maxSize: 'Maximum Size',
		legend: 'Legend',
		position: 'Position',
		markerSize: 'Marker Size',
		markerStroke: 'Marker Stroke',
		markerPadding: 'Marker Padding',
		itemSpacing: 'Item Spacing',
		itemPaddingX: 'Item Padding X',
		itemPaddingY: 'Item Padding Y',
		layoutHorizontalSpacing: 'Horizontal Spacing',
		layoutVerticalSpacing: 'Vertical Spacing',
		strokeWidth: 'Stroke Width',
		offset: 'Offset',
		offsets: 'Offsets',
		tooltips: 'Tooltips',
		callout: 'Callout',
		markers: 'Markers',
		shadow: 'Shadow',
		blur: 'Blur',
		xOffset: 'X Offset',
		yOffset: 'Y Offset',
		lineWidth: 'Line Width',
		normal: 'Normal',
		bold: 'Bold',
		italic: 'Italic',
		boldItalic: 'Bold Italic',
		predefined: 'Predefined',
		fillOpacity: 'Fill Opacity',
		strokeOpacity: 'Line Opacity',
		histogramBinCount: 'Bin count',
		columnGroup: 'Column',
		barGroup: 'Bar',
		pieGroup: 'Pie',
		lineGroup: 'Line',
		scatterGroup: 'X Y (Scatter)',
		areaGroup: 'Area',
		histogramGroup: 'Histogram',
		combinationGroup: 'Combination',
		groupedColumnTooltip: 'Grouped',
		stackedColumnTooltip: 'Stacked',
		normalizedColumnTooltip: '100% Stacked',
		groupedBarTooltip: 'Grouped',
		stackedBarTooltip: 'Stacked',
		normalizedBarTooltip: '100% Stacked',
		pieTooltip: 'Pie',
		doughnutTooltip: 'Doughnut',
		lineTooltip: 'Line',
		groupedAreaTooltip: 'Area',
		stackedAreaTooltip: 'Stacked',
		normalizedAreaTooltip: '100% Stacked',
		scatterTooltip: 'Scatter',
		bubbleTooltip: 'Bubble',
		histogramTooltip: 'Histogram',
		columnLineComboTooltip: 'Column & Line',
		areaColumnComboTooltip: 'Area & Column',
		customComboTooltip: 'Custom Combination',
		noDataToChart: 'No data available to be charted.',
		pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
		chartSettingsToolbarTooltip: 'Menu',
		chartLinkToolbarTooltip: 'Linked to Grid',
		chartUnlinkToolbarTooltip: 'Unlinked from Grid',
		chartDownloadToolbarTooltip: 'Download Chart',
		seriesChartType: 'Series Chart Type',
		seriesType: 'Series Type',
		secondaryAxis: 'Secondary Axis',
		ariaChecked: 'checked',
		ariaColumn: 'Column',
		ariaColumnGroup: 'Column Group',
		ariaColumnList: 'Column List',
		ariaColumnSelectAll: 'Toggle Select All Columns',
		ariaDateFilterInput: 'Date Filter Input',
		ariaDefaultListName: 'List',
		ariaFilterColumnsInput: 'Filter Columns Input',
		ariaFilterFromValue: 'Filter from value',
		ariaFilterInput: 'Filter Input',
		ariaFilterList: 'Filter List',
		ariaFilterToValue: 'Filter to value',
		ariaFilterValue: 'Filter Value',
		ariaFilteringOperator: 'Filtering Operator',
		ariaHidden: 'hidden',
		ariaIndeterminate: 'indeterminate',
		ariaInputEditor: 'Input Editor',
		ariaMenuColumn: 'Press CTRL ENTER to open column menu.',
		ariaRowDeselect: 'Press SPACE to deselect this row',
		ariaRowSelectAll: 'Press Space to toggle all rows selection',
		ariaRowToggleSelection: 'Press Space to toggle row selection',
		ariaRowSelect: 'Press SPACE to select this row',
		ariaSearch: 'Search',
		ariaSortableColumn: 'Press ENTER to sort',
		ariaToggleVisibility: 'Press SPACE to toggle visibility',
		ariaUnchecked: 'unchecked',
		ariaVisible: 'visible',
		ariaSearchFilterValues: 'Search filter values',
		ariaRowGroupDropZonePanelLabel: 'Row Groups',
		ariaValuesDropZonePanelLabel: 'Values',
		ariaPivotDropZonePanelLabel: 'Column Labels',
		ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
		ariaDropZoneColumnValueItemDescription:
			'Press ENTER to change the aggregation type',
		ariaLabelColumnMenu: 'Column Menu',
		ariaLabelCellEditor: 'Cell Editor',
		ariaLabelDialog: 'Dialog',
		ariaLabelSelectField: 'Select Field',
		ariaLabelTooltip: 'Tooltip',
		ariaLabelContextMenu: 'Context Menu',
		ariaLabelSubMenu: 'SubMenu',
		ariaLabelAggregationFunction: 'Aggregation Function',
		thousandSeparator: ',',
		decimalSeparator: '.'
	};
}
