import { timer, Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class Timer {
	private stop$ = new Subject();

	public stop(): void {
		this.stop$.next();
	}

	public delay(ms: number): Observable<number> {
		return timer(0, ms).pipe(takeUntil(this.stop$));
	}

	public static sleep(ms: number): Promise<void> {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}
}
