export interface Dot {
	position: number;
	active: boolean;
}

export type Dots = Array<Dot>;
export const Dots = [
	{ position: -2, active: false },
	{ position: -1, active: false },
	{ position: 0, active: true },
	{ position: 1, active: false },
	{ position: 2, active: false }
];
