import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserData } from '../auth-service/user-data.model';

@Injectable({
	providedIn: 'root'
})
/**
 * This is the event service class
 * @deprecated This class is deprecated and event handling should be done in their respective services.
 */
export class EventService {
	public isLoggedIn: BehaviorSubject<UserData> =
		new BehaviorSubject<UserData>(null);

	public loggedOut: EventEmitter<any> = new EventEmitter<any>();

	public clientAccessToken: EventEmitter<any> = new EventEmitter<any>();

	public removeAccessToken: EventEmitter<boolean> =
		new EventEmitter<boolean>();
}
