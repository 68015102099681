/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HealthFindrApiConfiguration, HealthFindrApiConfigurationParams } from './health-findr-api-configuration';

import { HealthFindrAdminService } from './services/health-findr-admin.service';
import { HealthFindrAnonymousService } from './services/health-findr-anonymous.service';
import { HealthFindrBroadcastMessageService } from './services/health-findr-broadcast-message.service';
import { HealthFindrCommunicationTemplateService } from './services/health-findr-communication-template.service';
import { HealthFindrPromotionalLinkService } from './services/health-findr-promotional-link.service';
import { HealthFindrServiceConfigurationService } from './services/health-findr-service-configuration.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    HealthFindrAdminService,
    HealthFindrAnonymousService,
    HealthFindrBroadcastMessageService,
    HealthFindrCommunicationTemplateService,
    HealthFindrPromotionalLinkService,
    HealthFindrServiceConfigurationService,
    HealthFindrApiConfiguration
  ],
})
export class HealthFindrModule {
  static forRoot(params: HealthFindrApiConfigurationParams): ModuleWithProviders<HealthFindrModule> {
    return {
      ngModule: HealthFindrModule,
      providers: [
        {
          provide: HealthFindrApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: HealthFindrModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('HealthFindrModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
