/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { OrganizationBaseService } from '../organization-base-service';
import { OrganizationApiConfiguration } from '../organization-api-configuration';
import { OrganizationStrictHttpResponse } from '../organization-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConsumerSearchModel } from '../models/consumer-search-model';
import { HealthcareServiceConsumerModel } from '../models/healthcare-service-consumer-model';
import { OrganizationSearchResultModel } from '../models/organization-search-result-model';

@Injectable({
  providedIn: 'root',
})
export class ORGConsumerService extends OrganizationBaseService {
  constructor(
    config: OrganizationApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation consumerHealthcareServicePost
   */
  static readonly ConsumerHealthcareServicePostPath = '/Consumer/HealthcareService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerHealthcareServicePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerHealthcareServicePost$Response(params?: {
    latitude?: number;
    longitude?: number;
    body?: ConsumerSearchModel
  }): Observable<OrganizationStrictHttpResponse<Array<HealthcareServiceConsumerModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ORGConsumerService.ConsumerHealthcareServicePostPath, 'post');
    if (params) {
      rb.query('latitude', params.latitude, {});
      rb.query('longitude', params.longitude, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<Array<HealthcareServiceConsumerModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerHealthcareServicePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerHealthcareServicePost(params?: {
    latitude?: number;
    longitude?: number;
    body?: ConsumerSearchModel
  }): Observable<Array<HealthcareServiceConsumerModel>> {

    return this.consumerHealthcareServicePost$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<Array<HealthcareServiceConsumerModel>>) => r.body as Array<HealthcareServiceConsumerModel>)
    );
  }

  /**
   * Path part for operation consumerOrganizationsPageSizeGet
   */
  static readonly ConsumerOrganizationsPageSizeGetPath = '/Consumer/Organizations/{pageSize}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerOrganizationsPageSizeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerOrganizationsPageSizeGet$Response(params: {
    pageSize: number;
  }): Observable<OrganizationStrictHttpResponse<Array<OrganizationSearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ORGConsumerService.ConsumerOrganizationsPageSizeGetPath, 'get');
    if (params) {
      rb.path('pageSize', params.pageSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<Array<OrganizationSearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerOrganizationsPageSizeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerOrganizationsPageSizeGet(params: {
    pageSize: number;
  }): Observable<Array<OrganizationSearchResultModel>> {

    return this.consumerOrganizationsPageSizeGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<Array<OrganizationSearchResultModel>>) => r.body as Array<OrganizationSearchResultModel>)
    );
  }

}
