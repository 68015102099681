import {
	Component,
	OnInit,
	Input,
	HostBinding,
	OnDestroy,
	AfterViewChecked
} from '@angular/core';
import { TranslatorService } from 'src/app/services/translate/translator.service';
import { Subscription } from 'rxjs';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
	selector: 'app-about-section',
	templateUrl: './about-section.component.html',
	styleUrls: ['./about-section.component.scss']
})
export class AboutSectionComponent
	implements OnInit, OnDestroy, AfterViewChecked
{
	public currentPage = 1;
	public direction: string;

	public slidesOptions: any = {
		initialSlide: this.currentPage - 1,
		slidesPerView: 1,
		grabCursor: true,
		loop: true,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			dynamicBullets: true,
			dynamicMainBullets: 3
		}
	};

	private subscriptions: Subscription = new Subscription();

	@Input() carousel = false;

	@Input()
	public closeSlides: () => void;

	@HostBinding('style.--heightToDisplay.px')
	public heightToDisplay: number;

	constructor(
		private translatorService: TranslatorService,
		private themeService: ThemeService
	) {}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnInit(): void {
		this.direction = document
			.getElementsByTagName('html')[0]
			.getAttribute('dir');

		this.subscriptions.add(
			this.translatorService.language$.subscribe((lang) => {
				this.direction = document
					.getElementsByTagName('html')[0]
					.getAttribute('dir');
			})
		);
	}

	ngAfterViewChecked(): void {
		if (this.themeService.layout == 'app') {
			this.heightToDisplay =
				document.getElementById('ionContent').scrollHeight;
		}
	}

	public introSlidesControl(nextSlide = false): void {
		let endReached = false;
		if (nextSlide == false) {
			--this.currentPage;
		} else {
			++this.currentPage;
		}

		if (this.currentPage == 0) {
			this.currentPage = 3;
			endReached = true;
		}

		if (this.currentPage == 4) {
			this.currentPage = 1;
			endReached = true;
		}

		if (!this.carousel && endReached) {
			this.currentPage = 1;
			this.closeSlides();
		}
	}
}
