/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CalendarBaseService } from '../calendar-base-service';
import { CalendarApiConfiguration } from '../calendar-api-configuration';
import { CalendarStrictHttpResponse } from '../calendar-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CalendarModel } from '../models/calendar-model';

@Injectable({
  providedIn: 'root',
})
export class CalendarConsumerService extends CalendarBaseService {
  constructor(
    config: CalendarApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation consumerLinkCalendarGet
   */
  static readonly ConsumerLinkCalendarGetPath = '/Consumer/LinkCalendar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerLinkCalendarGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerLinkCalendarGet$Response(params?: {
    redirectURL?: string;
  }): Observable<CalendarStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarConsumerService.ConsumerLinkCalendarGetPath, 'get');
    if (params) {
      rb.query('redirectURL', params.redirectURL, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerLinkCalendarGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerLinkCalendarGet(params?: {
    redirectURL?: string;
  }): Observable<string> {

    return this.consumerLinkCalendarGet$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation consumerCalendarsGet
   */
  static readonly ConsumerCalendarsGetPath = '/Consumer/Calendars';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerCalendarsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerCalendarsGet$Response(params?: {
  }): Observable<CalendarStrictHttpResponse<Array<CalendarModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarConsumerService.ConsumerCalendarsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<Array<CalendarModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerCalendarsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerCalendarsGet(params?: {
  }): Observable<Array<CalendarModel>> {

    return this.consumerCalendarsGet$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<Array<CalendarModel>>) => r.body as Array<CalendarModel>)
    );
  }

  /**
   * Path part for operation consumerCalendarPost
   */
  static readonly ConsumerCalendarPostPath = '/Consumer/Calendar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerCalendarPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerCalendarPost$Response(params?: {
    body?: CalendarModel
  }): Observable<CalendarStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarConsumerService.ConsumerCalendarPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as CalendarStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerCalendarPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerCalendarPost(params?: {
    body?: CalendarModel
  }): Observable<void> {

    return this.consumerCalendarPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<void>) => r.body as void)
    );
  }

}
