/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MediaBaseService } from '../media-base-service';
import { MediaApiConfiguration } from '../media-api-configuration';
import { MediaStrictHttpResponse } from '../media-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ArticleAudioModel } from '../models/article-audio-model';
import { ArticleDetailModel } from '../models/article-detail-model';
import { ArticleGetResponseModel } from '../models/article-get-response-model';
import { ArticleModel } from '../models/article-model';
import { ArticleRelationalTagModel } from '../models/article-relational-tag-model';
import { ArticleSearchResultModelSearchResultModel } from '../models/article-search-result-model-search-result-model';
import { InfoGraphicModel } from '../models/info-graphic-model';
import { InfoGraphicSaveModel } from '../models/info-graphic-save-model';
import { KeyValueGuidModel } from '../models/key-value-guid-model';
import { KeyValueModel } from '../models/key-value-model';
import { SearchRequestModel } from '../models/search-request-model';
import { TranslateModel } from '../models/translate-model';

@Injectable({
  providedIn: 'root',
})
export class MediaArticleService extends MediaBaseService {
  constructor(
    config: MediaApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation articleSearchArticlePublisherIdPost
   */
  static readonly ArticleSearchArticlePublisherIdPostPath = '/Article/SearchArticle/{publisherId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articleSearchArticlePublisherIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articleSearchArticlePublisherIdPost$Plain$Response(params: {
    publisherId: string;
    body?: SearchRequestModel
  }): Observable<MediaStrictHttpResponse<ArticleSearchResultModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticleSearchArticlePublisherIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<ArticleSearchResultModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articleSearchArticlePublisherIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articleSearchArticlePublisherIdPost$Plain(params: {
    publisherId: string;
    body?: SearchRequestModel
  }): Observable<ArticleSearchResultModelSearchResultModel> {

    return this.articleSearchArticlePublisherIdPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<ArticleSearchResultModelSearchResultModel>) => r.body as ArticleSearchResultModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articleSearchArticlePublisherIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articleSearchArticlePublisherIdPost$Json$Response(params: {
    publisherId: string;
    body?: SearchRequestModel
  }): Observable<MediaStrictHttpResponse<ArticleSearchResultModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticleSearchArticlePublisherIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<ArticleSearchResultModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articleSearchArticlePublisherIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articleSearchArticlePublisherIdPost$Json(params: {
    publisherId: string;
    body?: SearchRequestModel
  }): Observable<ArticleSearchResultModelSearchResultModel> {

    return this.articleSearchArticlePublisherIdPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<ArticleSearchResultModelSearchResultModel>) => r.body as ArticleSearchResultModelSearchResultModel)
    );
  }

  /**
   * Path part for operation articlePublisherIdIdGet
   */
  static readonly ArticlePublisherIdIdGetPath = '/Article/{publisherId}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdGet$Plain$Response(params: {
    publisherId: string;
    id: string;
  }): Observable<MediaStrictHttpResponse<ArticleGetResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdGetPath, 'get');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<ArticleGetResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdGet$Plain(params: {
    publisherId: string;
    id: string;
  }): Observable<ArticleGetResponseModel> {

    return this.articlePublisherIdIdGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<ArticleGetResponseModel>) => r.body as ArticleGetResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdGet$Json$Response(params: {
    publisherId: string;
    id: string;
  }): Observable<MediaStrictHttpResponse<ArticleGetResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdGetPath, 'get');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<ArticleGetResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdGet$Json(params: {
    publisherId: string;
    id: string;
  }): Observable<ArticleGetResponseModel> {

    return this.articlePublisherIdIdGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<ArticleGetResponseModel>) => r.body as ArticleGetResponseModel)
    );
  }

  /**
   * Path part for operation articlePublisherIdPost
   */
  static readonly ArticlePublisherIdPostPath = '/Article/{publisherId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdPost$Plain$Response(params: {
    publisherId: string;
    body?: ArticleModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdPost$Plain(params: {
    publisherId: string;
    body?: ArticleModel
  }): Observable<string> {

    return this.articlePublisherIdPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdPost$Json$Response(params: {
    publisherId: string;
    body?: ArticleModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdPost$Json(params: {
    publisherId: string;
    body?: ArticleModel
  }): Observable<string> {

    return this.articlePublisherIdPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation articlePublisherIdApproveIdPost
   */
  static readonly ArticlePublisherIdApproveIdPostPath = '/Article/{publisherId}/Approve/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdApproveIdPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdApproveIdPost$Plain$Response(params: {
    publisherId: string;
    id: string;
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdApproveIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdApproveIdPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdApproveIdPost$Plain(params: {
    publisherId: string;
    id: string;
  }): Observable<string> {

    return this.articlePublisherIdApproveIdPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdApproveIdPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdApproveIdPost$Json$Response(params: {
    publisherId: string;
    id: string;
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdApproveIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdApproveIdPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdApproveIdPost$Json(params: {
    publisherId: string;
    id: string;
  }): Observable<string> {

    return this.articlePublisherIdApproveIdPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation articlePublisherIdIdHtmlArticleLanguageIdGet
   */
  static readonly ArticlePublisherIdIdHtmlArticleLanguageIdGetPath = '/Article/{publisherId}/{id}/HtmlArticle/{languageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdHtmlArticleLanguageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdHtmlArticleLanguageIdGet$Plain$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<MediaStrictHttpResponse<ArticleDetailModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdHtmlArticleLanguageIdGetPath, 'get');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<ArticleDetailModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdHtmlArticleLanguageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdHtmlArticleLanguageIdGet$Plain(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<ArticleDetailModel> {

    return this.articlePublisherIdIdHtmlArticleLanguageIdGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<ArticleDetailModel>) => r.body as ArticleDetailModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdHtmlArticleLanguageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdHtmlArticleLanguageIdGet$Json$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<MediaStrictHttpResponse<ArticleDetailModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdHtmlArticleLanguageIdGetPath, 'get');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<ArticleDetailModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdHtmlArticleLanguageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdHtmlArticleLanguageIdGet$Json(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<ArticleDetailModel> {

    return this.articlePublisherIdIdHtmlArticleLanguageIdGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<ArticleDetailModel>) => r.body as ArticleDetailModel)
    );
  }

  /**
   * Path part for operation articlePublisherIdIdHtmlArticleLanguageIdPost
   */
  static readonly ArticlePublisherIdIdHtmlArticleLanguageIdPostPath = '/Article/{publisherId}/{id}/HtmlArticle/{languageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdHtmlArticleLanguageIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdHtmlArticleLanguageIdPost$Plain$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: ArticleDetailModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdHtmlArticleLanguageIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdHtmlArticleLanguageIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdHtmlArticleLanguageIdPost$Plain(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: ArticleDetailModel
  }): Observable<string> {

    return this.articlePublisherIdIdHtmlArticleLanguageIdPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdHtmlArticleLanguageIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdHtmlArticleLanguageIdPost$Json$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: ArticleDetailModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdHtmlArticleLanguageIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdHtmlArticleLanguageIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdHtmlArticleLanguageIdPost$Json(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: ArticleDetailModel
  }): Observable<string> {

    return this.articlePublisherIdIdHtmlArticleLanguageIdPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation articlePublisherIdIdInfoGraphicLanguageIdGet
   */
  static readonly ArticlePublisherIdIdInfoGraphicLanguageIdGetPath = '/Article/{publisherId}/{id}/InfoGraphic/{languageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdInfoGraphicLanguageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdInfoGraphicLanguageIdGet$Plain$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<MediaStrictHttpResponse<InfoGraphicModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdInfoGraphicLanguageIdGetPath, 'get');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<InfoGraphicModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdInfoGraphicLanguageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdInfoGraphicLanguageIdGet$Plain(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<InfoGraphicModel> {

    return this.articlePublisherIdIdInfoGraphicLanguageIdGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<InfoGraphicModel>) => r.body as InfoGraphicModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdInfoGraphicLanguageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdInfoGraphicLanguageIdGet$Json$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<MediaStrictHttpResponse<InfoGraphicModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdInfoGraphicLanguageIdGetPath, 'get');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<InfoGraphicModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdInfoGraphicLanguageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdInfoGraphicLanguageIdGet$Json(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<InfoGraphicModel> {

    return this.articlePublisherIdIdInfoGraphicLanguageIdGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<InfoGraphicModel>) => r.body as InfoGraphicModel)
    );
  }

  /**
   * Path part for operation articlePublisherIdIdInfoGraphicLanguageIdPost
   */
  static readonly ArticlePublisherIdIdInfoGraphicLanguageIdPostPath = '/Article/{publisherId}/{id}/InfoGraphic/{languageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdInfoGraphicLanguageIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdInfoGraphicLanguageIdPost$Plain$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: InfoGraphicSaveModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdInfoGraphicLanguageIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdInfoGraphicLanguageIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdInfoGraphicLanguageIdPost$Plain(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: InfoGraphicSaveModel
  }): Observable<string> {

    return this.articlePublisherIdIdInfoGraphicLanguageIdPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdInfoGraphicLanguageIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdInfoGraphicLanguageIdPost$Json$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: InfoGraphicSaveModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdInfoGraphicLanguageIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdInfoGraphicLanguageIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdInfoGraphicLanguageIdPost$Json(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: InfoGraphicSaveModel
  }): Observable<string> {

    return this.articlePublisherIdIdInfoGraphicLanguageIdPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation articlePublisherIdIdInfoGraphicImageLanguageIdGet
   */
  static readonly ArticlePublisherIdIdInfoGraphicImageLanguageIdGetPath = '/Article/{publisherId}/{id}/InfoGraphicImage/{languageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdInfoGraphicImageLanguageIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdInfoGraphicImageLanguageIdGet$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<MediaStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdInfoGraphicImageLanguageIdGetPath, 'get');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as MediaStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdInfoGraphicImageLanguageIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdInfoGraphicImageLanguageIdGet(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<void> {

    return this.articlePublisherIdIdInfoGraphicImageLanguageIdGet$Response(params).pipe(
      map((r: MediaStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation articlePublisherIdIdAudioLanguageIdGet
   */
  static readonly ArticlePublisherIdIdAudioLanguageIdGetPath = '/Article/{publisherId}/{id}/Audio/{languageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdAudioLanguageIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdAudioLanguageIdGet$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<MediaStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdAudioLanguageIdGetPath, 'get');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as MediaStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdAudioLanguageIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdAudioLanguageIdGet(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<void> {

    return this.articlePublisherIdIdAudioLanguageIdGet$Response(params).pipe(
      map((r: MediaStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation articlePublisherIdIdAudioLanguageIdPost
   */
  static readonly ArticlePublisherIdIdAudioLanguageIdPostPath = '/Article/{publisherId}/{id}/Audio/{languageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdAudioLanguageIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdAudioLanguageIdPost$Plain$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: ArticleAudioModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdAudioLanguageIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdAudioLanguageIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdAudioLanguageIdPost$Plain(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: ArticleAudioModel
  }): Observable<string> {

    return this.articlePublisherIdIdAudioLanguageIdPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdAudioLanguageIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdAudioLanguageIdPost$Json$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: ArticleAudioModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdAudioLanguageIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdAudioLanguageIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdAudioLanguageIdPost$Json(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: ArticleAudioModel
  }): Observable<string> {

    return this.articlePublisherIdIdAudioLanguageIdPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation articlePublisherIdIdRelationalTagLanguageIdGet
   */
  static readonly ArticlePublisherIdIdRelationalTagLanguageIdGetPath = '/Article/{publisherId}/{id}/RelationalTag/{languageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdRelationalTagLanguageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdRelationalTagLanguageIdGet$Plain$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<MediaStrictHttpResponse<Array<ArticleRelationalTagModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdRelationalTagLanguageIdGetPath, 'get');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<ArticleRelationalTagModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdRelationalTagLanguageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdRelationalTagLanguageIdGet$Plain(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<Array<ArticleRelationalTagModel>> {

    return this.articlePublisherIdIdRelationalTagLanguageIdGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<ArticleRelationalTagModel>>) => r.body as Array<ArticleRelationalTagModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdRelationalTagLanguageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdRelationalTagLanguageIdGet$Json$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<MediaStrictHttpResponse<Array<ArticleRelationalTagModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdRelationalTagLanguageIdGetPath, 'get');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<ArticleRelationalTagModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdRelationalTagLanguageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdRelationalTagLanguageIdGet$Json(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<Array<ArticleRelationalTagModel>> {

    return this.articlePublisherIdIdRelationalTagLanguageIdGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<ArticleRelationalTagModel>>) => r.body as Array<ArticleRelationalTagModel>)
    );
  }

  /**
   * Path part for operation articlePublisherIdIdRelationalTagLanguageIdPost
   */
  static readonly ArticlePublisherIdIdRelationalTagLanguageIdPostPath = '/Article/{publisherId}/{id}/RelationalTag/{languageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdRelationalTagLanguageIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdRelationalTagLanguageIdPost$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: ArticleRelationalTagModel
  }): Observable<MediaStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdRelationalTagLanguageIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as MediaStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdRelationalTagLanguageIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdRelationalTagLanguageIdPost(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: ArticleRelationalTagModel
  }): Observable<void> {

    return this.articlePublisherIdIdRelationalTagLanguageIdPost$Response(params).pipe(
      map((r: MediaStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation articlePublisherIdIdRelationalTagLanguageIdKeyDelete
   */
  static readonly ArticlePublisherIdIdRelationalTagLanguageIdKeyDeletePath = '/Article/{publisherId}/{id}/RelationalTag/{languageId}/{key}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdRelationalTagLanguageIdKeyDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdRelationalTagLanguageIdKeyDelete$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
    key: string;
  }): Observable<MediaStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdRelationalTagLanguageIdKeyDeletePath, 'delete');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
      rb.path('key', params.key, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as MediaStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdRelationalTagLanguageIdKeyDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdRelationalTagLanguageIdKeyDelete(params: {
    publisherId: string;
    id: string;
    languageId: string;
    key: string;
  }): Observable<void> {

    return this.articlePublisherIdIdRelationalTagLanguageIdKeyDelete$Response(params).pipe(
      map((r: MediaStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation articlePublisherIdIdTranslateLanguageIdPost
   */
  static readonly ArticlePublisherIdIdTranslateLanguageIdPostPath = '/Article/{publisherId}/{id}/Translate/{languageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdTranslateLanguageIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdTranslateLanguageIdPost$Plain$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: TranslateModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdTranslateLanguageIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdTranslateLanguageIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdTranslateLanguageIdPost$Plain(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: TranslateModel
  }): Observable<string> {

    return this.articlePublisherIdIdTranslateLanguageIdPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdTranslateLanguageIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdTranslateLanguageIdPost$Json$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: TranslateModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdTranslateLanguageIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdTranslateLanguageIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  articlePublisherIdIdTranslateLanguageIdPost$Json(params: {
    publisherId: string;
    id: string;
    languageId: string;
    body?: TranslateModel
  }): Observable<string> {

    return this.articlePublisherIdIdTranslateLanguageIdPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation articlePublisherIdIdTextToSpeechLanguageIdPost
   */
  static readonly ArticlePublisherIdIdTextToSpeechLanguageIdPostPath = '/Article/{publisherId}/{id}/TextToSpeech/{languageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdTextToSpeechLanguageIdPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdTextToSpeechLanguageIdPost$Plain$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdTextToSpeechLanguageIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdTextToSpeechLanguageIdPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdTextToSpeechLanguageIdPost$Plain(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<string> {

    return this.articlePublisherIdIdTextToSpeechLanguageIdPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdTextToSpeechLanguageIdPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdTextToSpeechLanguageIdPost$Json$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdTextToSpeechLanguageIdPostPath, 'post');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdTextToSpeechLanguageIdPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdTextToSpeechLanguageIdPost$Json(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<string> {

    return this.articlePublisherIdIdTextToSpeechLanguageIdPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation articleViewClientIdShortCodeLanguageCodeGet
   */
  static readonly ArticleViewClientIdShortCodeLanguageCodeGetPath = '/Article/View/{clientId}/{shortCode}/{languageCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articleViewClientIdShortCodeLanguageCodeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  articleViewClientIdShortCodeLanguageCodeGet$Response(params: {
    clientId: string;
    shortCode: string;
    languageCode: string;
  }): Observable<MediaStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticleViewClientIdShortCodeLanguageCodeGetPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.path('shortCode', params.shortCode, {});
      rb.path('languageCode', params.languageCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as MediaStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articleViewClientIdShortCodeLanguageCodeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articleViewClientIdShortCodeLanguageCodeGet(params: {
    clientId: string;
    shortCode: string;
    languageCode: string;
  }): Observable<void> {

    return this.articleViewClientIdShortCodeLanguageCodeGet$Response(params).pipe(
      map((r: MediaStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation articlePublisherIdIdPreviewLanguageIdGet
   */
  static readonly ArticlePublisherIdIdPreviewLanguageIdGetPath = '/Article/{publisherId}/{id}/Preview/{languageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articlePublisherIdIdPreviewLanguageIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdPreviewLanguageIdGet$Response(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<MediaStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticlePublisherIdIdPreviewLanguageIdGetPath, 'get');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
      rb.path('id', params.id, {});
      rb.path('languageId', params.languageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as MediaStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articlePublisherIdIdPreviewLanguageIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articlePublisherIdIdPreviewLanguageIdGet(params: {
    publisherId: string;
    id: string;
    languageId: string;
  }): Observable<void> {

    return this.articlePublisherIdIdPreviewLanguageIdGet$Response(params).pipe(
      map((r: MediaStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation articleArticleTypesGet
   */
  static readonly ArticleArticleTypesGetPath = '/Article/ArticleTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articleArticleTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  articleArticleTypesGet$Plain$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticleArticleTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articleArticleTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articleArticleTypesGet$Plain(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.articleArticleTypesGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articleArticleTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  articleArticleTypesGet$Json$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticleArticleTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articleArticleTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articleArticleTypesGet$Json(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.articleArticleTypesGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation articleStatusTypesGet
   */
  static readonly ArticleStatusTypesGetPath = '/Article/StatusTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articleStatusTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  articleStatusTypesGet$Plain$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticleStatusTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articleStatusTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articleStatusTypesGet$Plain(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.articleStatusTypesGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articleStatusTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  articleStatusTypesGet$Json$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticleStatusTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articleStatusTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articleStatusTypesGet$Json(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.articleStatusTypesGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation articleClientsGet
   */
  static readonly ArticleClientsGetPath = '/Article/Clients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articleClientsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  articleClientsGet$Plain$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<KeyValueGuidModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticleClientsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueGuidModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articleClientsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articleClientsGet$Plain(params?: {
  }): Observable<Array<KeyValueGuidModel>> {

    return this.articleClientsGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueGuidModel>>) => r.body as Array<KeyValueGuidModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `articleClientsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  articleClientsGet$Json$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<KeyValueGuidModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaArticleService.ArticleClientsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueGuidModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `articleClientsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  articleClientsGet$Json(params?: {
  }): Observable<Array<KeyValueGuidModel>> {

    return this.articleClientsGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueGuidModel>>) => r.body as Array<KeyValueGuidModel>)
    );
  }

}
