import { Storage } from '@capacitor/storage';
import { Injectable } from '@angular/core';
import { StorageKey } from './models/storage-key.enum';

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	async set(key: StorageKey, value: string): Promise<boolean> {
		return await Storage.set({
			key: key,
			value: value
		})
			.then(() => true)
			.catch(() => false);
	}

	async get(key: StorageKey): Promise<string> {
		return await Storage.get({ key: key })
			.then((response) => response.value)
			.catch(() => null as string);
	}

	async remove(key: StorageKey): Promise<boolean> {
		return await Storage.remove({ key: key })
			.then(() => true)
			.catch(() => false);
	}

	async clear(): Promise<boolean> {
		return await Storage.clear()
			.then(() => true)
			.catch(() => false);
	}

	async keys(): Promise<Array<StorageKey>> {
		return await Storage.keys()
			.then((response) => response.keys as StorageKey[])
			.catch(() => []);
	}
}
