import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

@Injectable({
	providedIn: 'root'
})
export class ImageUploaderService {
	public imageObject: any = {};
	public imageObjects: any[] = [];
	private isBusy: boolean;

	public async selectImage(imageResult?): Promise<void> {
		if (!imageResult) {
			if (!this.isBusy) {
				this.isBusy = true;
				const image = await Camera.getPhoto({
					quality: 100,
					allowEditing: false,
					resultType: CameraResultType.DataUrl,
					source: CameraSource.Photos
				});
				this.base64ToImage(this.convertDataUrlToBlob(image.dataUrl));
			}
		} else {
			this.base64ToImage(this.convertDataUrlToBlob(imageResult));
		}

		if (this.imageObject.base64String) {
			this.imageObjects.push(this.imageObject);
		}
		this.imageObject = {};
		this.isBusy = false;
	}

	public base64ToImage(base64): void {
		this.imageObject.url = URL.createObjectURL(base64);
	}

	public convertDataUrlToBlob(dataUrl): Blob {
		const arr = dataUrl.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		const contenttype = arr[0].substring(
			(arr[0].indexOf(':') as number) + 1,
			arr[0].indexOf(';')
		);
		const type = contenttype.substring(
			(contenttype.indexOf('/') as number) + 1
		);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		this.imageObject.contenttype = contenttype;
		this.imageObject.base64String = arr[1];
		this.imageObject.fileType = type;

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		const blob = new Blob([u8arr], { type: mime });
		this.imageObject.blob = blob;

		return blob;
	}

	public removeImage(image): void {
		this.imageObjects = this.imageObjects.filter((e) => e != image);
	}
}
