/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ContactPointTypeModel } from '../models/contact-point-type-model';
import { ContactPointTypeModelSearchResultModel } from '../models/contact-point-type-model-search-result-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MDIContactPointTypeService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation contactPointTypeSearchContactPointTypePost
   */
  static readonly ContactPointTypeSearchContactPointTypePostPath = '/ContactPointType/SearchContactPointType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactPointTypeSearchContactPointTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  contactPointTypeSearchContactPointTypePost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<ContactPointTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIContactPointTypeService.ContactPointTypeSearchContactPointTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ContactPointTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactPointTypeSearchContactPointTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  contactPointTypeSearchContactPointTypePost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<ContactPointTypeModelSearchResultModel> {

    return this.contactPointTypeSearchContactPointTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ContactPointTypeModelSearchResultModel>) => r.body as ContactPointTypeModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactPointTypeSearchContactPointTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  contactPointTypeSearchContactPointTypePost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<ContactPointTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIContactPointTypeService.ContactPointTypeSearchContactPointTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ContactPointTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactPointTypeSearchContactPointTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  contactPointTypeSearchContactPointTypePost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<ContactPointTypeModelSearchResultModel> {

    return this.contactPointTypeSearchContactPointTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ContactPointTypeModelSearchResultModel>) => r.body as ContactPointTypeModelSearchResultModel)
    );
  }

  /**
   * Path part for operation contactPointTypeIdGet
   */
  static readonly ContactPointTypeIdGetPath = '/ContactPointType/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactPointTypeIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactPointTypeIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<ContactPointTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIContactPointTypeService.ContactPointTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ContactPointTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactPointTypeIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactPointTypeIdGet$Plain(params: {
    id: string;
  }): Observable<ContactPointTypeModel> {

    return this.contactPointTypeIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ContactPointTypeModel>) => r.body as ContactPointTypeModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactPointTypeIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactPointTypeIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<ContactPointTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIContactPointTypeService.ContactPointTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ContactPointTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactPointTypeIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactPointTypeIdGet$Json(params: {
    id: string;
  }): Observable<ContactPointTypeModel> {

    return this.contactPointTypeIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ContactPointTypeModel>) => r.body as ContactPointTypeModel)
    );
  }

  /**
   * Path part for operation contactPointTypePost
   */
  static readonly ContactPointTypePostPath = '/ContactPointType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactPointTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  contactPointTypePost$Plain$Response(params?: {
    body?: ContactPointTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIContactPointTypeService.ContactPointTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactPointTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  contactPointTypePost$Plain(params?: {
    body?: ContactPointTypeModel
  }): Observable<string> {

    return this.contactPointTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactPointTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  contactPointTypePost$Json$Response(params?: {
    body?: ContactPointTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIContactPointTypeService.ContactPointTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactPointTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  contactPointTypePost$Json(params?: {
    body?: ContactPointTypeModel
  }): Observable<string> {

    return this.contactPointTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
