import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Timer } from 'src/app/utilities';

@Injectable({
	providedIn: 'root'
})
export class LoadingService {
	public loading: HTMLIonLoadingElement = null;
	private inShow = false;

	constructor(private loadingController: LoadingController) {}

	public async show(): Promise<void> {
		await this.hide();

		this.inShow = true;

		document.documentElement.classList.add('loader-showing');
		this.loading = await this.loadingController.create({
			mode: 'ios',
			spinner: 'crescent',
			cssClass: 'app-loading',
			duration: 18500
		});

		this.loading.onDidDismiss().then((e) => {
			document.documentElement.classList.remove('loader-showing');
		});

		await this.loading.present();

		this.inShow = false;
	}

	public async hide(clear = false): Promise<void> {
		while (this.inShow) await Timer.sleep(100);

		if (this.loading) {
			await this.loading.dismiss();
			this.loading = null;
			document.documentElement.classList.remove('loader-showing');
		} else if (clear) {
			this.loading = null;
			document.documentElement.classList.remove('loader-showing');
		}
	}
}
