import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@NgModule({
	imports: [
		AuthModule.forRoot({
			config: {
				authority: environment.authority,
				authWellknownEndpointUrl: environment.authWellknownEndpointUrl,
				redirectUrl: window.location.origin + '/home',
				clientId: environment.clientId,
				scope:
					'openid profile email offline_access ' + environment.scope,
				responseType: 'code',
				silentRenew: true,
				useRefreshToken: true,
				maxIdTokenIatOffsetAllowedInSeconds: 600,
				issValidationOff: false,
				autoUserInfo: false,
				customParamsAuthRequest: {
					prompt: 'select_account' // login, consent
				},

				triggerAuthorizationResultEvent: true,
				startCheckSession: false,
				disableRefreshIdTokenAuthTimeValidation: true,
				ignoreNonceAfterRefresh: true,
				renewTimeBeforeTokenExpiresInSeconds: 60 * 5,
				tokenRefreshInSeconds: 60,

				postLoginRoute: '/home',
				forbiddenRoute: '/forbidden',
				unauthorizedRoute: '/unauthorized',
				postLogoutRedirectUri: window.location.origin,

				secureRoutes: environment.secureRoutes
			}
		})
	],
	exports: [AuthModule]
})
export class AuthConfigModule {}
