import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslatorService } from '../translate/translator.service';

@Injectable({
	providedIn: 'root'
})
export class DateTimeService {
	private weekdays: BehaviorSubject<Array<string>> = new BehaviorSubject<
		Array<string>
	>([]);

	public weekdays$: Observable<Array<string>> = this.weekdays.asObservable();

	constructor(private translatorService: TranslatorService) {}

	public initialize(): void {
		this.registerSubscribers();
	}

	private registerSubscribers(): void {
		this.translatorService.contentLanguageMapped$.subscribe((language) => {
			moment.locale(this.mapLanguageCode(language));

			this.weekdays.next(
				moment.weekdaysShort().map((value) => {
					return value;
				})
			);
		});
	}

	private mapLanguageCode(languageCode: string): string {
		switch (languageCode) {
			case 'zh':
				return 'zh-cn';
			case 'zh-hant':
				return 'zh-tw';
			case 'pt-pt':
				return 'pt';
			default:
				return languageCode;
		}
	}
}
