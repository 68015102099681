/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AuthBaseService } from '../auth-base-service';
import { AuthApiConfiguration } from '../auth-api-configuration';
import { AuthStrictHttpResponse } from '../auth-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RoleModel } from '../models/role-model';
import { SearchRequestModel } from '../models/search-request-model';
import { SearchRoleResultModelSearchResultModel } from '../models/search-role-result-model-search-result-model';

@Injectable({
  providedIn: 'root',
})
export class AuthRoleService extends AuthBaseService {
  constructor(
    config: AuthApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation roleSearchRolePost
   */
  static readonly RoleSearchRolePostPath = '/Role/SearchRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleSearchRolePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  roleSearchRolePost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<AuthStrictHttpResponse<SearchRoleResultModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthRoleService.RoleSearchRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<SearchRoleResultModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleSearchRolePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  roleSearchRolePost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<SearchRoleResultModelSearchResultModel> {

    return this.roleSearchRolePost$Plain$Response(params).pipe(
      map((r: AuthStrictHttpResponse<SearchRoleResultModelSearchResultModel>) => r.body as SearchRoleResultModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleSearchRolePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  roleSearchRolePost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<AuthStrictHttpResponse<SearchRoleResultModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthRoleService.RoleSearchRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<SearchRoleResultModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleSearchRolePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  roleSearchRolePost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<SearchRoleResultModelSearchResultModel> {

    return this.roleSearchRolePost$Json$Response(params).pipe(
      map((r: AuthStrictHttpResponse<SearchRoleResultModelSearchResultModel>) => r.body as SearchRoleResultModelSearchResultModel)
    );
  }

  /**
   * Path part for operation roleIdGet
   */
  static readonly RoleIdGetPath = '/Role/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleIdGet$Plain$Response(params: {
    id: string;
  }): Observable<AuthStrictHttpResponse<RoleModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthRoleService.RoleIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<RoleModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleIdGet$Plain(params: {
    id: string;
  }): Observable<RoleModel> {

    return this.roleIdGet$Plain$Response(params).pipe(
      map((r: AuthStrictHttpResponse<RoleModel>) => r.body as RoleModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleIdGet$Json$Response(params: {
    id: string;
  }): Observable<AuthStrictHttpResponse<RoleModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthRoleService.RoleIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<RoleModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleIdGet$Json(params: {
    id: string;
  }): Observable<RoleModel> {

    return this.roleIdGet$Json$Response(params).pipe(
      map((r: AuthStrictHttpResponse<RoleModel>) => r.body as RoleModel)
    );
  }

  /**
   * Path part for operation rolePost
   */
  static readonly RolePostPath = '/Role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rolePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rolePost$Plain$Response(params?: {
    body?: RoleModel
  }): Observable<AuthStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AuthRoleService.RolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rolePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rolePost$Plain(params?: {
    body?: RoleModel
  }): Observable<string> {

    return this.rolePost$Plain$Response(params).pipe(
      map((r: AuthStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rolePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rolePost$Json$Response(params?: {
    body?: RoleModel
  }): Observable<AuthStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AuthRoleService.RolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rolePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rolePost$Json(params?: {
    body?: RoleModel
  }): Observable<string> {

    return this.rolePost$Json$Response(params).pipe(
      map((r: AuthStrictHttpResponse<string>) => r.body as string)
    );
  }

}
