import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	Router,
	UrlTree
} from '@angular/router';
import { Device } from '@capacitor/device';

@Injectable({
	providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
	constructor(private router: Router) {}

	async canActivate(
		route: ActivatedRouteSnapshot
	): Promise<boolean | UrlTree> {
		const info = await Device.getInfo();
		if (info.platform == 'web') {
			let phone = 'unknown';
			const userAgent =
				navigator.userAgent ||
				navigator.vendor ||
				(window as any).opera;

			if (/windows phone/i.test(userAgent)) phone = 'Windows Phone';
			if (/android/i.test(userAgent)) phone = 'Android';
			if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream)
				phone = 'iOS';

			if (phone == 'iOS') {
				window.location.href = route.data['appleUrl'];
			} else if (phone == 'Android') {
				window.location.href = route.data['googleUrl'];
			} else {
				window.location.href = route.data['externalUrl'];
			}

			return true;
		} else return this.router.parseUrl('/');
	}
}
