import { Component, Input, OnInit } from '@angular/core';
import * as slots from './models/timeslots.model';
import * as _ from 'lodash';
import { ionButton } from '../../../directives/text-reveal/ionButton-enum';
import { RegisteredUserProfileService } from 'src/app/services/sessions/registered-user-profile/registered-user-profile.service';
@Component({
	selector: 'time-selector',
	templateUrl: './time-selector.component.html',
	styleUrls: ['./time-selector.component.scss']
})
export class TimeSelectorComponent implements OnInit {
	public timeslots: Array<any>;
	public ionButton = ionButton;

	constructor(
		public registeredUserProfileService: RegisteredUserProfileService
	) {}

	@Input()
	public selectedTimeSlot;

	ngOnInit(): void {
		this.timeslots = _.cloneDeep(slots.Timeslots);
	}
}
