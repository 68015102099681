/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AuthBaseService } from '../auth-base-service';
import { AuthApiConfiguration } from '../auth-api-configuration';
import { AuthStrictHttpResponse } from '../auth-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SearchRequestModel } from '../models/search-request-model';
import { SetDefaultLanguageModel } from '../models/set-default-language-model';
import { UserIdentityModel } from '../models/user-identity-model';
import { UserSearchResultModelSearchResultModel } from '../models/user-search-result-model-search-result-model';

@Injectable({
  providedIn: 'root',
})
export class AuthUserService extends AuthBaseService {
  constructor(
    config: AuthApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userSearchUserPost
   */
  static readonly UserSearchUserPostPath = '/User/SearchUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSearchUserPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userSearchUserPost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<AuthStrictHttpResponse<UserSearchResultModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthUserService.UserSearchUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<UserSearchResultModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userSearchUserPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userSearchUserPost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<UserSearchResultModelSearchResultModel> {

    return this.userSearchUserPost$Plain$Response(params).pipe(
      map((r: AuthStrictHttpResponse<UserSearchResultModelSearchResultModel>) => r.body as UserSearchResultModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSearchUserPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userSearchUserPost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<AuthStrictHttpResponse<UserSearchResultModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthUserService.UserSearchUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<UserSearchResultModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userSearchUserPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userSearchUserPost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<UserSearchResultModelSearchResultModel> {

    return this.userSearchUserPost$Json$Response(params).pipe(
      map((r: AuthStrictHttpResponse<UserSearchResultModelSearchResultModel>) => r.body as UserSearchResultModelSearchResultModel)
    );
  }

  /**
   * Path part for operation userIdGet
   */
  static readonly UserIdGetPath = '/User/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userIdGet$Plain$Response(params: {
    id: string;
  }): Observable<AuthStrictHttpResponse<UserIdentityModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthUserService.UserIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<UserIdentityModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userIdGet$Plain(params: {
    id: string;
  }): Observable<UserIdentityModel> {

    return this.userIdGet$Plain$Response(params).pipe(
      map((r: AuthStrictHttpResponse<UserIdentityModel>) => r.body as UserIdentityModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userIdGet$Json$Response(params: {
    id: string;
  }): Observable<AuthStrictHttpResponse<UserIdentityModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthUserService.UserIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<UserIdentityModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userIdGet$Json(params: {
    id: string;
  }): Observable<UserIdentityModel> {

    return this.userIdGet$Json$Response(params).pipe(
      map((r: AuthStrictHttpResponse<UserIdentityModel>) => r.body as UserIdentityModel)
    );
  }

  /**
   * Path part for operation userGet
   */
  static readonly UserGetPath = '/User';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGet$Plain$Response(params?: {
  }): Observable<AuthStrictHttpResponse<UserIdentityModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthUserService.UserGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<UserIdentityModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGet$Plain(params?: {
  }): Observable<UserIdentityModel> {

    return this.userGet$Plain$Response(params).pipe(
      map((r: AuthStrictHttpResponse<UserIdentityModel>) => r.body as UserIdentityModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGet$Json$Response(params?: {
  }): Observable<AuthStrictHttpResponse<UserIdentityModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthUserService.UserGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<UserIdentityModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGet$Json(params?: {
  }): Observable<UserIdentityModel> {

    return this.userGet$Json$Response(params).pipe(
      map((r: AuthStrictHttpResponse<UserIdentityModel>) => r.body as UserIdentityModel)
    );
  }

  /**
   * Path part for operation userPost
   */
  static readonly UserPostPath = '/User';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPost$Plain$Response(params?: {
    body?: UserIdentityModel
  }): Observable<AuthStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AuthUserService.UserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPost$Plain(params?: {
    body?: UserIdentityModel
  }): Observable<string> {

    return this.userPost$Plain$Response(params).pipe(
      map((r: AuthStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPost$Json$Response(params?: {
    body?: UserIdentityModel
  }): Observable<AuthStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AuthUserService.UserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPost$Json(params?: {
    body?: UserIdentityModel
  }): Observable<string> {

    return this.userPost$Json$Response(params).pipe(
      map((r: AuthStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation userSetDefaultLanuagePost
   */
  static readonly UserSetDefaultLanuagePostPath = '/User/SetDefaultLanuage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSetDefaultLanuagePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userSetDefaultLanuagePost$Response(params?: {
    body?: SetDefaultLanguageModel
  }): Observable<AuthStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthUserService.UserSetDefaultLanuagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as AuthStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userSetDefaultLanuagePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userSetDefaultLanuagePost(params?: {
    body?: SetDefaultLanguageModel
  }): Observable<void> {

    return this.userSetDefaultLanuagePost$Response(params).pipe(
      map((r: AuthStrictHttpResponse<void>) => r.body as void)
    );
  }

}
