/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CalendarBaseService } from '../calendar-base-service';
import { CalendarApiConfiguration } from '../calendar-api-configuration';
import { CalendarStrictHttpResponse } from '../calendar-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AvailibilityRequestModel } from '../models/availibility-request-model';
import { CancelBookingRequestModel } from '../models/cancel-booking-request-model';
import { KeyValueModel } from '../models/key-value-model';
import { MedicalAdvisorBookingDetailModel } from '../models/medical-advisor-booking-detail-model';
import { MedicalAdvisorConfirmReservationRequestModel } from '../models/medical-advisor-confirm-reservation-request-model';
import { OrganizationSearchRequestModel } from '../models/organization-search-request-model';
import { OrganizationSearchResultModel } from '../models/organization-search-result-model';
import { ReservationRequest } from '../models/reservation-request';
import { UrnAvailabilityDayModelAvailibilityModel } from '../models/urn-availability-day-model-availibility-model';

@Injectable({
  providedIn: 'root',
})
export class CalendarMedicalAdvisorService extends CalendarBaseService {
  constructor(
    config: CalendarApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation medicalAdvisorFhirIdSpecialitiesGet
   */
  static readonly MedicalAdvisorFhirIdSpecialitiesGetPath = '/MedicalAdvisor/{fhirId}/Specialities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorFhirIdSpecialitiesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAdvisorFhirIdSpecialitiesGet$Response(params: {
    fhirId: string;
  }): Observable<CalendarStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarMedicalAdvisorService.MedicalAdvisorFhirIdSpecialitiesGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorFhirIdSpecialitiesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAdvisorFhirIdSpecialitiesGet(params: {
    fhirId: string;
  }): Observable<Array<KeyValueModel>> {

    return this.medicalAdvisorFhirIdSpecialitiesGet$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation medicalAdvisorHealthCareServicesPost
   */
  static readonly MedicalAdvisorHealthCareServicesPostPath = '/MedicalAdvisor/HealthCareServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorHealthCareServicesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorHealthCareServicesPost$Response(params?: {
    body?: OrganizationSearchRequestModel
  }): Observable<CalendarStrictHttpResponse<Array<OrganizationSearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarMedicalAdvisorService.MedicalAdvisorHealthCareServicesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<Array<OrganizationSearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorHealthCareServicesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorHealthCareServicesPost(params?: {
    body?: OrganizationSearchRequestModel
  }): Observable<Array<OrganizationSearchResultModel>> {

    return this.medicalAdvisorHealthCareServicesPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<Array<OrganizationSearchResultModel>>) => r.body as Array<OrganizationSearchResultModel>)
    );
  }

  /**
   * Path part for operation medicalAdvisorAvailibilityPost
   */
  static readonly MedicalAdvisorAvailibilityPostPath = '/MedicalAdvisor/Availibility';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorAvailibilityPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorAvailibilityPost$Response(params?: {
    body?: AvailibilityRequestModel
  }): Observable<CalendarStrictHttpResponse<UrnAvailabilityDayModelAvailibilityModel>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarMedicalAdvisorService.MedicalAdvisorAvailibilityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<UrnAvailabilityDayModelAvailibilityModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorAvailibilityPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorAvailibilityPost(params?: {
    body?: AvailibilityRequestModel
  }): Observable<UrnAvailabilityDayModelAvailibilityModel> {

    return this.medicalAdvisorAvailibilityPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<UrnAvailabilityDayModelAvailibilityModel>) => r.body as UrnAvailabilityDayModelAvailibilityModel)
    );
  }

  /**
   * Path part for operation medicalAdvisorRequestReservationPost
   */
  static readonly MedicalAdvisorRequestReservationPostPath = '/MedicalAdvisor/RequestReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorRequestReservationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorRequestReservationPost$Response(params?: {
    body?: ReservationRequest
  }): Observable<CalendarStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarMedicalAdvisorService.MedicalAdvisorRequestReservationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorRequestReservationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorRequestReservationPost(params?: {
    body?: ReservationRequest
  }): Observable<string> {

    return this.medicalAdvisorRequestReservationPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation medicalAdvisorConfirmReservationPost
   */
  static readonly MedicalAdvisorConfirmReservationPostPath = '/MedicalAdvisor/ConfirmReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorConfirmReservationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorConfirmReservationPost$Response(params?: {
    body?: MedicalAdvisorConfirmReservationRequestModel
  }): Observable<CalendarStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarMedicalAdvisorService.MedicalAdvisorConfirmReservationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorConfirmReservationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorConfirmReservationPost(params?: {
    body?: MedicalAdvisorConfirmReservationRequestModel
  }): Observable<string> {

    return this.medicalAdvisorConfirmReservationPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation medicalAdvisorConfirmBookingPost
   */
  static readonly MedicalAdvisorConfirmBookingPostPath = '/MedicalAdvisor/ConfirmBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorConfirmBookingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorConfirmBookingPost$Response(params?: {
    body?: MedicalAdvisorBookingDetailModel
  }): Observable<CalendarStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarMedicalAdvisorService.MedicalAdvisorConfirmBookingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorConfirmBookingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorConfirmBookingPost(params?: {
    body?: MedicalAdvisorBookingDetailModel
  }): Observable<string> {

    return this.medicalAdvisorConfirmBookingPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation medicalAdvisorBookingDetailUrnBookingIdGet
   */
  static readonly MedicalAdvisorBookingDetailUrnBookingIdGetPath = '/MedicalAdvisor/BookingDetail/{urnBookingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorBookingDetailUrnBookingIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAdvisorBookingDetailUrnBookingIdGet$Response(params: {
    urnBookingId: string;
  }): Observable<CalendarStrictHttpResponse<MedicalAdvisorBookingDetailModel>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarMedicalAdvisorService.MedicalAdvisorBookingDetailUrnBookingIdGetPath, 'get');
    if (params) {
      rb.path('urnBookingId', params.urnBookingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<MedicalAdvisorBookingDetailModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorBookingDetailUrnBookingIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAdvisorBookingDetailUrnBookingIdGet(params: {
    urnBookingId: string;
  }): Observable<MedicalAdvisorBookingDetailModel> {

    return this.medicalAdvisorBookingDetailUrnBookingIdGet$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<MedicalAdvisorBookingDetailModel>) => r.body as MedicalAdvisorBookingDetailModel)
    );
  }

  /**
   * Path part for operation medicalAdvisorCancelBookingPost
   */
  static readonly MedicalAdvisorCancelBookingPostPath = '/MedicalAdvisor/CancelBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorCancelBookingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorCancelBookingPost$Response(params?: {
    body?: CancelBookingRequestModel
  }): Observable<CalendarStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CalendarMedicalAdvisorService.MedicalAdvisorCancelBookingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CalendarStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorCancelBookingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorCancelBookingPost(params?: {
    body?: CancelBookingRequestModel
  }): Observable<string> {

    return this.medicalAdvisorCancelBookingPost$Response(params).pipe(
      map((r: CalendarStrictHttpResponse<string>) => r.body as string)
    );
  }

}
