/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CountryModel } from '../models/country-model';
import { CountryModelSearchResultModel } from '../models/country-model-search-result-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MDICountryService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation countrySearchCountryPost
   */
  static readonly CountrySearchCountryPostPath = '/Country/SearchCountry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countrySearchCountryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  countrySearchCountryPost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<CountryModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDICountryService.CountrySearchCountryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<CountryModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countrySearchCountryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  countrySearchCountryPost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<CountryModelSearchResultModel> {

    return this.countrySearchCountryPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<CountryModelSearchResultModel>) => r.body as CountryModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countrySearchCountryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  countrySearchCountryPost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<CountryModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDICountryService.CountrySearchCountryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<CountryModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countrySearchCountryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  countrySearchCountryPost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<CountryModelSearchResultModel> {

    return this.countrySearchCountryPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<CountryModelSearchResultModel>) => r.body as CountryModelSearchResultModel)
    );
  }

  /**
   * Path part for operation countryIdGet
   */
  static readonly CountryIdGetPath = '/Country/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countryIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  countryIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<CountryModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDICountryService.CountryIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<CountryModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countryIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countryIdGet$Plain(params: {
    id: string;
  }): Observable<CountryModel> {

    return this.countryIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<CountryModel>) => r.body as CountryModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countryIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  countryIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<CountryModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDICountryService.CountryIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<CountryModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countryIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countryIdGet$Json(params: {
    id: string;
  }): Observable<CountryModel> {

    return this.countryIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<CountryModel>) => r.body as CountryModel)
    );
  }

  /**
   * Path part for operation countryPost
   */
  static readonly CountryPostPath = '/Country';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  countryPost$Plain$Response(params?: {
    body?: CountryModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDICountryService.CountryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  countryPost$Plain(params?: {
    body?: CountryModel
  }): Observable<string> {

    return this.countryPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  countryPost$Json$Response(params?: {
    body?: CountryModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDICountryService.CountryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  countryPost$Json(params?: {
    body?: CountryModel
  }): Observable<string> {

    return this.countryPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
