import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Capacitor } from '@capacitor/core';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { interval, Subscription } from 'rxjs';
import { DeviceResizeService } from 'src/app/services/device-resize/device-resize.service';
import { ImageUploaderService } from 'src/app/services/image-uploader/image-uploader.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { RegisteredUserProfileService } from 'src/app/services/sessions/registered-user-profile/registered-user-profile.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
	selector: 'session-user-details',
	templateUrl: './session-user-details.component.html',
	styleUrls: ['./session-user-details.component.scss']
})
export class SessionUserDetailsComponent implements OnInit {
	private subscriptions: Subscription = new Subscription();
	private formExist: Subscription;
	@ViewChild('requestSessionForm') requestSessionForm: FormGroupDirective;

	constructor(
		public imageUploaderService: ImageUploaderService,
		public modalService: ModalService,
		public themeService: ThemeService,
		private deviceResizeService: DeviceResizeService,
		public registeredUserProfileService: RegisteredUserProfileService
	) {}

	async ngOnInit(): Promise<void> {
		this.deviceResizeService.setCurrentLayout();

		if (Capacitor.getPlatform() == 'ios') {
			await Keyboard.setResizeMode({ mode: KeyboardResize.None });
		}

		const timeInterval = interval(100);
		this.formExist = timeInterval.subscribe(() => {
			if (this.requestSessionForm) {
				this.registeredUserProfileService.requestSessionForm =
					this.requestSessionForm;
				this.formExist.unsubscribe();
			}
		});
	}

	public ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	public async collapseKeyboard(): Promise<void> {
		if (Capacitor.getPlatform() != 'web') {
			await Keyboard.hide();
		}
	}
}
