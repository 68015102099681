/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SearchRequestModel } from '../models/search-request-model';
import { TitleModel } from '../models/title-model';
import { TitleModelSearchResultModel } from '../models/title-model-search-result-model';

@Injectable({
  providedIn: 'root',
})
export class MDITitleService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation titleSearchTitlePost
   */
  static readonly TitleSearchTitlePostPath = '/Title/SearchTitle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `titleSearchTitlePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  titleSearchTitlePost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<TitleModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDITitleService.TitleSearchTitlePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<TitleModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `titleSearchTitlePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  titleSearchTitlePost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<TitleModelSearchResultModel> {

    return this.titleSearchTitlePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<TitleModelSearchResultModel>) => r.body as TitleModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `titleSearchTitlePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  titleSearchTitlePost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<TitleModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDITitleService.TitleSearchTitlePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<TitleModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `titleSearchTitlePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  titleSearchTitlePost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<TitleModelSearchResultModel> {

    return this.titleSearchTitlePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<TitleModelSearchResultModel>) => r.body as TitleModelSearchResultModel)
    );
  }

  /**
   * Path part for operation titleIdGet
   */
  static readonly TitleIdGetPath = '/Title/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `titleIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  titleIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<TitleModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDITitleService.TitleIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<TitleModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `titleIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  titleIdGet$Plain(params: {
    id: string;
  }): Observable<TitleModel> {

    return this.titleIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<TitleModel>) => r.body as TitleModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `titleIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  titleIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<TitleModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDITitleService.TitleIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<TitleModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `titleIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  titleIdGet$Json(params: {
    id: string;
  }): Observable<TitleModel> {

    return this.titleIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<TitleModel>) => r.body as TitleModel)
    );
  }

  /**
   * Path part for operation titlePost
   */
  static readonly TitlePostPath = '/Title';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `titlePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  titlePost$Plain$Response(params?: {
    body?: TitleModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDITitleService.TitlePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `titlePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  titlePost$Plain(params?: {
    body?: TitleModel
  }): Observable<string> {

    return this.titlePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `titlePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  titlePost$Json$Response(params?: {
    body?: TitleModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDITitleService.TitlePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `titlePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  titlePost$Json(params?: {
    body?: TitleModel
  }): Observable<string> {

    return this.titlePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
