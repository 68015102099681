import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SlideDirection } from './models/slideDirection.enums';

@Injectable({
	providedIn: 'root'
})
export class ArticleService {
	private slideChangeDirection: Subject<SlideDirection> =
		new Subject<SlideDirection>();
	public slideChangeDirection$: Observable<SlideDirection> =
		this.slideChangeDirection.asObservable();

	public changeSlideDirection(direction: SlideDirection): void {
		this.slideChangeDirection.next(direction);
	}
}
