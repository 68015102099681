import {
	AfterContentInit,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import { IonSlides, Platform } from '@ionic/angular';
import { BookmarkService } from 'src/app/services/bookmark/bookmark.service';
import { SeeAllPage } from 'src/app/shared/pages/see-all/see-all.page';
import { Location } from '@angular/common';
import { LanguagePage } from '../../pages/language/language.page';
import { TranslatorService } from 'src/app/services/translate/translator.service';
import { TitleHelperService } from 'src/app/services/title-helper/title-helper.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { combineLatest, Subscription } from 'rxjs';
import { ArticleService } from 'src/app/services/article/article.service';
import { SlideDirection } from 'src/app/services/article/models/slideDirection.enums';
import ContentMock from '../../../utilities/dummy-data/content.json';

@Component({
	templateUrl: './content-view.component.html',
	styleUrls: ['./content-view.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ContentViewComponent
	implements OnInit, AfterContentInit, OnDestroy
{
	@Input() stack;
	public interestTagCodes: string[];
	@Input() contentIds: string[];
	@Input() currentContentId: string;

	@Input() allowSwipe = true;

	public loading = true;
	public articles: any[];
	public count = 0;
	public start = 0;
	public direction: string;

	@ViewChild('slidesComponent')
	slidesComponent: IonSlides;

	@HostBinding('class.swiped')
	public swiped: boolean; // bubbled up from child component

	@HostBinding('class.swiping')
	public swiping: boolean; // bubbled up from child component

	@HostBinding('class.dragging')
	public dragging: boolean;

	@HostBinding('class.differentDrag')
	public differentDrag: boolean;

	@HostBinding('style.--window-innerHeight')
	windowInnerHeight: number;

	@HostBinding('style.--maxHeight')
	maxHeight: number;

	@HostBinding('style.--windowHeight.px')
	public windowHeight: number;

	@HostBinding('style.--width')
	width: number;
	@HostBinding('style.--height')
	height: number;

	public slideWillChange: EventEmitter<boolean> = new EventEmitter();

	public slideOpts = {
		allowTouchMove: true,
		grabCursor: true,
		initialSlide: 0,
		pagination: {
			el: '.content-view-slides .swiper-pagination',
			type: 'bullets',
			dynamicBullets: true,
			dynamicMainBullets: 1
		},
		threshold: 24
	};
	public index = 0;
	public didInit = false;

	public firstIndex = false;
	public lastIndex = false;
	private subscriptions: Subscription = new Subscription();

	constructor(
		public bookmarkService: BookmarkService,
		private location: Location,
		private platform: Platform,
		public translatorService: TranslatorService,
		private titleHelperService: TitleHelperService,
		private modalService: ModalService,
		private themeService: ThemeService,
		private articleService: ArticleService
	) {
		this.calculateHeights();

		const platformResizeSubscriber = this.platform.resize.subscribe(() => {
			this.calculateHeights();
		});
		this.subscriptions.add(platformResizeSubscriber);
	}

	public calculateHeights() {
		const body = document.body,
			html = document.documentElement;

		const height = Math.max(
			body.scrollHeight,
			body.offsetHeight,
			html.clientHeight,
			html.scrollHeight,
			html.offsetHeight
		);

		this.windowInnerHeight = height;
		this.maxHeight = height;
		this.windowHeight = height - 245;
		this.width = document.getElementsByTagName('ion-app')[0].clientWidth;
		this.height = document.getElementsByTagName('ion-app')[0].clientHeight;
	}

	async ngOnInit(): Promise<void> {
		this.loadArticles();
		let index = 0;
		if (this.slidesComponent) {
			index = await this.slidesComponent.getActiveIndex();
		}
		this.setUrl(index);

		const languageSubscriber = this.translatorService.language$.subscribe(
			() => {
				this.direction = document
					.getElementsByTagName('html')[0]
					.getAttribute('dir');
			}
		);
		this.subscriptions.add(languageSubscriber);

		const slideChangeDirectionSubscriber =
			this.articleService.slideChangeDirection$.subscribe((direction) => {
				if (direction == SlideDirection.Forward)
					void this.slidesComponent.slideNext();

				if (direction == SlideDirection.Backward)
					void this.slidesComponent.slidePrev();
			});
		this.subscriptions.add(slideChangeDirectionSubscriber);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	private loadArticles() {
		if (this.themeService.layout == 'web') {
			this.slideOpts.allowTouchMove = false;
		}
		let prevScrollpos = [];
		if (this.articles && this.articles.length > 0) {
			prevScrollpos = this.articles.map((a) => {
				return { Id: a.Id, articleScrolled: a.articleScrolled };
			});
		}

		const articleResults = ContentMock;

		if (this.currentContentId) {
			const toSetIndex = articleResults
				.map((_) => _.Id)
				.indexOf(this.currentContentId);

			this.index = toSetIndex < 0 ? 0 : toSetIndex;

			this.slideOpts.initialSlide = this.index;
		}

		articleResults.forEach((article) => {
			const prevArt = prevScrollpos.find((a) => a.Id == article.Id);
			if (prevArt) article.articleScrolled = prevArt.articleScrolled;
			else
				article.articleScrolled = this.allowSwipe
					? article.articleScrolled
					: true;
		});

		if (articleResults.length > 1 && this.stack) {
			const stackTitle = this.stack.i18nTitles.find(
				(e) => e.langCode == 'en'
			).translation;

			this.titleHelperService.setPageTitle(`${stackTitle}`);
		} else {
			this.titleHelperService.setPageTitle(
				`${articleResults[this.index].Title}`
			);
		}

		this.articles = articleResults;

		if (this.index == 0) {
			this.loading = false;
		}
	}

	ngAfterContentInit() {
		this.didInit = true;
	}

	async changed(event): Promise<void> {
		const i = await this.slidesComponent.getActiveIndex();
		this.checkIndex(i);

		this.index = i;
		this.swiped = this.articles[i].articleScrolled;
		this.dragging = false;
		this.setUrl(i);

		if (!this.articles[this.index].articleScrolled) {
			this.titleHelperService.setPageTitle(
				this.stack.tagCode.replace(/([A-Z])/g, ' $1').trim()
			);
		} else {
			this.titleHelperService.setPageTitle(
				`${this.articles[this.index].Title}`
			);
		}

		this.loading = false;
	}

	async loaded(): Promise<void> {
		this.swiped = this.articles[0].articleScrolled;
		const i = await this.slidesComponent.getActiveIndex();
		this.checkIndex(i);
	}

	private setUrl(index: number): void {
		const id = this.articles[index].Id;

		this.location.replaceState(
			`${this.location.path().split('/').splice(0, 4).join('/')}/${id}`
		);
	}

	drag(event): void {
		this.count++;
		this.differentDrag = true;
		if (this.count == 1) this.start = event.detail.touches[0].pageX;
		else {
			const pixelsDragged = this.start - event.detail.touches[0].pageX;
			if (pixelsDragged > 25 || pixelsDragged < -25) {
				this.dragging = true;
			} else {
				this.dragging = false;
			}
		}
	}

	async presentGalleryDrawer(): Promise<void> {
		const i = await this.slidesComponent.getActiveIndex();
		const scrolled = this.articles[i].articleScrolled;
		if (!scrolled) {
			await this.modalService.viewDrawerModalAsync(
				{
					allContent: this.articles,
					type: 'interest',
					stack: this.stack
				},
				SeeAllPage,
				'drawer-modal',
				'SeeAllPage'
			);
		}
	}

	public dismiss(): void {
		this.titleHelperService.setPageTitle();
		void this.modalService.contentDismissModalAsync();
		this.removeUrl();
	}

	async presentFilterModal(): Promise<void> {
		await this.modalService.viewDrawerModalAsync(
			{ isContentLanguage: true },
			LanguagePage,
			'drawer-modal',
			'LanguagePage'
		);
	}

	private checkIndex(i: number) {
		if (i == 0) {
			this.firstIndex = true;
			this.lastIndex = false;
		} else if (i == this.articles.length - 1) {
			this.lastIndex = true;
			this.firstIndex = false;
		} else {
			this.firstIndex = false;
			this.lastIndex = false;
		}
	}

	private removeUrl(): void {
		this.location.replaceState(
			`${this.location.path().split('/').splice(0, 3).join('/')}`
		);
	}

	public lockSlidingComponent(value: boolean): void {
		this.slidesComponent.lockSwipes(value);
	}
}
