/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LogoUseTypeModel } from '../models/logo-use-type-model';
import { LogoUseTypeModelSearchResultModel } from '../models/logo-use-type-model-search-result-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MDILogoUseTypeService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation logoUseTypeSearchLogoUseTypePost
   */
  static readonly LogoUseTypeSearchLogoUseTypePostPath = '/LogoUseType/SearchLogoUseType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logoUseTypeSearchLogoUseTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logoUseTypeSearchLogoUseTypePost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<LogoUseTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDILogoUseTypeService.LogoUseTypeSearchLogoUseTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<LogoUseTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logoUseTypeSearchLogoUseTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logoUseTypeSearchLogoUseTypePost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<LogoUseTypeModelSearchResultModel> {

    return this.logoUseTypeSearchLogoUseTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<LogoUseTypeModelSearchResultModel>) => r.body as LogoUseTypeModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logoUseTypeSearchLogoUseTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logoUseTypeSearchLogoUseTypePost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<LogoUseTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDILogoUseTypeService.LogoUseTypeSearchLogoUseTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<LogoUseTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logoUseTypeSearchLogoUseTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logoUseTypeSearchLogoUseTypePost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<LogoUseTypeModelSearchResultModel> {

    return this.logoUseTypeSearchLogoUseTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<LogoUseTypeModelSearchResultModel>) => r.body as LogoUseTypeModelSearchResultModel)
    );
  }

  /**
   * Path part for operation logoUseTypeIdGet
   */
  static readonly LogoUseTypeIdGetPath = '/LogoUseType/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logoUseTypeIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  logoUseTypeIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<LogoUseTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDILogoUseTypeService.LogoUseTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<LogoUseTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logoUseTypeIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logoUseTypeIdGet$Plain(params: {
    id: string;
  }): Observable<LogoUseTypeModel> {

    return this.logoUseTypeIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<LogoUseTypeModel>) => r.body as LogoUseTypeModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logoUseTypeIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  logoUseTypeIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<LogoUseTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDILogoUseTypeService.LogoUseTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<LogoUseTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logoUseTypeIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logoUseTypeIdGet$Json(params: {
    id: string;
  }): Observable<LogoUseTypeModel> {

    return this.logoUseTypeIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<LogoUseTypeModel>) => r.body as LogoUseTypeModel)
    );
  }

  /**
   * Path part for operation logoUseTypePost
   */
  static readonly LogoUseTypePostPath = '/LogoUseType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logoUseTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logoUseTypePost$Plain$Response(params?: {
    body?: LogoUseTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDILogoUseTypeService.LogoUseTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logoUseTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logoUseTypePost$Plain(params?: {
    body?: LogoUseTypeModel
  }): Observable<string> {

    return this.logoUseTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logoUseTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logoUseTypePost$Json$Response(params?: {
    body?: LogoUseTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDILogoUseTypeService.LogoUseTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logoUseTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logoUseTypePost$Json(params?: {
    body?: LogoUseTypeModel
  }): Observable<string> {

    return this.logoUseTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
