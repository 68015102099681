import { Component } from '@angular/core';
import { RegisteredUserProfileService } from 'src/app/services/sessions/registered-user-profile/registered-user-profile.service';
import { TranslatorService } from 'src/app/services/translate/translator.service';
import { ionButton } from '../../../directives/text-reveal/ionButton-enum';
import { Language } from 'src/app/services/translate/interfaces/language.interface';
import * as _ from 'lodash';

@Component({
	selector: 'session-language',
	templateUrl: './session-language.component.html',
	styleUrls: ['./session-language.component.scss']
})
export class SessionLanguageComponent {
	public selectedMode: string = null;
	public ionButton = ionButton;
	public filteredLanguages: any = [];

	constructor(
		private translatorService: TranslatorService,
		public registeredUserProfileService: RegisteredUserProfileService
	) {}

	ngOnInit() {
		this.filteredLanguages = this.sortLanguages();
	}

	public setLanguageRelatedContent(): void {
		if (this.registeredUserProfileService.requestSession.LanguageCode) {
			this.setLanguageNameAndServiceName();
		} else {
			if (
				this.registeredUserProfileService.appLanguage != 'en' &&
				this.registeredUserProfileService.appLanguage != 'nl' &&
				this.registeredUserProfileService.appLanguage != 'fr'
			) {
				this.registeredUserProfileService.requestSession.LanguageCode =
					'en';
			} else {
				this.registeredUserProfileService.requestSession.LanguageCode =
					this.registeredUserProfileService.appLanguage;
			}

			this.setLanguageNameAndServiceName();
		}
	}

	private setLanguageNameAndServiceName(): void {
		this.registeredUserProfileService.requestSession.LanguageName =
			this.translatorService.availableContentLanguages.find(
				(e) =>
					e.code ==
					this.registeredUserProfileService.requestSession
						.LanguageCode
			).name;
	}

	private sortLanguages(): Language[] {
		const order = ['en', 'fr', 'nl'];

		const langs = this.translatorService.availableLanguages.filter((l) =>
			order.some((o) => o === l.code)
		);

		return _.sortBy(langs, function (obj) {
			return _.indexOf(order, obj.code);
		});
	}
}
