/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SearchRequestModel } from '../models/search-request-model';
import { SpecialityModel } from '../models/speciality-model';
import { SpecialityModelSearchResultModel } from '../models/speciality-model-search-result-model';

@Injectable({
  providedIn: 'root',
})
export class MDISpecialityService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation specialitySearchSpecialityPost
   */
  static readonly SpecialitySearchSpecialityPostPath = '/Speciality/SearchSpeciality';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `specialitySearchSpecialityPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  specialitySearchSpecialityPost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<SpecialityModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDISpecialityService.SpecialitySearchSpecialityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<SpecialityModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `specialitySearchSpecialityPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  specialitySearchSpecialityPost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<SpecialityModelSearchResultModel> {

    return this.specialitySearchSpecialityPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<SpecialityModelSearchResultModel>) => r.body as SpecialityModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `specialitySearchSpecialityPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  specialitySearchSpecialityPost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<SpecialityModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDISpecialityService.SpecialitySearchSpecialityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<SpecialityModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `specialitySearchSpecialityPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  specialitySearchSpecialityPost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<SpecialityModelSearchResultModel> {

    return this.specialitySearchSpecialityPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<SpecialityModelSearchResultModel>) => r.body as SpecialityModelSearchResultModel)
    );
  }

  /**
   * Path part for operation specialityIdGet
   */
  static readonly SpecialityIdGetPath = '/Speciality/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `specialityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  specialityIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<SpecialityModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDISpecialityService.SpecialityIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<SpecialityModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `specialityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  specialityIdGet$Plain(params: {
    id: string;
  }): Observable<SpecialityModel> {

    return this.specialityIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<SpecialityModel>) => r.body as SpecialityModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `specialityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  specialityIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<SpecialityModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDISpecialityService.SpecialityIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<SpecialityModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `specialityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  specialityIdGet$Json(params: {
    id: string;
  }): Observable<SpecialityModel> {

    return this.specialityIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<SpecialityModel>) => r.body as SpecialityModel)
    );
  }

  /**
   * Path part for operation specialityPost
   */
  static readonly SpecialityPostPath = '/Speciality';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `specialityPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  specialityPost$Plain$Response(params?: {
    body?: SpecialityModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDISpecialityService.SpecialityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `specialityPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  specialityPost$Plain(params?: {
    body?: SpecialityModel
  }): Observable<string> {

    return this.specialityPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `specialityPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  specialityPost$Json$Response(params?: {
    body?: SpecialityModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDISpecialityService.SpecialityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `specialityPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  specialityPost$Json(params?: {
    body?: SpecialityModel
  }): Observable<string> {

    return this.specialityPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
