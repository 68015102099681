import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountriesService } from 'src/app/services/countries/countries.service';
import { Country } from 'src/app/services/countries/models/country.model';

@Pipe({
	name: 'isoCountrySingleName$'
})
export class IsoCountrySingleNamePipe implements PipeTransform {
	constructor(private countriesService: CountriesService) {}

	transform(item: string): Observable<string> {
		return this.countriesService.Countries$.pipe(
			map((countries: Country[]) => {
				return countries.find((itmInner) => {
					return itmInner.alpha2.toLowerCase() === item.toLowerCase();
				}).name;
			})
		);
	}
}
