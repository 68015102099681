import { BookmarkKey } from '../../bookmark/enums';

enum SecureKeys {
	UserLoggedIn = 'userLoggedIn',
	RefreshToken = 'refreshToken',
	RefreshTokenExpiry = 'refreshTokenExpiry',
	RefreshDay = 'refreshDay',
	ServiceHistory = 'service-history',
	Theme = 'theme',
	FontSizeModifier = 'font-size-modifier',
	FontSize = 'font-size',
	Language = 'Language',
	ContentLangOverride = 'contentLangOverride',
	UrlHistory = 'urlHistory',
	RoutingName = 'routingName',
	RequestSessionServiceId = 'requestSessionServiceId'
}

export const SecureStorageKey = { ...BookmarkKey, ...SecureKeys };
export type SecureStorageKey = BookmarkKey | SecureKeys;
