/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PracticeTypeModel } from '../models/practice-type-model';
import { PracticeTypeModelSearchResultModel } from '../models/practice-type-model-search-result-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MDIPracticeTypeService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation practiceTypeSearchPracticeTypePost
   */
  static readonly PracticeTypeSearchPracticeTypePostPath = '/PracticeType/SearchPracticeType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practiceTypeSearchPracticeTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practiceTypeSearchPracticeTypePost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<PracticeTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIPracticeTypeService.PracticeTypeSearchPracticeTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<PracticeTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `practiceTypeSearchPracticeTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practiceTypeSearchPracticeTypePost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<PracticeTypeModelSearchResultModel> {

    return this.practiceTypeSearchPracticeTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<PracticeTypeModelSearchResultModel>) => r.body as PracticeTypeModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practiceTypeSearchPracticeTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practiceTypeSearchPracticeTypePost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<PracticeTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIPracticeTypeService.PracticeTypeSearchPracticeTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<PracticeTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `practiceTypeSearchPracticeTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practiceTypeSearchPracticeTypePost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<PracticeTypeModelSearchResultModel> {

    return this.practiceTypeSearchPracticeTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<PracticeTypeModelSearchResultModel>) => r.body as PracticeTypeModelSearchResultModel)
    );
  }

  /**
   * Path part for operation practiceTypeIdGet
   */
  static readonly PracticeTypeIdGetPath = '/PracticeType/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practiceTypeIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  practiceTypeIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<PracticeTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIPracticeTypeService.PracticeTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<PracticeTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `practiceTypeIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  practiceTypeIdGet$Plain(params: {
    id: string;
  }): Observable<PracticeTypeModel> {

    return this.practiceTypeIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<PracticeTypeModel>) => r.body as PracticeTypeModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practiceTypeIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  practiceTypeIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<PracticeTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIPracticeTypeService.PracticeTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<PracticeTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `practiceTypeIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  practiceTypeIdGet$Json(params: {
    id: string;
  }): Observable<PracticeTypeModel> {

    return this.practiceTypeIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<PracticeTypeModel>) => r.body as PracticeTypeModel)
    );
  }

  /**
   * Path part for operation practiceTypePost
   */
  static readonly PracticeTypePostPath = '/PracticeType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practiceTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practiceTypePost$Plain$Response(params?: {
    body?: PracticeTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIPracticeTypeService.PracticeTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `practiceTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practiceTypePost$Plain(params?: {
    body?: PracticeTypeModel
  }): Observable<string> {

    return this.practiceTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practiceTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practiceTypePost$Json$Response(params?: {
    body?: PracticeTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIPracticeTypeService.PracticeTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `practiceTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practiceTypePost$Json(params?: {
    body?: PracticeTypeModel
  }): Observable<string> {

    return this.practiceTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
