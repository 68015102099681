import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'toHtml'
})
export class ToHtmlPipe implements PipeTransform {
	constructor(private sanitized: DomSanitizer) {}

	transform(value: string, ...args: unknown[]): unknown {
		if (value) {
			value = this.sanitized.sanitize(SecurityContext.NONE, value);
		}
		return this.sanitized.bypassSecurityTrustHtml(value);
	}
}
