export const Timeslots = [
	{
		group: 'Group 1',
		items: [
			{
				name: 'Slot 1',
				value: '08:00-09:00'
			},
			{
				name: 'Slot 2',
				value: '09:00-10:00'
			},
			{
				name: 'Slot 3',
				value: '10:00-11:00'
			}
		]
	},
	{
		group: 'Group 2',
		items: [
			{
				name: 'Slot 4',
				value: '11:00-12:00'
			},
			{
				name: 'Slot 5',
				value: '12:00-13:00'
			},
			{
				name: 'Slot 6',
				value: '13:00-14:00'
			}
		]
	},
	{
		group: 'Group 3',
		items: [
			{
				name: 'Slot 7',
				value: '14:00-15:00'
			},
			{
				name: 'Slot 8',
				value: '15:00-16:00'
			},
			{
				name: 'Slot 9',
				value: '16:00-17:00'
			}
		]
	},
	{
		group: 'Group 4',
		items: [
			{
				name: 'Slot 10',
				value: '17:00-18:00'
			},
			{
				name: 'Slot 11',
				value: '18:00-19:00'
			},
			{
				name: 'Slot 12',
				value: '19:00-20:00'
			}
		]
	}
];
