/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LanguageModel } from '../models/language-model';
import { LanguageModelSearchResultModel } from '../models/language-model-search-result-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MDILanguageService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation languageSearchLanguagePost
   */
  static readonly LanguageSearchLanguagePostPath = '/Language/SearchLanguage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `languageSearchLanguagePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  languageSearchLanguagePost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<LanguageModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDILanguageService.LanguageSearchLanguagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<LanguageModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `languageSearchLanguagePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  languageSearchLanguagePost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<LanguageModelSearchResultModel> {

    return this.languageSearchLanguagePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<LanguageModelSearchResultModel>) => r.body as LanguageModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `languageSearchLanguagePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  languageSearchLanguagePost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<LanguageModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDILanguageService.LanguageSearchLanguagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<LanguageModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `languageSearchLanguagePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  languageSearchLanguagePost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<LanguageModelSearchResultModel> {

    return this.languageSearchLanguagePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<LanguageModelSearchResultModel>) => r.body as LanguageModelSearchResultModel)
    );
  }

  /**
   * Path part for operation languageIdGet
   */
  static readonly LanguageIdGetPath = '/Language/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `languageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  languageIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<LanguageModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDILanguageService.LanguageIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<LanguageModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `languageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  languageIdGet$Plain(params: {
    id: string;
  }): Observable<LanguageModel> {

    return this.languageIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<LanguageModel>) => r.body as LanguageModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `languageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  languageIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<LanguageModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDILanguageService.LanguageIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<LanguageModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `languageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  languageIdGet$Json(params: {
    id: string;
  }): Observable<LanguageModel> {

    return this.languageIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<LanguageModel>) => r.body as LanguageModel)
    );
  }

  /**
   * Path part for operation languagePost
   */
  static readonly LanguagePostPath = '/Language';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `languagePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  languagePost$Plain$Response(params?: {
    body?: LanguageModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDILanguageService.LanguagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `languagePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  languagePost$Plain(params?: {
    body?: LanguageModel
  }): Observable<string> {

    return this.languagePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `languagePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  languagePost$Json$Response(params?: {
    body?: LanguageModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDILanguageService.LanguagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `languagePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  languagePost$Json(params?: {
    body?: LanguageModel
  }): Observable<string> {

    return this.languagePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
