/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { OrganizationBaseService } from '../organization-base-service';
import { OrganizationApiConfiguration } from '../organization-api-configuration';
import { OrganizationStrictHttpResponse } from '../organization-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConfigurationModel } from '../models/configuration-model';
import { HealthCareServiceLocationSearchResultModel } from '../models/health-care-service-location-search-result-model';
import { HealthCareServiceSpecialityLinkedModel } from '../models/health-care-service-speciality-linked-model';
import { HealthcareServiceLocationModel } from '../models/healthcare-service-location-model';
import { HealthcareServiceModel } from '../models/healthcare-service-model';
import { HealthcareServiceSearchResultModel } from '../models/healthcare-service-search-result-model';
import { KeyValueCodeModel } from '../models/key-value-code-model';
import { LanguageModel } from '../models/language-model';
import { OrganizationAffiliationModel } from '../models/organization-affiliation-model';
import { OrganizationAffiliationSearchResultModel } from '../models/organization-affiliation-search-result-model';
import { OrganizationBranchModel } from '../models/organization-branch-model';
import { OrganizationEmailConfigurationModel } from '../models/organization-email-configuration-model';
import { OrganizationLogoListModel } from '../models/organization-logo-list-model';
import { OrganizationLogoModel } from '../models/organization-logo-model';
import { OrganizationModel } from '../models/organization-model';
import { OrganizationSearchModel } from '../models/organization-search-model';
import { OrganizationSearchResultModel } from '../models/organization-search-result-model';
import { SpecialityModel } from '../models/speciality-model';
import { SpecialitySaveModel } from '../models/speciality-save-model';
import { SpecialitySearchModel } from '../models/speciality-search-model';

@Injectable({
  providedIn: 'root',
})
export class ORGAdminService extends OrganizationBaseService {
  constructor(
    config: OrganizationApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation adminSearchOrganizationPost
   */
  static readonly AdminSearchOrganizationPostPath = '/Admin/SearchOrganization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSearchOrganizationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchOrganizationPost$Response(params?: {
    body?: OrganizationSearchModel
  }): Observable<OrganizationStrictHttpResponse<Array<OrganizationSearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminSearchOrganizationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<Array<OrganizationSearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminSearchOrganizationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchOrganizationPost(params?: {
    body?: OrganizationSearchModel
  }): Observable<Array<OrganizationSearchResultModel>> {

    return this.adminSearchOrganizationPost$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<Array<OrganizationSearchResultModel>>) => r.body as Array<OrganizationSearchResultModel>)
    );
  }

  /**
   * Path part for operation adminFhirIdGet
   */
  static readonly AdminFhirIdGetPath = '/Admin/{fhirId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdGet$Response(params: {
    fhirId: string;
  }): Observable<OrganizationStrictHttpResponse<OrganizationModel>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<OrganizationModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdGet(params: {
    fhirId: string;
  }): Observable<OrganizationModel> {

    return this.adminFhirIdGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationModel>) => r.body as OrganizationModel)
    );
  }

  /**
   * Path part for operation adminPost
   */
  static readonly AdminPostPath = '/Admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost$Response(params?: {
    body?: OrganizationModel
  }): Observable<OrganizationStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost(params?: {
    body?: OrganizationModel
  }): Observable<string> {

    return this.adminPost$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation adminFhirIdLocationGet
   */
  static readonly AdminFhirIdLocationGetPath = '/Admin/{fhirId}/Location';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLocationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLocationGet$Response(params: {
    fhirId: string;
  }): Observable<OrganizationStrictHttpResponse<Array<HealthCareServiceLocationSearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdLocationGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<Array<HealthCareServiceLocationSearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLocationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLocationGet(params: {
    fhirId: string;
  }): Observable<Array<HealthCareServiceLocationSearchResultModel>> {

    return this.adminFhirIdLocationGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<Array<HealthCareServiceLocationSearchResultModel>>) => r.body as Array<HealthCareServiceLocationSearchResultModel>)
    );
  }

  /**
   * Path part for operation adminFhirIdLocationPost
   */
  static readonly AdminFhirIdLocationPostPath = '/Admin/{fhirId}/Location';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLocationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdLocationPost$Response(params: {
    fhirId: string;
    body?: HealthcareServiceLocationModel
  }): Observable<OrganizationStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdLocationPostPath, 'post');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLocationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdLocationPost(params: {
    fhirId: string;
    body?: HealthcareServiceLocationModel
  }): Observable<string> {

    return this.adminFhirIdLocationPost$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation adminFhirIdLocationLocationFhirIdGet
   */
  static readonly AdminFhirIdLocationLocationFhirIdGetPath = '/Admin/{fhirId}/Location/{locationFhirId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLocationLocationFhirIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLocationLocationFhirIdGet$Response(params: {
    fhirId: string;
    locationFhirId: string;
  }): Observable<OrganizationStrictHttpResponse<HealthcareServiceLocationModel>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdLocationLocationFhirIdGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
      rb.path('locationFhirId', params.locationFhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<HealthcareServiceLocationModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLocationLocationFhirIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLocationLocationFhirIdGet(params: {
    fhirId: string;
    locationFhirId: string;
  }): Observable<HealthcareServiceLocationModel> {

    return this.adminFhirIdLocationLocationFhirIdGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<HealthcareServiceLocationModel>) => r.body as HealthcareServiceLocationModel)
    );
  }

  /**
   * Path part for operation adminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost
   */
  static readonly AdminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPostPath = '/Admin/SpecialityLinkdedUnlinkedOrganizationHealthCareServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost$Response(params?: {
    body?: SpecialitySearchModel
  }): Observable<OrganizationStrictHttpResponse<Array<HealthCareServiceSpecialityLinkedModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<Array<HealthCareServiceSpecialityLinkedModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost(params?: {
    body?: SpecialitySearchModel
  }): Observable<Array<HealthCareServiceSpecialityLinkedModel>> {

    return this.adminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<Array<HealthCareServiceSpecialityLinkedModel>>) => r.body as Array<HealthCareServiceSpecialityLinkedModel>)
    );
  }

  /**
   * Path part for operation adminLinkUnlinkHealthcareServicesToSpecialityPost
   */
  static readonly AdminLinkUnlinkHealthcareServicesToSpecialityPostPath = '/Admin/LinkUnlinkHealthcareServicesToSpeciality';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminLinkUnlinkHealthcareServicesToSpecialityPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminLinkUnlinkHealthcareServicesToSpecialityPost$Response(params?: {
    body?: Array<SpecialitySaveModel>
  }): Observable<OrganizationStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminLinkUnlinkHealthcareServicesToSpecialityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as OrganizationStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminLinkUnlinkHealthcareServicesToSpecialityPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminLinkUnlinkHealthcareServicesToSpecialityPost(params?: {
    body?: Array<SpecialitySaveModel>
  }): Observable<void> {

    return this.adminLinkUnlinkHealthcareServicesToSpecialityPost$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation adminFhirIdHealthcareServiceGet
   */
  static readonly AdminFhirIdHealthcareServiceGetPath = '/Admin/{fhirId}/HealthcareService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdHealthcareServiceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdHealthcareServiceGet$Response(params: {
    fhirId: string;
  }): Observable<OrganizationStrictHttpResponse<Array<HealthcareServiceSearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdHealthcareServiceGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<Array<HealthcareServiceSearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdHealthcareServiceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdHealthcareServiceGet(params: {
    fhirId: string;
  }): Observable<Array<HealthcareServiceSearchResultModel>> {

    return this.adminFhirIdHealthcareServiceGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<Array<HealthcareServiceSearchResultModel>>) => r.body as Array<HealthcareServiceSearchResultModel>)
    );
  }

  /**
   * Path part for operation adminFhirIdHealthcareServicePost
   */
  static readonly AdminFhirIdHealthcareServicePostPath = '/Admin/{fhirId}/HealthcareService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdHealthcareServicePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdHealthcareServicePost$Response(params: {
    fhirId: string;
    body?: HealthcareServiceModel
  }): Observable<OrganizationStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdHealthcareServicePostPath, 'post');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdHealthcareServicePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdHealthcareServicePost(params: {
    fhirId: string;
    body?: HealthcareServiceModel
  }): Observable<string> {

    return this.adminFhirIdHealthcareServicePost$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation adminFhirIdHealthcareServiceHealthcareServiceFhirIdGet
   */
  static readonly AdminFhirIdHealthcareServiceHealthcareServiceFhirIdGetPath = '/Admin/{fhirId}/HealthcareService/{healthcareServiceFhirId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdHealthcareServiceHealthcareServiceFhirIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdHealthcareServiceHealthcareServiceFhirIdGet$Response(params: {
    fhirId: string;
    healthcareServiceFhirId: string;
  }): Observable<OrganizationStrictHttpResponse<HealthcareServiceModel>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdHealthcareServiceHealthcareServiceFhirIdGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
      rb.path('healthcareServiceFhirId', params.healthcareServiceFhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<HealthcareServiceModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdHealthcareServiceHealthcareServiceFhirIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdHealthcareServiceHealthcareServiceFhirIdGet(params: {
    fhirId: string;
    healthcareServiceFhirId: string;
  }): Observable<HealthcareServiceModel> {

    return this.adminFhirIdHealthcareServiceHealthcareServiceFhirIdGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<HealthcareServiceModel>) => r.body as HealthcareServiceModel)
    );
  }

  /**
   * Path part for operation adminFhirIdAffiliationGet
   */
  static readonly AdminFhirIdAffiliationGetPath = '/Admin/{fhirId}/Affiliation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdAffiliationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdAffiliationGet$Response(params: {
    fhirId: string;
  }): Observable<OrganizationStrictHttpResponse<Array<OrganizationAffiliationSearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdAffiliationGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<Array<OrganizationAffiliationSearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdAffiliationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdAffiliationGet(params: {
    fhirId: string;
  }): Observable<Array<OrganizationAffiliationSearchResultModel>> {

    return this.adminFhirIdAffiliationGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<Array<OrganizationAffiliationSearchResultModel>>) => r.body as Array<OrganizationAffiliationSearchResultModel>)
    );
  }

  /**
   * Path part for operation adminFhirIdAffiliationPost
   */
  static readonly AdminFhirIdAffiliationPostPath = '/Admin/{fhirId}/Affiliation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdAffiliationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdAffiliationPost$Response(params: {
    fhirId: string;
    body?: OrganizationAffiliationModel
  }): Observable<OrganizationStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdAffiliationPostPath, 'post');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdAffiliationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdAffiliationPost(params: {
    fhirId: string;
    body?: OrganizationAffiliationModel
  }): Observable<string> {

    return this.adminFhirIdAffiliationPost$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation adminFhirIdParticipatingAffiliationsGet
   */
  static readonly AdminFhirIdParticipatingAffiliationsGetPath = '/Admin/{fhirId}/ParticipatingAffiliations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdParticipatingAffiliationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdParticipatingAffiliationsGet$Response(params: {
    fhirId: string;
  }): Observable<OrganizationStrictHttpResponse<Array<OrganizationAffiliationSearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdParticipatingAffiliationsGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<Array<OrganizationAffiliationSearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdParticipatingAffiliationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdParticipatingAffiliationsGet(params: {
    fhirId: string;
  }): Observable<Array<OrganizationAffiliationSearchResultModel>> {

    return this.adminFhirIdParticipatingAffiliationsGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<Array<OrganizationAffiliationSearchResultModel>>) => r.body as Array<OrganizationAffiliationSearchResultModel>)
    );
  }

  /**
   * Path part for operation adminFhirIdAffiliationAffiliationFhirIdGet
   */
  static readonly AdminFhirIdAffiliationAffiliationFhirIdGetPath = '/Admin/{fhirId}/Affiliation/{affiliationFhirId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdAffiliationAffiliationFhirIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdAffiliationAffiliationFhirIdGet$Response(params: {
    fhirId: string;
    affiliationFhirId: string;
  }): Observable<OrganizationStrictHttpResponse<OrganizationAffiliationModel>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdAffiliationAffiliationFhirIdGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
      rb.path('affiliationFhirId', params.affiliationFhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<OrganizationAffiliationModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdAffiliationAffiliationFhirIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdAffiliationAffiliationFhirIdGet(params: {
    fhirId: string;
    affiliationFhirId: string;
  }): Observable<OrganizationAffiliationModel> {

    return this.adminFhirIdAffiliationAffiliationFhirIdGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationAffiliationModel>) => r.body as OrganizationAffiliationModel)
    );
  }

  /**
   * Path part for operation adminFhirIdBranchesGet
   */
  static readonly AdminFhirIdBranchesGetPath = '/Admin/{fhirId}/Branches';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdBranchesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdBranchesGet$Response(params: {
    fhirId: string;
  }): Observable<OrganizationStrictHttpResponse<Array<OrganizationBranchModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdBranchesGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<Array<OrganizationBranchModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdBranchesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdBranchesGet(params: {
    fhirId: string;
  }): Observable<Array<OrganizationBranchModel>> {

    return this.adminFhirIdBranchesGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<Array<OrganizationBranchModel>>) => r.body as Array<OrganizationBranchModel>)
    );
  }

  /**
   * Path part for operation adminFhirIdLogoGet
   */
  static readonly AdminFhirIdLogoGetPath = '/Admin/{fhirId}/Logo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLogoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoGet$Response(params: {
    fhirId: string;
  }): Observable<OrganizationStrictHttpResponse<Array<OrganizationLogoListModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdLogoGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<Array<OrganizationLogoListModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLogoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoGet(params: {
    fhirId: string;
  }): Observable<Array<OrganizationLogoListModel>> {

    return this.adminFhirIdLogoGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<Array<OrganizationLogoListModel>>) => r.body as Array<OrganizationLogoListModel>)
    );
  }

  /**
   * Path part for operation adminFhirIdLogoPost
   */
  static readonly AdminFhirIdLogoPostPath = '/Admin/{fhirId}/Logo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLogoPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdLogoPost$Response(params: {
    fhirId: string;
    body?: OrganizationLogoModel
  }): Observable<OrganizationStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdLogoPostPath, 'post');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLogoPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdLogoPost(params: {
    fhirId: string;
    body?: OrganizationLogoModel
  }): Observable<string> {

    return this.adminFhirIdLogoPost$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation adminFhirIdLogoIdGet
   */
  static readonly AdminFhirIdLogoIdGetPath = '/Admin/{fhirId}/Logo/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLogoIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoIdGet$Response(params: {
    fhirId: string;
    id: string;
  }): Observable<OrganizationStrictHttpResponse<OrganizationLogoModel>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdLogoIdGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<OrganizationLogoModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLogoIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoIdGet(params: {
    fhirId: string;
    id: string;
  }): Observable<OrganizationLogoModel> {

    return this.adminFhirIdLogoIdGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationLogoModel>) => r.body as OrganizationLogoModel)
    );
  }

  /**
   * Path part for operation adminFhirIdLogoImageIdGet
   */
  static readonly AdminFhirIdLogoImageIdGetPath = '/Admin/{fhirId}/LogoImage/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLogoImageIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoImageIdGet$Response(params: {
    fhirId: string;
    id: string;
  }): Observable<OrganizationStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdLogoImageIdGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as OrganizationStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLogoImageIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoImageIdGet(params: {
    fhirId: string;
    id: string;
  }): Observable<void> {

    return this.adminFhirIdLogoImageIdGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation adminFhirIdLogoThumbNailIdGet
   */
  static readonly AdminFhirIdLogoThumbNailIdGetPath = '/Admin/{fhirId}/LogoThumbNail/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLogoThumbNailIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoThumbNailIdGet$Response(params: {
    fhirId: string;
    id: string;
  }): Observable<OrganizationStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdLogoThumbNailIdGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as OrganizationStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLogoThumbNailIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoThumbNailIdGet(params: {
    fhirId: string;
    id: string;
  }): Observable<void> {

    return this.adminFhirIdLogoThumbNailIdGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation adminFhirIdSpecialitiesLanguageRegionGet
   */
  static readonly AdminFhirIdSpecialitiesLanguageRegionGetPath = '/Admin/{fhirId}/Specialities/{languageRegion}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdSpecialitiesLanguageRegionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdSpecialitiesLanguageRegionGet$Response(params: {
    fhirId: string;
    languageRegion: string;
  }): Observable<OrganizationStrictHttpResponse<Array<SpecialityModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdSpecialitiesLanguageRegionGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
      rb.path('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<Array<SpecialityModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdSpecialitiesLanguageRegionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdSpecialitiesLanguageRegionGet(params: {
    fhirId: string;
    languageRegion: string;
  }): Observable<Array<SpecialityModel>> {

    return this.adminFhirIdSpecialitiesLanguageRegionGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<Array<SpecialityModel>>) => r.body as Array<SpecialityModel>)
    );
  }

  /**
   * Path part for operation adminFhirIdKeyValueSpecialitiesLanguageRegionGet
   */
  static readonly AdminFhirIdKeyValueSpecialitiesLanguageRegionGetPath = '/Admin/{fhirId}/KeyValueSpecialities/{languageRegion}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdKeyValueSpecialitiesLanguageRegionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdKeyValueSpecialitiesLanguageRegionGet$Response(params: {
    fhirId: string;
    languageRegion: string;
  }): Observable<OrganizationStrictHttpResponse<Array<KeyValueCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdKeyValueSpecialitiesLanguageRegionGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
      rb.path('languageRegion', params.languageRegion, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<Array<KeyValueCodeModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdKeyValueSpecialitiesLanguageRegionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdKeyValueSpecialitiesLanguageRegionGet(params: {
    fhirId: string;
    languageRegion: string;
  }): Observable<Array<KeyValueCodeModel>> {

    return this.adminFhirIdKeyValueSpecialitiesLanguageRegionGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<Array<KeyValueCodeModel>>) => r.body as Array<KeyValueCodeModel>)
    );
  }

  /**
   * Path part for operation adminSpecialityPost
   */
  static readonly AdminSpecialityPostPath = '/Admin/Speciality';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSpecialityPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSpecialityPost$Response(params?: {
    body?: SpecialityModel
  }): Observable<OrganizationStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminSpecialityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminSpecialityPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSpecialityPost(params?: {
    body?: SpecialityModel
  }): Observable<string> {

    return this.adminSpecialityPost$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation adminFhirIdConfigurationGet
   */
  static readonly AdminFhirIdConfigurationGetPath = '/Admin/{fhirId}/Configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdConfigurationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdConfigurationGet$Response(params: {
    fhirId: string;
  }): Observable<OrganizationStrictHttpResponse<ConfigurationModel>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdConfigurationGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<ConfigurationModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdConfigurationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdConfigurationGet(params: {
    fhirId: string;
  }): Observable<ConfigurationModel> {

    return this.adminFhirIdConfigurationGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<ConfigurationModel>) => r.body as ConfigurationModel)
    );
  }

  /**
   * Path part for operation adminFhirIdConfigurationPost
   */
  static readonly AdminFhirIdConfigurationPostPath = '/Admin/{fhirId}/Configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdConfigurationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdConfigurationPost$Response(params: {
    fhirId: string;
    body?: ConfigurationModel
  }): Observable<OrganizationStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdConfigurationPostPath, 'post');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as OrganizationStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdConfigurationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdConfigurationPost(params: {
    fhirId: string;
    body?: ConfigurationModel
  }): Observable<void> {

    return this.adminFhirIdConfigurationPost$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation adminLanguagePost
   */
  static readonly AdminLanguagePostPath = '/Admin/Language';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminLanguagePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminLanguagePost$Response(params?: {
    body?: LanguageModel
  }): Observable<OrganizationStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminLanguagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminLanguagePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminLanguagePost(params?: {
    body?: LanguageModel
  }): Observable<string> {

    return this.adminLanguagePost$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation adminFhirIdEmailConfigurationGet
   */
  static readonly AdminFhirIdEmailConfigurationGetPath = '/Admin/{fhirId}/EmailConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdEmailConfigurationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdEmailConfigurationGet$Response(params: {
    fhirId: string;
  }): Observable<OrganizationStrictHttpResponse<OrganizationEmailConfigurationModel>> {

    const rb = new RequestBuilder(this.rootUrl, ORGAdminService.AdminFhirIdEmailConfigurationGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as OrganizationStrictHttpResponse<OrganizationEmailConfigurationModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdEmailConfigurationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdEmailConfigurationGet(params: {
    fhirId: string;
  }): Observable<OrganizationEmailConfigurationModel> {

    return this.adminFhirIdEmailConfigurationGet$Response(params).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationEmailConfigurationModel>) => r.body as OrganizationEmailConfigurationModel)
    );
  }

}
