/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PublicHolidayRecordModel } from '../models/public-holiday-record-model';
import { PublicHolidayRecordModelSearchResultModel } from '../models/public-holiday-record-model-search-result-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MDIPublicHolidayService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation publicHolidaySearchPublicHolidayPost
   */
  static readonly PublicHolidaySearchPublicHolidayPostPath = '/PublicHoliday/SearchPublicHoliday';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicHolidaySearchPublicHolidayPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publicHolidaySearchPublicHolidayPost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<PublicHolidayRecordModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIPublicHolidayService.PublicHolidaySearchPublicHolidayPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<PublicHolidayRecordModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publicHolidaySearchPublicHolidayPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publicHolidaySearchPublicHolidayPost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<PublicHolidayRecordModelSearchResultModel> {

    return this.publicHolidaySearchPublicHolidayPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<PublicHolidayRecordModelSearchResultModel>) => r.body as PublicHolidayRecordModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicHolidaySearchPublicHolidayPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publicHolidaySearchPublicHolidayPost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<PublicHolidayRecordModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIPublicHolidayService.PublicHolidaySearchPublicHolidayPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<PublicHolidayRecordModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publicHolidaySearchPublicHolidayPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publicHolidaySearchPublicHolidayPost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<PublicHolidayRecordModelSearchResultModel> {

    return this.publicHolidaySearchPublicHolidayPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<PublicHolidayRecordModelSearchResultModel>) => r.body as PublicHolidayRecordModelSearchResultModel)
    );
  }

  /**
   * Path part for operation publicHolidayIdGet
   */
  static readonly PublicHolidayIdGetPath = '/PublicHoliday/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicHolidayIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicHolidayIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<PublicHolidayRecordModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIPublicHolidayService.PublicHolidayIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<PublicHolidayRecordModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publicHolidayIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicHolidayIdGet$Plain(params: {
    id: string;
  }): Observable<PublicHolidayRecordModel> {

    return this.publicHolidayIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<PublicHolidayRecordModel>) => r.body as PublicHolidayRecordModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicHolidayIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicHolidayIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<PublicHolidayRecordModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIPublicHolidayService.PublicHolidayIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<PublicHolidayRecordModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publicHolidayIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicHolidayIdGet$Json(params: {
    id: string;
  }): Observable<PublicHolidayRecordModel> {

    return this.publicHolidayIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<PublicHolidayRecordModel>) => r.body as PublicHolidayRecordModel)
    );
  }

  /**
   * Path part for operation publicHolidayPost
   */
  static readonly PublicHolidayPostPath = '/PublicHoliday';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicHolidayPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publicHolidayPost$Plain$Response(params?: {
    body?: PublicHolidayRecordModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIPublicHolidayService.PublicHolidayPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publicHolidayPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publicHolidayPost$Plain(params?: {
    body?: PublicHolidayRecordModel
  }): Observable<string> {

    return this.publicHolidayPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicHolidayPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publicHolidayPost$Json$Response(params?: {
    body?: PublicHolidayRecordModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIPublicHolidayService.PublicHolidayPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publicHolidayPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publicHolidayPost$Json(params?: {
    body?: PublicHolidayRecordModel
  }): Observable<string> {

    return this.publicHolidayPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
