/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AuthBaseService } from '../auth-base-service';
import { AuthApiConfiguration } from '../auth-api-configuration';
import { AuthStrictHttpResponse } from '../auth-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TokenRequestModel } from '../models/token-request-model';
import { TokenResponseModel } from '../models/token-response-model';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceTokenService extends AuthBaseService {
  constructor(
    config: AuthApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation serviceTokenAccessTokenPost
   */
  static readonly ServiceTokenAccessTokenPostPath = '/ServiceToken/AccessToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceTokenAccessTokenPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceTokenAccessTokenPost$Plain$Response(params?: {
    body?: TokenRequestModel
  }): Observable<AuthStrictHttpResponse<TokenResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthServiceTokenService.ServiceTokenAccessTokenPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<TokenResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceTokenAccessTokenPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceTokenAccessTokenPost$Plain(params?: {
    body?: TokenRequestModel
  }): Observable<TokenResponseModel> {

    return this.serviceTokenAccessTokenPost$Plain$Response(params).pipe(
      map((r: AuthStrictHttpResponse<TokenResponseModel>) => r.body as TokenResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceTokenAccessTokenPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceTokenAccessTokenPost$Json$Response(params?: {
    body?: TokenRequestModel
  }): Observable<AuthStrictHttpResponse<TokenResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthServiceTokenService.ServiceTokenAccessTokenPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as AuthStrictHttpResponse<TokenResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceTokenAccessTokenPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceTokenAccessTokenPost$Json(params?: {
    body?: TokenRequestModel
  }): Observable<TokenResponseModel> {

    return this.serviceTokenAccessTokenPost$Json$Response(params).pipe(
      map((r: AuthStrictHttpResponse<TokenResponseModel>) => r.body as TokenResponseModel)
    );
  }

}
