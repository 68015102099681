import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IsRegisteredGuard } from './shared/guards/is-registered/is-registered.guard';
import { IsServicesLoadedGuard } from './shared/guards/is-services-loaded/is-services-loaded.guard';
import { LoginGuard } from './shared/guards/login/login.guard';
import { RedirectGuard } from './shared/guards/redirect/redirect.guard';
import { LanguagePage } from './shared/pages/language/language.page';

const routes: Routes = [
	{
		path: '',
		canActivateChild: [IsServicesLoadedGuard],
		children: [
			{
				path: 'medical-advisor',
				loadChildren: () =>
					import(
						'./pages/medical-advisor/medical-advisor.module'
					).then((m) => m.MedicalAadvisorPageModule),
				data: {
					title: 'Medical Advisor'
				}
			},
			{
				path: 'language',
				component: LanguagePage,
				data: {
					title: 'Language'
				}
			},
			{
				path: 'get',
				canActivate: [RedirectGuard],
				component: RedirectGuard,
				data: {
					externalUrl: '',
					appleUrl: '',
					googleUrl: ''
				}
			},
			{
				path: '',
				redirectTo: '/medical-advisor',
				pathMatch: 'full'
			},
			{ path: '**', redirectTo: '/medical-advisor' }
		]
	}
];
@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
