import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import {
	ChangeDetectorRef,
	Component,
	HostListener,
	OnDestroy,
	OnInit
} from '@angular/core';
import { ComponentCanDeactivate } from './utility.component';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from 'src/app/services/base/popup.service';
import { MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmPopupComponent } from '../popups/confirm.popup.component';
import { InputPopupComponent } from '../popups/input.popup.component';
import { environment } from 'src/environments/environment';
import { TranslatorService } from 'src/app/services/translate/translator.service';
import { ToastController } from '@ionic/angular';

@Component({
	selector: 'modal-base-component',
	template: ''
})
export class ModalBaseComponent
	implements OnInit, OnDestroy, ComponentCanDeactivate
{
	protected _unsubscribeAll: Subject<any>;
	protected popup: boolean = false;
	protected contentDirection: string;
	public uniqueId: string;
	public gridActivated: boolean = true;
	public swal = require('sweetalert');

	private languageSubscription: Subscription;

	constructor(
		protected _changeDetectorRef: ChangeDetectorRef,
		protected _router: Router,
		protected _location: Location,
		// protected eventService: EventService,
		protected toastController: ToastController,
		protected _translate: TranslateService,
		protected _translator: TranslatorService
	) {
		this._unsubscribeAll = new Subject();
		this.uniqueId = Math.random().toString(36).substring(7);
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		//this.languageSubscription.unsubscribe();
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	saveBase64File(base64String, mimeType, fileName) {
		const source = `data:${mimeType};base64,${base64String}`;
		const link = document.createElement('a');
		link.href = source;
		link.download = `${fileName}`;
		link.click();
	}

	ngOnInit(): void {
		this._translator.contentLanguageDirection$.subscribe(
			(event) => (this.contentDirection = event)
		);
	}

	protected recreateGrids(): void {
		var me = this;

		me.ngOnInit();

		me.gridActivated = false;
		setTimeout(() => {
			me.gridActivated = true;
			me.languageChanged();
			this._changeDetectorRef.markForCheck();
		});
	}

	protected languageChanged() {}

	protected scrollTo(element: string) {
		setTimeout(() => {
			document.getElementById(element).scrollIntoView();
		}, 200);
	}

	// protected RegisterGrid(params: GridReadyEvent) {
	//   // params.api;
	//   this.gridList.push(params.api);
	// }

	public async presentToast(message: string, icon: string) {
		const toast = await this.toastController.create({
			message: message,
			cssClass: 'toast-success',
			duration: 1500,
			position: 'bottom',
			icon: icon,
			animated: true,
			mode: 'ios'
		});

		await toast.present();
	}

	public returnPopup(): boolean {
		return this.popup;
	}

	public isHome(): boolean {
		return this._router.url.endsWith('/home');
	}

	public currentDate(): Date {
		return new Date();
	}

	public setPopup(value: boolean): void {
		this.popup = value;
	}

	public goBack(): void {
		this._location.back();
	}

	protected showSuccess(message: string, title: string = 'Success') {
		this.swal({ title: title, text: message, icon: 'success' });
	}

	protected showError(errorMessage: any, title: string = 'Error') {
		this.swal({ title: title, text: errorMessage, icon: 'warning' });
	}
	protected showErrors(
		errorMessage: any,
		title: string = 'Error',
		errorDetails: string[] = []
	) {
		//this.popupService.showErrorMessage(errorMessage, title, errorDetails);
	}

	protected showInfo(message: string, title: string = 'Info') {
		//this.popupService.showInfoMessage(message, title);
	}

	protected showConfirm(
		message: string,
		title: string = 'Confirm'
	): MatDialogRef<ConfirmPopupComponent> {
		//return this.popupService.showConfirmMessage(message, title);
		return null;
	}

	protected showInput(
		placeholderText: string,
		title: string = 'Input',
		buttonText: string
	): MatDialogRef<InputPopupComponent> {
		// return this.popupService.showInputMessage(
		// 	placeholderText,
		// 	title,
		// 	buttonText
		// );
		return null;
	}

	protected openDialog<DialogInputModel, DialogOutputModel>(
		dialogComponent: any,
		data: DialogInputModel,
		options: MatDialogConfig
	): MatDialogRef<any, DialogOutputModel> {
		// return this.popupService.openDialog<
		// 	DialogInputModel,
		// 	DialogOutputModel
		// >(dialogComponent, data, options);
		return null;
	}

	protected notify(message: string, action: string) {
		return null;
		// return this.popupService.notify(message, action);
	}

	protected throwError(
		errorObject: any,
		statusText: string,
		title: string = this._translate.instant('error-pages.SERVER-ERROR')
	) {
		if (errorObject?.error?.message) {
			this.swal({
				text: errorObject.error.message,
				icon: 'warning',
				buttons: {
					confirm: {
						text: this._translate.instant('button.OK'),
						value: true,
						visible: true,
						className: 'bg-primary',
						closeModal: true
					}
				}
			});
		} else if (errorObject?.message) {
			this.swal({
				text: errorObject.message,
				icon: 'warning',
				buttons: {
					confirm: {
						text: this._translate.instant('button.OK'),
						value: true,
						visible: true,
						className: 'bg-primary',
						closeModal: true
					}
				}
			});
		} else {
			this.swal({
				text: errorObject,
				icon: 'warning',
				buttons: {
					confirm: {
						text: this._translate.instant('button.OK'),
						value: true,
						visible: true,
						className: 'bg-primary',
						closeModal: true
					}
				}
			});
		}
	}

	public updateForm(form: FormGroup, value: any): void {
		form.patchValue(value);
	}

	public resetForm(form: FormGroup): void {
		form.reset();
	}

	public getRouteParam(
		activatedRoute: ActivatedRoute,
		parameter: string
	): string {
		const parameterValue = activatedRoute.snapshot.params[parameter];
		if (parameterValue === undefined) return '';
		if (parameterValue === null) return '';
		if (parameterValue === 'undefined') return '';
		if (parameterValue === 'null') return '';
		if (parameterValue === '') return '';
		return parameterValue;
	}

	public getQueryParam(
		activatedRoute: ActivatedRoute,
		parameter: string
	): string {
		const parameterValue = activatedRoute.snapshot.queryParams[parameter];
		if (parameterValue === undefined) return '';
		if (parameterValue === null) return '';
		if (parameterValue === 'undefined') return '';
		if (parameterValue === 'null') return '';
		if (parameterValue === '') return '';
		return parameterValue;
	}

	public hasValue(value: any): boolean {
		if (value === undefined) return false;
		if (value === null) return false;
		if (value === 'undefined') return false;
		if (value === 'null') return false;
		if (value === '') return false;
		return true;
	}

	public toFormData<T extends object>(formValue: T) {
		const formData = new FormData();
		for (const key of Object.keys(formValue)) {
			if (Array.isArray(formValue[key])) {
				formValue[key].map((value) => formData.append(key, value));
			} else if (formValue[key]?._isAMomentObject) {
				formData.append(key, formValue[key].toISOString());
			} else {
				if (formValue[key] != null && formValue[key] != undefined) {
					formData.append(key, formValue[key]);
				}
			}
		}
		return formData;
	}

	public updateObject<T extends object>(source: T, updated: T) {
		if (this.hasValue(source) && this.hasValue(updated)) {
			for (const key of Object.keys(updated)) {
				if (!Array.isArray(source[key])) {
					source[key] = updated[key];
				}
			}
		}
	}

	public getMatErrors(formName: string = '_form'): string[] {
		let errors: string[] = [];
		document.getElementsByName(formName).forEach((c) => {
			c.querySelectorAll('mat-error').forEach((i) => {
				errors.push(i.innerHTML);
			});
		});
		return errors;
	}

	public splitCamelCase(word): string {
		var output,
			i,
			l,
			capRe = /[A-Z]/;
		if (typeof word !== 'string') {
			throw new Error('The "word" parameter must be a string.');
		}
		output = [];
		for (i = 0, l = word.length; i < l; i += 1) {
			if (i === 0) {
				output.push(word[i].toUpperCase());
			} else {
				if (i > 0 && capRe.test(word[i])) {
					output.push(' ');
				}
				output.push(word[i]);
			}
		}
		return output.join('');
	}

	// @HostListener allows us to also guard against browser refresh, close, etc.
	@HostListener('window:beforeunload')
	public canDeactivate(): Observable<boolean> | boolean {
		// insert logic to check if there are pending changes here;
		// returning true will navigate without confirmation
		// returning false will show a confirm dialog before navigating away
		return true;
	}

	public setupDPConfig(): DatePickerConfig {
		return new DatePickerConfig();
	}
}

class DatePickerConfig {
	containerClass: string = 'theme-angle';
	isAnimated: boolean = true;
	dateInputFormat: string = 'DD/MM/YYYY';
	autoclose: boolean = true;
}
