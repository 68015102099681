/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MediaApiConfiguration, MediaApiConfigurationParams } from './media-api-configuration';

import { MediaArticleService } from './services/media-article.service';
import { MediaCategoryService } from './services/media-category.service';
import { MediaClientService } from './services/media-client.service';
import { MediaDashboardService } from './services/media-dashboard.service';
import { MediaMasterDataService } from './services/media-master-data.service';
import { MediaPublisherService } from './services/media-publisher.service';
import { MediaSearchService } from './services/media-search.service';
import { MediaUserService } from './services/media-user.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    MediaArticleService,
    MediaCategoryService,
    MediaClientService,
    MediaDashboardService,
    MediaMasterDataService,
    MediaPublisherService,
    MediaSearchService,
    MediaUserService,
    MediaApiConfiguration
  ],
})
export class MediaModule {
  static forRoot(params: MediaApiConfigurationParams): ModuleWithProviders<MediaModule> {
    return {
      ngModule: MediaModule,
      providers: [
        {
          provide: MediaApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: MediaModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('MediaModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
