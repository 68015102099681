import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'input-popup',
	template: `
		<div class="custom-dialog-container">
			<div class="md-card">
				<div class="md-card-heading bg-primary">
					<div class="md-card-title">
						{{ data.title }}
					</div>
				</div>
				<div class="card-body">
					<div class="form-group" show-errors>
						<div class="col-sm-12"></div>
					</div>
				</div>
				<div class="card-footer">
					<p class="text-center">
						<button class="btn btn-primary" (click)="proceed()">
							<i class="fa icon-like"></i>
							<span class="d-none d-sm-inline">
								{{ data.buttonProceedText }}
							</span>
						</button>
					</p>
				</div>
			</div>
		</div>
	`
})
export class InputPopupComponent {
	inputData: string = '';

	constructor(
		public dialogRef: MatDialogRef<InputPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public data: InputPopupData
	) {
		setTimeout(() => {
			dialogRef.close();
		}, 60000);
	}

	proceed(): void {
		this.dialogRef.close(this.inputData);
	}

	onInputChange({ target }): void {
		this.inputData = target.value;
	}
}

export interface InputPopupData {
	title: string;
	placeholderText: string;
	buttonText: string;
	buttonProceedText: string;
}
