import { Injectable } from '@angular/core';
import { TranslatorService } from '../translate/translator.service';

@Injectable({
	providedIn: 'root'
})
export class LanguagesDisplayService {
	public language = 'English';

	constructor(private translatorService: TranslatorService) {}

	public initialize(): void {
		this.translatorService.language$.subscribe((language) => {
			this.setLanguage(language);
		});
	}

	public setLanguage(selectedLanguage: string): void {
		const languagesBySelected = this.translatorService.availableLanguages;

		if (!languagesBySelected || languagesBySelected.length == 0) return;

		const ourLanguage = languagesBySelected.find(
			(element) => element.code == selectedLanguage
		);

		this.language = ourLanguage.nativeName;
	}
}
