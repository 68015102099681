/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrganizationTypeModel } from '../models/organization-type-model';
import { OrganizationTypeModelSearchResultModel } from '../models/organization-type-model-search-result-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MDIOrganizationTypeService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation organizationTypeSearchOrganizationTypePost
   */
  static readonly OrganizationTypeSearchOrganizationTypePostPath = '/OrganizationType/SearchOrganizationType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationTypeSearchOrganizationTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationTypeSearchOrganizationTypePost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<OrganizationTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIOrganizationTypeService.OrganizationTypeSearchOrganizationTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<OrganizationTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationTypeSearchOrganizationTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationTypeSearchOrganizationTypePost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<OrganizationTypeModelSearchResultModel> {

    return this.organizationTypeSearchOrganizationTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<OrganizationTypeModelSearchResultModel>) => r.body as OrganizationTypeModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationTypeSearchOrganizationTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationTypeSearchOrganizationTypePost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<OrganizationTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIOrganizationTypeService.OrganizationTypeSearchOrganizationTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<OrganizationTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationTypeSearchOrganizationTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationTypeSearchOrganizationTypePost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<OrganizationTypeModelSearchResultModel> {

    return this.organizationTypeSearchOrganizationTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<OrganizationTypeModelSearchResultModel>) => r.body as OrganizationTypeModelSearchResultModel)
    );
  }

  /**
   * Path part for operation organizationTypeIdGet
   */
  static readonly OrganizationTypeIdGetPath = '/OrganizationType/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationTypeIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationTypeIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<OrganizationTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIOrganizationTypeService.OrganizationTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<OrganizationTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationTypeIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationTypeIdGet$Plain(params: {
    id: string;
  }): Observable<OrganizationTypeModel> {

    return this.organizationTypeIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<OrganizationTypeModel>) => r.body as OrganizationTypeModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationTypeIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationTypeIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<OrganizationTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIOrganizationTypeService.OrganizationTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<OrganizationTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationTypeIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationTypeIdGet$Json(params: {
    id: string;
  }): Observable<OrganizationTypeModel> {

    return this.organizationTypeIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<OrganizationTypeModel>) => r.body as OrganizationTypeModel)
    );
  }

  /**
   * Path part for operation organizationTypePost
   */
  static readonly OrganizationTypePostPath = '/OrganizationType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationTypePost$Plain$Response(params?: {
    body?: OrganizationTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIOrganizationTypeService.OrganizationTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationTypePost$Plain(params?: {
    body?: OrganizationTypeModel
  }): Observable<string> {

    return this.organizationTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationTypePost$Json$Response(params?: {
    body?: OrganizationTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIOrganizationTypeService.OrganizationTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationTypePost$Json(params?: {
    body?: OrganizationTypeModel
  }): Observable<string> {

    return this.organizationTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
