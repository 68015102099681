/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HealthFindrBaseService } from '../health-findr-base-service';
import { HealthFindrApiConfiguration } from '../health-findr-api-configuration';
import { HealthFindrStrictHttpResponse } from '../health-findr-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BroadcastMessageRequestModel } from '../models/broadcast-message-request-model';
import { CommunicationBlobRequestModel } from '../models/communication-blob-request-model';
import { PromotionalLinkModel } from '../models/promotional-link-model';
import { PromotionalLinkSpeciality } from '../models/promotional-link-speciality';
import { ServiceScheduleModel } from '../models/service-schedule-model';
import { SpecialityValidityResultModel } from '../models/speciality-validity-result-model';

@Injectable({
  providedIn: 'root',
})
export class HealthFindrAnonymousService extends HealthFindrBaseService {
  constructor(
    config: HealthFindrApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation anonymousPromotionalLinkIdGet
   */
  static readonly AnonymousPromotionalLinkIdGetPath = '/Anonymous/PromotionalLink/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousPromotionalLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousPromotionalLinkIdGet$Response(params: {
    id: string;
  }): Observable<HealthFindrStrictHttpResponse<PromotionalLinkModel>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrAnonymousService.AnonymousPromotionalLinkIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<PromotionalLinkModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `anonymousPromotionalLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousPromotionalLinkIdGet(params: {
    id: string;
  }): Observable<PromotionalLinkModel> {

    return this.anonymousPromotionalLinkIdGet$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<PromotionalLinkModel>) => r.body as PromotionalLinkModel)
    );
  }

  /**
   * Path part for operation anonymousSpecialityPromotionalLinkIdGet
   */
  static readonly AnonymousSpecialityPromotionalLinkIdGetPath = '/Anonymous/SpecialityPromotionalLink/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousSpecialityPromotionalLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousSpecialityPromotionalLinkIdGet$Response(params: {
    id: string;
  }): Observable<HealthFindrStrictHttpResponse<PromotionalLinkSpeciality>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrAnonymousService.AnonymousSpecialityPromotionalLinkIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<PromotionalLinkSpeciality>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `anonymousSpecialityPromotionalLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousSpecialityPromotionalLinkIdGet(params: {
    id: string;
  }): Observable<PromotionalLinkSpeciality> {

    return this.anonymousSpecialityPromotionalLinkIdGet$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<PromotionalLinkSpeciality>) => r.body as PromotionalLinkSpeciality)
    );
  }

  /**
   * Path part for operation anonymousBroadcastMessagesPost
   */
  static readonly AnonymousBroadcastMessagesPostPath = '/Anonymous/BroadcastMessages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousBroadcastMessagesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  anonymousBroadcastMessagesPost$Response(params?: {
    body?: BroadcastMessageRequestModel
  }): Observable<HealthFindrStrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrAnonymousService.AnonymousBroadcastMessagesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `anonymousBroadcastMessagesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  anonymousBroadcastMessagesPost(params?: {
    body?: BroadcastMessageRequestModel
  }): Observable<Array<string>> {

    return this.anonymousBroadcastMessagesPost$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation anonymousFhirIdSpecialityValidityGet
   */
  static readonly AnonymousFhirIdSpecialityValidityGetPath = '/Anonymous/{fhirId}/SpecialityValidity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousFhirIdSpecialityValidityGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousFhirIdSpecialityValidityGet$Response(params: {
    fhirId: string;
  }): Observable<HealthFindrStrictHttpResponse<Array<SpecialityValidityResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrAnonymousService.AnonymousFhirIdSpecialityValidityGetPath, 'get');
    if (params) {
      rb.path('fhirId', params.fhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<Array<SpecialityValidityResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `anonymousFhirIdSpecialityValidityGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousFhirIdSpecialityValidityGet(params: {
    fhirId: string;
  }): Observable<Array<SpecialityValidityResultModel>> {

    return this.anonymousFhirIdSpecialityValidityGet$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<SpecialityValidityResultModel>>) => r.body as Array<SpecialityValidityResultModel>)
    );
  }

  /**
   * Path part for operation anonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet
   */
  static readonly AnonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGetPath = '/Anonymous/ServiceHoursOfOperation/{healthCareServiceFhirId}/{locationFhirId}/{specialityFhirId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet$Response(params: {
    healthCareServiceFhirId: string;
    locationFhirId: string;
    specialityFhirId: string;
  }): Observable<HealthFindrStrictHttpResponse<ServiceScheduleModel>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrAnonymousService.AnonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGetPath, 'get');
    if (params) {
      rb.path('healthCareServiceFhirId', params.healthCareServiceFhirId, {});
      rb.path('locationFhirId', params.locationFhirId, {});
      rb.path('specialityFhirId', params.specialityFhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<ServiceScheduleModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `anonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet(params: {
    healthCareServiceFhirId: string;
    locationFhirId: string;
    specialityFhirId: string;
  }): Observable<ServiceScheduleModel> {

    return this.anonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<ServiceScheduleModel>) => r.body as ServiceScheduleModel)
    );
  }

  /**
   * Path part for operation anonymousCommunicationBlobContentIdPost
   */
  static readonly AnonymousCommunicationBlobContentIdPostPath = '/Anonymous/CommunicationBlobContentId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousCommunicationBlobContentIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  anonymousCommunicationBlobContentIdPost$Response(params?: {
    body?: CommunicationBlobRequestModel
  }): Observable<HealthFindrStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, HealthFindrAnonymousService.AnonymousCommunicationBlobContentIdPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as HealthFindrStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `anonymousCommunicationBlobContentIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  anonymousCommunicationBlobContentIdPost(params?: {
    body?: CommunicationBlobRequestModel
  }): Observable<string> {

    return this.anonymousCommunicationBlobContentIdPost$Response(params).pipe(
      map((r: HealthFindrStrictHttpResponse<string>) => r.body as string)
    );
  }

}
