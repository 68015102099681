/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MPIBaseService } from '../mpi-base-service';
import { MPIApiConfiguration } from '../mpi-api-configuration';
import { MPIStrictHttpResponse } from '../mpi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PatientModel } from '../models/patient-model';
import { PhotoModel } from '../models/photo-model';

@Injectable({
  providedIn: 'root',
})
export class MPIConsumerService extends MPIBaseService {
  constructor(
    config: MPIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation consumerPersonIdPhotoGet
   */
  static readonly ConsumerPersonIdPhotoGetPath = '/Consumer/{personId}/Photo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdPhotoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdPhotoGet$Response(params: {
    personId: string;
  }): Observable<MPIStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MPIConsumerService.ConsumerPersonIdPhotoGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as MPIStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdPhotoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdPhotoGet(params: {
    personId: string;
  }): Observable<void> {

    return this.consumerPersonIdPhotoGet$Response(params).pipe(
      map((r: MPIStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation consumerPersonIdThumbnailGet
   */
  static readonly ConsumerPersonIdThumbnailGetPath = '/Consumer/{personId}/Thumbnail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdThumbnailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdThumbnailGet$Response(params: {
    personId: string;
  }): Observable<MPIStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MPIConsumerService.ConsumerPersonIdThumbnailGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as MPIStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdThumbnailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdThumbnailGet(params: {
    personId: string;
  }): Observable<void> {

    return this.consumerPersonIdThumbnailGet$Response(params).pipe(
      map((r: MPIStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation consumerPersonIdMyPhotoGet
   */
  static readonly ConsumerPersonIdMyPhotoGetPath = '/Consumer/{personId}/MyPhoto';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdMyPhotoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdMyPhotoGet$Response(params: {
    personId: string;
  }): Observable<MPIStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MPIConsumerService.ConsumerPersonIdMyPhotoGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as MPIStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdMyPhotoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdMyPhotoGet(params: {
    personId: string;
  }): Observable<void> {

    return this.consumerPersonIdMyPhotoGet$Response(params).pipe(
      map((r: MPIStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation consumerPersonIdMyThumbnailGet
   */
  static readonly ConsumerPersonIdMyThumbnailGetPath = '/Consumer/{personId}/MyThumbnail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdMyThumbnailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdMyThumbnailGet$Response(params: {
    personId: string;
  }): Observable<MPIStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MPIConsumerService.ConsumerPersonIdMyThumbnailGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as MPIStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdMyThumbnailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdMyThumbnailGet(params: {
    personId: string;
  }): Observable<void> {

    return this.consumerPersonIdMyThumbnailGet$Response(params).pipe(
      map((r: MPIStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation consumerGet
   */
  static readonly ConsumerGetPath = '/Consumer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGet$Plain$Response(params?: {
    summary?: boolean;
  }): Observable<MPIStrictHttpResponse<PatientModel>> {

    const rb = new RequestBuilder(this.rootUrl, MPIConsumerService.ConsumerGetPath, 'get');
    if (params) {
      rb.query('summary', params.summary, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MPIStrictHttpResponse<PatientModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGet$Plain(params?: {
    summary?: boolean;
  }): Observable<PatientModel> {

    return this.consumerGet$Plain$Response(params).pipe(
      map((r: MPIStrictHttpResponse<PatientModel>) => r.body as PatientModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGet$Json$Response(params?: {
    summary?: boolean;
  }): Observable<MPIStrictHttpResponse<PatientModel>> {

    const rb = new RequestBuilder(this.rootUrl, MPIConsumerService.ConsumerGetPath, 'get');
    if (params) {
      rb.query('summary', params.summary, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MPIStrictHttpResponse<PatientModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGet$Json(params?: {
    summary?: boolean;
  }): Observable<PatientModel> {

    return this.consumerGet$Json$Response(params).pipe(
      map((r: MPIStrictHttpResponse<PatientModel>) => r.body as PatientModel)
    );
  }

  /**
   * Path part for operation consumerPost
   */
  static readonly ConsumerPostPath = '/Consumer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPost$Plain$Response(params?: {
    body?: PatientModel
  }): Observable<MPIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MPIConsumerService.ConsumerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MPIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPost$Plain(params?: {
    body?: PatientModel
  }): Observable<string> {

    return this.consumerPost$Plain$Response(params).pipe(
      map((r: MPIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPost$Json$Response(params?: {
    body?: PatientModel
  }): Observable<MPIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MPIConsumerService.ConsumerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MPIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPost$Json(params?: {
    body?: PatientModel
  }): Observable<string> {

    return this.consumerPost$Json$Response(params).pipe(
      map((r: MPIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation consumerPhotoPost
   */
  static readonly ConsumerPhotoPostPath = '/Consumer/Photo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPhotoPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPhotoPost$Plain$Response(params?: {
    body?: PhotoModel
  }): Observable<MPIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MPIConsumerService.ConsumerPhotoPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MPIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPhotoPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPhotoPost$Plain(params?: {
    body?: PhotoModel
  }): Observable<string> {

    return this.consumerPhotoPost$Plain$Response(params).pipe(
      map((r: MPIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPhotoPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPhotoPost$Json$Response(params?: {
    body?: PhotoModel
  }): Observable<MPIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MPIConsumerService.ConsumerPhotoPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MPIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `consumerPhotoPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPhotoPost$Json(params?: {
    body?: PhotoModel
  }): Observable<string> {

    return this.consumerPhotoPost$Json$Response(params).pipe(
      map((r: MPIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
