/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrganizationRoleModel } from '../models/organization-role-model';
import { OrganizationRoleModelSearchResultModel } from '../models/organization-role-model-search-result-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MDIOrganizationRoleService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation organizationRoleSearchOrganizationRolePost
   */
  static readonly OrganizationRoleSearchOrganizationRolePostPath = '/OrganizationRole/SearchOrganizationRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRoleSearchOrganizationRolePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationRoleSearchOrganizationRolePost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<OrganizationRoleModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIOrganizationRoleService.OrganizationRoleSearchOrganizationRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<OrganizationRoleModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRoleSearchOrganizationRolePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationRoleSearchOrganizationRolePost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<OrganizationRoleModelSearchResultModel> {

    return this.organizationRoleSearchOrganizationRolePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<OrganizationRoleModelSearchResultModel>) => r.body as OrganizationRoleModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRoleSearchOrganizationRolePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationRoleSearchOrganizationRolePost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<OrganizationRoleModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIOrganizationRoleService.OrganizationRoleSearchOrganizationRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<OrganizationRoleModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRoleSearchOrganizationRolePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationRoleSearchOrganizationRolePost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<OrganizationRoleModelSearchResultModel> {

    return this.organizationRoleSearchOrganizationRolePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<OrganizationRoleModelSearchResultModel>) => r.body as OrganizationRoleModelSearchResultModel)
    );
  }

  /**
   * Path part for operation organizationRoleIdGet
   */
  static readonly OrganizationRoleIdGetPath = '/OrganizationRole/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRoleIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRoleIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<OrganizationRoleModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIOrganizationRoleService.OrganizationRoleIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<OrganizationRoleModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRoleIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRoleIdGet$Plain(params: {
    id: string;
  }): Observable<OrganizationRoleModel> {

    return this.organizationRoleIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<OrganizationRoleModel>) => r.body as OrganizationRoleModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRoleIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRoleIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<OrganizationRoleModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIOrganizationRoleService.OrganizationRoleIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<OrganizationRoleModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRoleIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRoleIdGet$Json(params: {
    id: string;
  }): Observable<OrganizationRoleModel> {

    return this.organizationRoleIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<OrganizationRoleModel>) => r.body as OrganizationRoleModel)
    );
  }

  /**
   * Path part for operation organizationRolePost
   */
  static readonly OrganizationRolePostPath = '/OrganizationRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRolePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationRolePost$Plain$Response(params?: {
    body?: OrganizationRoleModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIOrganizationRoleService.OrganizationRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRolePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationRolePost$Plain(params?: {
    body?: OrganizationRoleModel
  }): Observable<string> {

    return this.organizationRolePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRolePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationRolePost$Json$Response(params?: {
    body?: OrganizationRoleModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIOrganizationRoleService.OrganizationRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRolePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationRolePost$Json(params?: {
    body?: OrganizationRoleModel
  }): Observable<string> {

    return this.organizationRolePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
