import {
	ChangeDetectorRef,
	Injectable,
	Pipe,
	PipeTransform,
	SecurityContext
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ReplaceWith, replaceWithList } from './models/replace-with.model';

@Injectable()
@Pipe({
	name: 'translate',
	pure: false
})
export class TranslateToHtmlPipe
	extends TranslatePipe
	implements PipeTransform
{
	constructor(
		private sanitized: DomSanitizer,
		translate: TranslateService,
		_ref: ChangeDetectorRef
	) {
		super(translate, _ref);
	}

	transform(query: string, ...args: any[]): any {
		let value = super.transform(query, ...args);

		if (value) {
			value = this.replaceWithReplaceList(value, replaceWithList);
			value = this.sanitized.sanitize(SecurityContext.NONE, value);
		}

		return value;
	}

	private replaceWithReplaceList(
		value: string,
		replaceWithList: ReplaceWith[]
	) {
		for (let index = 0; index < replaceWithList.length; index++) {
			const replaceWith = replaceWithList[index];

			if (replaceWith.replaceAll) {
				value = this.replaceAll(
					value,
					replaceWith.lookup,
					replaceWith.value
				);
			} else {
				value = value.replace(replaceWith.lookup, replaceWith.value);
			}
		}

		return value;
	}

	private replaceAll(original, replace, replaceWith) {
		// See http://stackoverflow.com/a/3561711/556609
		const esc = replace.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
		const reg = new RegExp(esc, 'ig');
		return original.replace(reg, replaceWith);
	}
}
