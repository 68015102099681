import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { get } from 'scriptjs';

if (environment.production) {
	enableProdMode();
}

get(
	'https://maps.googleapis.com/maps/api/js?&libraries=places&key=' +
		environment.googleApiKey,
	() => {
		//Google Maps library has been loaded...
		platformBrowserDynamic()
			.bootstrapModule(AppModule)
			.catch((err) => console.log(err));
	}
);

// Call the element loader after the platform has been bootstrapped
void defineCustomElements(window);
