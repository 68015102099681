import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslatorService } from 'src/app/services/translate/translator.service';
import { I18nSettings } from '../models/i18n-settings.model';

@Pipe({
	name: 'i18nArrayTranslate$'
})
export class i18nArrayTranslatePipe implements PipeTransform {
	constructor(private translatorService: TranslatorService) {}

	transform(values: any[], forceLanguage: string = null): Observable<string> {
		if (!values || values.length === 0) {
			return of('N/A');
		}

		const settings = this.getTranslationSettings(values);
		if (!settings) {
			return of('N/A');
		}

		if (forceLanguage) {
			return of(this.getTranslation(values, settings, forceLanguage));
		}

		return this.translatorService.languageMapped$.pipe(
			map((language) => {
				return this.getTranslation(values, settings, language);
			})
		);
	}

	private getTranslation(
		values: any[],
		settings: I18nSettings,
		toLanguage: string
	): string {
		let i18nLanguage = values.find(
			(value) => value[settings.languageCodePropertyName] == toLanguage
		);

		if (!i18nLanguage) {
			i18nLanguage = values.find(
				(value) =>
					value[settings.languageCodePropertyName] ==
					this.translatorService.defaultLanguage
			);
		}

		if (!i18nLanguage) {
			return 'N/A';
		}

		return i18nLanguage[settings.translationPropertyName];
	}

	private getTranslationSettings(values: any[]): I18nSettings {
		const value = values[0];

		if ('langCode' in value && 'translation' in value) {
			return {
				languageCodePropertyName: 'langCode',
				translationPropertyName: 'translation'
			};
		}

		if ('lang' in value && 'note' in value) {
			return {
				languageCodePropertyName: 'lang',
				translationPropertyName: 'note'
			};
		}

		if ('language' in value && 'name' in value) {
			return {
				languageCodePropertyName: 'language',
				translationPropertyName: 'name'
			};
		}

		if ('language' in value && 'description' in value) {
			return {
				languageCodePropertyName: 'language',
				translationPropertyName: 'description'
			};
		}

		return null;
	}
}
