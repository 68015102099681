import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonRow } from '@ionic/angular';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TranslatorService } from 'src/app/services/translate/translator.service';
import { SeeAllPage } from 'src/app/shared/pages/see-all/see-all.page';

import ContentMock from '../../../utilities/dummy-data/content.json';

@Component({
	selector: 'more-like-this',
	templateUrl: './more-like-this.component.html',
	styleUrls: ['./more-like-this.component.scss']
})
export class MoreLikeThisComponent implements OnInit {
	@Input() content: any;
	@Input() moreLikeThis: any[] = [];

	@ViewChild('moreHolder')
	public moreHolder: IonRow;

	constructor(
		public translatorService: TranslatorService,
		public modalService: ModalService
	) {}

	public ngOnInit(): void {
		this.moreLikeThis = ContentMock;
	}

	public viewContent(contentId: string): void {
		void this.modalService.contentViewByContentModal(
			contentId,
			this.moreLikeThis.map((_) => _.Id)
		);
	}

	async presentModal(): Promise<void> {
		await this.modalService.viewDrawerModalAsync(
			{
				allContent: this.moreLikeThis,
				type: 'view'
			},
			SeeAllPage,
			'drawer-modal',
			'SeeAllPage'
		);
	}
}
