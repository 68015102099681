import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { Timer } from 'src/app/utilities';
import { SecureStorageKey } from '../storage/models/secure-storage-key.enum';
import { SecureStorageService } from '../storage/secure-storage.service';

const delay = 300000;

@Injectable({
	providedIn: 'root'
})
export class RefreshService {
	private refreshDay: moment.Moment;
	private timer: Timer = new Timer();

	private refresh: Subject<void> = new Subject<void>();
	public refresh$: Observable<void> = this.refresh.asObservable();

	constructor(
		private secureStorageService: SecureStorageService,
		private platform: Platform
	) {}

	public async initializeAsync(): Promise<void> {
		await this.refreshTimerInitializeAsync();
	}

	private async refreshTimerInitializeAsync() {
		await this.loadRefreshDayAsync();

		this.timer.delay(delay).subscribe(() => {
			void this.updateRefreshDayAsync();
		});

		this.platform.resume.subscribe(() => {
			void this.updateRefreshDayAsync();
		});
	}

	private async loadRefreshDayAsync() {
		const refreshDay = await this.secureStorageService.get(
			SecureStorageKey.RefreshDay
		);

		this.refreshDay = moment(refreshDay);

		if (!this.refreshDay.isValid()) {
			this.refreshDay = moment().add(1, 'days').startOf('day');
		}
	}

	private async updateRefreshDayAsync(): Promise<void> {
		if (moment().isAfter(this.refreshDay)) {
			this.refreshDay = moment().add(1, 'days').startOf('day');
			await this.secureStorageService
				.set(SecureStorageKey.RefreshDay, this.refreshDay.toISOString())
				.then(() => this.refresh.next());
		}
	}
}
