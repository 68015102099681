/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PractitionerBaseService } from '../practitioner-base-service';
import { PractitionerApiConfiguration } from '../practitioner-api-configuration';
import { PractitionerStrictHttpResponse } from '../practitioner-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrganizationLocationModel } from '../models/organization-location-model';
import { PhotoModel } from '../models/photo-model';
import { PractitionerAdminModel } from '../models/practitioner-admin-model';
import { PractitionerRoleModel } from '../models/practitioner-role-model';
import { PractitionerSearchModel } from '../models/practitioner-search-model';
import { PractitionerSearchResultModel } from '../models/practitioner-search-result-model';

@Injectable({
  providedIn: 'root',
})
export class PRACAdminService extends PractitionerBaseService {
  constructor(
    config: PractitionerApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation adminSearchPost
   */
  static readonly AdminSearchPostPath = '/Admin/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSearchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchPost$Plain$Response(params?: {
    body?: PractitionerSearchModel
  }): Observable<PractitionerStrictHttpResponse<Array<PractitionerSearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<Array<PractitionerSearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminSearchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchPost$Plain(params?: {
    body?: PractitionerSearchModel
  }): Observable<Array<PractitionerSearchResultModel>> {

    return this.adminSearchPost$Plain$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<Array<PractitionerSearchResultModel>>) => r.body as Array<PractitionerSearchResultModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSearchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchPost$Json$Response(params?: {
    body?: PractitionerSearchModel
  }): Observable<PractitionerStrictHttpResponse<Array<PractitionerSearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<Array<PractitionerSearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminSearchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchPost$Json(params?: {
    body?: PractitionerSearchModel
  }): Observable<Array<PractitionerSearchResultModel>> {

    return this.adminSearchPost$Json$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<Array<PractitionerSearchResultModel>>) => r.body as Array<PractitionerSearchResultModel>)
    );
  }

  /**
   * Path part for operation adminPersonIdGet
   */
  static readonly AdminPersonIdGetPath = '/Admin/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGet$Plain$Response(params: {
    personId: string;
  }): Observable<PractitionerStrictHttpResponse<PractitionerAdminModel>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminPersonIdGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<PractitionerAdminModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGet$Plain(params: {
    personId: string;
  }): Observable<PractitionerAdminModel> {

    return this.adminPersonIdGet$Plain$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<PractitionerAdminModel>) => r.body as PractitionerAdminModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGet$Json$Response(params: {
    personId: string;
  }): Observable<PractitionerStrictHttpResponse<PractitionerAdminModel>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminPersonIdGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<PractitionerAdminModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGet$Json(params: {
    personId: string;
  }): Observable<PractitionerAdminModel> {

    return this.adminPersonIdGet$Json$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<PractitionerAdminModel>) => r.body as PractitionerAdminModel)
    );
  }

  /**
   * Path part for operation adminPersonIdDelete
   */
  static readonly AdminPersonIdDeletePath = '/Admin/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdDelete$Response(params: {
    personId: string;
  }): Observable<PractitionerStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminPersonIdDeletePath, 'delete');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PractitionerStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdDelete(params: {
    personId: string;
  }): Observable<void> {

    return this.adminPersonIdDelete$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation adminPost
   */
  static readonly AdminPostPath = '/Admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost$Plain$Response(params?: {
    body?: PractitionerAdminModel
  }): Observable<PractitionerStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost$Plain(params?: {
    body?: PractitionerAdminModel
  }): Observable<string> {

    return this.adminPost$Plain$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost$Json$Response(params?: {
    body?: PractitionerAdminModel
  }): Observable<PractitionerStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost$Json(params?: {
    body?: PractitionerAdminModel
  }): Observable<string> {

    return this.adminPost$Json$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation adminPersonIdGetRolesGet
   */
  static readonly AdminPersonIdGetRolesGetPath = '/Admin/{personId}/GetRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdGetRolesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGetRolesGet$Plain$Response(params: {
    personId: string;
  }): Observable<PractitionerStrictHttpResponse<Array<PractitionerRoleModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminPersonIdGetRolesGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<Array<PractitionerRoleModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdGetRolesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGetRolesGet$Plain(params: {
    personId: string;
  }): Observable<Array<PractitionerRoleModel>> {

    return this.adminPersonIdGetRolesGet$Plain$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<Array<PractitionerRoleModel>>) => r.body as Array<PractitionerRoleModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdGetRolesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGetRolesGet$Json$Response(params: {
    personId: string;
  }): Observable<PractitionerStrictHttpResponse<Array<PractitionerRoleModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminPersonIdGetRolesGetPath, 'get');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<Array<PractitionerRoleModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdGetRolesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGetRolesGet$Json(params: {
    personId: string;
  }): Observable<Array<PractitionerRoleModel>> {

    return this.adminPersonIdGetRolesGet$Json$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<Array<PractitionerRoleModel>>) => r.body as Array<PractitionerRoleModel>)
    );
  }

  /**
   * Path part for operation adminPersonIdSaveRolesPost
   */
  static readonly AdminPersonIdSaveRolesPostPath = '/Admin/{personId}/SaveRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdSaveRolesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdSaveRolesPost$Plain$Response(params: {
    personId: string;
    body?: PractitionerRoleModel
  }): Observable<PractitionerStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminPersonIdSaveRolesPostPath, 'post');
    if (params) {
      rb.path('personId', params.personId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdSaveRolesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdSaveRolesPost$Plain(params: {
    personId: string;
    body?: PractitionerRoleModel
  }): Observable<string> {

    return this.adminPersonIdSaveRolesPost$Plain$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdSaveRolesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdSaveRolesPost$Json$Response(params: {
    personId: string;
    body?: PractitionerRoleModel
  }): Observable<PractitionerStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminPersonIdSaveRolesPostPath, 'post');
    if (params) {
      rb.path('personId', params.personId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdSaveRolesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdSaveRolesPost$Json(params: {
    personId: string;
    body?: PractitionerRoleModel
  }): Observable<string> {

    return this.adminPersonIdSaveRolesPost$Json$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation adminPersonIdDeleteRoleRoleIdDelete
   */
  static readonly AdminPersonIdDeleteRoleRoleIdDeletePath = '/Admin/{personId}/DeleteRole/{roleId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdDeleteRoleRoleIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdDeleteRoleRoleIdDelete$Response(params: {
    personId: string;
    roleId: string;
  }): Observable<PractitionerStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminPersonIdDeleteRoleRoleIdDeletePath, 'delete');
    if (params) {
      rb.path('personId', params.personId, {});
      rb.path('roleId', params.roleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PractitionerStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdDeleteRoleRoleIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdDeleteRoleRoleIdDelete(params: {
    personId: string;
    roleId: string;
  }): Observable<void> {

    return this.adminPersonIdDeleteRoleRoleIdDelete$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation adminOrganizationLocationsOrganizationFhirIdGet
   */
  static readonly AdminOrganizationLocationsOrganizationFhirIdGetPath = '/Admin/OrganizationLocations/{organizationFhirId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminOrganizationLocationsOrganizationFhirIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminOrganizationLocationsOrganizationFhirIdGet$Plain$Response(params: {
    organizationFhirId: string;
  }): Observable<PractitionerStrictHttpResponse<Array<OrganizationLocationModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminOrganizationLocationsOrganizationFhirIdGetPath, 'get');
    if (params) {
      rb.path('organizationFhirId', params.organizationFhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<Array<OrganizationLocationModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminOrganizationLocationsOrganizationFhirIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminOrganizationLocationsOrganizationFhirIdGet$Plain(params: {
    organizationFhirId: string;
  }): Observable<Array<OrganizationLocationModel>> {

    return this.adminOrganizationLocationsOrganizationFhirIdGet$Plain$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<Array<OrganizationLocationModel>>) => r.body as Array<OrganizationLocationModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminOrganizationLocationsOrganizationFhirIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminOrganizationLocationsOrganizationFhirIdGet$Json$Response(params: {
    organizationFhirId: string;
  }): Observable<PractitionerStrictHttpResponse<Array<OrganizationLocationModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminOrganizationLocationsOrganizationFhirIdGetPath, 'get');
    if (params) {
      rb.path('organizationFhirId', params.organizationFhirId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<Array<OrganizationLocationModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminOrganizationLocationsOrganizationFhirIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminOrganizationLocationsOrganizationFhirIdGet$Json(params: {
    organizationFhirId: string;
  }): Observable<Array<OrganizationLocationModel>> {

    return this.adminOrganizationLocationsOrganizationFhirIdGet$Json$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<Array<OrganizationLocationModel>>) => r.body as Array<OrganizationLocationModel>)
    );
  }

  /**
   * Path part for operation adminPersonIdPhotoPost
   */
  static readonly AdminPersonIdPhotoPostPath = '/Admin/{personId}/Photo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdPhotoPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdPhotoPost$Plain$Response(params: {
    personId: string;
    body?: PhotoModel
  }): Observable<PractitionerStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminPersonIdPhotoPostPath, 'post');
    if (params) {
      rb.path('personId', params.personId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdPhotoPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdPhotoPost$Plain(params: {
    personId: string;
    body?: PhotoModel
  }): Observable<string> {

    return this.adminPersonIdPhotoPost$Plain$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdPhotoPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdPhotoPost$Json$Response(params: {
    personId: string;
    body?: PhotoModel
  }): Observable<PractitionerStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PRACAdminService.AdminPersonIdPhotoPostPath, 'post');
    if (params) {
      rb.path('personId', params.personId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PractitionerStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdPhotoPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdPhotoPost$Json(params: {
    personId: string;
    body?: PhotoModel
  }): Observable<string> {

    return this.adminPersonIdPhotoPost$Json$Response(params).pipe(
      map((r: PractitionerStrictHttpResponse<string>) => r.body as string)
    );
  }

}
