import { Injectable } from '@angular/core';
import { Semaphore } from 'src/app/utilities';
import { EventService } from '../events/event.service';
import { AuthService } from '../auth-service/auth.service';
@Injectable({
	providedIn: 'root'
})
export class TokenService {
	private lock = new Semaphore(1);
	private token: string = null;
	private expiry: Date = null;
	private hasClientToken = false;

	constructor(
		private eventService: EventService,
		private authService: AuthService
	) {
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		this.eventService.clientAccessToken.subscribe((token) => {
			this.token = token.token;
			this.expiry = new Date(0);
			this.expiry.setUTCSeconds(token.expiry);
			this.hasClientToken = true;
		});
		this.eventService.removeAccessToken.subscribe((clearToken) => {
			if (clearToken) {
				this.resetToken();
			}
		});
	}

	public async getTokenAsync(): Promise<string> {
		this.hasClientToken = await this.authService.hasUserLoggedInAsync();
		// If we have a token that is not expired, send it.
		if (this.expiry && this.expiry > new Date()) return this.token;

		// only allow one call at a time through to potensially call token api
		await this.lock.callFunction(async (_) => {
			// If a previous locked call is allowed through and a new no expired token is there, return.
			if (this.expiry && this.expiry > new Date()) return;
			//If we have a client access token (not app access token), send it
			if (this.hasClientToken) {
				const refreshToken = await this.authService.refreshToken();
				if (refreshToken) {
					this.token = refreshToken.access_token;
					this.expiry = new Date(0);
					this.expiry.setUTCSeconds(refreshToken.expires_on);
				}
				return;
			}
		}, []);
		return this.token;
	}

	public resetToken(): void {
		this.expiry = null;
		this.token = null;
	}
}
