import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'cofirm-popup',
  template: `
    <div class="custom-dialog-container">
      <div class="md-card">
        <div class="md-card-heading bg-warning">
          <div class="md-card-title">
            {{ data.title }}
          </div>
        </div>
        <div class="card-body">
          <p>{{ data.message }}</p>
        </div>
        <div class="card-footer">
          <p class="text-center">
            <button class="btn btn-primary" (click)="proceed()">
              <i class="fa icon-like"></i>
              <span class="d-none d-sm-inline"> {{ data.buttonProceedText }}</span>
            </button>
            <button class="btn btn-secondary" (click)="cancel()">
              <i class="fa icon-ban"></i>
              <span class="d-none d-sm-inline"> {{ data.buttonCloseText }}</span>
            </button>
          </p>
        </div>
      </div>
    </div>
  `,
})
export class ConfirmPopupComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: InfoPopupData) {
    setTimeout(() => {
      dialogRef.close();
    }, 60000);
  }

  proceed(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}

export interface InfoPopupData {
  title: string;
  message: string;
  buttonCloseText: string;
  buttonProceedText: string;
}
