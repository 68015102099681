/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MediaBaseService } from '../media-base-service';
import { MediaApiConfiguration } from '../media-api-configuration';
import { MediaStrictHttpResponse } from '../media-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CategoryModel } from '../models/category-model';
import { CategorySearchItemModelSearchResultModel } from '../models/category-search-item-model-search-result-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MediaCategoryService extends MediaBaseService {
  constructor(
    config: MediaApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation categorySearchCategoryPost
   */
  static readonly CategorySearchCategoryPostPath = '/Category/SearchCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `categorySearchCategoryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  categorySearchCategoryPost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MediaStrictHttpResponse<CategorySearchItemModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaCategoryService.CategorySearchCategoryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<CategorySearchItemModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `categorySearchCategoryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  categorySearchCategoryPost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<CategorySearchItemModelSearchResultModel> {

    return this.categorySearchCategoryPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<CategorySearchItemModelSearchResultModel>) => r.body as CategorySearchItemModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `categorySearchCategoryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  categorySearchCategoryPost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MediaStrictHttpResponse<CategorySearchItemModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaCategoryService.CategorySearchCategoryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<CategorySearchItemModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `categorySearchCategoryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  categorySearchCategoryPost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<CategorySearchItemModelSearchResultModel> {

    return this.categorySearchCategoryPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<CategorySearchItemModelSearchResultModel>) => r.body as CategorySearchItemModelSearchResultModel)
    );
  }

  /**
   * Path part for operation categoryIdGet
   */
  static readonly CategoryIdGetPath = '/Category/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `categoryIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  categoryIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MediaStrictHttpResponse<CategoryModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaCategoryService.CategoryIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<CategoryModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `categoryIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  categoryIdGet$Plain(params: {
    id: string;
  }): Observable<CategoryModel> {

    return this.categoryIdGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<CategoryModel>) => r.body as CategoryModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `categoryIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  categoryIdGet$Json$Response(params: {
    id: string;
  }): Observable<MediaStrictHttpResponse<CategoryModel>> {

    const rb = new RequestBuilder(this.rootUrl, MediaCategoryService.CategoryIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<CategoryModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `categoryIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  categoryIdGet$Json(params: {
    id: string;
  }): Observable<CategoryModel> {

    return this.categoryIdGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<CategoryModel>) => r.body as CategoryModel)
    );
  }

  /**
   * Path part for operation categoryPost
   */
  static readonly CategoryPostPath = '/Category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `categoryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  categoryPost$Plain$Response(params?: {
    body?: CategoryModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaCategoryService.CategoryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `categoryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  categoryPost$Plain(params?: {
    body?: CategoryModel
  }): Observable<string> {

    return this.categoryPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `categoryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  categoryPost$Json$Response(params?: {
    body?: CategoryModel
  }): Observable<MediaStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaCategoryService.CategoryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `categoryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  categoryPost$Json(params?: {
    body?: CategoryModel
  }): Observable<string> {

    return this.categoryPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<string>) => r.body as string)
    );
  }

}
