/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MedicalAidModel } from '../models/medical-aid-model';
import { MedicalAidModelSearchResultModel } from '../models/medical-aid-model-search-result-model';
import { MedicalAidOptionModel } from '../models/medical-aid-option-model';
import { MedicalAidOptionModelSearchResultModel } from '../models/medical-aid-option-model-search-result-model';
import { MedicalAidSchemeModel } from '../models/medical-aid-scheme-model';
import { MedicalAidSchemeModelSearchResultModel } from '../models/medical-aid-scheme-model-search-result-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MDIMedicalAidService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation medicalAidSearchMedicalAidPost
   */
  static readonly MedicalAidSearchMedicalAidPostPath = '/MedicalAid/SearchMedicalAid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidSearchMedicalAidPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidSearchMedicalAidPost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<MedicalAidModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidSearchMedicalAidPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<MedicalAidModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidSearchMedicalAidPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidSearchMedicalAidPost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<MedicalAidModelSearchResultModel> {

    return this.medicalAidSearchMedicalAidPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<MedicalAidModelSearchResultModel>) => r.body as MedicalAidModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidSearchMedicalAidPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidSearchMedicalAidPost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<MedicalAidModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidSearchMedicalAidPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<MedicalAidModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidSearchMedicalAidPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidSearchMedicalAidPost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<MedicalAidModelSearchResultModel> {

    return this.medicalAidSearchMedicalAidPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<MedicalAidModelSearchResultModel>) => r.body as MedicalAidModelSearchResultModel)
    );
  }

  /**
   * Path part for operation medicalAidIdGet
   */
  static readonly MedicalAidIdGetPath = '/MedicalAid/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAidIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<MedicalAidModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<MedicalAidModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAidIdGet$Plain(params: {
    id: string;
  }): Observable<MedicalAidModel> {

    return this.medicalAidIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<MedicalAidModel>) => r.body as MedicalAidModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAidIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<MedicalAidModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<MedicalAidModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAidIdGet$Json(params: {
    id: string;
  }): Observable<MedicalAidModel> {

    return this.medicalAidIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<MedicalAidModel>) => r.body as MedicalAidModel)
    );
  }

  /**
   * Path part for operation medicalAidPost
   */
  static readonly MedicalAidPostPath = '/MedicalAid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidPost$Plain$Response(params?: {
    body?: MedicalAidModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidPost$Plain(params?: {
    body?: MedicalAidModel
  }): Observable<string> {

    return this.medicalAidPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidPost$Json$Response(params?: {
    body?: MedicalAidModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidPost$Json(params?: {
    body?: MedicalAidModel
  }): Observable<string> {

    return this.medicalAidPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation medicalAidMedicalAidIdSearchMedicalAidSchemePost
   */
  static readonly MedicalAidMedicalAidIdSearchMedicalAidSchemePostPath = '/MedicalAid/{medicalAidId}/SearchMedicalAidScheme';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidMedicalAidIdSearchMedicalAidSchemePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidIdSearchMedicalAidSchemePost$Plain$Response(params: {
    medicalAidId: string;
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<MedicalAidSchemeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidMedicalAidIdSearchMedicalAidSchemePostPath, 'post');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<MedicalAidSchemeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidMedicalAidIdSearchMedicalAidSchemePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidIdSearchMedicalAidSchemePost$Plain(params: {
    medicalAidId: string;
    body?: SearchRequestModel
  }): Observable<MedicalAidSchemeModelSearchResultModel> {

    return this.medicalAidMedicalAidIdSearchMedicalAidSchemePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<MedicalAidSchemeModelSearchResultModel>) => r.body as MedicalAidSchemeModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidMedicalAidIdSearchMedicalAidSchemePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidIdSearchMedicalAidSchemePost$Json$Response(params: {
    medicalAidId: string;
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<MedicalAidSchemeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidMedicalAidIdSearchMedicalAidSchemePostPath, 'post');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<MedicalAidSchemeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidMedicalAidIdSearchMedicalAidSchemePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidIdSearchMedicalAidSchemePost$Json(params: {
    medicalAidId: string;
    body?: SearchRequestModel
  }): Observable<MedicalAidSchemeModelSearchResultModel> {

    return this.medicalAidMedicalAidIdSearchMedicalAidSchemePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<MedicalAidSchemeModelSearchResultModel>) => r.body as MedicalAidSchemeModelSearchResultModel)
    );
  }

  /**
   * Path part for operation medicalAidMedicalAidSchemeGetMedicalAidIdMedicalAidSchemeIdGet
   */
  static readonly MedicalAidMedicalAidSchemeGetMedicalAidIdMedicalAidSchemeIdGetPath = '/MedicalAid/MedicalAidSchemeGet/{medicalAidId}/{medicalAidSchemeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidMedicalAidSchemeGetMedicalAidIdMedicalAidSchemeIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAidMedicalAidSchemeGetMedicalAidIdMedicalAidSchemeIdGet$Plain$Response(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
  }): Observable<MDIStrictHttpResponse<MedicalAidSchemeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidMedicalAidSchemeGetMedicalAidIdMedicalAidSchemeIdGetPath, 'get');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
      rb.path('medicalAidSchemeId', params.medicalAidSchemeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<MedicalAidSchemeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidMedicalAidSchemeGetMedicalAidIdMedicalAidSchemeIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAidMedicalAidSchemeGetMedicalAidIdMedicalAidSchemeIdGet$Plain(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
  }): Observable<MedicalAidSchemeModel> {

    return this.medicalAidMedicalAidSchemeGetMedicalAidIdMedicalAidSchemeIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<MedicalAidSchemeModel>) => r.body as MedicalAidSchemeModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidMedicalAidSchemeGetMedicalAidIdMedicalAidSchemeIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAidMedicalAidSchemeGetMedicalAidIdMedicalAidSchemeIdGet$Json$Response(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
  }): Observable<MDIStrictHttpResponse<MedicalAidSchemeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidMedicalAidSchemeGetMedicalAidIdMedicalAidSchemeIdGetPath, 'get');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
      rb.path('medicalAidSchemeId', params.medicalAidSchemeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<MedicalAidSchemeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidMedicalAidSchemeGetMedicalAidIdMedicalAidSchemeIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAidMedicalAidSchemeGetMedicalAidIdMedicalAidSchemeIdGet$Json(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
  }): Observable<MedicalAidSchemeModel> {

    return this.medicalAidMedicalAidSchemeGetMedicalAidIdMedicalAidSchemeIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<MedicalAidSchemeModel>) => r.body as MedicalAidSchemeModel)
    );
  }

  /**
   * Path part for operation medicalAidMedicalAidSchemeSaveMedicalAidIdPost
   */
  static readonly MedicalAidMedicalAidSchemeSaveMedicalAidIdPostPath = '/MedicalAid/MedicalAidSchemeSave/{medicalAidId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidMedicalAidSchemeSaveMedicalAidIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidSchemeSaveMedicalAidIdPost$Plain$Response(params: {
    medicalAidId: string;
    body?: MedicalAidSchemeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidMedicalAidSchemeSaveMedicalAidIdPostPath, 'post');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidMedicalAidSchemeSaveMedicalAidIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidSchemeSaveMedicalAidIdPost$Plain(params: {
    medicalAidId: string;
    body?: MedicalAidSchemeModel
  }): Observable<string> {

    return this.medicalAidMedicalAidSchemeSaveMedicalAidIdPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidMedicalAidSchemeSaveMedicalAidIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidSchemeSaveMedicalAidIdPost$Json$Response(params: {
    medicalAidId: string;
    body?: MedicalAidSchemeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidMedicalAidSchemeSaveMedicalAidIdPostPath, 'post');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidMedicalAidSchemeSaveMedicalAidIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidSchemeSaveMedicalAidIdPost$Json(params: {
    medicalAidId: string;
    body?: MedicalAidSchemeModel
  }): Observable<string> {

    return this.medicalAidMedicalAidSchemeSaveMedicalAidIdPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation medicalAidMedicalAidIdMedicalAidSchemeIdSearchMedicalAidOptionPost
   */
  static readonly MedicalAidMedicalAidIdMedicalAidSchemeIdSearchMedicalAidOptionPostPath = '/MedicalAid/{medicalAidId}/{medicalAidSchemeId}/SearchMedicalAidOption';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidMedicalAidIdMedicalAidSchemeIdSearchMedicalAidOptionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidIdMedicalAidSchemeIdSearchMedicalAidOptionPost$Plain$Response(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<MedicalAidOptionModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidMedicalAidIdMedicalAidSchemeIdSearchMedicalAidOptionPostPath, 'post');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
      rb.path('medicalAidSchemeId', params.medicalAidSchemeId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<MedicalAidOptionModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidMedicalAidIdMedicalAidSchemeIdSearchMedicalAidOptionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidIdMedicalAidSchemeIdSearchMedicalAidOptionPost$Plain(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
    body?: SearchRequestModel
  }): Observable<MedicalAidOptionModelSearchResultModel> {

    return this.medicalAidMedicalAidIdMedicalAidSchemeIdSearchMedicalAidOptionPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<MedicalAidOptionModelSearchResultModel>) => r.body as MedicalAidOptionModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidMedicalAidIdMedicalAidSchemeIdSearchMedicalAidOptionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidIdMedicalAidSchemeIdSearchMedicalAidOptionPost$Json$Response(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<MedicalAidOptionModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidMedicalAidIdMedicalAidSchemeIdSearchMedicalAidOptionPostPath, 'post');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
      rb.path('medicalAidSchemeId', params.medicalAidSchemeId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<MedicalAidOptionModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidMedicalAidIdMedicalAidSchemeIdSearchMedicalAidOptionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidIdMedicalAidSchemeIdSearchMedicalAidOptionPost$Json(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
    body?: SearchRequestModel
  }): Observable<MedicalAidOptionModelSearchResultModel> {

    return this.medicalAidMedicalAidIdMedicalAidSchemeIdSearchMedicalAidOptionPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<MedicalAidOptionModelSearchResultModel>) => r.body as MedicalAidOptionModelSearchResultModel)
    );
  }

  /**
   * Path part for operation medicalAidMedicalAidOptionGetMedicalAidIdMedicalAidSchemeIdMedicalAidOptionIdGet
   */
  static readonly MedicalAidMedicalAidOptionGetMedicalAidIdMedicalAidSchemeIdMedicalAidOptionIdGetPath = '/MedicalAid/MedicalAidOptionGet/{medicalAidId}/{medicalAidSchemeId}/{medicalAidOptionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidMedicalAidOptionGetMedicalAidIdMedicalAidSchemeIdMedicalAidOptionIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAidMedicalAidOptionGetMedicalAidIdMedicalAidSchemeIdMedicalAidOptionIdGet$Plain$Response(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
    medicalAidOptionId: string;
  }): Observable<MDIStrictHttpResponse<MedicalAidOptionModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidMedicalAidOptionGetMedicalAidIdMedicalAidSchemeIdMedicalAidOptionIdGetPath, 'get');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
      rb.path('medicalAidSchemeId', params.medicalAidSchemeId, {});
      rb.path('medicalAidOptionId', params.medicalAidOptionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<MedicalAidOptionModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidMedicalAidOptionGetMedicalAidIdMedicalAidSchemeIdMedicalAidOptionIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAidMedicalAidOptionGetMedicalAidIdMedicalAidSchemeIdMedicalAidOptionIdGet$Plain(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
    medicalAidOptionId: string;
  }): Observable<MedicalAidOptionModel> {

    return this.medicalAidMedicalAidOptionGetMedicalAidIdMedicalAidSchemeIdMedicalAidOptionIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<MedicalAidOptionModel>) => r.body as MedicalAidOptionModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidMedicalAidOptionGetMedicalAidIdMedicalAidSchemeIdMedicalAidOptionIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAidMedicalAidOptionGetMedicalAidIdMedicalAidSchemeIdMedicalAidOptionIdGet$Json$Response(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
    medicalAidOptionId: string;
  }): Observable<MDIStrictHttpResponse<MedicalAidOptionModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidMedicalAidOptionGetMedicalAidIdMedicalAidSchemeIdMedicalAidOptionIdGetPath, 'get');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
      rb.path('medicalAidSchemeId', params.medicalAidSchemeId, {});
      rb.path('medicalAidOptionId', params.medicalAidOptionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<MedicalAidOptionModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidMedicalAidOptionGetMedicalAidIdMedicalAidSchemeIdMedicalAidOptionIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAidMedicalAidOptionGetMedicalAidIdMedicalAidSchemeIdMedicalAidOptionIdGet$Json(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
    medicalAidOptionId: string;
  }): Observable<MedicalAidOptionModel> {

    return this.medicalAidMedicalAidOptionGetMedicalAidIdMedicalAidSchemeIdMedicalAidOptionIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<MedicalAidOptionModel>) => r.body as MedicalAidOptionModel)
    );
  }

  /**
   * Path part for operation medicalAidMedicalAidOptionSaveMedicalAidIdMedicalAidSchemeIdPost
   */
  static readonly MedicalAidMedicalAidOptionSaveMedicalAidIdMedicalAidSchemeIdPostPath = '/MedicalAid/MedicalAidOptionSave/{medicalAidId}/{medicalAidSchemeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidMedicalAidOptionSaveMedicalAidIdMedicalAidSchemeIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidOptionSaveMedicalAidIdMedicalAidSchemeIdPost$Plain$Response(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
    body?: MedicalAidOptionModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidMedicalAidOptionSaveMedicalAidIdMedicalAidSchemeIdPostPath, 'post');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
      rb.path('medicalAidSchemeId', params.medicalAidSchemeId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidMedicalAidOptionSaveMedicalAidIdMedicalAidSchemeIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidOptionSaveMedicalAidIdMedicalAidSchemeIdPost$Plain(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
    body?: MedicalAidOptionModel
  }): Observable<string> {

    return this.medicalAidMedicalAidOptionSaveMedicalAidIdMedicalAidSchemeIdPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAidMedicalAidOptionSaveMedicalAidIdMedicalAidSchemeIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidOptionSaveMedicalAidIdMedicalAidSchemeIdPost$Json$Response(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
    body?: MedicalAidOptionModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIMedicalAidService.MedicalAidMedicalAidOptionSaveMedicalAidIdMedicalAidSchemeIdPostPath, 'post');
    if (params) {
      rb.path('medicalAidId', params.medicalAidId, {});
      rb.path('medicalAidSchemeId', params.medicalAidSchemeId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `medicalAidMedicalAidOptionSaveMedicalAidIdMedicalAidSchemeIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAidMedicalAidOptionSaveMedicalAidIdMedicalAidSchemeIdPost$Json(params: {
    medicalAidId: string;
    medicalAidSchemeId: string;
    body?: MedicalAidOptionModel
  }): Observable<string> {

    return this.medicalAidMedicalAidOptionSaveMedicalAidIdMedicalAidSchemeIdPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
