import { Component, OnInit, ViewChild } from '@angular/core';
import { TitleHelperService } from 'src/app/services/title-helper/title-helper.service';
import { CountriesService } from 'src/app/services/countries/countries.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { StringNormalize } from 'src/app/utilities/index';
import { IonContent, IonSearchbar } from '@ionic/angular';
import { Country } from 'src/app/services/countries/models/country.model';
import { LocationService } from 'src/app/services/location/location.service';
//import { AtlasLocation } from 'src/app/api/atlas-api/models';

@Component({
	templateUrl: './country.page.html',
	styleUrls: ['./country.page.scss']
})
export class CountryPage implements OnInit {
	@ViewChild('searchBar')
	private searchBar: IonSearchbar;

	@ViewChild('ionContent')
	private ionContent: IonContent;

	public loading = true;
	public selectedCountry;
	public countries: Country[] = [];

	constructor(
		private modalService: ModalService,
		private titleHelperService: TitleHelperService,
		public countriesService: CountriesService,
		private locationService: LocationService
	) {}

	ngOnInit(): void {
		this.titleHelperService.setPageTitle('Country');
		this.loading = true;
		this.countriesService.Countries$.subscribe((countries) => {
			this.countries = countries;

			// this.locationService.location$
			// 	.subscribe
			// 	// (location: AtlasLocation) => {
			// 	// 	this.selectedCountry = this.countries.find(
			// 	// 		(e) =>
			// 	// 			e.alpha2.toLowerCase() ==
			// 	// 			location.countryRegion.isoCode.toLowerCase()
			// 	// 	);
			// 	// 	this.loading = false;
			// 	// }
			// 	();
		});
	}

	public searchChange(): void {
		const term = StringNormalize.ASCIIFolding(this.searchBar.value);
		this.countries = this.countries.filter((l) => {
			if (
				this.countriesService.filterCountryIsoName(
					l.alpha2,
					l.name,
					term
				)
			) {
				return l;
			}
		});
	}

	public dismiss(): void {
		this.titleHelperService.setPageTitle();
		void this.modalService.dismissModalAsync('CountryPage');
	}

	public clearSearch(): void {
		if (this.searchBar) this.searchBar.value = '';
		if (this.ionContent)
			(this.ionContent as any).el.scroll({
				top: 0,
				behavior: 'smooth'
			});
	}
}
