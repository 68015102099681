/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MediaBaseService } from '../media-base-service';
import { MediaApiConfiguration } from '../media-api-configuration';
import { MediaStrictHttpResponse } from '../media-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DashboardDownloadReponse } from '../models/dashboard-download-reponse';
import { DashboardResponse } from '../models/dashboard-response';
import { DashboardSearchModel } from '../models/dashboard-search-model';
import { KeyValueGuidModel } from '../models/key-value-guid-model';

@Injectable({
  providedIn: 'root',
})
export class MediaDashboardService extends MediaBaseService {
  constructor(
    config: MediaApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation dashboardDashboardSearchPost
   */
  static readonly DashboardDashboardSearchPostPath = '/Dashboard/DashboardSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardDashboardSearchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardDashboardSearchPost$Plain$Response(params?: {
    body?: DashboardSearchModel
  }): Observable<MediaStrictHttpResponse<DashboardResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MediaDashboardService.DashboardDashboardSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<DashboardResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashboardDashboardSearchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardDashboardSearchPost$Plain(params?: {
    body?: DashboardSearchModel
  }): Observable<DashboardResponse> {

    return this.dashboardDashboardSearchPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<DashboardResponse>) => r.body as DashboardResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardDashboardSearchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardDashboardSearchPost$Json$Response(params?: {
    body?: DashboardSearchModel
  }): Observable<MediaStrictHttpResponse<DashboardResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MediaDashboardService.DashboardDashboardSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<DashboardResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashboardDashboardSearchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardDashboardSearchPost$Json(params?: {
    body?: DashboardSearchModel
  }): Observable<DashboardResponse> {

    return this.dashboardDashboardSearchPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<DashboardResponse>) => r.body as DashboardResponse)
    );
  }

  /**
   * Path part for operation dashboardDashboardDownloadPost
   */
  static readonly DashboardDashboardDownloadPostPath = '/Dashboard/DashboardDownload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardDashboardDownloadPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardDashboardDownloadPost$Plain$Response(params?: {
    body?: DashboardSearchModel
  }): Observable<MediaStrictHttpResponse<DashboardDownloadReponse>> {

    const rb = new RequestBuilder(this.rootUrl, MediaDashboardService.DashboardDashboardDownloadPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<DashboardDownloadReponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashboardDashboardDownloadPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardDashboardDownloadPost$Plain(params?: {
    body?: DashboardSearchModel
  }): Observable<DashboardDownloadReponse> {

    return this.dashboardDashboardDownloadPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<DashboardDownloadReponse>) => r.body as DashboardDownloadReponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardDashboardDownloadPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardDashboardDownloadPost$Json$Response(params?: {
    body?: DashboardSearchModel
  }): Observable<MediaStrictHttpResponse<DashboardDownloadReponse>> {

    const rb = new RequestBuilder(this.rootUrl, MediaDashboardService.DashboardDashboardDownloadPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<DashboardDownloadReponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashboardDashboardDownloadPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardDashboardDownloadPost$Json(params?: {
    body?: DashboardSearchModel
  }): Observable<DashboardDownloadReponse> {

    return this.dashboardDashboardDownloadPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<DashboardDownloadReponse>) => r.body as DashboardDownloadReponse)
    );
  }

  /**
   * Path part for operation dashboardClientListPublisherIdGet
   */
  static readonly DashboardClientListPublisherIdGetPath = '/Dashboard/ClientList/{publisherId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardClientListPublisherIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardClientListPublisherIdGet$Plain$Response(params: {
    publisherId: string;
  }): Observable<MediaStrictHttpResponse<Array<KeyValueGuidModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaDashboardService.DashboardClientListPublisherIdGetPath, 'get');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueGuidModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashboardClientListPublisherIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardClientListPublisherIdGet$Plain(params: {
    publisherId: string;
  }): Observable<Array<KeyValueGuidModel>> {

    return this.dashboardClientListPublisherIdGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueGuidModel>>) => r.body as Array<KeyValueGuidModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardClientListPublisherIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardClientListPublisherIdGet$Json$Response(params: {
    publisherId: string;
  }): Observable<MediaStrictHttpResponse<Array<KeyValueGuidModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaDashboardService.DashboardClientListPublisherIdGetPath, 'get');
    if (params) {
      rb.path('publisherId', params.publisherId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueGuidModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashboardClientListPublisherIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardClientListPublisherIdGet$Json(params: {
    publisherId: string;
  }): Observable<Array<KeyValueGuidModel>> {

    return this.dashboardClientListPublisherIdGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueGuidModel>>) => r.body as Array<KeyValueGuidModel>)
    );
  }

  /**
   * Path part for operation dashboardPublisherListGet
   */
  static readonly DashboardPublisherListGetPath = '/Dashboard/PublisherList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardPublisherListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardPublisherListGet$Plain$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<KeyValueGuidModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaDashboardService.DashboardPublisherListGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueGuidModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashboardPublisherListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardPublisherListGet$Plain(params?: {
  }): Observable<Array<KeyValueGuidModel>> {

    return this.dashboardPublisherListGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueGuidModel>>) => r.body as Array<KeyValueGuidModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardPublisherListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardPublisherListGet$Json$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<KeyValueGuidModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaDashboardService.DashboardPublisherListGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueGuidModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashboardPublisherListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardPublisherListGet$Json(params?: {
  }): Observable<Array<KeyValueGuidModel>> {

    return this.dashboardPublisherListGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueGuidModel>>) => r.body as Array<KeyValueGuidModel>)
    );
  }

}
