import { Component, Input } from '@angular/core';
import { HyperlinkService } from 'src/app/services/hyperlink/hyperlink.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TitleHelperService } from 'src/app/services/title-helper/title-helper.service';

@Component({
	selector: 'home-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
	@Input()
	public title: string;

	constructor(
		private pageTitle: TitleHelperService,
		private modalService: ModalService,
		private hyperlinkService: HyperlinkService
	) {}

	clicked(): void {
		void this.modalService.closeModalsAsync();
		this.pageTitle.setPageTitle();
	}

	public async navigateTo(routeTo: string, newProps = null): Promise<void> {
		const fromUrl = window.location.pathname;

		await this.hyperlinkService.navigateTo(routeTo, fromUrl, newProps);
	}
}
