import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslatorService } from './translator/translator.service';

@NgModule({
	imports: [],
	providers: [TranslatorService],
	declarations: [],
	exports: []
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {}
}
