import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { interval, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { JoinSessionComponent } from '../join-session/join-session.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { AcsService } from 'src/app/services/sessions/acs/acs.service';
import { Capacitor } from '@capacitor/core';
import { BrowserService } from 'src/app/services/browser/browser.service';
import ServicesMock from '../../../../utilities/dummy-data/services.json';

@Component({
	selector: 'session-details',
	templateUrl: './session-details.component.html',
	styleUrls: ['./session-details.component.scss']
})
export class SessionDetailsComponent implements OnInit, OnDestroy {
	@Input() session;
	@Input() isPrevious = false;
	@Input() type = 'video';
	private DateCopy;
	private originalStartDate;
	private originalEndDate;
	private canJoin: Subscription;
	public service;

	constructor(
		private modalService: ModalService,
		private themeService: ThemeService,
		private acsService: AcsService,
		private browserService: BrowserService
	) {}

	ngOnInit(): void {
		this.service = ServicesMock.find((e) => e.id == this.session.ServiceId);

		this.DateCopy = _.cloneDeep(this.session.Date);
		this.originalStartDate = moment(this.session.Date).format(
			'DD/MM/YYYY HH:mm'
		);
		this.originalEndDate = moment(this.session.EndTime).format(
			'DD/MM/YYYY HH:mm'
		);

		const endTime = moment(this.session.EndTime).format('HH:mm');

		if (this.type == 'video') {
			this.session.Date =
				this.session.Date.length > 10
					? moment(this.session.Date).format(
							'ddd MMMM DD YYYY HH:mm'
					  ) +
					  '-' +
					  endTime
					: moment(this.session.Date).format('ddd MMMM DD YYYY');
		} else {
			this.session.Date = moment(this.session.Date).format(
				'ddd MMMM DD YYYY HH:mm'
			);
		}

		if (!this.isPrevious) {
			if (
				this.checkIfCanJoin() &&
				this.session.Status == 'Confirmed' &&
				this.session.TeamsLink != null &&
				this.session.TeamsLink != ''
			) {
				this.session.Status = 'Join';
			}

			const timeInterval = interval(15000);
			this.canJoin = timeInterval.subscribe(() => {
				if (
					this.checkIfCanJoin() &&
					this.session.Status == 'Confirmed' &&
					this.session.TeamsLink != null &&
					this.session.TeamsLink != ''
				) {
					this.session.Status = 'Join';
					this.canJoin.unsubscribe();
				}
			});
		}
	}

	ngOnDestroy(): void {
		if (!this.isPrevious) {
			this.canJoin.unsubscribe();
		}
	}

	private checkIfCanJoin(): boolean {
		if (moment().diff(moment(this.DateCopy), 'days') == 0) {
			const nowHour = moment().hour();
			const startHour = moment(
				this.originalStartDate,
				'DD/MM/YYYY HH:mm'
			).hour();
			const endHour = moment(
				this.originalEndDate,
				'DD/MM/YYYY HH:mm'
			).hour();

			const duration = moment
				.duration(
					moment(this.originalStartDate, 'DD/MM/YYYY HH:mm').diff(
						moment()
					)
				)
				.asMinutes();

			if (startHour <= nowHour && endHour >= nowHour && duration <= 5) {
				return true;
			}
			return false;
		} else {
			return false;
		}
	}

	async presentModal(): Promise<void> {
		if (this.session.Status == 'Join') {
			if (Capacitor.getPlatform() == 'web') {
				let customClass = 'custom-modal';
				if (this.themeService.layout == 'web') {
					customClass = 'web-modal-full-page-no-border';
				}

				await this.modalService.viewDrawerModalAsync(
					{
						session: this.session
					},
					JoinSessionComponent,
					customClass,
					'JoinSessionComponent',
					false
				);
				return;
			}

			this.browserService.setBrowserOpened(true);
			this.acsService.startCall(this.session);
		}
	}
}
