import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ToHtmlPipe } from './pipes/to-html/to-html.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { i18nArrayTranslatePipe } from './pipes/i18n-translate/i18n-array-translate/i18n-array-translate.pipe';
import { HtmlContainerComponent } from './components/html-container/html-container.component';
import { SeeAllPage } from './pages/see-all/see-all.page';
import { MoreLikeThisComponent } from './components/more-like-this/more-like-this.component';
import { isoCountryNamePipe } from './pipes/iso-country-name/iso-country-name.pipe';
import { IsoCountrySingleNamePipe } from './pipes/iso-country-single-name/iso-country-single-name.pipe';
import { ArticleReadingComponent } from './components/article-reading/article-reading.component';
import { ContentThumbnailComponent } from './components/content-thumbnail/content-thumbnail.component';
import { I18nFieldTranslatePipe } from './pipes/i18n-translate/i18n-field-translate/i18n-field-translate.pipe';
import { ContentViewComponent } from './components/content-view/content-view.component';
import { DynamicImageDirective } from './directives/dynamic-image/dynamic-image.directive';
import { LoaderComponent } from './components/loader/loader.component';
import { LanguagePage } from './pages/language/language.page';
import { NavigationTabsComponent } from './components/navigation-tabs/navigation-tabs.component';
import { LayoutDirective } from './directives/layout/layout.directive';
import { DragAndDropImageDirective } from './directives/drag-and-drop-image/drag-and-drop-image.directive';
import { ResizeObserverDirective } from './directives/resize-observer/resize-observer.directive';
import { TextRevealDirective } from './directives/text-reveal/text-reveal.directive';
import { TranslateToHtmlPipe } from './pipes/translate-to-html/translate-to-html.pipe';
import { TimeSelectorComponent } from './components/sessions/time-selector/time-selector.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { AboutSectionComponent } from './components/about-section/about-section.component';
import { CalendarModule } from 'ion2-calendar';
import { SessionUserDetailsComponent } from './components/sessions/session-user-details/session-user-details.component';
import { SessionLanguageComponent } from './components/sessions/session-language/session-language.component';
import { SessionDetailsComponent } from './components/sessions/session-details/session-details.component';
import { JoinSessionComponent } from './components/sessions/join-session/join-session.component';
import { PopupService } from '../services/base/popup.service';
import { EventService } from '../services/base/event.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TypeaheadComponent } from './components/typeahead/typeahead.component';

const components = [
	HeaderComponent,
	ToHtmlPipe,
	i18nArrayTranslatePipe,
	HtmlContainerComponent,
	SeeAllPage,
	MoreLikeThisComponent,
	isoCountryNamePipe,
	IsoCountrySingleNamePipe,
	ArticleReadingComponent,
	ContentThumbnailComponent,
	I18nFieldTranslatePipe,
	DynamicImageDirective,
	ContentViewComponent,
	LoaderComponent,
	LanguagePage,
	NavigationTabsComponent,
	LayoutDirective,
	DragAndDropImageDirective,
	ResizeObserverDirective,
	TextRevealDirective,
	TranslateToHtmlPipe,
	SessionUserDetailsComponent,
	SessionLanguageComponent,
	TimeSelectorComponent,
	CalendarComponent,
	AboutSectionComponent,
	SessionDetailsComponent,
	JoinSessionComponent,
	TypeaheadComponent
];

@NgModule({
	declarations: [...components],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [PopupService, EventService],
	entryComponents: [],
	imports: [
		CommonModule,
		RouterModule,
		IonicModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		CalendarModule.forRoot({
			doneLabel: 'Save',
			closeIcon: true
		})
	],
	exports: [FormsModule, ReactiveFormsModule, TranslateModule, ...components]
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule
		};
	}
}
