/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SearchRequestModel } from '../models/search-request-model';
import { ServiceDeliveryLocationRoleTypeModel } from '../models/service-delivery-location-role-type-model';
import { ServiceDeliveryLocationRoleTypeModelSearchResultModel } from '../models/service-delivery-location-role-type-model-search-result-model';

@Injectable({
  providedIn: 'root',
})
export class MDIServiceDeliveryLocationRoleTypeService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation serviceDeliveryLocationRoleTypeSearchServiceDeliveryLocationRoleTypePost
   */
  static readonly ServiceDeliveryLocationRoleTypeSearchServiceDeliveryLocationRoleTypePostPath = '/ServiceDeliveryLocationRoleType/SearchServiceDeliveryLocationRoleType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceDeliveryLocationRoleTypeSearchServiceDeliveryLocationRoleTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceDeliveryLocationRoleTypeSearchServiceDeliveryLocationRoleTypePost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<ServiceDeliveryLocationRoleTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIServiceDeliveryLocationRoleTypeService.ServiceDeliveryLocationRoleTypeSearchServiceDeliveryLocationRoleTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ServiceDeliveryLocationRoleTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceDeliveryLocationRoleTypeSearchServiceDeliveryLocationRoleTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceDeliveryLocationRoleTypeSearchServiceDeliveryLocationRoleTypePost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<ServiceDeliveryLocationRoleTypeModelSearchResultModel> {

    return this.serviceDeliveryLocationRoleTypeSearchServiceDeliveryLocationRoleTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ServiceDeliveryLocationRoleTypeModelSearchResultModel>) => r.body as ServiceDeliveryLocationRoleTypeModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceDeliveryLocationRoleTypeSearchServiceDeliveryLocationRoleTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceDeliveryLocationRoleTypeSearchServiceDeliveryLocationRoleTypePost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<ServiceDeliveryLocationRoleTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIServiceDeliveryLocationRoleTypeService.ServiceDeliveryLocationRoleTypeSearchServiceDeliveryLocationRoleTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ServiceDeliveryLocationRoleTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceDeliveryLocationRoleTypeSearchServiceDeliveryLocationRoleTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceDeliveryLocationRoleTypeSearchServiceDeliveryLocationRoleTypePost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<ServiceDeliveryLocationRoleTypeModelSearchResultModel> {

    return this.serviceDeliveryLocationRoleTypeSearchServiceDeliveryLocationRoleTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ServiceDeliveryLocationRoleTypeModelSearchResultModel>) => r.body as ServiceDeliveryLocationRoleTypeModelSearchResultModel)
    );
  }

  /**
   * Path part for operation serviceDeliveryLocationRoleTypeIdGet
   */
  static readonly ServiceDeliveryLocationRoleTypeIdGetPath = '/ServiceDeliveryLocationRoleType/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceDeliveryLocationRoleTypeIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceDeliveryLocationRoleTypeIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<ServiceDeliveryLocationRoleTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIServiceDeliveryLocationRoleTypeService.ServiceDeliveryLocationRoleTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ServiceDeliveryLocationRoleTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceDeliveryLocationRoleTypeIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceDeliveryLocationRoleTypeIdGet$Plain(params: {
    id: string;
  }): Observable<ServiceDeliveryLocationRoleTypeModel> {

    return this.serviceDeliveryLocationRoleTypeIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ServiceDeliveryLocationRoleTypeModel>) => r.body as ServiceDeliveryLocationRoleTypeModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceDeliveryLocationRoleTypeIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceDeliveryLocationRoleTypeIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<ServiceDeliveryLocationRoleTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIServiceDeliveryLocationRoleTypeService.ServiceDeliveryLocationRoleTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<ServiceDeliveryLocationRoleTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceDeliveryLocationRoleTypeIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceDeliveryLocationRoleTypeIdGet$Json(params: {
    id: string;
  }): Observable<ServiceDeliveryLocationRoleTypeModel> {

    return this.serviceDeliveryLocationRoleTypeIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<ServiceDeliveryLocationRoleTypeModel>) => r.body as ServiceDeliveryLocationRoleTypeModel)
    );
  }

  /**
   * Path part for operation serviceDeliveryLocationRoleTypePost
   */
  static readonly ServiceDeliveryLocationRoleTypePostPath = '/ServiceDeliveryLocationRoleType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceDeliveryLocationRoleTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceDeliveryLocationRoleTypePost$Plain$Response(params?: {
    body?: ServiceDeliveryLocationRoleTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIServiceDeliveryLocationRoleTypeService.ServiceDeliveryLocationRoleTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceDeliveryLocationRoleTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceDeliveryLocationRoleTypePost$Plain(params?: {
    body?: ServiceDeliveryLocationRoleTypeModel
  }): Observable<string> {

    return this.serviceDeliveryLocationRoleTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceDeliveryLocationRoleTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceDeliveryLocationRoleTypePost$Json$Response(params?: {
    body?: ServiceDeliveryLocationRoleTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIServiceDeliveryLocationRoleTypeService.ServiceDeliveryLocationRoleTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceDeliveryLocationRoleTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceDeliveryLocationRoleTypePost$Json(params?: {
    body?: ServiceDeliveryLocationRoleTypeModel
  }): Observable<string> {

    return this.serviceDeliveryLocationRoleTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
