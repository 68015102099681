/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MediaBaseService } from '../media-base-service';
import { MediaApiConfiguration } from '../media-api-configuration';
import { MediaStrictHttpResponse } from '../media-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { KeyValueGuidModel } from '../models/key-value-guid-model';
import { KeyValueModel } from '../models/key-value-model';

@Injectable({
  providedIn: 'root',
})
export class MediaMasterDataService extends MediaBaseService {
  constructor(
    config: MediaApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation masterDataSupportedLanguageTypesGet
   */
  static readonly MasterDataSupportedLanguageTypesGetPath = '/MasterData/SupportedLanguageTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataSupportedLanguageTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSupportedLanguageTypesGet$Plain$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaMasterDataService.MasterDataSupportedLanguageTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataSupportedLanguageTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSupportedLanguageTypesGet$Plain(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataSupportedLanguageTypesGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataSupportedLanguageTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSupportedLanguageTypesGet$Json$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<KeyValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaMasterDataService.MasterDataSupportedLanguageTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataSupportedLanguageTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataSupportedLanguageTypesGet$Json(params?: {
  }): Observable<Array<KeyValueModel>> {

    return this.masterDataSupportedLanguageTypesGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueModel>>) => r.body as Array<KeyValueModel>)
    );
  }

  /**
   * Path part for operation masterDataPublisherListGet
   */
  static readonly MasterDataPublisherListGetPath = '/MasterData/PublisherList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPublisherListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPublisherListGet$Plain$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<KeyValueGuidModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaMasterDataService.MasterDataPublisherListGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueGuidModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPublisherListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPublisherListGet$Plain(params?: {
  }): Observable<Array<KeyValueGuidModel>> {

    return this.masterDataPublisherListGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueGuidModel>>) => r.body as Array<KeyValueGuidModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataPublisherListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPublisherListGet$Json$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<KeyValueGuidModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaMasterDataService.MasterDataPublisherListGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueGuidModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataPublisherListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataPublisherListGet$Json(params?: {
  }): Observable<Array<KeyValueGuidModel>> {

    return this.masterDataPublisherListGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueGuidModel>>) => r.body as Array<KeyValueGuidModel>)
    );
  }

  /**
   * Path part for operation masterDataCategoryListGet
   */
  static readonly MasterDataCategoryListGetPath = '/MasterData/CategoryList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataCategoryListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataCategoryListGet$Plain$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<KeyValueGuidModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaMasterDataService.MasterDataCategoryListGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueGuidModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataCategoryListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataCategoryListGet$Plain(params?: {
  }): Observable<Array<KeyValueGuidModel>> {

    return this.masterDataCategoryListGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueGuidModel>>) => r.body as Array<KeyValueGuidModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `masterDataCategoryListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataCategoryListGet$Json$Response(params?: {
  }): Observable<MediaStrictHttpResponse<Array<KeyValueGuidModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaMasterDataService.MasterDataCategoryListGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<KeyValueGuidModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `masterDataCategoryListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  masterDataCategoryListGet$Json(params?: {
  }): Observable<Array<KeyValueGuidModel>> {

    return this.masterDataCategoryListGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<KeyValueGuidModel>>) => r.body as Array<KeyValueGuidModel>)
    );
  }

}
