import { Component, Input } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';
@Component({
	selector: 'app-see-all',
	templateUrl: './see-all.page.html',
	styleUrls: ['./see-all.page.scss']
})
export class SeeAllPage {
	@Input() allContent: any[];
	@Input() type: string;

	@Input() stack;

	constructor(public modalService: ModalService) {}

	dismiss(): void {
		void this.modalService.dismissModalAsync('SeeAllPage');
	}

	public viewContent(contentId: string): void {
		this.dismiss();

		void this.modalService.contentViewByContentModal(
			contentId,
			this.allContent.map((_) => _.Id),
			this.stack
		);
	}
}
