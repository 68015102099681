/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EducationSubFieldModel } from '../models/education-sub-field-model';
import { EducationSubFieldModelSearchResultModel } from '../models/education-sub-field-model-search-result-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MDIEducationSubFieldService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation educationSubFieldSearchEducationSubFieldPost
   */
  static readonly EducationSubFieldSearchEducationSubFieldPostPath = '/EducationSubField/SearchEducationSubField';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `educationSubFieldSearchEducationSubFieldPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  educationSubFieldSearchEducationSubFieldPost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<EducationSubFieldModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIEducationSubFieldService.EducationSubFieldSearchEducationSubFieldPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<EducationSubFieldModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `educationSubFieldSearchEducationSubFieldPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  educationSubFieldSearchEducationSubFieldPost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<EducationSubFieldModelSearchResultModel> {

    return this.educationSubFieldSearchEducationSubFieldPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<EducationSubFieldModelSearchResultModel>) => r.body as EducationSubFieldModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `educationSubFieldSearchEducationSubFieldPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  educationSubFieldSearchEducationSubFieldPost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<EducationSubFieldModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIEducationSubFieldService.EducationSubFieldSearchEducationSubFieldPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<EducationSubFieldModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `educationSubFieldSearchEducationSubFieldPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  educationSubFieldSearchEducationSubFieldPost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<EducationSubFieldModelSearchResultModel> {

    return this.educationSubFieldSearchEducationSubFieldPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<EducationSubFieldModelSearchResultModel>) => r.body as EducationSubFieldModelSearchResultModel)
    );
  }

  /**
   * Path part for operation educationSubFieldIdGet
   */
  static readonly EducationSubFieldIdGetPath = '/EducationSubField/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `educationSubFieldIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  educationSubFieldIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<EducationSubFieldModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIEducationSubFieldService.EducationSubFieldIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<EducationSubFieldModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `educationSubFieldIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  educationSubFieldIdGet$Plain(params: {
    id: string;
  }): Observable<EducationSubFieldModel> {

    return this.educationSubFieldIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<EducationSubFieldModel>) => r.body as EducationSubFieldModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `educationSubFieldIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  educationSubFieldIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<EducationSubFieldModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIEducationSubFieldService.EducationSubFieldIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<EducationSubFieldModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `educationSubFieldIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  educationSubFieldIdGet$Json(params: {
    id: string;
  }): Observable<EducationSubFieldModel> {

    return this.educationSubFieldIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<EducationSubFieldModel>) => r.body as EducationSubFieldModel)
    );
  }

  /**
   * Path part for operation educationSubFieldPost
   */
  static readonly EducationSubFieldPostPath = '/EducationSubField';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `educationSubFieldPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  educationSubFieldPost$Plain$Response(params?: {
    body?: EducationSubFieldModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIEducationSubFieldService.EducationSubFieldPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `educationSubFieldPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  educationSubFieldPost$Plain(params?: {
    body?: EducationSubFieldModel
  }): Observable<string> {

    return this.educationSubFieldPost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `educationSubFieldPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  educationSubFieldPost$Json$Response(params?: {
    body?: EducationSubFieldModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIEducationSubFieldService.EducationSubFieldPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `educationSubFieldPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  educationSubFieldPost$Json(params?: {
    body?: EducationSubFieldModel
  }): Observable<string> {

    return this.educationSubFieldPost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
