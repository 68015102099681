/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MDIBaseService } from '../mdi-base-service';
import { MDIApiConfiguration } from '../mdi-api-configuration';
import { MDIStrictHttpResponse } from '../mdi-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RelationshipTypeModel } from '../models/relationship-type-model';
import { RelationshipTypeModelSearchResultModel } from '../models/relationship-type-model-search-result-model';
import { SearchRequestModel } from '../models/search-request-model';

@Injectable({
  providedIn: 'root',
})
export class MDIRelationshipTypeService extends MDIBaseService {
  constructor(
    config: MDIApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation relationshipTypeSearchRelationshipTypePost
   */
  static readonly RelationshipTypeSearchRelationshipTypePostPath = '/RelationshipType/SearchRelationshipType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `relationshipTypeSearchRelationshipTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  relationshipTypeSearchRelationshipTypePost$Plain$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<RelationshipTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIRelationshipTypeService.RelationshipTypeSearchRelationshipTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<RelationshipTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `relationshipTypeSearchRelationshipTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  relationshipTypeSearchRelationshipTypePost$Plain(params?: {
    body?: SearchRequestModel
  }): Observable<RelationshipTypeModelSearchResultModel> {

    return this.relationshipTypeSearchRelationshipTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<RelationshipTypeModelSearchResultModel>) => r.body as RelationshipTypeModelSearchResultModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `relationshipTypeSearchRelationshipTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  relationshipTypeSearchRelationshipTypePost$Json$Response(params?: {
    body?: SearchRequestModel
  }): Observable<MDIStrictHttpResponse<RelationshipTypeModelSearchResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIRelationshipTypeService.RelationshipTypeSearchRelationshipTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<RelationshipTypeModelSearchResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `relationshipTypeSearchRelationshipTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  relationshipTypeSearchRelationshipTypePost$Json(params?: {
    body?: SearchRequestModel
  }): Observable<RelationshipTypeModelSearchResultModel> {

    return this.relationshipTypeSearchRelationshipTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<RelationshipTypeModelSearchResultModel>) => r.body as RelationshipTypeModelSearchResultModel)
    );
  }

  /**
   * Path part for operation relationshipTypeIdGet
   */
  static readonly RelationshipTypeIdGetPath = '/RelationshipType/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `relationshipTypeIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  relationshipTypeIdGet$Plain$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<RelationshipTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIRelationshipTypeService.RelationshipTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<RelationshipTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `relationshipTypeIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  relationshipTypeIdGet$Plain(params: {
    id: string;
  }): Observable<RelationshipTypeModel> {

    return this.relationshipTypeIdGet$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<RelationshipTypeModel>) => r.body as RelationshipTypeModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `relationshipTypeIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  relationshipTypeIdGet$Json$Response(params: {
    id: string;
  }): Observable<MDIStrictHttpResponse<RelationshipTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, MDIRelationshipTypeService.RelationshipTypeIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<RelationshipTypeModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `relationshipTypeIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  relationshipTypeIdGet$Json(params: {
    id: string;
  }): Observable<RelationshipTypeModel> {

    return this.relationshipTypeIdGet$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<RelationshipTypeModel>) => r.body as RelationshipTypeModel)
    );
  }

  /**
   * Path part for operation relationshipTypePost
   */
  static readonly RelationshipTypePostPath = '/RelationshipType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `relationshipTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  relationshipTypePost$Plain$Response(params?: {
    body?: RelationshipTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIRelationshipTypeService.RelationshipTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `relationshipTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  relationshipTypePost$Plain(params?: {
    body?: RelationshipTypeModel
  }): Observable<string> {

    return this.relationshipTypePost$Plain$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `relationshipTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  relationshipTypePost$Json$Response(params?: {
    body?: RelationshipTypeModel
  }): Observable<MDIStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MDIRelationshipTypeService.RelationshipTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MDIStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `relationshipTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  relationshipTypePost$Json(params?: {
    body?: RelationshipTypeModel
  }): Observable<string> {

    return this.relationshipTypePost$Json$Response(params).pipe(
      map((r: MDIStrictHttpResponse<string>) => r.body as string)
    );
  }

}
