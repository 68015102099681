import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonSearchbar } from '@ionic/angular';
import { TitleHelperService } from 'src/app/services/title-helper/title-helper.service';
import { TranslatorService } from 'src/app/services/translate/translator.service';
import { Keyboard } from '@capacitor/keyboard';
import { Timer } from 'src/app/utilities';
import { Capacitor } from '@capacitor/core';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Subscription } from 'rxjs';
import { Language } from 'src/app/services/translate/interfaces/language.interface';
import { StringNormalize } from 'src/app/utilities/index';
import { TranslatorService as CoreTranslator } from 'src/app/core/translator/translator.service';

@Component({
	selector: 'app-language',
	templateUrl: './language.page.html',
	styleUrls: ['./language.page.scss']
})
export class LanguagePage implements OnInit, OnDestroy {
	@Input() isContentLanguage = false;

	@ViewChild('ionContent')
	public ionContent: IonContent;

	@ViewChild('searchBar')
	private searchBarControl: IonSearchbar;

	public searching = false;
	public selectedLanguage = 'en';
	public filteredLanguages: any = [];

	private currentLanguage: string;
	private subscriptions: Subscription = new Subscription();

	constructor(
		private translatorService: TranslatorService,
		private titleHelperService: TitleHelperService,
		private modalService: ModalService,
		public coreTranslator: CoreTranslator
	) {}

	public ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	public ngOnInit(): void {
		this.titleHelperService.setPageTitle('Language');
		if (this.isContentLanguage) {
			const contentLanguageSubscriber =
				this.translatorService.contentLanguageMapped$.subscribe(
					(language: string) => {
						this.setLanguages(language);
					}
				);

			this.subscriptions.add(contentLanguageSubscriber);
		} else {
			const languageSubscriber =
				this.translatorService.language$.subscribe(
					(language: string) => {
						this.setLanguages(language);
					}
				);
			this.subscriptions.add(languageSubscriber);
		}
	}

	public languageChanged(): void {
		if (
			this.selectedLanguage &&
			this.selectedLanguage != this.currentLanguage
		) {
			this.clearSearch();
			this.searchBarControl.value = null;
			if (this.isContentLanguage) {
				this.translatorService.setContentLanguage(
					this.selectedLanguage
				);
				this.coreTranslator.setContentLanguage(this.selectedLanguage);
			} else {
				this.translatorService.setLanguage(this.selectedLanguage);
				this.coreTranslator.useLanguage(this.selectedLanguage);
			}

			(this.ionContent as any).el.scroll({
				top: 0,
				behavior: 'smooth'
			});
		}
	}

	public focus(): void {
		if (Capacitor.isNativePlatform()) {
			document.documentElement.classList.add('keyboard-open');
		}
	}

	private setLanguages(selectedLanguage: string): void {
		this.currentLanguage = this.selectedLanguage = selectedLanguage;
		const languagesBySelected = this.isContentLanguage
			? this.translatorService.availableContentLanguages
			: this.translatorService.availableLanguages;

		if (!languagesBySelected || languagesBySelected.length == 0) return;

		this.filteredLanguages = this.sort(languagesBySelected);
	}

	public searchChange(event): void {
		const term = StringNormalize.ASCIIFolding(
			event.detail.value.trim() as string
		);

		let languages = this.isContentLanguage
			? this.translatorService.availableContentLanguages
			: this.translatorService.availableLanguages;

		languages = languages.filter(
			(l) =>
				StringNormalize.ASCIIFolding(l.name).startsWith(
					term.toLowerCase()
				) ||
				StringNormalize.ASCIIFolding(l.nativeName)?.startsWith(
					term.toLowerCase()
				)
		);

		this.filteredLanguages = this.sort(languages).filter((e) => e);
	}

	public async dismiss(): Promise<void> {
		if (document.documentElement.classList.contains('keyboard-open')) {
			await Keyboard.hide();
			await Timer.sleep(500);
		}
		void this.modalService.dismissModalAsync('LanguagePage');
		this.titleHelperService.setPageTitle();
	}

	public collapseKeyboard(): void {
		void Keyboard.hide();
	}

	public detectChar(): void {
		this.searching = this.searchBarControl.value != '';
	}

	public clearSearch(): void {
		this.searching = false;
	}

	private sort(language: Language[]) {
		// get the current language
		const ourLanguage = language.find(
			(element) => element.code == this.selectedLanguage
		);

		// remove from list
		language = language.filter(
			(element) => element.code != this.selectedLanguage
		);

		// sort alphabetical
		language = language.sort((a, b) => (a.name < b.name ? -1 : 1));

		// add current back to top
		language.unshift(ourLanguage);

		return language;
	}
}
