import { Injectable } from '@angular/core';
import {
	MatDialogRef,
	MatDialog,
	MatDialogConfig
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmPopupComponent } from 'src/app/shared/popups/confirm.popup.component';
import { InputPopupComponent } from 'src/app/shared/popups/input.popup.component';
import { ModalService } from '../modal/modal.service';

@Injectable()
export class PopupService {
	constructor(
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		protected _translate: TranslateService,
		private modalService: ModalService
	) {}
	public swal = require('sweetalert');

	showSuccessMessage(
		message: string,
		title: string = this._translate.instant('notification.SUCCESS')
	) {
		this.swal({
			title: title,
			text: message,
			icon: 'success',
			buttons: {
				confirm: {
					text: this._translate.instant('button.OK'),
					value: true,
					visible: true,
					className: 'bg-primary',
					closeModal: true
				}
			}
		});
	}

	showInfoMessage(
		message: string,
		title: string = this._translate.instant('notification.INFO')
	) {
		this.swal({
			title: title,
			text: message,
			icon: 'warning',
			buttons: {
				confirm: {
					text: this._translate.instant(
						'health-finder.book-appointment.dialog.BOOK'
					),
					value: true,
					visible: true,
					className: 'bg-primary',
					closeModal: true
				}
			}
		});
	}

	async showErrorMessage(
		errorMessage: any,
		title: string = this._translate.instant('notification.INFO'),
		errorDetails: string[] = []
	) {
		await this.swal({
			title: title,
			text: errorMessage,
			icon: 'error',
			buttons: {
				confirm: {
					text: this._translate.instant(
						'health-finder.book-appointment.dialog.BOOK'
					),
					value: true,
					visible: true,
					className: 'bg-primary',
					closeModal: true
				}
			}
		});
	}

	showConfirmMessage(
		message: any,
		title: string = this._translate.instant('notification.CONFIRM')
	): MatDialogRef<ConfirmPopupComponent> {
		return this.dialog.open(ConfirmPopupComponent, {
			autoFocus: true,
			width: '450px',
			data: {
				title,
				message,
				buttonCloseText: this._translate.instant('button.NO'),
				buttonProceedText: this._translate.instant('button.YES')
			},
			panelClass: 'notification-dialog-container'
		});
	}

	showInputMessage(
		placeholderText: string,
		title: string = this._translate.instant('notification.INPUT'),
		buttonText: string
	): MatDialogRef<InputPopupComponent> {
		return this.dialog.open(InputPopupComponent, {
			autoFocus: true,
			width: '450px',
			data: {
				title,
				placeholderText,
				buttonText,
				buttonProceedText: this._translate.instant('button.PROCEED')
			},
			panelClass: 'notification-dialog-container'
		});
	}

	openTimeoutDialog() {
		console.log('openTimeoutDialog');
		//return this.openDialog(TimeoutPopupComponent, {});
	}

	openDialog<DialogInputModel = null, DialogOutputModel = null>(
		component: any,
		data: DialogInputModel,
		options: MatDialogConfig
	): MatDialogRef<any, DialogOutputModel> {
		if (!options) {
			options = new MatDialogConfig();
			options.width = '70vw';
			options.height = '70vh';
		}
		options.panelClass = 'notification-dialog-container';
		options.data = data;
		return this.dialog.open<any, DialogInputModel, DialogOutputModel>(
			component as any,
			options
		);
	}

	notify(message: string, action: string) {
		this.snackBar.open(message, action, {
			duration: 3000
		});
	}
}
