import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslatorService } from 'src/app/services/translate/translator.service';

@Pipe({
	name: 'i18nFieldTranslate$'
})
export class I18nFieldTranslatePipe implements PipeTransform {
	constructor(private translator: TranslatorService) {}

	transform(value: any, fieldName: string): Observable<string> {
		return this.translator.contentLanguageFormatted$.pipe(
			map((language) => {
				const i18nFieldName = `${fieldName}_${language}`;

				if (!value) return '';

				if (
					Object.prototype.hasOwnProperty.call(
						value,
						'@search.highlights'
					)
				) {
					if (
						Object.prototype.hasOwnProperty.call(
							value['@search.highlights'],
							i18nFieldName
						)
					) {
						const hightlights =
							value['@search.highlights'][i18nFieldName];
						let result = value[i18nFieldName];
						for (let i = 0; i < hightlights.length; i++) {
							const highlight = hightlights[i];
							const actualText = highlight
								.replace(/<em class="search-highlight">/g, '')
								.replace(/<\/em>/g, '');

							result = result.replace(actualText, hightlights[i]);
						}
						return result as string;
					}
				}

				if (
					Object.prototype.hasOwnProperty.call(value, i18nFieldName)
				) {
					return value[i18nFieldName] as string;
				} else if (
					Object.prototype.hasOwnProperty.call(value, fieldName)
				) {
					return value[fieldName] as string;
				} else if (
					Object.prototype.hasOwnProperty.call(
						value,
						`${fieldName}_en`
					)
				) {
					return value[`${fieldName}_en`] as string;
				}
				return '';
			})
		);
	}
}
