/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MDIApiConfiguration, MDIApiConfigurationParams } from './mdi-api-configuration';

import { MDIContactPointTypeService } from './services/mdi-contact-point-type.service';
import { MDICountryService } from './services/mdi-country.service';
import { MDIEducationSubFieldService } from './services/mdi-education-sub-field.service';
import { MDILanguageService } from './services/mdi-language.service';
import { MDILocationPhysicalTypeService } from './services/mdi-location-physical-type.service';
import { MDILogoUseTypeService } from './services/mdi-logo-use-type.service';
import { MDIMasterDataService } from './services/mdi-master-data.service';
import { MDIMedicalAidService } from './services/mdi-medical-aid.service';
import { MDIOrganizationRoleService } from './services/mdi-organization-role.service';
import { MDIOrganizationTypeService } from './services/mdi-organization-type.service';
import { MDIPracticeTypeService } from './services/mdi-practice-type.service';
import { MDIProfessionService } from './services/mdi-profession.service';
import { MDIPublicHolidayService } from './services/mdi-public-holiday.service';
import { MDIQualificationService } from './services/mdi-qualification.service';
import { MDIQualificationTypeService } from './services/mdi-qualification-type.service';
import { MDIRelationshipTypeService } from './services/mdi-relationship-type.service';
import { MDIServiceCategoryService } from './services/mdi-service-category.service';
import { MDIServiceDeliveryLocationRoleTypeService } from './services/mdi-service-delivery-location-role-type.service';
import { MDIServiceTypeService } from './services/mdi-service-type.service';
import { MDISpecialityService } from './services/mdi-speciality.service';
import { MDISystemIdentifierService } from './services/mdi-system-identifier.service';
import { MDITitleService } from './services/mdi-title.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    MDIContactPointTypeService,
    MDICountryService,
    MDIEducationSubFieldService,
    MDILanguageService,
    MDILocationPhysicalTypeService,
    MDILogoUseTypeService,
    MDIMasterDataService,
    MDIMedicalAidService,
    MDIOrganizationRoleService,
    MDIOrganizationTypeService,
    MDIPracticeTypeService,
    MDIProfessionService,
    MDIPublicHolidayService,
    MDIQualificationService,
    MDIQualificationTypeService,
    MDIRelationshipTypeService,
    MDIServiceCategoryService,
    MDIServiceDeliveryLocationRoleTypeService,
    MDIServiceTypeService,
    MDISpecialityService,
    MDISystemIdentifierService,
    MDITitleService,
    MDIApiConfiguration
  ],
})
export class MDIModule {
  static forRoot(params: MDIApiConfigurationParams): ModuleWithProviders<MDIModule> {
    return {
      ngModule: MDIModule,
      providers: [
        {
          provide: MDIApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: MDIModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('MDIModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
