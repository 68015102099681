export const variables = {
	signin: 'b2c_1a_terra_auth_susi'
};
export const environment = {
	production: false,
	terraAppBaseURL: 'https://uat-medadvisor.bic.health/medical-advisor',
	version: '1.0',
	build: 'dev build', // do not change this, it is detected/changed with a pipeline
	appName: 'Terra',
	correlationHeaderDomains: ['localhost'],
	authenticateOptions: {
		logoutUrl: `https://bicsol.b2clogin.com/bicsol.onmicrosoft.com/${variables.signin}/oauth2/v2.0/logout`,
		appId: 'df248fa2-20d2-449e-8979-100b1a4d2680',
		authorizationBaseUrl: `https://bicsol.b2clogin.com/bicsol.onmicrosoft.com/${variables.signin}/oauth2/v2.0/authorize`,
		scope: 'openid profile email offline_access https://bicsol.onmicrosoft.com/df248fa2-20d2-449e-8979-100b1a4d2680/Terra_Access', // See Azure Portal -> API permission
		accessTokenEndpoint: `https://bicsol.b2clogin.com/bicsol.onmicrosoft.com/${variables.signin}/oauth2/v2.0/token`,
		responseType: 'code',
		pkceEnabled: true,
		logsEnabled: true,
		web: {
			redirectUrl: window.location.origin + '/home',
			windowTarget: '_self'
		},
		android: {
			handleResultOnNewIntent: true,
			redirectUrl: 'msauth://terra.app/GlmFTMxErZ5Uwucy8J%2BM0AEOnEA%3D' // See Azure Portal -> Authentication -> Android Configuration "Redirect URI"
		},
		ios: {
			pkceEnabled: true,
			redirectUrl: 'msauth.terra.app://auth'
		},
		additionalParameters: {
			// ui_locales: 'en',
			// ui_platform: 'web',
			prompt: 'select_account',
			nonce: 'defaultNonce'
		}
	},

	refreshOptions: {
		appId: 'df248fa2-20d2-449e-8979-100b1a4d2680',
		accessTokenEndpoint: `https://bicsol.b2clogin.com/bicsol.onmicrosoft.com/${variables.signin}/oauth2/v2.0/token`
	},

	authority:
		'https://login.microsoftonline.com/c24c432d-64f7-4a10-8bc5-3767882ea65b/v2.0',
	clientId: 'b4d265ff-f253-4756-bb24-8675f9dbd6c1',
	scope: 'https://bicsol.onmicrosoft.com/app-media-api/app-media-api',
	authWellknownEndpointUrl:
		'https://bicsol.b2clogin.com/bicsol.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_TERRA_AUTH_SUSI',

	mpiBaseUrl: 'https://dev-api.bic.health/mpi',
	mdiBaseUrl: 'https://dev-api.bic.health/mdi',
	mediaBaseUrl: 'https://dev-api.bic.health/media',
	organizationBaseUrl: 'https://dev-api.bic.health/organization',
	practitionerBaseUrl: 'https://dev-api.bic.health/practitioner',
	authBaseUrl: 'https://dev-api.bic.health/authorization',
	calendarBaseUrl: 'https://dev-api.bic.health/calendar',
	healthFindrBaseUrl: 'https://dev-api.bic.health/healthFindr',

	secureRoutes: [
		'https://localhost:7239', //HealthFindr Config
		'https://localhost:7235', //Calendar
		'https://localhost:7234', //Authorization
		'https://localhost:7233', //Practitioner
		'https://localhost:7232', //Organization
		'https://localhost:7231', //MDI
		'https://localhost:7230', //MPI
		'https://localhost:7229', //Media
		'https://dev-api.bic.health/' //api endppoint lists or all api gateway endpoints
	],

	/*
	 *  Default connections for MDI, MPI, Organization, Media, Practitioner, Authorization, Calendar
	 *  https://dev-api.bic.health/mpi
	 *  https://dev-api.bic.health/mdi
	 *  https://dev-api.bic.health/organization
	 *  https://dev-api.bic.health/media
	 *  https://dev-api.bic.health/practitioner
	 *  https://dev-api.bic.health/authorization
	 *  https://dev-api.bic.health/calendar
	 */

	googleApiKey: 'AIzaSyDA9X-PsRsKFMN5atzaxdLqzweEKuYpENo',

	/* Environment variable to specify if the environment is in debug or not */
	isDebug: true,

	availablelanguages: [
		{
			code: 'en',
			name: 'English',
			nativeName: 'English',
			isRightToLeft: false,
			contentSupport: true
		},
		{
			code: 'ar',
			name: 'عربي',
			nativeName: 'العربية',
			isRightToLeft: true,
			contentSupport: true,
			fontFamily: 'Noto Sans Arabic',
			fontSize: ['8vw', '3vh'],
			lineHeight: [
				'30px',
				'28px',
				'25px',
				'20px',
				'17px',
				'11px',
				'19px'
			],
			textRevealClientHeightAdd: '2px'
		},
		{
			code: 'af',
			name: 'Afrikaans',
			nativeName: 'Afrikaans',
			contentSupport: true,
			isRightToLeft: false,
			fontFamily: 'ApaxThree'
		},
		{
			code: 'nl',
			name: 'Dutch',
			nativeName: 'Nederlands',
			contentSupport: true,
			isRightToLeft: false,
			fontFamily: 'ApaxThree'
		},
		{
			code: 'fr',
			name: 'French',
			nativeName: 'français',
			contentSupport: true,
			isRightToLeft: false,
			fontFamily: 'ApaxThree'
		},
		{
			code: 'de',
			name: 'German',
			nativeName: 'Deutsch',
			contentSupport: true,
			isRightToLeft: false,
			fontFamily: 'ApaxThree'
		},
		{
			code: 'zu',
			name: 'Zulu',
			nativeName: 'isiZulu',
			contentSupport: true,
			isRightToLeft: false,
			fontFamily: 'ApaxThree'
		}
	]
};
