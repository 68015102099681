import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { BookmarkService } from '../bookmark/bookmark.service';
import ContentMock from '../../utilities/dummy-data/content.json';

@Injectable({
	providedIn: 'root'
})
export class GrowService {
	public InProgress: any[] = [];
	public Completed: any[] = [];
	public Saved: any[] = [];

	private inProgressUpdating = false;
	private completedUpdating = false;
	private savedUpdating = false;

	constructor(private bookmarkService: BookmarkService) {}

	public initialize(): void {
		combineLatest([this.bookmarkService.completed$]).subscribe(
			([completed]) => {
				if (this.completedUpdating) return;
				this.completedUpdating = true;
				this.Completed = this.getArticles(completed, this.Completed);
				this.completedUpdating = false;
			}
		);

		combineLatest([this.bookmarkService.inProgress$]).subscribe(
			([inProgress]) => {
				if (this.inProgressUpdating) return;
				this.inProgressUpdating = true;
				this.InProgress = this.getArticles(inProgress, this.InProgress);
				this.inProgressUpdating = false;
			}
		);

		combineLatest([this.bookmarkService.saved$]).subscribe(([saved]) => {
			if (this.savedUpdating) return;
			this.savedUpdating = true;
			this.Saved = this.getArticles(saved, this.Saved);
			this.savedUpdating = false;
		});
	}

	private getArticles(bookmarks: any[], contents: any[]) {
		let cloneContents = [...contents];
		const contentIdsToLoad = bookmarks
			.filter(
				(bookmark) =>
					!cloneContents
						.map((content) => content.Id)
						.includes(bookmark.id)
			)
			.map((bookmark) => bookmark.id);

		if (contentIdsToLoad.length > 0) {
			cloneContents.push(
				...this.loadArticleByIds(contentIdsToLoad, false)
			);
		}

		cloneContents = cloneContents.sort((a, b) => {
			return a.Id < b.Id ? -1 : 1;
		});

		for (const element of bookmarks) {
			const contentResult = cloneContents.find((a) => a.Id == element.id);
			if (!contentResult) continue;

			contentResult.progress = element.progress;
			contentResult.date = element.date;
			contentResult.ImageUrl = element.imageUrl;
		}

		cloneContents = cloneContents.filter(
			(v, i) => cloneContents.findIndex((item) => item.Id == v.Id) === i
		);
		cloneContents = cloneContents.filter((ar) =>
			bookmarks.find((rm) => rm.id === ar.Id)
		);

		return cloneContents;
	}

	private loadArticleByIds(ids: string[], withContent = true): any {
		const articles = [];
		ids.forEach((id) => {
			const article = ContentMock.find((content) => content.Id == id);
			if (article) articles.push(article);
		});
		return articles;
	}
}
