import {
	Component,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	ViewChild
} from '@angular/core';
import type { OnInit } from '@angular/core';
import { KeyValueModel } from 'src/app/api/proxy/mdi/mdi-models';
import { ModalBaseComponent } from '../../base/modal-base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TranslatorService } from 'src/app/services/translate/translator.service';
import { ToastController } from '@ionic/angular';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { Location } from '@angular/common';

@Component({
	selector: 'app-typeahead',
	styleUrls: ['typeahead.component.scss'],
	templateUrl: 'typeahead.component.html'
})
export class TypeaheadComponent extends ModalBaseComponent implements OnInit {
	@Input() items: KeyValueModel[] = [];
	@Input() selectedItems: KeyValueModel[] = [];
	@Input() title = 'Select Items';

	@Output() selectionCancel = new EventEmitter<void>();
	@Output() selectionChange = new EventEmitter<KeyValueModel[]>();

	@ViewChild('typeaheadForm') typeaheadForm!: NgForm;
	public modalForm!: FormGroup;

	filteredItems: KeyValueModel[] = [];
	workingSelectedValues: KeyValueModel[] = [];

	constructor(
		protected _changeDetectorRef: ChangeDetectorRef,
		protected _route: ActivatedRoute,
		protected _router: Router,
		protected _location: Location,
		protected _formBuilder: FormBuilder,
		protected _translate: TranslateService,
		public _translator: TranslatorService,
		public themeService: ThemeService,
		protected _toastController: ToastController
	) {
		super(
			_changeDetectorRef,
			_router,
			_location,
			_toastController,
			_translate,
			_translator
		);
	}

	ngOnInit() {
		this.filteredItems = [...this.items];
		this.workingSelectedValues = [...this.selectedItems];

		this.modalForm = this._formBuilder.group({
			key: [null],
			value: [null]
		});

		if (this.workingSelectedValues && this.workingSelectedValues[0])
			this.updateForm(this.modalForm, {
				key: this.workingSelectedValues[0].key,
				value: this.workingSelectedValues[0].value
			});
	}

	trackItems(index: number, item: Item) {
		return item.value;
	}

	cancelChanges() {
		this.selectionCancel.emit();
	}

	confirmChanges() {
		this.selectionChange.emit(this.workingSelectedValues);
	}

	searchbarInput(ev) {
		this.filterList(ev.target.value);
	}

	/**
	 * Update the rendered view with
	 * the provided search query. If no
	 * query is provided, all data
	 * will be rendered.
	 */
	filterList(searchQuery: string | undefined) {
		/**
		 * If no search query is defined,
		 * return all options.
		 */
		if (searchQuery === undefined) {
			this.filteredItems = [...this.items];
		} else {
			/**
			 * Otherwise, normalize the search
			 * query and check to see which items
			 * contain the search query as a substring.
			 */
			const normalizedQuery = searchQuery.toLowerCase();
			this.filteredItems = this.items.filter((item) => {
				return item.value.toLowerCase().includes(normalizedQuery);
			});
		}
	}

	focusRecord(record) {
		this.workingSelectedValues = [];
		this.workingSelectedValues.push(record);
		this.confirmChanges();
	}
}

export interface Item {
	text: string;
	value: string;
}
