/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MediaBaseService } from '../media-base-service';
import { MediaApiConfiguration } from '../media-api-configuration';
import { MediaStrictHttpResponse } from '../media-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ArticleSearchModel } from '../models/article-search-model';
import { LanguageModel } from '../models/language-model';
import { SearchCategoryModel } from '../models/search-category-model';
import { SearchResultModel } from '../models/search-result-model';

@Injectable({
  providedIn: 'root',
})
export class MediaSearchService extends MediaBaseService {
  constructor(
    config: MediaApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation searchClientIdLanguageGet
   */
  static readonly SearchClientIdLanguageGetPath = '/Search/{clientId}/Language';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchClientIdLanguageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchClientIdLanguageGet$Plain$Response(params: {
    clientId: string;
  }): Observable<MediaStrictHttpResponse<Array<LanguageModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaSearchService.SearchClientIdLanguageGetPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<LanguageModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchClientIdLanguageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchClientIdLanguageGet$Plain(params: {
    clientId: string;
  }): Observable<Array<LanguageModel>> {

    return this.searchClientIdLanguageGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<LanguageModel>>) => r.body as Array<LanguageModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchClientIdLanguageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchClientIdLanguageGet$Json$Response(params: {
    clientId: string;
  }): Observable<MediaStrictHttpResponse<Array<LanguageModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaSearchService.SearchClientIdLanguageGetPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<LanguageModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchClientIdLanguageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchClientIdLanguageGet$Json(params: {
    clientId: string;
  }): Observable<Array<LanguageModel>> {

    return this.searchClientIdLanguageGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<LanguageModel>>) => r.body as Array<LanguageModel>)
    );
  }

  /**
   * Path part for operation searchClientIdCategoriesLangugeIsoCodeGet
   */
  static readonly SearchClientIdCategoriesLangugeIsoCodeGetPath = '/Search/{clientId}/Categories/{langugeIsoCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchClientIdCategoriesLangugeIsoCodeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchClientIdCategoriesLangugeIsoCodeGet$Plain$Response(params: {
    clientId: string;
    langugeIsoCode: string;
  }): Observable<MediaStrictHttpResponse<Array<SearchCategoryModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaSearchService.SearchClientIdCategoriesLangugeIsoCodeGetPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.path('langugeIsoCode', params.langugeIsoCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<SearchCategoryModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchClientIdCategoriesLangugeIsoCodeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchClientIdCategoriesLangugeIsoCodeGet$Plain(params: {
    clientId: string;
    langugeIsoCode: string;
  }): Observable<Array<SearchCategoryModel>> {

    return this.searchClientIdCategoriesLangugeIsoCodeGet$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<SearchCategoryModel>>) => r.body as Array<SearchCategoryModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchClientIdCategoriesLangugeIsoCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchClientIdCategoriesLangugeIsoCodeGet$Json$Response(params: {
    clientId: string;
    langugeIsoCode: string;
  }): Observable<MediaStrictHttpResponse<Array<SearchCategoryModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaSearchService.SearchClientIdCategoriesLangugeIsoCodeGetPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.path('langugeIsoCode', params.langugeIsoCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<SearchCategoryModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchClientIdCategoriesLangugeIsoCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchClientIdCategoriesLangugeIsoCodeGet$Json(params: {
    clientId: string;
    langugeIsoCode: string;
  }): Observable<Array<SearchCategoryModel>> {

    return this.searchClientIdCategoriesLangugeIsoCodeGet$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<SearchCategoryModel>>) => r.body as Array<SearchCategoryModel>)
    );
  }

  /**
   * Path part for operation searchClientIdPost
   */
  static readonly SearchClientIdPostPath = '/Search/{clientId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchClientIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchClientIdPost$Plain$Response(params: {
    clientId: string;
    body?: ArticleSearchModel
  }): Observable<MediaStrictHttpResponse<Array<SearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaSearchService.SearchClientIdPostPath, 'post');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<SearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchClientIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchClientIdPost$Plain(params: {
    clientId: string;
    body?: ArticleSearchModel
  }): Observable<Array<SearchResultModel>> {

    return this.searchClientIdPost$Plain$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<SearchResultModel>>) => r.body as Array<SearchResultModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchClientIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchClientIdPost$Json$Response(params: {
    clientId: string;
    body?: ArticleSearchModel
  }): Observable<MediaStrictHttpResponse<Array<SearchResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaSearchService.SearchClientIdPostPath, 'post');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as MediaStrictHttpResponse<Array<SearchResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchClientIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchClientIdPost$Json(params: {
    clientId: string;
    body?: ArticleSearchModel
  }): Observable<Array<SearchResultModel>> {

    return this.searchClientIdPost$Json$Response(params).pipe(
      map((r: MediaStrictHttpResponse<Array<SearchResultModel>>) => r.body as Array<SearchResultModel>)
    );
  }

  /**
   * Path part for operation searchClientLibraryClientIdGet
   */
  static readonly SearchClientLibraryClientIdGetPath = '/Search/ClientLibrary/{clientId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchClientLibraryClientIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchClientLibraryClientIdGet$Response(params: {
    clientId: string;
  }): Observable<MediaStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaSearchService.SearchClientLibraryClientIdGetPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as MediaStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchClientLibraryClientIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchClientLibraryClientIdGet(params: {
    clientId: string;
  }): Observable<void> {

    return this.searchClientLibraryClientIdGet$Response(params).pipe(
      map((r: MediaStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation searchSearchPartialClientIdGet
   */
  static readonly SearchSearchPartialClientIdGetPath = '/Search/SearchPartial/{clientId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchSearchPartialClientIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSearchPartialClientIdGet$Response(params: {
    clientId: string;
  }): Observable<MediaStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaSearchService.SearchSearchPartialClientIdGetPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as MediaStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchSearchPartialClientIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSearchPartialClientIdGet(params: {
    clientId: string;
  }): Observable<void> {

    return this.searchSearchPartialClientIdGet$Response(params).pipe(
      map((r: MediaStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation searchClientPageClientIdGet
   */
  static readonly SearchClientPageClientIdGetPath = '/Search/ClientPage/{clientId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchClientPageClientIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchClientPageClientIdGet$Response(params: {
    clientId: string;
  }): Observable<MediaStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaSearchService.SearchClientPageClientIdGetPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as MediaStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchClientPageClientIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchClientPageClientIdGet(params: {
    clientId: string;
  }): Observable<void> {

    return this.searchClientPageClientIdGet$Response(params).pipe(
      map((r: MediaStrictHttpResponse<void>) => r.body as void)
    );
  }

}
